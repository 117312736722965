import Vue from 'vue'
import Vuex from 'vuex'
import api from "./api/api";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        appIsLoading: true,
        snackbar: {
            active: false,
            color: "primary",
            timeout: 6000,
            title: "",
            text: "",
            url: "",
            btnText: "",
            actionClick: () =>
            {
            }
        },
        dialog: {
            active: false,
            title: "",
            text: "",
            closeBtnText: "",
            actionBtnText: "",
            alert: null,
            actionClick: () =>
            {
            }
        },
        unreadMessages: 0,
        globalOverlay: {
            show: false,
            opacity: 0.8
        },
        tourActive: false,
        editTour: false,
        choosingTourTarget: false,
        tours: [],
        currentRoute: "",
        welcomeMessageShown: false,
    },
    mutations: {
        updateUser(state, user)
        {
            state.user = user
        },
        deleteUser(state)
        {
            state.user = undefined
        },
        setAppIsLoading(state, isLoading)
        {
            state.appIsLoading = isLoading
        },
        setGlobalOverlay(state, overlay)
        {
            let opacity = overlay.opacity ? overlay.opacity : 0.8
            overlay.opacity = opacity
            state.globalOverlay = overlay
        },
        setSnackbar(state, snackbar)
        {
            // Default values
            let active = typeof snackbar.active !== "undefined" ? snackbar.active : true
            let color = snackbar.color ? snackbar.color : "primary"
            let timeout = snackbar.timeout ? snackbar.timeout : 6000 // 6 sec
            let text = snackbar.text ? snackbar.text : "Viktigt meddelande"
            let actionClick = snackbar.actionClick ? snackbar.actionClick : null

            // hide snackbar incase it is already showing
            state.snackbar.active = false

            // then we set the snackbar with proper values after a small delay
            window.setTimeout(() =>
            {
                state.snackbar = {active: active, color: color, timeout: timeout, title: snackbar.title, text: text, url: snackbar.url, btnText: snackbar.btnText, actionClick: actionClick}
            }, 100)
        },
        setDialog(state, dialog) // dialog is used instead of confirmation alerts (e.g when confirming to delete an item)
        {
            // Default values
            let active = typeof dialog.active !== "undefined" ? dialog.active : true
            dialog.active = active

            // hide dialog incase it is already showing (should not happen)
            state.dialog.active = false

            // then we set the dialog with proper values after a small delay
            window.setTimeout(() =>
            {
                state.dialog = dialog
            }, 100)
        },
        setUnreadMessages(state, unreadMessages)
        {
            state.unreadMessages = unreadMessages
        },
        setEditTour(state, edit)
        {
            state.editTour = edit
        },
        setChoosingTourTarget(state, val)
        {
            state.choosingTourTarget = val
        },
        setTours(state, val)
        {
            state.tours = val
        },
        setTourActive(state, active)
        {
            state.tourActive = active
        },
        setCurrentRoute(state, name)
        {
            state.currentRoute = name
        },
        setWelcomeMessageShown(state, shown)
        {
            state.welcomeMessageShown = shown
        },
    },
    actions: {
        async updateUnreadMessages(context, user)
        {
            let serverResponse = await api.getFromEndpoint(
                api.ENDPOINTS.USERACCOUNTS + "/" + user.id + "/" +
                api.ENDPOINTS.MESSAGES + "/" +
                api.ENDPOINTS.RECEIVED +
                "?onlyUnread=true"
            )
            if (serverResponse)
            {
                context.commit('setUnreadMessages', serverResponse.length)
            }
        },
    }
})
