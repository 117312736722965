<template>
  <v-list three-line v-if="bookingPeriods.length > 0">
    <v-list-item
        :key="item.name + item.id"
        :to="{name: 'bookingPeriodDetails', params:{id: item.id}}"
        v-for="item in bookingPeriods"
    >
      <v-list-item-content>
        <v-list-item-title><b>{{ item.name }}</b>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small class="mr-1" color="grey">event</v-icon>
          <v-chip outlined small>
            {{ getFormattedDate(item.calendarEvent.start) }}
          </v-chip>
          -
          <v-chip outlined small>
            {{ getFormattedDate(item.calendarEvent.end) }}
          </v-chip>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="pt-2">
          <p>{{ item.notes }}</p>
        </v-list-item-subtitle>
        <customer-autocomplete-component
            :value-object="item"
            class="mt-2"
            fetch-async
            multiple
            read-only
            style="max-width: 500px;"
            v-if="item.status > bookingPeriodStatuses.STATUSES_OBJ.OPEN.value"
        ></customer-autocomplete-component>
      </v-list-item-content>
      <v-list-item-action-text class="text-right">
        <p>
          <v-tooltip :disabled="Number.parseInt(item.price) === 0" bottom>
            <template v-slot:activator="{ on }">
              <v-chip class="mr-2" label outlined small v-on="on">
                <v-icon class="mr-2" small>mdi-tag-outline</v-icon>
                <span v-if="Number.parseInt(item.price) === 0">Inget pris satt</span>
                <span v-else>{{ item.priceSeperated }} SEK</span>
              </v-chip>
            </template>
            <span>Pris</span>
          </v-tooltip>
          <v-tooltip bottom :disabled="getCurrentBalanceIsZero(item)" v-if="item.paymentsInitiatedAt">
            <template v-slot:activator="{ on }">
              <v-chip small class="mr-2" outlined :color="getBalanceColor(item)"
                      :input-value="getCurrentBalanceIsZero(item)" filter label
                      v-on="on">
                <v-icon class="mr-2" v-if="!getCurrentBalanceIsZero(item)" small>mdi-scale-balance</v-icon>
                <span>{{ getCurrentBalanceIsZero(item) ? 'Betald' : item.currentBalance }}</span>
              </v-chip>
            </template>
            <span>Kvar att betala</span>
          </v-tooltip>
          <v-tooltip bottom v-if="getDuePaymentRequestCount(item) > 0">
            <template v-slot:activator="{ on }">
              <v-chip color="error" label small v-on="on">
                <v-icon small>mdi-credit-card-clock-outline</v-icon>
              </v-chip>
            </template>
            <span>{{ getDuePaymentRequestText(item) }}</span>
          </v-tooltip>
        </p>
        {{ bookingPeriodStatuses.STATUSES[item.status].text }}
        <v-avatar>
          <v-icon :color="bookingPeriodStatuses.STATUSES[item.status].color">
            {{ bookingPeriodStatuses.STATUSES[item.status].icon }}
          </v-icon>
        </v-avatar>
        <p>
          <booking-object-item-component :booking-object="item.bookingObject" class="truncate" hide-avatar
                                         hide-notes></booking-object-item-component>
          <booking-object-item-component :booking-object="item.bookingObject" hide-notes
                                         hide-name></booking-object-item-component>
        </p>
      </v-list-item-action-text>
    </v-list-item>
  </v-list>
  <no-data-component class="pb-2 pt-1" icon="mdi-calendar-remove-outline"
                     text="Det finns inga uthyrningsperioder att visa"
                     v-else-if="viewOnlyList"></no-data-component>
  <no-data-component class="pb-2 pt-1" icon="search" text="Inga uthyrningsperioder hittade med ditt angivna filter. Ändra dina visningsalternativ eller använd
            plus-knappen längst upp till höger för att lägga till en ny period" v-else></no-data-component>
</template>

<script>
import NoDataComponent from "../../components/global/NoDataComponent";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import BookingObjectItemComponent from "../bookingobject/BookingObjectItemComponent";
import paymentStatuses from "../../models/paymentStatuses";
import CustomerAutocompleteComponent from "@/components/customer/CustomerAutocompleteComponent";

export default {
  components: {CustomerAutocompleteComponent, BookingObjectItemComponent, NoDataComponent},
  data: () => ({
    bookingPeriodStatuses: bookingPeriodStatuses
  }),
  props: {
    viewOnlyList: Boolean,
    bookingPeriodsDisplayOptions: Object,
    showNewPeriodButton: Boolean,
    bookingPeriods: Array
  },
  methods: {
    getFormattedDate(date)
    {
      return this.$moment(date).format('lll')
    },
    getCurrentBalanceIsZero(bookingPeriod)
    {
      return bookingPeriod.currentBalance === 0
    },
    getBalanceColor(bookingPeriod)
    {
      return this.getCurrentBalanceIsZero(bookingPeriod) ? 'success' : 'error'
    },
    getDuePaymentRequestCount(bookingPeriod)
    {
      if (bookingPeriod.paymentRequests)
      {
        return bookingPeriod.paymentRequests.filter(req => req.status === paymentStatuses.STATUSES.PAYMENT_DUE.value).length
      }
      return 0
    },
    getDuePaymentRequestText(bookingPeriod)
    {
      let text = `Denna period har ${this.getDuePaymentRequestCount(bookingPeriod)} `
      text += this.getDuePaymentRequestCount(bookingPeriod) === 1 ? 'förfallen betalningsförfrågan!' : 'förfallna betalningsförfrågningar!'
      return text
    }
  }
}
</script>

<style scoped>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
