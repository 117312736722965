<template>
    <div>
        <v-bottom-sheet hide-overlay no-click-animation persistent :retain-focus="false" v-model="editTour">
            <v-card :loading="loading" :disabled="loading" style="overflow-x: auto; overflow-y: hidden;" tile>
                <v-list>
                    <v-list-item>
                        <v-list-item-icon
                        >
                            <v-btn @click="$store.commit('setEditTour', false)" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                        <div :key="'step_'+index" v-for="(step, index) in currentSteps">
                            <v-hover v-slot:default="{ hover }">
                                <v-card :class="['step-card', (currentStep === index && tourActive ? ' highlight-pulse' : '')]" :elevation="hover ? '2' :''"
                                        @click="editStep(step, index)"
                                        outlined
                                >
                                    <v-card-title>
                                        {{index + 1}}
                                        <v-spacer></v-spacer>
                                    </v-card-title>
                                    <v-card-text style="max-height: 300px">
                                        {{step.header.title}}
                                    </v-card-text>
                                    <v-fade-transition>
                                        <v-overlay
                                                absolute
                                                color="secondary"
                                                opacity="0.1"
                                                v-if="currentStep === index && tourActive"
                                        ></v-overlay>
                                    </v-fade-transition>
                                </v-card>
                            </v-hover>
                        </div>
                        <v-hover v-slot:default="{ hover }">
                            <v-card :elevation="hover ? '2' :''" @click="newStep" class="step-card" outlined
                            >
                                <v-card-subtitle>
                                    Nytt steg
                                </v-card-subtitle>
                                <v-card-text class="text-center mt-6">
                                    <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                        <v-spacer></v-spacer>
                        <v-list-item-icon class="d-block mr-6">
                            <v-btn color="error" style="width: 200px" class="mt-3 mr-2" v-if="showDelete" @click="deleteTour">
                                Ta bort
                            </v-btn>
                            <v-btn :disabled="steps.length <= 0" style="width: 200px" class="mt-3 mr-2" @click="$tours['walkthroughTour'].start()">
                                Förhandsgranska
                            </v-btn>
                            <v-btn @click="saveTour" style="width: 200px" class="mt-3" :disabled="steps.length <= 0" color="primary">
                                Spara
                            </v-btn>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-bottom-sheet>
        <v-dialog max-width="600px" persistent v-model="dialog">
            <step-details-component @delete="deleteStep" :step="selectedStep" :step-index="currentStep" @cancel="dialog = false"
                                    @chooseTarget="chooseTarget" @save="saveStep" :show-cancel="newStepSelected"></step-details-component>
        </v-dialog>
        <target-choice-component @targetChosen="targetChosen" v-if="choosingTarget"></target-choice-component>
    </div>
</template>

<script>
    import StepDetailsComponent from "./StepDetailsComponent";
    import TargetChoiceComponent from "./TargetChoiceComponent";

    export default {
        components: {TargetChoiceComponent, StepDetailsComponent},
        props: {
            steps: Array,
            currentStep: Number,
            tourActive: Boolean,
            loading: Boolean,
            showDelete: Boolean,
        },
        computed: {
            currentSteps: {
                get()
                {
                    return this.steps
                },
                set(val)
                {
                    this.$emit('update:steps', val)
                }
            },
            editTour()
            {
                return this.$store.state.editTour && this.$store.state.user.systemAdmin === 1
            },
            choosingTarget()
            {
                return this.$store.state.choosingTourTarget
            },
        },
        data: () => ({
            selectedStep: null,
            dialog: false,
            newStepSelected: false
        }),
        mounted()
        {
        },
        methods: {
            editStep(step, index)
            {
                step.index = index
                if(!step.header)
                {
                    step.header = {title: ""}
                }
                this.selectedStep = step
                this.newStepSelected = false
                this.dialog = true
            },
            newStep()
            {
                this.selectedStep = {
                    target: null,
                    content: '',
                    header: {
                        title: ""
                    },
                    params: {
                        placement: 'top',
                        enableScrolling: false
                    }
                }
                this.newStepSelected = true
                this.dialog = true
            },
            saveStep(step)
            {
                if (this.newStepSelected)
                {
                    this.currentSteps.push(step)
                }
                this.dialog = false
            },
            deleteStep(index)
            {
                this.currentSteps.splice(index, 1)
                this.dialog = false
            },
            chooseTarget()
            {
                this.dialog = false
                this.$store.commit('setEditTour', false)
                setTimeout(() => this.$store.commit('setChoosingTourTarget', true), 250)
            },
            targetChosen(target)
            {
                this.dialog = true
                this.$store.commit('setEditTour', true)
                this.$store.commit('setChoosingTourTarget', false)
                this.selectedStep.target = target
            },
            saveTour()
            {
                this.$emit('saved')
            },
            deleteTour()
            {
                this.$emit('deleted')
            }
        }
    }
</script>

<style scoped>
    .step-card {
        width: 240px;
        min-width: 240px;
        height: 200px;
        margin: 15px;
    }

    .step-card-text {
        max-height: 130px;
        overflow: hidden;
    }
</style>
