<template>
  <div class="my-2">
    <v-img
        class="mx-auto"
        :src="imageUrl"
        :alt="data.name"
        :width="data.width + '%'"
        :max-height="data.height ? data.height + 'px' : null"
        :contain="data.contain"
    ></v-img>
  </div>
</template>

<script>

import api from "@/api/api";

export default {
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
    imageUrl()
    {
      return api.BASE_URL + this.data.bookingObjectFile.fileData.src
    },
  }
}
</script>
