<template>
  <div>
    <v-slide-x-transition mode="out-in">
      <v-layout style="height: 90vh" align-content-center justify-center align-center v-if="errorStatus.msg || success.msg">
        <v-flex lg6 xs12>
          <v-alert class="elevation-6" prominent type="error" v-if="errorStatus.msg">
            <h3>Något gick fel</h3>
            <p>{{ errorStatus.msg }}</p>
          </v-alert>
          <v-alert class="elevation-6" prominent type="success" v-else-if="success.msg">
            <template v-slot:prepend>
              <v-btn class="mr-6 ml-2 highlight-pulse" icon>
                <v-icon x-large>mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <h3>{{ success.title }}</h3>
            <p>{{ success.msg }}</p>
          </v-alert>
        </v-flex>
      </v-layout>
      <v-card v-else-if="bookingObject" :flat="flat">
        <v-toolbar flat v-if="showLogo">
          <logo-component class="ml-2" color="#8380b6" height="40px"
                          width="40px"></logo-component>
        </v-toolbar>
        <v-img
            :src="getImageUrl(bookingObject)"
            max-height="420"
            v-if="bookingObject.fileData && externalBookerSettings.showImage && !isEmbedded"
        ></v-img>
        <div v-if="externalBookerSettings.showDescription && !isEmbedded">
          <v-card-title>Boka {{ bookingObject.name }}</v-card-title>
          <v-card-text>
            <div style="white-space: pre-line;">{{ bookingObject.description }}</div>
          </v-card-text>
        </div>
        <v-divider class="mx-4" v-if="showLogo"></v-divider>
        <v-card-title>{{ externalBookerSettings.title }}</v-card-title>
        <v-card-subtitle class="pt-3 mb-3" style="white-space: pre-line;">{{ externalBookerSettings.subtitle }}
        </v-card-subtitle>
        <v-card-text :class="isEmbedded ? 'px-1' : ''" style="min-height: 400px;">
          <v-progress-linear :active="loadingCalendar" indeterminate></v-progress-linear>
          <booking-calendar-component
              :free-events="freeEvents"
              :occupied-events="occupiedEvents"
              @change="getFreeAndOccupiedEvents"
              @select="showConfirmBooking"
              @setSnackbar="setSnackbar"
          ></booking-calendar-component>
        </v-card-text>
        <date-request-action
            v-if="!isEmbedded || !isMobile"
            :disabled="readOnly"
            @click="showConfirmRequest"
        ></date-request-action>
      </v-card>
    </v-slide-x-transition>
    <div class="text-center" v-if="showFooter && userAccount">
      <p class="mt-3 grey--text subtitle-2 text--darken-1">&copy; Uthyrd 2021</p>
      <div>
        <v-icon class="mr-1" color="grey" small>mdi-email-outline</v-icon>
        <a :href="`mailto:${userAccount.applicationEmailAddress}`" class="grey--text caption"
           v-if="userAccount">{{ userAccount.applicationEmailAddress }}</a>
      </div>
      <div>
        <v-icon class="mr-1" color="grey" small>mdi-web</v-icon>
        <a class="grey--text caption" href="https://uthyrd.se">Uthyrd.se</a>
      </div>
    </div>
    <booking-request-dialog-component :booking-object="bookingObject"
                                      :user-account-id="userAccount.id"
                                      :calendar-event="selectedEvent" :dialog-is-open.sync="requestDialog"
                                      @saved="showSuccess" @setSnackbar="setSnackbar"
                                      v-if="bookingObject && userAccount"></booking-request-dialog-component>
    <v-snackbar
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        v-model="snackbar.active"
    >
      <p>
        <span class="font-weight-bold" v-if="snackbar.title">{{ snackbar.title }} <br></span>
        {{ snackbar.text }}
      </p>
      <template v-slot:action>
        <v-btn
            @click="snackbar.active = false"
            dark
            text
        >
          Stäng
        </v-btn>
      </template>
    </v-snackbar>
    <div
        v-if="isEmbedded && isMobile && !success.msg && !errorStatus.msg"
        class="p-relative mt-16"
    >
      <div class="date-request-footer white">
        <v-divider></v-divider>
        <date-request-action
            :disabled="readOnly"
            @click="showConfirmRequest"
        ></date-request-action>
      </div>
    </div>
  </div>
</template>

<script>
import {getImageUrl} from "../../util/functions";
import LogoComponent from "../../components/global/LogoComponent";
import api from "../../api/api";
import BookingCalendarComponent from "../../components/externalbooking/BookingCalendarComponent";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import BookingRequestDialogComponent from "../../components/externalbooking/request/BookingRequestDialogComponent";
import DateRequestAction from "@/components/externalbooking/request/DateRequestAction.vue";

export default {
  components: {
    DateRequestAction,
    BookingRequestDialogComponent,
    BookingCalendarComponent,
    LogoComponent,
  },
  props: {
    flat: Boolean,
    isEmbedded: Boolean,
    showLogo: Boolean,
    showFooter: Boolean,
    readOnly: Boolean,
    bookingObject: Object,
    uuid: String,
    externalBookerSettings: Object,
    userAccount: Object,
    error: Object
  },
  data: () => ({
    loadingCalendar: false,
    selectedEvent: null,
    success: {},
    requestDialog: false,
    selectedDate: "",
    customRangePicker: false,
    freeEvents: [],
    occupiedEvents: [],
    snackbar: {
      active: false,
      title: "",
      text: "",
      color: "error",
      timeout: 8000
    }
  }),
  computed: {
    errorStatus: {
      get() {
        return this.error
      },
      set(val) {
        this.$emit('update:error', val)
      }
    },
    occupiedStatuses() {
      let statuses = bookingPeriodStatuses.STATUSES_OBJ
      return [statuses.BOOKED.value]
    },
    freeStatuses() {
      let statuses = bookingPeriodStatuses.STATUSES_OBJ
      return [statuses.OPEN.value, statuses.REQUESTED.value]
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  async mounted() {
    this.selectedDate = this.$moment().add(1, 'd').format("YYYY-MM-DD")
  },
  methods: {
    async getFreeAndOccupiedEvents(date) {
      let startDate = date.start.date

      this.freeEvents = await this.getBookingPeriodsEvents(startDate, date.end.date, this.freeStatuses)
      this.occupiedEvents = await this.getBookingPeriodsEvents(startDate, date.end.date, this.occupiedStatuses)
    },
    async getBookingPeriodsEvents(fromDate, toDate, statuses) {
      this.loadingCalendar = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.uuid + "/" + api.ENDPOINTS.BOOKINGPERIODS + '/' + api.ENDPOINTS.CALENDAREVENTS + '?start=' + fromDate + '&end=' + toDate + '&statuses=' + statuses)
      if (!serverResponse) {
        this.errorStatus.msg = "Kunde inte hämta in uthyrningsperioder. Vänligen ladda om sidan eller kontakta oss på support@uthyrd.se om problemet kvarstår"
      }
      this.loadingCalendar = false
      return serverResponse
    },
    showConfirmRequest() {
      this.selectedEvent = null
      this.requestDialog = true
    },
    showConfirmBooking(calendarEvent) {
      if (!this.readOnly) {
        this.selectedEvent = calendarEvent
        this.requestDialog = true
      }
    },
    showSuccess(customer) {
      this.success = {
        title: "Förfrågan skickad",
        msg: "Tack " + customer.firstname + "! Din förfrågan har nu skickats in till oss. Vi återkommer till dig så snart vi kan för att bekräfta och färdigställa bokningen."
      }
    },
    getImageUrl(item) {
      return getImageUrl(item)
    },
    setSnackbar(title, text, color) {
      // hide snackbar incase it is already showing
      this.snackbar.active = false

      // then we set the snackbar with proper values after a small delay
      window.setTimeout(() => {
        this.snackbar = {active: true, color: color, timeout: 8000, title: title, text: text}
      }, 100)
    }
  }
}
</script>

<style scoped>
.date-request-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
</style>

