<template>
  <div>
    <v-dialog v-model="dialogProp" :eager="eager" max-width="800px" persistent scrollable
              transition="slide-x-transition">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title>
          Ange namn
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
              ref="form"
              v-model="validForm"

              @submit.prevent
          >
            <v-subheader class="pl-0">Filinformation</v-subheader>
            <v-text-field v-model="name"
                          :rules="[v => !!v || 'Du måste ange ett namn']"
                          append-icon="mdi-file-pdf-outline" label="Namn"
                          placeholder="Ange ett unikt namn"
                          solo></v-text-field>
            <v-row>
              <v-col>
                <v-btn color="primary" block @click="editContent = true">Redigera innehåll
                  <v-icon class="ml-2">mdi-file-document-edit-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn color="accent"
                       block
                       @click="showPreview">Förhandsgranska
                  <v-icon right>mdi-file-eye</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- TODO maybe let user choose file type instead of always creating a pdf -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dialogProp = false">Avbryt</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="submit">Skapa fil
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="previewDialog"
        eager
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
            dense
        >
          <v-btn
              dark
              icon
              @click="previewDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Förhandsgranskning
          </v-toolbar-title>
        </v-toolbar>
        <dynamic-document-render-component v-if="selectedDynamicDocument" ref="preview"
                                           :booking-period-id="bookingPeriod.id"
                                           :content="selectedDynamicDocument.content"
        ></dynamic-document-render-component>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editContent"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
            dense
        >
          <v-btn
              dark
              icon
              @click="editContent = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Innehåll</v-toolbar-title>
        </v-toolbar>
        <text-editor-component v-if="selectedDynamicDocument" :value.sync="selectedDynamicDocument.content"
                               placeholder="Här skriver du innehållet i dokumentet. I verktygsraden ovan hittar du användbara verktyg såsom teckenstorlek. Dynamisk data kan användas för att hämta in dynamisk text från perioden när dokumentet genereras, t.ex: kundens namn, incheckningsdatum och mycket mer!"></text-editor-component>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DynamicDocumentRenderComponent from "@/components/dynamicdocument/DynamicDocumentRenderComponent";
import {validateForm} from "@/util/functions";
import api from "@/api/api";
import TextEditorComponent from "@/components/global/TextEditorComponent";

export default {
  components: {TextEditorComponent, DynamicDocumentRenderComponent},
  data: () => ({
    name: "",
    loading: false,
    previewDialog: false,
    validForm: false,
    editContent: false,
  }),
  props: {
    dialog: Boolean,
    selectedDynamicDocument: Object,
    bookingPeriod: Object,
    eager: Boolean,
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
  },
  watch: {
    dialogProp(val)
    {
      if (val === true)
      {
        this.setNameFromDynamicDocument()
      }
    }
  },
  methods: {
    async setNameFromDynamicDocument()
    {
      this.name = this.selectedDynamicDocument.name + '.pdf'
    },
    showPreview()
    {
      this.previewDialog = true
      this.$nextTick(function ()
      {
        this.$refs.preview.refreshRender()
      })
    },
    async submit()
    {
      let form = this.$refs.form
      if (validateForm(form))
      {
        this.loading = true
        let fileData = await api.postToEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + this.selectedDynamicDocument.id + "/" + api.ENDPOINTS.FILEDATA,
            {name: this.name, bookingPeriodId: this.bookingPeriod.id, content: this.selectedDynamicDocument.content}
        )
        if (fileData)
        {
          this.dialogProp = false
          this.$emit('saved', fileData)
        }
        else
        {
          this.$store.commit('setSnackbar', {
            color: "error",
            text: "§"
          })
        }
        this.loading = false

      }
    }
  }
}
</script>
