<template>
  <v-item v-slot="{ active, toggle }">
    <div>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card class="text-center" height="450px" rounded width="100%" @click="toggle">
            <v-banner v-if="bannerText" color="secondary" dark single-line>
              <h4>{{ bannerText }}</h4>
            </v-banner>
            <div class="pt-6">
              <logo-component color="#837fb6" height="60px" hide-text offset-height="45px"
                              width="60px"></logo-component>
              <h1 class="primary--text">{{ item.name }}</h1>
            </div>
            <h3 class="my-2 font-weight-medium primary--text">
              <v-icon class="ml-1" color="primary" small>mdi-tag</v-icon>
              {{ item.price }}
            </h3>
            <h5 v-if="item.row1" class="my-2 grey--text">{{ item.row1 }}</h5>
            <v-divider></v-divider>
            <v-card-text>
              {{ item.description }}
            </v-card-text>
            <v-card-actions>
                <v-btn :ripple="false"  width="95%" color="primary" style="position: absolute; bottom: 15px;">Välj</v-btn>
            </v-card-actions>
            <v-fade-transition>
              <v-overlay
                  v-show="hover && !active"
                  absolute
                  color="accent"
                  opacity="0.25"
              >
              </v-overlay>
            </v-fade-transition>
            <v-scroll-y-transition>
              <v-overlay
                  v-if="active"
                  absolute
                  color="success"
                  opacity="0.55"
              >
                <span><b><v-icon dark>mdi-check-circle</v-icon></b></span>
              </v-overlay>
            </v-scroll-y-transition>
          </v-card>

        </template>
      </v-hover>
    </div>
  </v-item>
</template>

<script>

import LogoComponent from "@/components/global/LogoComponent";

export default {
  components: {LogoComponent},
  props: {
    price: Object,
    active: Boolean,
    bannerText: String
  },
  computed: {
    item: {
      get: function ()
      {
        return this.price;
      },
      set: function (newValue)
      {
        this.$emit('update:price', newValue)
      }
    },
  },
}
</script>
