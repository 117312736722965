<template>
  <transition appear mode="out-in" name="slide-x-transition">
    <uthyrd-loader-component loading-text="Laddar..."
                             v-if="loading"></uthyrd-loader-component>
    <page-not-found-component routerless v-else-if="notFound"></page-not-found-component>
    <div v-else-if="website">
      <v-app-bar
          app
          color="secondary"
          flat
          dense
      >
        <v-container class="py-0 fill-height">
          <v-fade-transition mode="out-in">
            <span class="toolbar-logo">
              <a href="https://uthyrd.se">
                <logo-component class="align-middle" color="white" height="36px"
                                width="36px"></logo-component>
                </a>
            </span>
          </v-fade-transition>
          <h3 v-if="bookingObject" :class="{'text-truncate': $vuetify.breakpoint.mobile, 'white--text ml-4': true}">{{ "Boka " + bookingObject.name }}</h3>
          <v-spacer></v-spacer>
          <v-btn v-if="bookingObject && userAccount" :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" dark :href="`mailto:${userAccount.applicationEmailAddress}?&subject=Ang. ${bookingObject.name}`">
            <span v-if="!$vuetify.breakpoint.mobile">Mejla uthyrare</span>
            <v-icon :right="!$vuetify.breakpoint.mobile">mdi-email-outline</v-icon>
          </v-btn>
        </v-container>
      </v-app-bar>
      <v-main class="grey lighten-3">
        <website-render-component :website="website"></website-render-component>
        <div class="text-center my-12">
          <p class="mt-3 grey--text text--darken-2 font-weight-bold subtitle-1">Prova det nya sättet att hyra ut, på dina villkor!</p>
          <div>
            <v-icon class="mr-1" color="grey" small>mdi-web</v-icon>
            <a class="grey--text caption" href="https://uthyrd.se">Uthyrd.se</a>
          </div>
          <div>
            <v-icon class="mr-1" color="grey" small>mdi-email-outline</v-icon>
            <a :href="`mailto:support@uthyrd.se`" class="grey--text caption">support@uthyrd.se</a>
          </div>
          <p class="mt-3 grey--text subtitle-2 text--darken-1">&copy; Uthyrd 2021</p>
        </div>
      </v-main>
    </div>
    <page-not-found-component v-else></page-not-found-component>
  </transition>
</template>

<script>

import UthyrdLoaderComponent from "@/components/global/UthyrdLoaderComponent";
import api from "@/api/api";
import PageNotFoundComponent from "@/components/errors/PageNotFoundComponent";
import WebsiteRenderComponent from "@/components/website/render/WebsiteRenderComponent";
import LogoComponent from "@/components/global/LogoComponent";

export default {
  components: {
    LogoComponent,
    WebsiteRenderComponent,
    PageNotFoundComponent,
    UthyrdLoaderComponent,
  },
  data: () => ({
    website: null,
    bookingObject: null,
    userAccount: null,
    loading: false,
    notFound: false
  }),
  async mounted()
  {
    await this.getWebsite()
    if (this.website)
    {
      this.logWebsiteVisit()
      await this.getBookingObject()
      await this.getUserAccountFromUuid()
    }
  },
  methods: {
    async getWebsite()
    {
      let url = this.$route.params.url
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.WEBSITES + "/" + api.ENDPOINTS.EXTERNAL + "/" + url)
      if (serverResponse)
      {
        this.website = serverResponse
      }
      else
      {
        this.notFound = true
      }
      this.loading = false
    },
    async getBookingObject()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + this.website.bookingObjectUuid)
      if (serverResponse)
      {
        this.bookingObject = serverResponse
      }
      this.loading = false
    },
    async getUserAccountFromUuid()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + this.website.bookingObjectUuid + "/" + api.ENDPOINTS.USERACCOUNT)
      if (serverResponse)
      {
        this.userAccount = serverResponse
      }
      this.loading = false
    },
    async logWebsiteVisit()
    {
      await api.postToEndpoint(api.ENDPOINTS.WEBSITES + "/" + this.website.id + "/" + api.ENDPOINTS.LOGS)
    },
  }
}
</script>

<style scoped>
.text-truncate {
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

