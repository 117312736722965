<template>
  <span>
    <v-autocomplete
        :prepend-icon="prependIcon"
        :items="types"
        v-model="type"
        @keyup.enter="keyupEnter"
        :required="required"
        :rules="rules"
        @change="$emit('change')"
        :label="label"
        return-object
        :hint="hint"
        :placeholder="placeholder"
        item-text="name"
        item-value="id"
        no-data-text="Din sökning gav inga resultat"
        :disabled="disabled"
        :loading="loading"
        @click:clear="clear"
        :solo="solo"
        :clearable="clearable"
    >
      <template v-slot:selection="data">
        <v-chip label color="primary"><v-icon left>{{data.item.icon}}</v-icon>{{data.item.name}}</v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-icon>
            <v-icon>{{data.item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import api from "@/api/api";

export default {
  components: {},
  data: () =>
  {
    return {
      types: [],
      loading: false,
    }
  },
  props: {
    value: Object,
    solo: Boolean,
    disabled: Boolean,
    required: Boolean,
    prependIcon: String,
    label: String,
    hint: String,
    placeholder: String,
    clearable: Boolean
  },
  computed: {
    rules()
    {
      return this.required ? [v => !!v || 'Du måste välja en typ'] : []
    },
    type: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    }
  },
  async mounted()
  {
    this.getTypes()
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    async getTypes()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.WEBSITES + "/" + api.ENDPOINTS.SECTIONS + "/" + api.ENDPOINTS.TYPES)
      if (serverResponse)
      {
        this.types = serverResponse
      }
      this.loading = false
    },
    clear()
    {
      this.type = null
      this.$emit("change", this.type)
    }
  }
}
</script>
