<template>
    <respondent-card-component @showMessageDetails="showMessageDetails" @emailAdressClick="emailAdressClick" :message="message" v-if="isRespondent"></respondent-card-component>
    <user-card-component @showMessageDetails="showMessageDetails" @emailAdressClick="emailAdressClick" :message="message" v-else></user-card-component>
</template>

<script>

    import RespondentCardComponent from "./RespondentCardComponent";
    import UserCardComponent from "./UserCardComponent";
    export default {
        components: {UserCardComponent, RespondentCardComponent},
        data: () => ({
            MESSAGE_TYPES: {RECEIVED: 1, SENT: 2},
        }),
        props: {
            message: Object,
        },
        computed: {
            isRespondent() {
                return this.message.type === this.MESSAGE_TYPES.RECEIVED
            }
        },
        methods: {
            showMessageDetails(message)
            {
                this.$emit("showMessageDetails", message)
            },
            emailAdressClick(emailAddress)
            {
                this.$emit("emailAdressClick", emailAddress)
            },
        }
    }
</script>
