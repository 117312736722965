<template>
  <div>
    <v-autocomplete
        :clearable="clearable"
        :disabled="readOnly"
        :items="bookingPeriods"
        :loading="loading"
        color="primary"
        :multiple="multiple"
        :required="required"
        :rules="rules"
        :hide-details="hideDetails"
        @change="change"
        :search-input.sync="searchQuery"
        @keyup.enter="keyupEnter"
        :cache-items="multiple"
        no-filter
        class="pt-0"
        :solo="solo"
        :light="solo"
        item-text="name"
        item-value="id"
                :hide-no-data="((!searchQuery || searchQuery.length === 0) && bookingPeriods.length === 0) || loading"
        no-data-text="Din sökning gav inga resultat"
                placeholder="Sök efter uthyrningsperiod"
        return-object
        v-model="bookingPeriodValue"
    >
      <template v-slot:prepend-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-subheader class="pa-0">Endast framtida perioder</v-subheader>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
                v-model="onlyFuturePeriods"
                @change="getBookingPeriods"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-slot:selection="data">
        <v-avatar left>
          <v-icon v-if="data.item.deleted" color="grey">
            mdi-calendar-minus
          </v-icon>
          <v-icon v-else :color="bookingPeriodStatuses.STATUSES[data.item.status].color">
            {{ bookingPeriodStatuses.STATUSES[data.item.status].icon }}
          </v-icon>
        </v-avatar>
        <span :class="{'grey--text': data.item.deleted}">{{ data.item.deleted ? "Raderad period" : data.item.name }}</span>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title><b>{{ data.item.name }}</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip>
                <v-icon class="mr-1">event</v-icon>
                {{
                  getFormattedDate(data.item.calendarEvent.start) + ' - ' +
                  getFormattedDate(data.item.calendarEvent.end)
                }}
              </v-chip>
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ data.item.notes }}</v-list-item-subtitle>
            <customer-autocomplete-component
                :value-object="data.item"
                class="mt-2"
                fetch-async
                multiple
                read-only
                style="max-width: 500px;"
                v-if="data.item.status > bookingPeriodStatuses.STATUSES_OBJ.OPEN.value"
            ></customer-autocomplete-component>
          </v-list-item-content>
          <v-list-item-action-text class="text-right">
            <span v-if="data.item.deleted" class="grey--text">Raderad period</span>
            <span v-else>{{ bookingPeriodStatuses.STATUSES[data.item.status].text }}</span>
            <v-avatar>
              <v-icon v-if="data.item.deleted" color="grey">
                mdi-calendar-minus
              </v-icon>
              <v-icon v-else :color="bookingPeriodStatuses.STATUSES[data.item.status].color">
                {{ bookingPeriodStatuses.STATUSES[data.item.status].icon }}
              </v-icon>
            </v-avatar>
            <p>
              <booking-object-item-component :booking-object="data.item.bookingObject"
                                             class="truncate" hide-avatar
                                             hide-notes></booking-object-item-component>
              <booking-object-item-component :booking-object="data.item.bookingObject"
                                             hide-notes
                                             hide-name></booking-object-item-component>
            </p>
          </v-list-item-action-text>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import api from "../../api/api";
import BookingObjectItemComponent from "../bookingobject/BookingObjectItemComponent";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import CustomerAutocompleteComponent from "@/components/customer/CustomerAutocompleteComponent";

export default {
  components: {CustomerAutocompleteComponent, BookingObjectItemComponent},
  data: () =>
  {
    return {
      bookingPeriods: [],
      loading: false,
      onlyFuturePeriods: true,
      searchQuery: "",
      bookingPeriodStatuses: bookingPeriodStatuses,
      timeout: 0
    }
  },
  props: {
    value: Object, // value.bookingPeriods must be an array if multiple, otherwise value.bookingPeriod should be an object
    readOnly: Boolean,
    required: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    solo: Boolean,
    hideDetails: Boolean,
  },
  computed: {
    rules()
    {
      return this.required ? [v => !!v || 'Du måste välja en uthyrningsperiod'] : []
    },
    bookingPeriodValue: {
      get()
      {
        return this.multiple ? this.value.bookingPeriods : this.value.bookingPeriod
      },
      set(val)
      {
        if (this.multiple)
        {
          this.value.bookingPeriods = val
        }
        else
        {
          this.value.bookingPeriod = val
        }
      }
    }
  },
  watch: {
    searchQuery(val)
    {
      this.search(val)
    },
  },
  methods: {
    async search(val)
    {
      clearTimeout(this.timeout);
      if (val && val.length > 1)
      {
        this.timeout = await setTimeout(async () =>
        {
          this.getBookingPeriods()
        }, 250)
      }
    },
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    change(data)
    {
      this.$emit("change", data)
    },
    getFormattedDate(date)
    {
      return this.$moment(date).format("lll")
    },
    async getBookingPeriods()
    {
      this.loading = true
      let params = '?ignoreDisplayOptions=true&limit=20&onlyFuturePeriods=' + this.onlyFuturePeriods
      if (this.searchQuery)
      {
        params += '&searchQuery=' + this.searchQuery
      }
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGPERIODS + params)
      if (serverResponse)
      {
        this.bookingPeriods = serverResponse
      }
      this.loading = false
    },
    setInitialPeriod(bookingPeriod)
    { // used when there is an initial value that must be set
      this.bookingPeriods.push(bookingPeriod)
    }
  }
}
</script>
<style scoped>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
