<template>
    <div>
        <v-menu
                :nudge-bottom="25"
                :nudge-left="50"
                :position-x="shareMenuX"
                :position-y="shareMenuY"
                offset-y
                transition="slide-y-transition"
                v-model="shareMenu"
        >
            <v-list>
                <v-list-item :href="smsHref">
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-cellphone-message</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Dela via sms</v-list-item-title>
                </v-list-item>
                <v-list-item :href="mailToHref">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-email-send-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Dela via e-post</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-card-title>Inbäddningsbar bokningsmodul</v-card-title>
        <v-text-field
                @click="copyToClipboard(generatedExternalIframe)"
                append-icon="mdi-clipboard-text-outline"
                autocomplete="off"
                hint="Använd denna länk vid inbäddning eller länkning till din bokningsmodul (Klicka för att kopiera)"
                label="Inbäddningslänk"
                persistent-hint
                prepend-icon="mdi-code-tags"
                readonly
                solo
                style="max-width: 800px;"
                v-model="generatedExternalIframe"
                class="clickable"
        >
            <template v-slot:append-outer>
                <v-tooltip bottom open-delay="600">
                    <template v-slot:activator="{ on }">
                        <v-btn
                                @click="showShareMenu"
                                class="ml-2"
                                color="primary"
                                id="shareBtn"
                                outlined
                                style="margin-top: -4px;"
                                v-on="on"
                        >
                            <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                    </template>
                    <span>Dela bokningsmodulen</span>
                </v-tooltip>
            </template>
        </v-text-field>
        <v-slide-x-transition>
            <v-textarea
                    hint="Använd dessa kod-taggar för att bokningsmodulens höjd automatiskt ska justeras vid inbäddning (Klicka för att kopiera)"
                    label="Kod-taggar"
                    solo
                    class="mt-5 clickable"
                    persistent-hint
                    prepend-icon="mdi-code-tags"
                    v-model="generatedExternalScripts"
                    style="max-width: 800px;"
                    no-resize
                    append-icon="mdi-clipboard-text-outline"
                    v-show="embed"
                    @click="copyToClipboard(generatedExternalScripts)"
            ></v-textarea>
        </v-slide-x-transition>
        <v-container fluid>
            <v-row no-gutters dense>
                <v-col cols="12" md="8">
                    <v-subheader>Förhandsgranskning</v-subheader>
                    <iframe :src="iframeSrc" frameborder="none" id="previewIframe"
                            onload='javascript:(function(o){window.setTimeout(function (){o.style.height=o.contentWindow.document.body.scrollHeight+50+"px";}, 500);}(this));'
                            scrolling="no"
                            style="position: relative;height:200px;width:100%;border:none;overflow:hidden; resize: none;"
                            width="100%"></iframe>
                </v-col>
                <v-col cols="12" md="4">
                    <v-subheader>Inställningar</v-subheader>
                    <v-checkbox
                            label="Generera som inbäddningskod"
                            v-model="embed"
                    ></v-checkbox>
                    <v-checkbox
                            @change="debounceSaveExternalBookerSettings"
                            label="Visa bild på uthyrningsobjektet"
                            v-model="externalBookerSettings.showImage"
                    ></v-checkbox>
                    <v-checkbox
                            @change="debounceSaveExternalBookerSettings"
                            label="Visa beskrivning av uthyrningsobjektet"
                            v-model="externalBookerSettings.showDescription"
                    ></v-checkbox>
                    <v-text-field
                            @keyup="debounceSaveExternalBookerSettings"
                            label="Titel"
                            v-model="externalBookerSettings.title"
                    ></v-text-field>
                    <v-textarea
                            @keyup="debounceSaveExternalBookerSettings"
                            auto-grow
                            label="Hjälptext"
                            outlined
                            v-model="externalBookerSettings.subtitle"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import api from "../../../../api/api";

export default {
        components: {},
        data: () => ({
            shareMenu: false,
            shareMenuX: 0,
            shareMenuY: 0,
            embed: true,
            timeout: -1,
            externalBookerSettings: {
                showImage: true,
                showDescription: true,
                title: "",
                subtitle: "",
                bookingObjectId: null,
            },
        }),
        props: {
            bookingObject: Object,
        },
        computed: {
            generatedExternalScripts() {
                // need some special chars for this to work in a Vue file
                return this.htmlDecode("" +
                    "&lt;script src=\"https://app.uthyrd.se/assets/javascripts/iframeResizer.min.js\"&gt;&lt;/script&gt;\n" +
                    "&lt;script&gt;\n" +
                    "    iFrameResize({ log: false }, '#uthyrdIframe');\n" +
                    "&lt;/script&gt;")
            },
            generatedExternalIframe() {
              if(this.embed)
              {
                return "<iframe id=\"uthyrdIframe\" src=\"" + this.generatedHref + "\" frameborder=\"none\" width=\"100%\" overflow=\"hidden\" style=\"position: relative;height:1500px;width:100%;border:none;\"></iframe>\n"
              }
              return this.generatedHref
            },
            generatedHref()
            {
                return `${api.BASE_URL_FRONTEND}external/book?uuid=${this.bookingObject.uuid}`
            },
            iframeSrc()
            {
                return this.generatedHref + '&readOnly=true'
            },
            mailToHref()
            {
                return encodeURI(`mailto:?&subject=Boka ${this.bookingObject.name}!&body=Hej! \nAnvänd denna länk för att boka: ${this.generatedHref}`)
            },
            smsHref()
            {
                return encodeURI(`sms:?&body=Hej!\nBoka ${this.bookingObject.name} med följande länk: ${this.generatedHref}`)
            }
        },
        async mounted()
        {
            await this.getExternalBookerSettings()
            document.getElementById('previewIframe').contentWindow.location.reload();
        },
        methods: {
            async getExternalBookerSettings()
            {
                let settings = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id + "/" + api.ENDPOINTS.EXTERNALBOOKERSETTINGS)
                if (settings)
                {
                    this.externalBookerSettings = settings
                }
            },
            htmlDecode(input)
            {
                const e = document.createElement('div')
                e.innerHTML = input
                return e.childNodes[0].nodeValue
            },
            debounceSaveExternalBookerSettings()
            {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async () =>
                {
                    this.saveExternalBookerSettings()
                }, 600)
            },
            async saveExternalBookerSettings()
            {
                let response = await api.postToEndpoint(api.ENDPOINTS.EXTERNALBOOKERSETTINGS, this.externalBookerSettings)
                if (response)
                {
                    this.externalBookerSettings = response
                    document.getElementById('previewIframe').contentWindow.location.reload();
                }
            },
            copyToClipboard(text)
            {
                var tempInput = document.createElement("input");
                tempInput.style = "position: absolute; left: -1000px; top: -1000px";
                tempInput.value = text;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand("copy");
                document.body.removeChild(tempInput);
                this.$store.commit('setSnackbar', {color: "primary", text: "Kopierade länken till urklipp!"})
            },
            showShareMenu(e)
            {
                e.preventDefault()
                this.shareMenuX = e.clientX
                this.shareMenuY = e.clientY
                this.$nextTick(() =>
                {
                    this.shareMenu = true
                })
            },
        }
    }
</script>

<style scoped>
    #previewIframe iframe {
        height: 300px;
        width: 100%;
        resize: both;
        overflow: auto;
    }
</style>
