<template>
  <div>
    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex md6>
          <v-subheader class="pl-0">Grundläggande information</v-subheader>
          <v-text-field
              :counter="100"
              :disabled="readOnly"
              :rules="[v => !!v || 'Du måste ange ett namn']"
              @keyup="unsavedChanges = true"
              @keyup.enter="saveBookingPeriod"
              autocomplete="off"
              autofocus
              class="mb-3"
              label="Namn"
              required
              solo
              v-model="bookingPeriod.name"
          ></v-text-field>
          <v-card class="mb-4">
            <v-card-title class="py-0">
              <v-subheader class="pl-0">Kunder</v-subheader>
            </v-card-title>
            <v-card-text>
              <customer-list-component
                  :customers="bookingPeriod.customers"
                  :favorites-disabled="periodCancelledBookedOrCompleted"
                  @customerMailClick="customerMailClick"
                  no-data-icon="mdi-account-off-outline"
                  no-data-text="Det finns inga kunder kopplade till denna period. Välj en befintlig kund eller skapa en ny från rullistan nedan"
                  read-only
                  show-favorite
                  class="mb-4"
                  v-if="bookingPeriod"
                  :disable-message-button="!this.bookingPeriod.id || this.bookingPeriod.id <= 0"
              ></customer-list-component>
              <customer-autocomplete-component
                  :read-only="periodCancelledBookedOrCompleted"
                  :value-object.sync="bookingPeriod"
                  @change="unsavedChanges = true"
                  @keyupEnter="saveBookingPeriod(false)"
                  fetch-async
                  :clearable="!periodCancelledBookedOrCompleted"
                  multiple
                  outlined
                  hide-details
                  prepend="mdi-account-multiple-outline"
              >
              </customer-autocomplete-component>
            </v-card-text>
          </v-card>
          <v-textarea
              :disabled="readOnly"
              @keyup="unsavedChanges = true"
              auto-grow
              label="Egna anteckningar"
              outlined
              v-model="bookingPeriod.notes"
          ></v-textarea>
          <v-textarea
              :disabled="readOnly"
              @keyup="unsavedChanges = true"
              auto-grow
              label="Övrigt"
              outlined
              v-model="bookingPeriod.other"
          ></v-textarea>
          <booking-period-start-end-component :booking-period="bookingPeriod"
                                              :read-only="periodCancelledBookedOrCompleted"
                                              @changed="checkDateCollision"></booking-period-start-end-component>
          <div><p class="error--text pt-5 mt-5">{{ errorText }}</p></div>
          <colliding-dates-component :booking-period="bookingPeriod"
                                     :colliding-dates="collidingDates"></colliding-dates-component>
          <calendar-component :default-view="defaultView"
                              :editable="false"
                              :selectable="true"
                              :unselect-auto="false"
                              @select="calendarRangeSelected"
                              ref="calendarComponent"
          ></calendar-component>
        </v-flex>
        <v-flex md6>
          <v-card class="mb-3">
            <v-parallax :src="bookingObjectAvatar"
                        height="250"
                        v-if="bookingObjectAvatar && bookingObjectAvatar.length > 0"></v-parallax>
            <v-card-title class="pt-0">
              <v-subheader class="pl-0">Uthyrningsobjekt</v-subheader>
            </v-card-title>
            <v-card-text>
              <booking-object-select-component :read-only="periodCancelledBookedOrCompleted"
                                               :value.sync="bookingPeriod"
                                               @change="unsavedChanges = true; checkDateCollision(); setPeriodPriceFromBookingObject"
                                               @keyupEnter="saveBookingPeriod(false)"
                                               required></booking-object-select-component>
              <address-component outlined flat :address="bookingPeriod.bookingObject.address"
                                 :opened-index="1"
                                 read-only
                                 v-if="bookingPeriod.bookingObject"></address-component>
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-3" v-if="bookingPeriod.id && bookingPeriod.id > 0">
            <v-card-text>
              <conversation-list-component :booking-period="bookingPeriod"
                                           allow-archive
                                           conversations-from-booking-period
                                           show-subheader
                                           v-if="bookingPeriod.id"
                                           view-only-list></conversation-list-component>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="customerMailClick('')"
                  color="primary"
                  outlined
                  small>
                Ny konversation
              </v-btn>
            </v-card-actions>
          </v-card>
          <event-log-component outlined :elevation="0" :entity-id="bookingPeriod.id"
                               :entity-type="bookingPeriodEntityType"
                               max-height="400px"
                               v-if="bookingPeriod.id"></event-log-component>
        </v-flex>
      </v-layout>
    </v-container>
    <new-conversation-dialog-component :booking-object="bookingPeriod.bookingObject"
                                       :booking-period="bookingPeriod"
                                       :customers="bookingPeriod.customers"
                                       :dialog.sync="newConversationDialog"
                                       ref="newConversationDialog"></new-conversation-dialog-component>
  </div>
</template>
<script>
import api from "../../../api/api";
import calendarViews from "../../../models/calendarViews";
import bookingPeriodStatuses from "../../../models/bookingPeriodStatuses";
import AddressComponent from "../../../components/global/AddressComponent";
import BookingObjectSelectComponent from "../../../components/bookingobject/BookingObjectSelectComponent";
import CalendarComponent from "../../../components/calendar/CalendarComponent";
import CustomerListComponent from "../../../components/customer/CustomerListComponent";
import BookingPeriodStartEndComponent from "../../../components/bookingperiod/BookingPeriodStartEndComponent";
import CollidingDatesComponent from "../../../components/bookingperiod/CollidingDatesComponent";
import ConversationListComponent from "../../../components/conversation/ConversationListComponent";
import EventLogComponent from "../../../components/global/eventlog/EventLogComponent";
import NewConversationDialogComponent from "../../../views/conversations/NewConversationDialogComponent";
import CustomerAutocompleteComponent from "@/components/customer/CustomerAutocompleteComponent";
import entityType from "@/models/entityType";

export default {
  components: {
    CustomerAutocompleteComponent,
    NewConversationDialogComponent,
    EventLogComponent,
    ConversationListComponent,
    CollidingDatesComponent,
    BookingPeriodStartEndComponent,
    CustomerListComponent,
    CalendarComponent,
    BookingObjectSelectComponent, AddressComponent
  },
  props: {
    bookingPeriod: Object,
    unsavedChanged: Boolean,
    readOnly: Boolean,
    errorText: String
  },
  data: () => ({
    bookingPeriodEntityType: entityType.types.BOOKING_PERIOD,
    newConversationDialog: false,
    availableStatuses: bookingPeriodStatuses.STATUSES,
    defaultView: calendarViews.views.dayGridMonth,
    collidingDates: [],
  }),
  computed: {
    periodCancelledBookedOrCompleted()
    {
      return this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.CANCELLED.value || this.bookingPeriod.status >= bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value
    },
    unsavedChanges: {
      get()
      {
        return this.unsavedChanged
      },
      set(val)
      {
        this.$emit('update:unsavedChanged', val)
      }
    },
    bookingObjectAvatar()
    {
      if (this.bookingPeriod.bookingObject && this.bookingPeriod.bookingObject.fileData && this.bookingPeriod.bookingObject.fileData.src)
      {
        return api.BASE_URL + this.bookingPeriod.bookingObject.fileData.src
      }
      return ""
    }
  },
  methods: {
    saveBookingPeriod(elevateStatus)
    {
      this.$emit('saveBookingPeriod', elevateStatus)
    },
    calendarRangeSelected(data)
    {
      this.unsavedChanges = true
      let startTime = this.$moment(this.bookingPeriod.calendarEvent.start).format("HH:mm")
      let endTime = this.$moment(this.bookingPeriod.calendarEvent.end).format("HH:mm")

      data.startStr = this.$moment(data.startStr).format("YYYY-MM-DD") + " " + startTime
      // set endStr one day back to allow the check-out time
      let endDate = this.$moment(data.endStr).subtract(1, "days")
      data.endStr = endDate.format("YYYY-MM-DD")
      data.endStr += " " + endTime

      this.bookingPeriod.calendarEvent.start = data.startStr
      this.bookingPeriod.calendarEvent.end = data.endStr
      this.autoSetNameFromStartAndEnd(data.startStr, data.endStr)
      this.checkDateCollision()
    },
    setPeriodPriceFromBookingObject()
    {
      this.bookingPeriod.price = this.bookingPeriod.bookingObject.defaultPricePerPeriod
    },
    autoSetNameFromStartAndEnd(start, end)
    {
      if (!this.bookingPeriod.name || this.bookingPeriod.name.length <= 0)
      {
        // If there is no name set we set an appropriate name from the selected dates
        let startingWeek = this.getWeekNumber(start)

        // subtract one day to get proper weeknumber
        let endingWeek = this.getWeekNumber(this.$moment(end).subtract(1, "days"))

        this.bookingPeriod.name = "Vecka " + startingWeek
        if (startingWeek !== endingWeek) // if end and start week is not the same
        {
          this.bookingPeriod.name = "Vecka " + startingWeek + " - " + endingWeek
        }
      }
    },
    getWeekNumber(date)
    {
      return this.$moment(date).format('w')
    },
    updateCalendarSelection(start, end)
    {
      this.$refs.calendarComponent.setCalendarSelection(start, end)
    },
    async checkDateCollision()
    {
      let start = this.bookingPeriod.calendarEvent.start
      let end = this.bookingPeriod.calendarEvent.end

      if (this.$moment(start).isValid() && this.$moment(end).isValid())
      {
      this.checkAndSetEndAfterStart()
      this.collidingDates = []
      if (this.bookingPeriod.bookingObject && this.bookingPeriod.bookingObject.id > 0)
      {
          let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIOD + "/" + this.bookingPeriod.bookingObject.id + "/" + api.ENDPOINTS.CALENDAREVENTS + '?start=' + start + '&end=' + end)
        if (serverResponse && serverResponse.length > 0)
        {
          let filtered = serverResponse
          if (this.bookingPeriod.id)
          {
            filtered = serverResponse.filter(bookingPeriod =>
            {
              return bookingPeriod.id !== this.bookingPeriod.id
            })
          }
          this.collidingDates = filtered
        }
      }
      }
    },
    // make sure end is set after start and correct if necessary
    checkAndSetEndAfterStart()
    {
      if (!this.$moment(this.bookingPeriod.calendarEvent.end).isAfter(this.$moment(this.bookingPeriod.calendarEvent.start)))
      {
        // end is before start -> set end to start + 1 hour
        this.bookingPeriod.calendarEvent.end = this.$moment(this.bookingPeriod.calendarEvent.start).add(1, "hours").format("YYYY-MM-DD HH:mm")
        this.$store.commit('setSnackbar', {
          color: "secondary",
          text: "Utcheckning måste vara efter incheckning. Ändrade automatiskt utcheckningen till en timme efter incheckning.",
          timeout: 7000
        })
      }
    },
    customerMailClick(email)
    {
      this.$refs.newConversationDialog.selectEmail(email)
      this.newConversationDialog = true
    }
  }
}
</script>

<style scoped>
</style>
