<template>
  <v-card
      :disabled="loading"
      :height="height"
      :max-height="maxHeight"
      :loading="loading"
      class="mx-auto"
      :elevation="elevation"
      max-width="100%"
      style="overflow-y: auto;"
      :flat="flat"
      :outlined="outlined"
  >
    <v-card-title class="font-weight-light">
      <v-icon
          color="primary"
          left
      >
        mdi-timeline-text-outline
      </v-icon>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <span class="body-2 grey--text">Senaste {{ fromDays }} dagar</span>
    </v-card-title>
    <v-card-text>
      <transition appear name="slide-x-transition" mode="out-in">
        <no-data-component icon="mdi-timeline-help-outline" text="Det finns inga händelser att visa"
                           v-if="eventLogs.length === 0"></no-data-component>
        <v-list dense v-else-if="list">
          <span v-for="(eventLog, index) in eventLogs" :key="'event_' + eventLog.id">
            <event-log-list-item-component :event-log="eventLog"></event-log-list-item-component>
            <v-divider v-if="index < eventLogs.length -1"></v-divider>
          </span>
        </v-list>
        <v-timeline clipped dense v-else>
          <event-log-timeline-item-component :event-log="eventLog" :key="'event_' + eventLog.id"
                                             v-for="eventLog in eventLogs"></event-log-timeline-item-component>
        </v-timeline>
      </transition>

    </v-card-text>
  </v-card>
</template>

<script>
import api from "../../../api/api";
import NoDataComponent from "../NoDataComponent";
import EventLogTimelineItemComponent from "@/components/global/eventlog/EventLogTimelineItemComponent";
import EventLogListItemComponent from "@/components/global/eventlog/EventLogListItemComponent";

export default {
  components: {EventLogListItemComponent, EventLogTimelineItemComponent, NoDataComponent},
  props: {
    height: String,
    outlined: Boolean,
    maxHeight: String,
    entityId: Number,
    entityType: String,
    flat: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      default: 6
    },
    title: {
      type: String,
      default: "Händelser"
    },
    count: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    loading: false,
    eventLogs: [],
    fromDays: 8
  }),
  mounted()
  {
    if (this.entityId && this.entityId > 0)
    {
      this.getEventLogsForEntity()
    }
    else
    {
      this.getEventLogsForUserAccount()
    }
  },
  computed: {
    eventLogsCount: {
      get()
      {
        return this.count
      },
      set(val) {
        this.$emit('update:count', val)
      }
    }
  },
  methods: {
    async getEventLogsForUserAccount()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS +
          "/" + this.$store.state.user.id +
          "/" + api.ENDPOINTS.EVENTLOGS +
          "?fromDate=" + this.$moment().subtract(this.fromDays, 'd').format("YYYY-MM-DD"))
      if (serverResponse)
      {
        this.eventLogs = serverResponse
        this.eventLogsCount = serverResponse.length
      }
      this.loading = false
    },
    async getEventLogsForEntity()
    {
      this.loading = true
      this.fromDays = 20 // Higher default for connected entities
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ENTITIES +
          "/" + this.entityId +
          "/" + api.ENDPOINTS.EVENTLOGS +
          "?fromDate=" + this.$moment().subtract(this.fromDays, 'd').format("YYYY-MM-DD") +
          "&entityType=" + this.entityType)
      if (serverResponse)
      {
        this.eventLogs = serverResponse
        this.eventLogsCount = serverResponse.length
      }
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
