<template>
    <!-- Message components are loaded depending on the route and its params -->
    <router-view></router-view>
</template>

<script>

    export default {
        data: () => ({
        })
    }
</script>
