<template>
  <div>
    <v-icon color="primary" style="position: absolute; left: 0; padding-top: 10px;" v-if="prependIcon">
      mdi-label-percent-outline
    </v-icon>
    <v-expansion-panels :class="{'pl-8': prependIcon}" :flat="flat" :value="openedIndex">
      <v-expansion-panel :class="{'outlined': outlined}">
        <v-expansion-panel-header>
                <span>
                    <v-icon color="primary" left v-if="!prependIcon">mdi-label-percent-outline</v-icon>
                    Attribut
                </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
            :class="{'elevation-0': true}">
          <p class="grey--text text--darken-1">Klicka för att välja de attribut som passar in på ditt uthyrningsobjekt. På vissa av attributen kan du även ange en siffra för ytterligare beskrivning exempelvis antal rum.</p>
          <v-divider></v-divider>
          <div :key="attributeGroup.id + '_attrGroup'"
               v-for="attributeGroup in attributeGroupsSelected"
          >
            <v-subheader>{{ attributeGroup.name }}</v-subheader>
            <v-chip-group
                class="ml-4"
                column
                multiple

            >
              <p v-if="attributeGroup.attributes.length <= 0">Det finns inga attribut i denna
                grupp</p>
              <v-badge
                  :key="attribute.id + 'badge'"
                  :value="attribute.selected && attribute.hasValue"
                  color="secondary"
                  left
                  offset-x="25px"
                  overlap
                  v-for="attribute in attributeGroup.attributes"
              >
                <template v-slot:badge>
                  <span>{{ attribute.value }}</span>
                </template>
                <v-chip :color="attribute.selected ? 'primary':'grey'"
                        :id="'attribute_'+attribute.id"
                        :data-cy="'attr_' + attribute.id"
                        :input-value="attribute.selected"
                        :key="attribute.id + 'attr'"
                        @click="attributeClicked(attribute, false, $event)"
                        outlined
                >
                  <v-icon :color="attribute.selected ? 'primary':'grey'"
                          left v-if="attribute.icon && attribute.icon.length > 0">
                    {{ attribute.icon }}
                  </v-icon>
                  <span class="black--text">{{ attribute.name }}</span>
                  <v-scale-transition>
                    <v-icon color="success" right v-if="attribute.selected">check</v-icon>
                  </v-scale-transition>
                </v-chip>
              </v-badge>
            </v-chip-group>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-menu
        :close-on-click="false"
        :close-on-content-click="false"
        :position-x="menuX"
        :position-y="menuY"
        absolute
        transition="slide-y-transition"
        v-model="menu"
    >
      <v-card width="400px">
        <v-list color="secondary" dark>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-if="selectedAttribute.icon">{{ selectedAttribute.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ selectedAttribute.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ selectedAttribute.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-text-field :label="selectedAttribute.valueUnit"
                      @keyup.enter="attributeClicked(selectedAttribute, true); menu = false;" class="ma-5"
                      hide-details min="1"
                      required
                      :data-cy="'attr_input_' + selectedAttribute.id"
                      type="number"
                      v-model="selectedAttribute.value"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="menu = false" text>Avbryt</v-btn>
          <v-btn @click="attributeClicked(selectedAttribute, true); menu = false;" color="primary" text>
            Spara
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import api from "../../api/api";

export default {
  data: () => ({
    attributeGroups: [],
    loading: true,
    menu: false,
    menuX: 0,
    menuY: 0,
    selectedAttribute: {icon: "", name: "", description: ""},
    selectedAttributeElementId: ""
  }),
  props: {
    bookingObject: Object,
    openedIndex: Number, // 0 = closed, 1 = opened
    prependIcon: Boolean,
    flat: Boolean,
    outlined: Boolean
  },
  async mounted()
  {
    this.loading = true
    let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ATTRIBUTEGROUPS)
    if (serverResponse)
    {
      this.attributeGroups = serverResponse
    }
    this.loading = false
  },
  computed: {
    attributeGroupsSelected()
    {
      return this.getAttributesWithSelected(this.attributeGroups)
    }
  },
  methods: {
    getAttributesWithSelected(attributeGroups)
    {
      let groups = []
      attributeGroups.forEach((group) =>
      {
        group.attributes.forEach((attribute) =>
        {
          attribute.value = 1
          attribute.selected = false
          let existingItemIndex = this.findAttributeIndexInBookingObject(attribute.id)
          if (existingItemIndex >= 0)
          {
            attribute.selected = true
            let val = this.bookingObject.attributes[existingItemIndex].value
            attribute.value = val ? val : 1
          }
        })
        groups.push(group)
      })
      return groups
    },
    attributeClicked(attribute, fromMenu, event)
    {
      if (!fromMenu && attribute.hasValue && !attribute.selected)
      {
        this.selectedAttribute = attribute
        this.selectedAttributeElementId = "attribute_" + attribute.id

        event.preventDefault()
        this.showMenu = false
        this.menuX = event.clientX
        this.menuY = event.clientY
        this.$nextTick(() =>
        {
          this.menu = true
        })
      }
      else
      {
        let existingItemIndex = this.findAttributeIndexInBookingObject(attribute.id)
        if (attribute.selected)
        {
          this.remove(attribute, existingItemIndex)
        }
        else
        {
          this.add(attribute)
        }
      }
    },
    remove(item, index)
    {
      item.selected = false
      item.value = 1
      let attributeObj = []
      attributeObj = this.bookingObject.attributes
      attributeObj.splice(index, 1)
      this.emitBookingObjectAttributesUpdate(attributeObj)
    },
    add(attribute)
    {
      attribute.selected = true
      let attributeObj = []
      attributeObj = this.bookingObject.attributes
      // using a reference to avoid circular structure
      attributeObj.push({
        attribute: attribute,
        bookingObjectId: this.bookingObject.id,
        value: attribute.value
      })
      this.emitBookingObjectAttributesUpdate(attributeObj)
    },
    // returns the index of a BookingObjectAttribute in our bookingObject
    findAttributeIndexInBookingObject(attributeId)
    {
      return this.bookingObject.attributes.findIndex(bookingObjectAttribute => bookingObjectAttribute.attribute.id === attributeId)
    },
    emitBookingObjectAttributesUpdate(attributes)
    {
      let newBookingObject = {}
      Object.assign(newBookingObject, this.bookingObject);
      newBookingObject.attributes = attributes
      this.$emit('change')
      this.$emit('update:bookingObject', newBookingObject)
    }
  }
}
</script>

<style>
.v-chip-group .v-slide-group__content {
  padding: 10px !important;
}
</style>
