<template>
    <v-dialog persistent v-model="dialogProp" scrollable max-width="800px">
        <v-card :loading="loading">
            <v-card-title>
                Redigerar bild
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-container class="text-center" fluid>
                    <div class="mb-6">
                        <croppa
                                v-model="croppa"
                                :initial-image="imageSrc"
                                :canvas-color="'primary'"
                                :disable-rotation="false"
                                :disable-click-to-choose="true"
                                :prevent-white-space="true"
                                :show-remove-button="false"
                                :width="300"
                                :height="300"
                                :show-loading="true"
                                :zoom-speed="8"
                        ></croppa>
                        <p class="mt-4 pb-0 grey--text">Skrolla eller nyp skärmen för att zooma eller använd knapparna
                            nedan.</p>
                    </div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn fab small class="elevation-2 white mr-10"
                                   v-on="on"
                                   @click="rotateLeft"
                            >
                                <v-icon color="primary">mdi-rotate-left</v-icon>
                            </v-btn>
                        </template>
                        <span>Rotera vänster</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn fab small class="elevation-2 white mr-4"
                                   v-on="on"
                                   @click="zoomOut"
                            >
                                <v-icon color="secondary">mdi-magnify-minus-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Zooma ut</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn fab small class="elevation-2 white ml-4"
                                   v-on="on"
                                   @click="zoomIn"
                            >
                                <v-icon color="secondary">mdi-magnify-plus-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Zooma in</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn fab small class="elevation-2 white ml-10"
                                   v-on="on"
                                   @click="rotateRight"
                            >
                                <v-icon color="primary">mdi-rotate-right</v-icon>
                            </v-btn>
                        </template>
                        <span>Rotera höger</span>
                    </v-tooltip>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text @click="dialogProp = false">Avbryt</v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="imageSaved">Spara</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import api from "../../api/api";

    export default {
        data()
        {
            return {
                croppa: null,
                loading: false
            }
        },
        props: {
            image: Object,
            dialog: Boolean,
            closeOnSave: Boolean,
        },
        computed: {
            dialogProp: {
                get: function ()
                {
                    return this.dialog;
                },
                set: function (newValue)
                {
                    this.$emit('update:dialog', newValue)
                }
            },
            imageSrc()
            {
                if (this.image && this.image.src)
                {
                    return api.BASE_URL + this.image.src
                }
                return ""
            }
        },
        methods: {
            async uploadCroppedImage()
            {
                let blob = await this.croppa.promisedBlob()
                return await api.postMultipartToEndpoint(api.ENDPOINTS.FILES, blob)
            },
            async imageSaved()
            {
                this.loading = true
                let uploadedFiles = await this.uploadCroppedImage()
                this.$emit("imageSaved", uploadedFiles[0])
                this.loading = false
                if (this.closeOnSave)
                {
                    this.dialogProp = false
                }
            },
            rotateRight()
            {
                this.croppa.rotate();
            },
            rotateLeft()
            {
                this.croppa.rotate(-1);
            },
            zoomIn()
            {
                this.croppa.zoom(1, 5);
            },
            zoomOut()
            {
                this.croppa.zoom(0, 5);
            }
        }
    }
</script>
