<template>
  <v-card flat :loading="loading" :disabled="loading">
    <v-list-item @click="itemClicked">
      <v-list-item-content>
        <v-list-item-title> {{ website.url }}</v-list-item-title>
        <v-list-item-subtitle> Skapad: {{ website.createdAt | calendarDate($moment) }}</v-list-item-subtitle>
        <v-list-item-subtitle>Senast ändrad: {{ website.updatedAt | calendarDate($moment) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-chip v-if="website.published" label color="primary">Publicerad
          <v-icon small right>mdi-check</v-icon>
        </v-chip>
      </v-list-item-action>
    </v-list-item>
    <website-edit-menu-component @changePublished="changePublishStatus" @duplicate="duplicateWebsite" @edit="editWebsite" @delete="deleteWebsite"
                                 :booking-object="bookingObject" :x-pos="menuX" :y-pos="menuY" :value.sync="showEdit"
                                 :website="website"></website-edit-menu-component>
  </v-card>
</template>

<script>
import WebsiteEditMenuComponent from "@/components/website/WebsiteEditMenuComponent";
import api from "@/api/api";

export default {
  components: {WebsiteEditMenuComponent},
  data: () => ({
    loading: false,
    showEdit: false,
    menuX: 0,
    menuY: 0,
  }),
  props: {
    website: Object,
    bookingObject: Object,
  },
  filters: {
    calendarDate(val, moment) {
      return moment(val).calendar()
    }
  },
  methods: {
    itemClicked(ev) {
      ev.preventDefault()
      this.menuX = ev.clientX
      this.menuY = ev.clientY
      this.showEdit = false
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.showEdit = true
        }, 30)
      })
    },
    async changePublishStatus() {
      this.loading = true
      let response = await api.putToEndpoint(api.ENDPOINTS.WEBSITES + "/" + this.website.id + "/" + api.ENDPOINTS.PUBLISHED)
      if (response != null) {
        this.$store.commit('setSnackbar', {color: "success", text: "Ändringar sparade!"})
        this.notifyRefresh()
      }
      this.loading = false
    },
    async duplicateWebsite() {
      {
        this.$emit('duplicate', this.website.id, false)
      }
    },
    editWebsite() {
      this.loading = true
      window.setTimeout(() => {
        location.href = '/websites/edit/'+ this.website.id
      }, 250)
    },
    async deleteWebsite() {
      this.loading = true
      let response = await api.deleteReqToEndpoint(api.ENDPOINTS.WEBSITES + "/" + this.website.id)
      if (response != null) {
        this.$store.commit('setSnackbar', {color: "primary", text: "Webbplats raderad!"})
        this.notifyRefresh()
      }
      this.loading = false
    },
    notifyRefresh() {
      this.$emit('refresh')
    }
  }
}
</script>
