<template>
  <div>
    <v-row class="py-2" justify="center">
      <v-col
          cols="12"
          md="6"
      >
        <v-card
            :disabled="loading"
            :loading="loading"
            outlined
            rounded
        >
          <div class="d-flex flex-no-wrap justify-space-between primary white--text">
            <div v-if="!loading">
              <v-card-title
                  v-if="accountSubscription"
                  class="headline d-block"
              >
                Prenumeration: <br><span v-if="subscriptionType" class="font-weight-bold">{{
                  subscriptionType.name
                }}</span>
              </v-card-title>
              <v-card-subtitle v-if="accountSubscription && subscriptionType" class="white--text mt-1">
                Giltig t.o.m: <b v-if="!accountSubscription.expiresAt">Tills vidare</b> <b
                  v-else>{{ $moment(accountSubscription.expiresAt).format('L') }}</b>
                <!-- TODO - Alert icon if subscription is expired -->
                <br>Senast ändrad: <b>{{ $moment(this.accountSubscription.updatedAt).calendar() }}</b>
                <h4 v-if="hasSeats" class="mb-1">Antal uthyrningsobjekt: {{ accountSubscription.seats }}</h4>
                <v-divider dark></v-divider>
                <h2 class="pb-2 mt-3">{{ subscriptionPrice }}</h2>
              </v-card-subtitle>
            </div>
            <logo-component class="ma-4" color="white" height="76px" width="76px"></logo-component>
          </div>
          <v-card-text v-if="loading">
            <p class="text-center my-10">Hämtar data...</p>
          </v-card-text>
          <v-card-text v-else-if="subscriptionType" v-html="subscriptionType.description">
          </v-card-text>
          <v-card-actions class="mb-2 mt-4">
            <v-btn
                color="error darken-1"
                plain
                @click="confirmDeleteAccount = true"
            >
              Avsluta konto
            </v-btn>
            <v-spacer></v-spacer>
            <account-subscription-manage-button-component color="primary" :account-subscription="accountSubscription" @openSubscriptionDialog="subscriptionDialog = true"></account-subscription-manage-button-component>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="accountSubscription">
      <v-col cols="12">
        <v-subheader>Historik</v-subheader>
        <v-divider></v-divider>
        <v-list two-line>
          <v-list-item v-for="item in accountSubscription.history" :key="item.id">
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              <v-list-item-subtitle>{{ $moment(item.createdAt).calendar() }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <account-subscription-dialog-component v-if="accountSubscription" :dialog.sync="subscriptionDialog"
                                           :subscription.sync="accountSubscription"></account-subscription-dialog-component>
    <delete-account-dialog-component :dialog.sync="confirmDeleteAccount"></delete-account-dialog-component>
  </div>
</template>

<script>

import LogoComponent from "@/components/global/LogoComponent";
import api from "@/api/api";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";
import AccountSubscriptionDialogComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionDialogComponent";
import accountSubscriptionPrices from "@/models/accountSubscriptionPrices";
import AccountSubscriptionManageButtonComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionManageButtonComponent";
import DeleteAccountDialogComponent from "@/components/useraccount/accountsubscription/DeleteAccountDialogComponent";

export default {
  components: {
    DeleteAccountDialogComponent,
    AccountSubscriptionManageButtonComponent, AccountSubscriptionDialogComponent, LogoComponent},
  data: () => ({
    validForm: true,
    loading: false,
    loadingCustomerPortal: false,
    accountSubscription: null,
    subscriptionDialog: false,
    prices: accountSubscriptionPrices.PRICES,
    confirmDeleteAccount: false,
  }),
  computed: {
    stateUser()
    {
      return this.$store.state.user
    },
    subscriptionType()
    {
      if (this.accountSubscription)
      {
        return accountSubscriptionTypes.TYPES[this.accountSubscription.type]
      }
      return null
    },
    hasSeats()
    {
      return accountSubscriptionTypes.TYPES[this.accountSubscription.type].hasSeats
    },
    totalPrice()
    {
      if (this.accountSubscription && this.accountSubscription.stripePriceId)
      {
        let price = this.prices.find((price) => price.id === this.accountSubscription.stripePriceId)
        if (price)
        {
          let seatsPrice = 0
          if (this.accountSubscription.seats > 1)
          {
            seatsPrice = (this.accountSubscription.seats - 1) * price.seatPriceInt
          }
          return price.priceInt + seatsPrice
        }
      }
      return "Totalpris saknas"
    },
    subscriptionPrice()
    {
      if (this.accountSubscription && this.accountSubscription.stripePriceId)
      {
        let price = this.prices.find((price) => price.id === this.accountSubscription.stripePriceId)
        if (price)
        {
          return this.totalPrice + "kr / " + price.paymentInterval.altText
        }
        return "Pris saknas"
      }
      return this.subscriptionType.price
    }
  },
  mounted()
  {
    this.getUserAccountSubscription()
  },
  methods: {
    async getUserAccountSubscription()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.ACCOUNTSUBSCRIPTION + "?includeHistory=true")
      if (serverResponse)
      {
        this.accountSubscription = serverResponse
      }
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
