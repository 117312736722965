<template>
  <v-card flat :loading="loading" :disabled="loading">
    <v-list three-line v-if="dynamicDocuments.length > 0">
      <v-list-item
          v-for="item in dynamicDocuments"
          :key="item.title"
          @click="itemClick(item)"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text v-if="showUpdatedAt">
          Senast ändrad: {{ getFormattedDate(item.updatedAt) }}
        </v-list-item-action-text>
      </v-list-item>
    </v-list>
    <div v-else-if="loading" class="mb-10">
      <v-progress-linear height="5px" indeterminate></v-progress-linear>
      <p class="text-center mt-8 grey--text">Hämtar data...</p>
    </div>
    <no-data-component v-else-if="adminView" class="mt-14 mb-10" icon="arrow_upward" pulse text="Du har inte skapat några dynamiska dokument än. Använd
            plus-knappen längst upp till höger för att göra det"></no-data-component>
    <no-data-component v-else class="mt-14 mb-10" icon="mdi-file-hidden"
                       text="Du har inte skapat några dynamiska dokument än. Välj dynamiska dokument i huvudmenyn till vänster för att administrera dina dynamiska dokument"></no-data-component>
  </v-card>
</template>

<script>

import NoDataComponent from "@/components/global/NoDataComponent";
import api from "@/api/api";

export default {
  components: {NoDataComponent},
  props: {
    showUpdatedAt: Boolean,
    adminView: Boolean,
  },
  data: () => ({
    dynamicDocuments: [],
    loading: false,
  }),
  mounted()
  {
    this.getDynamicDocuments()
  },
  methods: {
    itemClick(item)
    {
      // Will emit on
      this.$emit('itemClick', item)
    },
    getFormattedDate(date)
    {
      return this.$moment(date).fromNow()
    },
    async getDynamicDocuments()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.DYNAMICDOCUMENTS)
      if (serverResponse)
      {
        this.dynamicDocuments = serverResponse
      }
      this.loading = false
    },
  },
}
</script>
<style>
.no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
