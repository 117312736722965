<template>
  <div :style="'height: ' + this.data.height + 'px; width: 100%;'"></div>
</template>

<script>

export default {
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
  }
}
</script>
