,
<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card color="white">
                    <v-toolbar color="secondary" dark>
                        <v-toolbar-title class="text-center">E-postinnehåll</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-expansion-panels :value="openedIndex" multiple flat>
                            <v-expansion-panel class="outlined">
                                <v-expansion-panel-header>
                                    <span>
                                        <v-icon color="primary" left>mdi-credit-card-settings-outline</v-icon>
                                        Betalningsförfrågningar
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="elevation-0">
                                    <payment-request-text-component></payment-request-text-component>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="outlined mt-4">
                                <v-expansion-panel-header>
                                    <span>
                                        <v-icon color="primary" left>mdi-email-outline</v-icon>
                                        Övriga utskick
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="elevation-0">
                                    <email-content-component></email-content-component>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import PaymentRequestTextComponent from "../../components/bookingperiod/paymentrequest/PaymentRequestTextComponent";
    import EmailContentComponent from "../../components/emailcontent/EmailContentComponent";

    export default {
        components: {EmailContentComponent, PaymentRequestTextComponent},
        data: () => ({
            openedIndex: null
        }),
    }
</script>
