const INTERVALS = {
    MONTHLY: {
        value: 'MONTHLY',
        text: "Månadsvis",
        altText: "månad"
    },
    YEARLY: {
        value: 'YEARLY',
        text: "Årsvis",
        altText: "år"
    },
}

export default {
    INTERVALS
}
