<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card :loading="loading" outlined>
            <v-subheader>
              <v-badge
                  :content="bookingPeriod.files.length"
                  :value="bookingPeriod.files && bookingPeriod.files.length > 0"
                  color="accent"
                  inline
                  left
              >
                <span class="font-weight-bold">Filer</span>
              </v-badge>
              <v-spacer></v-spacer>
              <v-menu v-model="addNew" bottom left nudge-bottom="5" offset-y
                      transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :class="{'highlight-pulse': bookingPeriod.files.length <= 0}" color="primary" fab
                         outlined
                         small
                  >
                    <v-icon>{{ addNew ? 'mdi-close' : 'mdi-plus' }}</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item @click="fileUploadDialog = true">
                    <v-list-item-title>Ladda upp filer</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="dynamicDocumentsDialog = true">
                    <v-list-item-title>Skapa fil från dynamiskt dokument</v-list-item-title>
                    <v-list-item-action>
                      <v-icon color="accent">mdi-file-document-outline</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-subheader>
            <v-divider></v-divider>
            <v-card-text>
              <file-library-component ref="files" :files="bookingPeriod.files" show-actions
                                      @fileDeleted="fileRemoved"
                                      @fileUpdated="filesUpdated"></file-library-component>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="fileUploadDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Ladda upp filer
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <file-upload-component :max-filesize="10"
                                 :maxfiles="25" @fileUploaded="filesAttached"></file-upload-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="fileUploadDialog = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dynamicDocumentsDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Välj dynamiskt dokument
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-file-document-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <dynamic-documents-list-component @itemClick="selectDynamicDocument"></dynamic-documents-list-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dynamicDocumentsDialog = false">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dynamic-document-to-file-dialog-component eager @saved="dynamicDocumentSaved" :booking-period="bookingPeriod"
                                               :dialog.sync="dynamicDocumentToFileDialog"
                                               :selected-dynamic-document="selectedDynamicDocument"></dynamic-document-to-file-dialog-component>
  </div>
</template>

<script>
import FileUploadComponent from "../../global/file/FileUploadComponent";
import FileLibraryComponent from "../../global/file/FileLibraryComponent";
import DynamicDocumentsListComponent from "@/components/dynamicdocument/DynamicDocumentsListComponent";
import DynamicDocumentToFileDialogComponent from "@/components/dynamicdocument/DynamicDocumentToFileDialogComponent";

export default {
  components: {
    DynamicDocumentToFileDialogComponent,
    DynamicDocumentsListComponent,
    FileLibraryComponent,
    FileUploadComponent
  },
  data: () => ({
    fileUploadDialog: false,
    dynamicDocumentsDialog: false,
    dynamicDocumentToFileDialog: false,
    uploadTimer: null,
    addNew: false,
    selectedDynamicDocument: null
  }),
  props: {
    bookingPeriod: Object,
    loading: Boolean
  },
  methods: {
    saveBookingPeriod()
    {
      this.$emit('saveBookingPeriod')
    },
    filesAttached(response, file) // when a file is uploaded we update the object
    {
      response.forEach((fileData) =>
      {
        let bookingPeriodFile = {
          name: file.name,
          fileData: fileData,
          bookingPeriodId: this.bookingPeriod.id
        }
        this.bookingPeriod.files.push(bookingPeriodFile)
      })
      // update the bookingPeriod if it has an id (with debounce incase multiple files trigger this method)
      clearTimeout(this.uploadTimer)
      this.uploadTimer = setTimeout(async () =>
      {
        if (this.bookingPeriod.id > 0)
        {
          this.saveBookingPeriod()
        }
      }, 650)
    },
    filesUpdated()
    {
      if (this.bookingPeriod.id > 0)
      {
        this.saveBookingPeriod()
      }
    },
    fileRemoved()
    {
      if (this.bookingPeriod.id > 0)
      {
        this.saveBookingPeriod()
      }
    },
    selectDynamicDocument(dynamicDocument)
    {
      this.selectedDynamicDocument = Object.assign({}, dynamicDocument)
      this.dynamicDocumentToFileDialog = true
    },
    dynamicDocumentSaved(fileData)
    {
      this.dynamicDocumentToFileDialog = false
      this.bookingPeriod.files.push(fileData)
      this.$store.commit('setSnackbar', {color: "success", text: "Dynamiskt dokument sparad som fil i nuvarande period"})
      this.dynamicDocumentsDialog = false
    },
  }
}
</script>

<style scoped>
</style>
