<template>
    <v-slide-y-transition>
        <v-alert class="mt-6 ml-3 mr-3 text-center" type="error" outlined color="primary"
                 v-if="collidingDates && collidingDates.length > 0 && bookingPeriod.bookingObject && bookingPeriod.bookingObject.id && bookingPeriod.bookingObject.id > 0">
            Det valda uthyrningsobjektet har redan följande perioder med angivet datum:
            <div class="mt-2">
                <v-chip :to="{name: 'bookingPeriodDetails', params: {id: bookingPeriod.id}}"
                        :key="'conflict_'+bookingPeriod.id" color="primary"
                        v-for="bookingPeriod in collidingDates">{{bookingPeriod.name}}
                </v-chip>
            </div>
        </v-alert>
    </v-slide-y-transition>
</template>

<script>
    export default {
        data: () => ({

        }),
        props: {
            bookingPeriod: Object,
            collidingDates: Array
        }
    }
</script>

<style scoped>

</style>
