<template>
  <v-card :disabled="loading" :loading="loading" flat v-if="section && section.data" class="text-center">
    <v-slide-x-transition mode="out-in">
      <v-card v-if="fileUpload">
        <file-upload-component flat @fileUploaded="imageUploaded"
                               acceptedFileTypes="image/*" :max-filesize="10"></file-upload-component>
        <v-card-actions>
          <v-btn color="primary" @click="cancel">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="filePicker">
        <image-picker-component :images="bookingObjectImages"
                                @imageSelected="imageSelected"
                                bigger-tiles
                                close-on-click></image-picker-component>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="cancel">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
      <v-menu close-on-content-click v-else v-model="menu"
              transition="slide-y-reverse-transition">
        <template v-slot:activator="{on}">
          <avatar-admin-component v-on="on" tile :avatar-src="selectedImage"
                                  :size="256"
                                  @avatarClick="menu = true"
          ></avatar-admin-component>
        </template>
        <v-list class="pa-0">
          <v-list-item @click="fileUpload = true">
            <v-list-item-title>Ladda upp ny bild</v-list-item-title>
            <v-list-item-action>
              <v-icon color="accent-darken">mdi-cloud-upload-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="filePicker = true">
            <v-list-item-title>Välj bild från uthyrningsobjekt</v-list-item-title>
            <v-list-item-action>
              <v-icon color="primary">mdi-file-cabinet</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-slide-x-transition>
    <v-alert type="error" dense border="right" elevation="1" colored-border transition="slide-x-transition"
             :value="!section.data.bookingObjectFile && !filePicker && !fileUpload">
      <span class="text-left grey--text text--darken-2">Du måste välja en bild för att kunna spara sektionen</span>
    </v-alert>
    <v-text-field
        outlined
        v-model="section.data.name"
        prepend-icon="mdi-format-text"
        @keyup.enter="keyupEnter"
        hint="Vad bilden ska ha för namn"
        :rules="requiredRules"
        class="mt-8"
        label="Namn">
    </v-text-field>
    <v-text-field v-model="section.data.bookingObjectFile" :rules="requiredRules" v-show="false">
    </v-text-field>
    <v-checkbox label="Beskär automatiskt" :false-value="true" :true-value="false" v-model="section.data.contain"></v-checkbox>
    <v-subheader class="pl-0">Bredd</v-subheader>
    <v-slider
        v-model="section.data.width"
        :max="100"
        :min="10"
        step="5"
        thumb-label="always"
        :thumb-size="45"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value }}%
      </template>
    </v-slider>
    <v-subheader class="pl-0">Höjd</v-subheader>
    <v-checkbox class="mb-12" label="Fast höjd" @change="heightEnabledChanged" v-model="heightEnabled"></v-checkbox>
    <v-slide-y-transition>
      <v-slider
          v-model="section.data.height"
          :max="2000"
          :min="100"
          step="5"
          thumb-label="always"
          :thumb-size="45"
          v-if="heightEnabled"
      >
        <template v-slot:thumb-label="{ value }">
          {{ value }}px
        </template>
      </v-slider>
    </v-slide-y-transition>
  </v-card>
</template>

<script>


import ImagePickerComponent from "@/components/global/ImagePickerComponent";
import filetypes from "@/models/filetypes";
import FileUploadComponent from "@/components/global/file/FileUploadComponent";
import api from "@/api/api";
import AvatarAdminComponent from "@/components/global/AvatarAdminComponent";

export default {
  components: {AvatarAdminComponent, FileUploadComponent, ImagePickerComponent},
  data: () =>
  {
    return {
      menu: false,
      fileUpload: false,
      filePicker: false,
      loading: false,
      heightEnabled: true,
      defaultHeight: 400,
      defaultWidth: 100,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ]
    }
  },
  props: {
    value: Object,
    website: Object,
    bookingObject: Object
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
    bookingObjectProp: {
      get()
      {
        return this.bookingObject
      },
      set(val)
      {
        this.$emit('update:bookingObject', val)
      }
    },
    bookingObjectImages() // filtered array with only images
    {
      return this.bookingObjectProp.files.filter(file => file.fileData.filetype === filetypes.TYPES.IMAGE)
    },
    selectedImage()
    {
      if (this.section.data.bookingObjectFile && this.section.data.bookingObjectFile.fileData.src)
      {
        return api.BASE_URL + this.section.data.bookingObjectFile.fileData.src
      }
      return ""
    },
  },
  async mounted()
  {
    this.handleInitData()
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    cancel()
    {
      this.filePicker = false
      this.fileUpload = false
      this.menu = false
    },
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          bookingObjectFile: null,
          name: null,
          contain: false,
          width: this.defaultWidth,
          height: this.defaultHeight
        }
      }
    },
    imageSelected(bookingObjectFile)
    {
      this.section.data.bookingObjectFile = bookingObjectFile
      this.section.data.name = bookingObjectFile.name
      this.cancel()
    },
    async imageUploaded(response, file)
    {
      let fileData = response[0]
      let bookingObjectFile = {
        name: file.name,
        fileData: fileData,
        bookingObjectId: this.bookingObject.id
      }
      this.bookingObject.files.push(bookingObjectFile)
      await this.saveBookingObject()
      this.section.data.bookingObjectFile = this.bookingObject.files[this.bookingObject.files.length - 1]
      this.section.data.name = bookingObjectFile.name
      this.cancel()
    },
    async saveBookingObject() // when an image is uploaded we update the BookingObject
    {
      this.loading = true
      this.bookingObjectProp = await api.putToEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObjectProp.id, this.bookingObjectProp)
      if (this.bookingObjectProp)
      {
        this.$emit('showSnackbar', "success", "Bilden har laddats upp och sparats i uthyrningsobjektet")
      }
      this.loading = false
    },
    heightEnabledChanged() {
      if(!this.section.data.height)
      {
        this.section.data.height = this.defaultHeight
      }
      else
      {
        this.section.data.height = null
      }
    }
  }
}
</script>
