<template>
  <div>
    <v-menu v-if="canBeManaged" offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip :disabled="!typeIsPioneer" open-delay="200" top>
          <template v-slot:activator="{ on: tooltip }">
                    <span
                        v-on="{ ...tooltip }">
                      <v-btn
                          v-bind="attrs"
                          v-on="{...menu}"
                          :disabled="typeIsPioneer"
                          :loading="loadingCustomerPortal"
                          :color="color"
                          text
                          :dark="dark"
                      >
                        Hantera prenumeration
                      </v-btn>
                    </span>
          </template>
          <span>Pionjärer behöver aldrig ändra sin prenumeration 😎</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item @click="toStripeCustomerPortal">
          <v-list-item-title>Kortuppgifter</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="primary">mdi-credit-card-settings-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="$emit('openSubscriptionDialog')">
          <v-list-item-title>Ändra prenumeration</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="accent">mdi-account-settings-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
        v-else-if="canBeUpgraded"
        :color="color"
        text
        :dark="dark"
        @click="$emit('openSubscriptionDialog')"
    >
      Uppgradera prenumeration
    </v-btn>
  </div>
</template>

<script>

import api from "@/api/api";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";

export default {
  components: {},
  data: () => ({
    validForm: true,
    loading: false,
    loadingCustomerPortal: false,
  }),
  props: {
    accountSubscription: Object,
    dark: Boolean,
    color: String,
  },
  computed: {
    typeIsPioneer()
    {
      if (this.accountSubscription)
      {
        return accountSubscriptionTypes.TYPES[this.accountSubscription.type] === accountSubscriptionTypes.TYPES.PIONEER
      }
      return false
    },
    canBeManaged()
    {
      if (this.accountSubscription)
      {
        return accountSubscriptionTypes.TYPES[this.accountSubscription.type] === accountSubscriptionTypes.TYPES.PREMIUM || this.typeIsPioneer
      }
      return false
    },
    canBeUpgraded()
    {
      if (this.accountSubscription)
      {
        return accountSubscriptionTypes.TYPES[this.accountSubscription.type] === accountSubscriptionTypes.TYPES.PREMIUM_TEST
      }
      return false
    },
  },
  methods: {
    toStripeCustomerPortal()
    {
      this.loadingCustomerPortal = true
      location.href = api.BASE_URL + api.API + api.ENDPOINTS.STRIPE + "/" + api.ENDPOINTS.PORTAL
    },
  }
}
</script>
