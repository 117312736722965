<template>
    <div id="selector" class="clickable">
        <div id="selector-top"></div>
        <div id="selector-left"></div>
        <div id="selector-right"></div>
        <div id="selector-bottom"></div>
    </div>
</template>

<script>
    import unique from 'unique-selector';

    export default {
        data: () => ({
            elements: null,
            target: null
        }),
        mounted()
        {
            this.elements = {
                top: document.getElementById('selector-top'),
                left: document.getElementById('selector-left'),
                right: document.getElementById('selector-right'),
                bottom: document.getElementById('selector-bottom')
            }
            window.addEventListener('mousemove', this.mouseMove);
            window.addEventListener('click', this.targetChosen);
        },
        destroyed()
        {
            window.removeEventListener('mousemove', this.mouseMove);
            window.removeEventListener('click', this.targetChosen);
        },
        methods: {
            targetChosen()
            {
                this.$emit('targetChosen', this.target)
            },
            mouseMove(event)
            {

                var id = event.target.id, tagName = event.target.tagName;

                if (id.indexOf('selector') !== -1 || tagName === 'BODY' || tagName === 'HTML')
                {
                    return;
                }

                let $target = event.target;
                this.target = unique($target)
                let targetOffset = $target.getBoundingClientRect()
                let targetHeight = targetOffset.height
                let targetWidth = targetOffset.width

                this.elements.top.style.left = (targetOffset.left - 4) + "px"
                this.elements.top.style.top = (targetOffset.top - 4) + "px"
                this.elements.top.style.width = (targetWidth + 5) + "px"

                this.elements.bottom.style.left = (targetOffset.left - 3) + "px"
                this.elements.bottom.style.top = (targetOffset.top + targetHeight + 1) + "px"
                this.elements.bottom.style.width = (targetWidth + 4) + "px"

                this.elements.left.style.left = (targetOffset.left - 5) + "px"
                this.elements.left.style.top = (targetOffset.top - 5) + "px"
                this.elements.left.style.height = (targetHeight + 8) + "px"

                this.elements.right.style.left = (targetOffset.left + targetWidth + 1) + "px"
                this.elements.right.style.top = (targetOffset.top - 4) + "px"
                this.elements.right.style.height = (targetHeight + 8) + "px"

            }
        }
    }
</script>

<style lang="scss" scoped>

    #selector-top, #selector-bottom {
        background: #a797e8;
        height: 3px;
        position: fixed;
        transition: all 300ms ease;
        z-index: 9999;
    }

    #selector-left, #selector-right {
        background: #a797e8;
        width: 3px;
        position: fixed;
        transition: all 300ms ease;
        z-index: 9999;
    }

</style>
