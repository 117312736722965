<template>
  <v-list subheader three-line>
    <v-progress-linear indeterminate :active="loadingConversations" color="primary"></v-progress-linear>
    <v-subheader class="mt-2">
      {{ showSubheader ? 'Konversationer' : '' }}
      <v-spacer></v-spacer>
      <v-menu
          v-model="fromDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="slide-y-transition"
          offset-y
          min-width="290px"
          v-if="allowDateFilter"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="fromDate"
              label="Senast inkommet meddelande efter"
              prepend-icon="event"
              readonly
              v-on="on"
              class="mr-6"
              style="max-width: 300px"
              hide-details
          ></v-text-field>
        </template>
        <v-date-picker @change="getConversations" :max="new Date().toISOString()" v-model="fromDate"
                       @input="fromDateMenu = false"></v-date-picker>
      </v-menu>
      <v-switch label="Visa arkiverade" color="primary" @change="getConversations"
                v-model="includeArchived" v-if="allowArchive"
      ></v-switch>
    </v-subheader>
    <transition mode="out-in" name="slide-x-transition">
      <no-data-component class="mb-12 mt-0 pt-0 pl-5 pr-5" v-if="viewOnlyList && conversations.length <= 0"
                         text="Inga konversationer att visa"
                         icon="mdi-message-bulleted-off"></no-data-component>
      <no-data-component class="mb-12 mt-0 pt-8 pl-5 pr-5" v-else-if="conversations.length <= 0" text="Det finns inga konversationer att visa med valda filter.
                        Skapa en ny konversation från ett meddelande i inkorgen eller använd knappen uppe till höger för att skapa ett nytt meddelande. Alternativt justera datum för senast inkommet meddelande"
                         icon="mdi-arrow-up" pulse></no-data-component>
      <transition-group v-else name="slide-x-transition">
        <conversation-list-item-component
            v-for="item in conversationsWithDividers"
            :key="item.name + item.id"
            :item="item"
            :allow-archive="allowArchive"
            @confirmArchive="confirmArchive"
            @archiveConversation="archiveConversation"
        >
        </conversation-list-item-component>
      </transition-group>
    </transition>
  </v-list>
</template>

<script>
import NoDataComponent from "../../components/global/NoDataComponent";
import api from "../../api/api";
import ConversationListItemComponent from "./ConversationListItemComponent";

export default {
  components: {ConversationListItemComponent, NoDataComponent},
  data: () => ({
    loadingConversations: false,
    conversations: [],
    includeArchived: false,
    fromDateMenu: false,
    fromDate: "2020-01-01"
  }),
  props: {
    showSubheader: Boolean,
    allowArchive: Boolean,
    allowDateFilter: Boolean,
    conversationsFromBookingPeriod: Boolean,
    bookingPeriod: Object,
    viewOnlyList: Boolean,
  },
  computed: {
    conversationsWithDividers()
    {
      let filteredConversations = []
      this.conversations.forEach((item, index) =>
      {
        filteredConversations.push(item)
        if (index + 1 < this.conversations.length)
        {
          filteredConversations.push({divider: true, id: index, name: 'divider'})
        }
        index++
      })
      return filteredConversations
    }
  },
  mounted()
  {
    let fromMonths = this.allowDateFilter && this.conversationsFromBookingPeriod ? 24 : 2
    this.fromDate = this.$moment().subtract(fromMonths, 'months').format("YYYY-MM-DD")
    this.getConversations()
  },
  methods: {
    async getConversations() // must be outside the listComponent since we might want to apply filters
    {
      let baseEndpoint = this.conversationsFromBookingPeriod ? api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id : api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id
      let fullEndpoint = baseEndpoint + "/" + api.ENDPOINTS.CONVERSATIONS + "?includeArchived=" + this.includeArchived + "&fromDate=" + this.fromDate
      this.loadingConversations = true
      let serverResponse = await api.getFromEndpoint(fullEndpoint)
      if (serverResponse)
      {
        this.conversations = serverResponse
      }
      this.loadingConversations = false
    },
    confirmArchive(item)
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Arkivera",
        title: "Bekräfta arkivering",
        text: `Är du säker på att du vill arkivera konversationen?`,
        actionClick: () =>
        {
          this.archiveConversation(item)
        }
      })
    },
    async archiveConversation(conversation)
    {
      conversation.archived = 1
      this.$store.commit('setAppIsLoading', true)
      await api.deleteReqToEndpoint(api.ENDPOINTS.CONVERSATIONS + "/" + conversation.id, conversation)
      this.conversations.splice(this.conversations.findIndex((item) => item.id === conversation.id), 1)
      this.$store.commit('setAppIsLoading', false)
      this.$store.commit('setSnackbar', {color: "primary", text: "Konversation arkiverad"})
    },
  }
}
</script>
