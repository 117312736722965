<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <booking-object-details-component :unsaved-changes.sync="unsavedChanges" :seats-left="seatsLeft"></booking-object-details-component>
      </v-flex>
    </v-layout>
    <unsaved-changes-dialog-component ref="unsaved" :value.sync="unsavedChanges"></unsaved-changes-dialog-component>
  </v-container>
</template>

<script>
import BookingObjectDetailsComponent from "../../components/bookingobject/BookingObjectDetailsComponent";
import api from "@/api/api";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";
import UnsavedChangesDialogComponent from "@/components/global/UnsavedChangesDialogComponent";

export default {
  components: {UnsavedChangesDialogComponent, BookingObjectDetailsComponent},
  data: () => ({
    bookingObjects: [], // used to check if there are seats left
    unsavedChanges: false,
  }),
  mounted()
  {
    this.getBookingObjects()
  },
  computed: {
    seatsLeft() {
      return this.bookingObjectsMaxLimit - this.bookingObjects.length
    },
    stateUser() {
      return this.$store.state.user
    },
    bookingObjectsMaxLimit() {
      let defaultLimit = 150 // Limit pioneers and testers to 150 for now
      return this.hasSeats && this.subscriptionIsStandardRenter ? this.stateUser.accountSubscription.seats : defaultLimit
    },
    hasSeats() {
      return accountSubscriptionTypes.TYPES[this.stateUser.accountSubscription.type].hasSeats
    },
    subscriptionIsStandardRenter() {
      return accountSubscriptionTypes.TYPES[this.stateUser.accountSubscription.type] === accountSubscriptionTypes.TYPES.PREMIUM
    }
  },
  methods: {
    async getBookingObjects()
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGOBJECTS)
      if (serverResponse)
      {
        this.bookingObjects = serverResponse
      }
    },
  },
  beforeRouteLeave(to, from, next)
  {
    if(this.$refs.unsaved)
    {
      this.$refs.unsaved.beforeRouteLeave(to, from, next)
    }
  },
}
</script>
<style scoped>

</style>
