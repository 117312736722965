const TYPES = {
    PAYMENT_REGISTERED: {
        value: 'PAYMENT_REGISTERED',
        text: "Betalning",
        color: "success"
    },
    PAYMENT_REFUNDED: {
        value: 'PAYMENT_REFUNDED',
        text: "Återbetalning",
        color: "error"
    },
    PAYMENT_REQUEST_SENT: {
        value: 'PAYMENT_REQUEST_SENT',
        text: "Förfrågan skickad",
        color: "blue"
    },
    PAYMENT_REQUEST_REMINDER_SENT: {
        value: 'PAYMENT_REQUEST_REMINDER_SENT',
        text: "Påminnelse skickad",
        color: "primary"
    },
    PAYMENT_REQUEST_CANCELLED: {
        value: 'PAYMENT_REQUEST_CANCELLED',
        text: "Förfrågan inställd",
        color: "error",
    },
    BOOKING_PERIOD_PRICE_CHANGED: {
        value: 'BOOKING_PERIOD_PRICE_CHANGED',
        text: "Pris ändrat",
        color: "accent"
    },
    BOOKING_PERIOD_PRICE_SET: {
        value: 'BOOKING_PERIOD_PRICE_SET',
        text: "Pris satt",
        color: "grey"
    }
}

export default {
    TYPES
}
