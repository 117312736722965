<template>
  <div>
    <v-alert data-cy="introAlert" color="primary" outlined type="info">Innan du kan börja använda Uthyrd ska du få göra några val, lägga
      in dina uthyrningsobjekt och även ange din
      information. Klicka på knappen längst ned till höger för att sätta igång.
    </v-alert>
    <div class="mt-6">
      <h5>Om Uthyrd</h5>
      <p class="mb-3">Uthyrd är ett modernt webbbaserat uthyrningsverktyg där du sköter uthyrningen av ditt boende
        själv.
        Du får full koll, bestämmer allt själv och betalar endast en låg månadskostnad. Enklare kan det inte bli. </p>
      <h4>Några av Uthyrds fördelar:</h4>
      <v-container fluid>
        <v-row>
          <v-col
              :key="item.title"
              cols="12"
              :xl="4"
              :md="6"
              :sm="12"
              v-for="item in highlights"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card outlined class="text-center" :elevation="hover ? 4 : 0"
                      :height="$vuetify.breakpoint.smAndDown ? '220' : $vuetify.breakpoint.xlOnly ? '280' : '310'">
                <div class="pt-6">
                  <v-icon :color="item.iconColor" x-large>{{ item.icon }}</v-icon>
                </div>
                <v-card-subtitle class="font-weight-bold">{{ item.title }}</v-card-subtitle>
                <v-card-text>{{ item.description }}</v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    highlights: [
      {
        title: "Allt du hyr ut på ett och samma ställe",
        icon: "mdi-calendar-multiple-check",
        iconColor: "primary",
        description: "Oavsett vad, hur ofta och när du hyr ut så kan du se allt i Uthyrd och i din kalender"
      },
      {
        title: "Skapa hemsidor och marknadsför det du hyr ut",
        icon: "mdi-web",
        iconColor: "indigo",
        description: "Med Uthyrd kan du skapa hemsidor för dina uthyrningsobjekt. Du når då ut till dina kunder som kan läsa om vad du har att erbjuda och boka direkt. " +
            "Bokningen kommer självklart in i Uthyrd där du enkelt kan hantera den."
      },
      {
        title: "Samlade konversationer med dina kunder",
        icon: "mdi-message-text-outline",
        iconColor: "accent",
        description: "Alla mejlkonversationer hamnar direkt i Uthyrd och du kan koppla de till dina kunder, objekt och perioder så att du alltid kan se exakt vilka diskussioner du fört"
      },
      {
        title: "Full koll på betalningar från kunden",
        icon: "mdi-credit-card-outline",
        iconColor: "success",
        description: "På ett bara ett ögonblick kan du se saldo per uthyrningsperiod och hålla reda på vem som har betalt hur mycket och när"
      },
      {
        title: "Prenumeration på dina uthyrningsperioder",
        icon: "mdi-calendar-range-outline",
        iconColor: "secondary",
        description: "Se dina uthyrningsperioder i alla dina kalendrar oavsett vilken enhet du använder dig utav genom att prenumerera på Uthyrds kalender. Du kan även ställa in specifika filter så att du ser endast det du vill se."
      },
      {
        title: "Ditt alldeles egna kundregister",
        icon: "mdi-contact-mail-outline",
        iconColor: "purple darken-2",
        description: "Med Uthyrd kan du lägga in alla kunder som du haft eller potentiellt kan ha i framtiden i ditt kundregister"
      },
      {
        title: "Och mycket mer!",
        icon: "mdi-dots-horizontal-circle-outline",
        iconColor: "info",
        description: "Detta är bara en liten sammanfattning på det du kan förvänta dig av Uthyrd. Klicka dig vidare och sätt igång direkt!"
      }
    ]
  }),
  computed: {
    user()
    {
      return this.$store.state.user
    },
  },
}
</script>

<style scoped>

</style>
