const STATUSES = {
    sent: {
        value: 'sent',
        text: "Meddelande skickat",
        icon: "mdi-weather-cloudy-arrow-right",
        iconColor: "grey"
    },
    delivered: {
        value: 'delivered',
        text: "Meddelande mottaget",
        icon: "mdi-mailbox-outline",
        iconColor: "primary"
    },
    opened: {
        value: 'opened',
        text: "Meddelande öppnat av mottagaren",
        icon: "mdi-email-check-outline",
        iconColor: "success"
    },
    failed: {
        value: 'failed',
        text: "Misslyckades att skicka meddelandet",
        icon: "mdi-email-alert",
        iconColor: "error"
    }
}

export default {
    STATUSES
}
