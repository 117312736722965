<template>
  <v-list v-if="grouped" :dense="dense">
    <div :key="key" v-for="[key, value] in Object.entries(attributesByGroup)">
      <v-subheader class="pl-2">{{ key }}</v-subheader>
      <attribute-list-item-component
          v-for="item in value"
          :key="'attribute_' + item.id"
          :item="item"
      >
      </attribute-list-item-component>
    </div>
  </v-list>
  <v-list v-else :dense="dense">
    <attribute-list-item-component
        v-for="item in attributes"
        :key="'attribute_' + item.id"
        :item="item"
    >
    </attribute-list-item-component>
  </v-list>
</template>

<script>

import AttributeListItemComponent from "@/components/attribute/AttributeListItemComponent";

export default {
  components: {AttributeListItemComponent},
  props: {
    grouped: Boolean,
    dense: Boolean,
    bookingObjectAttributes: Array
  },
  computed: {
    attributes()
    {
      return this.bookingObjectAttributes
    },
    attributesByGroup()
    {
      return this.attributes.reduce((r, a) =>
      {
        r[a.attribute.attributeGroup.name] = [...r[a.attribute.attributeGroup.name] || [], a];
        return r;
      }, {});
    }
  },
}
</script>
