<template>
  <v-list-item
      :value="item.attribute.id"
  >
    <v-list-item-icon>
      <v-icon>{{ item.attribute.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ item.attribute.name }}</v-list-item-title>
      <v-list-item-subtitle v-if="item.attribute.hasValue === 1">
        {{ item.attribute.valueUnit }}: {{ item.value }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  props: {
    item: Object,
  },
}
</script>
