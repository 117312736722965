<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-btn icon outlined v-on="on" :disabled="!isPrevAvailable"
                 :to="prev"
                 :dark="dark">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </span>
      </template>
      <span v-if="isPrevAvailable">Gå till föregående uthyrningsperiod</span>
      <span v-else>Det finns ingen uthyrningsperiod före denna</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-btn icon outlined class="ml-4" :disabled="!isNextAvailable"
                 :to="next" :dark="dark">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </span>
      </template>
      <span v-if="isNextAvailable">Gå till nästa uthyrningsperiod</span>
      <span v-else>Det finns ingen uthyrningsperiod efter denna</span>
    </v-tooltip>
    <!-- BookingPeriodScrollerComponent -->
  </span>
</template>

<script>

import api from "@/api/api";

export default {
  data: () => ({
    prevPeriod: null,
    nextPeriod: null,
  }),
  props: {
    dark: Boolean,
    bookingPeriod: Object
  },
  computed: {
    isPrevAvailable()
    {
      return this.prevPeriod && this.prevPeriod.id > 0
    },
    isNextAvailable()
    {
      return this.nextPeriod && this.nextPeriod.id > 0
    },
    prev()
    {
      return this.prevPeriod ? {name: 'bookingPeriodDetails', params: {id: this.prevPeriod.id}} : null
    },
    next()
    {
      return this.nextPeriod ? {name: 'bookingPeriodDetails', params: {id: this.nextPeriod.id}} : null
    }
  },
  mounted()
  {
    this.getPrevious()
    this.getNext()
  },
  methods: {
    async getPrevious()
    {
      this.prevPeriod = null
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.BEFORE)
      if (serverResponse && serverResponse.id)
      {
        this.prevPeriod = serverResponse
      }
    },
    async getNext()
    {
      this.nextPeriod = null
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.AFTER)
      if (serverResponse && serverResponse.id)
      {
        this.nextPeriod = serverResponse
      }
    },
  }
}
</script>
