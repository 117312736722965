<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <dynamic-document-details-component :unsaved-changes.sync="unsavedChanges"></dynamic-document-details-component>
      </v-flex>
    </v-layout>
    <unsaved-changes-dialog-component ref="unsaved" :value.sync="unsavedChanges"></unsaved-changes-dialog-component>
  </v-container>
</template>

<script>
import DynamicDocumentDetailsComponent from "@/components/dynamicdocument/DynamicDocumentDetailsComponent";
import UnsavedChangesDialogComponent from "@/components/global/UnsavedChangesDialogComponent";

export default {
  components: {UnsavedChangesDialogComponent, DynamicDocumentDetailsComponent},
  data: () => ({
    unsavedChanges: false,
  }),
  beforeRouteLeave(to, from, next)
  {
    if(this.$refs.unsaved)
    {
      this.$refs.unsaved.beforeRouteLeave(to, from, next)
    }
  },
}
</script>
<style scoped>

</style>
