<template>
  <div>
    <div class="text-center mb-4">
      <avatar-admin-component :text="avatarText" :avatar-src="bookingObjectAvatar" :size="196"
                              @avatarClick="avatarClick"></avatar-admin-component>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card :loading="loading" outlined>
            <v-subheader>
              <v-badge
                  :value="bookingObject.files && bookingObject.files.length > 0"
                  color="accent"
                  inline
                  left
                  :content="bookingObject.files.length"
              >
                <span class="font-weight-bold">Filer</span>
              </v-badge>
              <v-spacer></v-spacer>
              <v-btn small color="primary" outlined :class="{'highlight-pulse': bookingObject.files.length <= 0}"
                     @click="fileUploadDialog = true">
                Ladda upp filer
                <v-icon class="ml-2">mdi-cloud-upload-outline</v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-card-text>
              <file-library-component :files="bookingObject.files" @fileDeleted="fileRemoved"
                                      @fileUpdated="filesUpdated"
                                      show-actions></file-library-component>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <image-picker-dialog v-if="bookingObject.id" :dialog.sync="pickingAvatar" :images="bookingObjectImages"
                         :loading="loading"
                         @imageSelected="selectImageAsAvatar"
                         close-on-click></image-picker-dialog>
    <v-dialog max-width="800px" v-model="fileUploadDialog">
      <v-card>
        <v-card-title>
          Ladda upp filer
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <file-upload-component :max-filesize="10"
                                 :maxfiles="25" @fileUploaded="filesAttached"></file-upload-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fileUploadDialog = false" text>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileUploadComponent from "../../global/file/FileUploadComponent";
import FileLibraryComponent from "../../global/file/FileLibraryComponent";
import filetypes from "../../../models/filetypes";
import ImagePickerDialog from "../../global/ImagePickerDialog";
import AvatarAdminComponent from "../../global/AvatarAdminComponent";
import api from "../../../api/api";

export default {
  components: {
    AvatarAdminComponent,
    ImagePickerDialog,
    FileLibraryComponent,
    FileUploadComponent
  },
  data: () => ({
    pickingAvatar: false,
    fileUploadDialog: false,
    uploadTimer: null
  }),
  props: {
    bookingObject: Object,
    loading: Boolean
  },
  computed: {
    bookingObjectAvatar()
    {
      if (this.bookingObject.fileData && this.bookingObject.fileData.src)
      {
        return api.BASE_URL + this.bookingObject.fileData.src
      }
      return ""
    },
    bookingObjectImages() // filtered array with only images
    {
      return this.bookingObject.files.filter(file => file.fileData.filetype === filetypes.TYPES.IMAGE)
    },
    avatarText()
    {
      return this.bookingObject.id ? null : "Spara uthyrningsobjektet för att kunna välja en bild"
    },
    selectedFiles() {
      return this.bookingObject.files.filter((file) => file.selected)
    }
  },
  methods: {
    saveBookingObject()
    {
      this.$emit('saveBookingObject')
    },
    selectImageAsAvatar(selectedImage)
    {
      this.bookingObject.fileData = selectedImage.fileData
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    filesAttached(response, file) // when a file is uploaded we update the object
    {
      response.forEach((fileData) =>
      {
        let bookingObjectFile = {
          name: file.name,
          fileData: fileData,
          bookingObjectId: this.bookingObject.id
        }
        this.bookingObject.files.push(bookingObjectFile)
      })
      // update the bookingObject if it has an id (with debounce incase multiple files trigger this method)
      clearTimeout(this.uploadTimer)
      this.uploadTimer = setTimeout(async () =>
      {
        if (this.bookingObject.id > 0)
        {
          this.saveBookingObject()
        }
      }, 650)
    },
    filesUpdated()
    {
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    fileRemoved()
    {
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    avatarClick()
    {
      if (this.bookingObject.id > 0)
      {
        this.pickingAvatar = true
      }
    },
  }
}
</script>

<style scoped>
</style>
