<template>
    <v-card :loading="loading" :disabled="loading">
        <v-card-text>
            <v-container grid-list-xl>
                <v-layout row wrap v-if="user">
                    <v-flex xs12>
                        <h3 data-cy="userSetupHeader">Börja med att ange dina uppgifter nedan.</h3>
                    </v-flex>
                    <v-flex lg6>
                        <user-account-admin-component
                                :app-email-show="false"
                                @saved="saveUser"
                                ref="accountDetails"
                                v-model="user"></user-account-admin-component>
                    </v-flex>
                    <v-flex lg6>
                        <user-account-avatar-admin-component
                                v-model="user"></user-account-avatar-admin-component>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn
                    @click="$emit('previousStep')"
            >
                <v-icon class="mr-2">mdi-chevron-left</v-icon>
                Tillbaka
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn data-cy="step2NextBtn" @click="saveUser" color="secondary">Nästa
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import UserAccountAdminComponent from "../useraccount/UserAccountAdminComponent";
    import api from "../../api/api";
    import UserAccountAvatarAdminComponent from "../useraccount/UserAccountAvatarAdminComponent";
    import {validateForm} from "../../util/functions";

    export default {
        components: {UserAccountAvatarAdminComponent, UserAccountAdminComponent},
        data: () => ({
            loading: false,
            user: null,
        }),
        props: {
            setup: {
                type: Boolean,
                default: false
            },
        },
        async mounted()
        {
            this.loading = true
            let authResponse = await api.getFromEndpoint(api.ENDPOINTS.AUTH)
            if (authResponse)
            {
                if (authResponse.address == null)
                {
                    authResponse.address = {
                        address1: "",
                        address2: "",
                        address3: "",
                        postcode: "",
                        city: "",
                        country: "Sverige"
                    }
                }
                this.user = authResponse
            }
            this.loading = false
        },
        methods: {
            async saveUser()
            {
                let form = this.$refs.accountDetails.$refs.form
                if (validateForm(form))
                {
                    this.loading = true
                    this.user = await api.putToEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.user.id, this.user)
                    if (this.user != null)
                    {
                        this.$store.commit('updateUser', this.user)
                        this.$store.commit('setSnackbar', {color: "success", text: "Användarkonto uppdaterad"})
                        this.$emit('nextStep')
                    }
                    this.loading = false
                }
            }
        }
    }
</script>

<style scoped>

</style>
