<template>
  <v-card :disabled="loading" :loading="loading" flat v-if="section && section.data">
    <v-subheader class="pl-0">Höjd</v-subheader>
    <v-slider
        v-model="section.data.height"
        :max="2000"
        :min="10"
        step="10"
        thumb-label="always"
        :thumb-size="45"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value }}px
      </template>
    </v-slider>
  </v-card>
</template>

<script>

export default {
  components: {},
  data: () =>
  {
    return {
      defaultHeight: 100,
    }
  },
  props: {
    value: Object,
    website: Object,
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    }
  },
  async mounted()
  {
    this.handleInitData()
  },
  methods: {
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          height: this.defaultHeight
        }
      }
    },
  }
}
</script>
