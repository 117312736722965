<template>
  <div>
    <v-card :disabled="saving" :loading="saving" flat>
      <v-container grid-list-xl>
        <v-layout class="pb-8" row wrap>
          <v-flex grow xs12>
            <v-layout row wrap>
              <v-flex sm6 xs12>
                <v-text-field
                    :rules="[v => parseInt(v) >= 0 || 'Du måste ange ett pris']"
                    @keyup="unsavedChanges = true"
                    @keyup.enter="saveBookingPeriod"
                    append-icon="mdi-credit-card-outline"
                    autocomplete="off"
                    class="mr-4"
                    label="Pris"
                    min="0"
                    outlined
                    required
                    suffix="SEK"
                    type="number"
                    v-model="bookingPeriod.price"
                >
                  <template v-slot:append-outer>
                    <v-scale-transition>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-if="defaultCurrentPriceMismatch" v-on="on" color="warning">
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>OBS! Angivet pris matchar inte standardpriset på uthyrningsobjektet</span>
                      </v-tooltip>
                    </v-scale-transition>
                  </template>
                </v-text-field> <!-- TODO - i18n for currency -->
              </v-flex>
              <v-flex sm6 xs12>
                <v-tooltip :disabled="!paymentFlowNotAllowed" activator="#paymentFlowBtn" bottom>
                  <span v-if="invalidPrice">Betalningar går endast att hantera på perioder med ett satt pris</span>
                  <span v-else>Betalningar går endast att hantera på uthyrda perioder</span>
                </v-tooltip>
                <v-slide-x-transition mode="out-in">
                                <span id="paymentFlowBtn" v-if="!bookingPeriod.paymentsInitiatedAt">
                                    <v-btn :class="{'highlight-pulse': !paymentFlowNotAllowed, 'xs-mt-0': true}"
                                           :disabled="paymentFlowNotAllowed"
                                           @click="confirmPaymentRequests"
                                           block
                                           color="primary"
                                           height="56px"
                                           large
                                           outlined>
                                        Starta betalningsflöde
                                        <v-icon class="ml-2">mdi-credit-card-clock-outline</v-icon>
                                    </v-btn>
                                </span>
                  <v-alert class="text-center xs-mt-0" color="primary" outlined type="info" v-else>
                    Betalningsflödet startades {{
                      bookingPeriod.paymentsInitiatedAt |
                          calendarFormat($moment)
                    }}
                  </v-alert>
                </v-slide-x-transition>
              </v-flex>
            </v-layout>
            <payment-requests-component :booking-period.sync="bookingPeriod" :saving="saving"
                                        :unsaved-changed.sync="unsavedChanges"
                                        @updatePaymentRequestStatus="updatePaymentRequestStatus"
            ></payment-requests-component>
          </v-flex>
          <v-flex xs12>
            <v-card :disabled="!bookingPeriod.paymentsInitiatedAt" flat>
              <booking-period-payment-log-component
                  :booking-period="bookingPeriod"
                  v-if="bookingPeriod.id"
                  :saving="saving"
                  @refreshBookingPeriod="refreshBookingPeriod"
                  :unsaved-changed.sync="unsavedChanges"
                  ref="logComponent"></booking-period-payment-log-component>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <payment-point-to-payment-request-dialog-component :booking-period="bookingPeriod"
                                                         :dialog="confirmingPaymentPoints"
                                                         @cancel="confirmingPaymentPoints = false"
                                                         @confirm="initPaymentFlow"
      ></payment-point-to-payment-request-dialog-component>
    </v-card>
  </div>
</template>

<script>
import PaymentPointToPaymentRequestDialogComponent from "../paymentrequest/PaymentPointToPaymentRequestDialogComponent";
import PaymentRequestsComponent from "../paymentrequest/PaymentRequestsComponent";
import BookingPeriodPaymentLogComponent from "../balance/BookingPeriodPaymentLogComponent";
import bookingPeriodStatuses from "../../../models/bookingPeriodStatuses";

export default {
  components: {
    BookingPeriodPaymentLogComponent,
    PaymentRequestsComponent,
    PaymentPointToPaymentRequestDialogComponent
  },
  data: () => ({
    pointsValid: true,
    paymentRequestDialog: false,
    valuesAsPercentage: false,
    editing: false,
    confirmingPaymentPoints: false,
    defaultCurrentPriceMismatch: false,
  }),
  props: {
    bookingPeriod: Object,
    saving: Boolean,
    unsavedChanged: Boolean,
  },
  filters: {
    typeString(type, paymentPointStrings) {
      return paymentPointStrings[type]
    },
    calendarFormat(date, moment) {
      return moment(date).calendar()
    },
    percentageFilter(amount, price, valuesAsPercentage) {
      if (valuesAsPercentage) {
        return Math.floor((amount / price) * 100)
      }
      return amount
    }
  },
  watch: {
    'bookingObject.id': {
      handler() {
        this.handleAutoPriceFromObject()
      },
      immediate: true
    },
  },
  mounted() {
    this.handleAutoPriceFromObject()
  },
  computed: {
    unsavedChanges: {
      get() {
        return this.unsavedChanged
      },
      set(val) {
        this.$emit('update:unsavedChanged', val)
      }
    },
    paymentFlowNotAllowed() {
      return this.invalidPrice || this.bookingPeriod.status < bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value || this.bookingPeriod.paymentsInitiatedAt
    },
    bookingObject() {
      return this.bookingPeriod.bookingObject
    },
    periodCancelledOrCompleted() {
      return this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.CANCELLED.value || this.bookingPeriod.status > bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value
    },
    invalidPrice() {
      return Number.parseInt(this.bookingPeriod.price) <= 0
    }
  },
  methods: {
    saveBookingPeriod() {
      this.$emit('saveBookingPeriod', false)
    },
    confirmPaymentRequests() {
      if (this.bookingObject && this.bookingObject.paymentPoints && this.bookingObject.paymentPoints.length > 0) {
        this.confirmingPaymentPoints = true
      } else {
        this.initPaymentFlow([])
      }
    },
    initPaymentFlow(paymentRequests) {
      this.confirmingPaymentPoints = false
      this.bookingPeriod.paymentsInitiatedAt = this.$moment().format('YYYY-MM-DD HH:mm')
      this.bookingPeriod.paymentRequests = paymentRequests
      this.unsavedChanges = true
      this.saveBookingPeriod()
    },
    setPriceFromObject() {
      this.bookingPeriod.price = this.bookingObject.defaultPricePerPeriod
      this.$store.commit('setSnackbar', {
        color: "secondary",
        text: "Satte pris på perioden till standardvärdet på uthyrningsobjektet (" + this.bookingObject.defaultPricePerPeriod + " SEK).",
        timeout: 7000,
        btnText: "Ändra pris",
        actionClick: this.goToEconomyTab
      })
      this.unsavedChanges = true
    },
    goToEconomyTab() {
      this.$emit('goToEconomyTab')
    },
    handleAutoPriceFromObject() {
      if (!this.periodCancelledOrCompleted) {
        // if there is a bookingObject and the bookingObject has a defaultPricePerPeriod set to something > 0
        if (this.bookingObject && parseInt(this.bookingObject.defaultPricePerPeriod) > 0) {
          // if current price is 0 or not set and there is an id
          if (this.bookingPeriod.id && parseInt(this.bookingPeriod.price) === 0) {
            // just show a very minor warning
            this.defaultCurrentPriceMismatch = true
          }
          else if (parseInt(this.bookingPeriod.price) === 0) // otherwise if no id is present and price is zero or not set
          {
            // change to bookingObject default and show snackbar
            this.setPriceFromObject()
          }
        }
      }
    },
    refreshBookingPeriod() {
      this.$refs.logComponent.getPaymentLogsFromBookingPeriod()
      this.$emit('refreshBookingPeriod')
    },
    updatePaymentRequestStatus(id, newStatus)
    {
      this.$emit('updatePaymentRequestStatus', id, newStatus)
    }
  }
}
</script>

<style scoped>
tr.no-hover {
  background: none !important;
}
</style>
