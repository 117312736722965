<template>
  <v-card :loading="loading" :disabled="loading" flat v-if="section && section.data">
    <v-subheader class="pl-0">Inställningar</v-subheader>
    <v-text-field
        label="Rubrik"
        v-model="section.data.externalBookerSettings.title"
    ></v-text-field>
    <v-textarea
        auto-grow
        label="Hjälptext"
        outlined
        v-model="section.data.externalBookerSettings.subtitle"
    ></v-textarea>
    <v-checkbox
        label="Visa bild på uthyrningsobjektet"
        v-model="section.data.externalBookerSettings.showImage"
    ></v-checkbox>
    <v-checkbox
        label="Visa beskrivning av uthyrningsobjektet"
        v-model="section.data.externalBookerSettings.showDescription"
    ></v-checkbox>
  </v-card>
</template>

<script>

import api from "@/api/api";

export default {
  components: {},
  data: () =>
  {
    return {
      requiredRules: [
        v => !!v && v > 0 || 'Obligatoriskt fält'
      ],
      loading: false
    }
  },
  props: {
    value: Object,
    bookingObject: Object,
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    }
  },
  async mounted()
  {
    this.handleInitData()
    this.getExternalBookerSettings()
  },
  methods: {
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          externalBookerSettings: {
            showImage: false,
            showDescription: false,
            title: "",
            subtitle: "",
            bookingObjectId: this.bookingObject.id,
          },
        }
      }
    },
    async getExternalBookerSettings()
    {
      this.loading = true
      let settings = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id + "/" + api.ENDPOINTS.EXTERNALBOOKERSETTINGS)
      if (settings)
      {
        this.section.data.externalBookerSettings = settings
      }
      this.loading = false
    },
  }
}
</script>
