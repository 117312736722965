<template>
  <div class="my-2">
    <v-card class="mx-auto" :width="data.width + '%'">
      <v-carousel
          :continuous="data.autoplay"
          :cycle="data.autoplay"
          :show-arrows-on-hover="data.showPrevNext"
          :show-arrows="data.showPrevNext"
          :hide-delimiters="!data.showDelimiters"
          height="100%"
          :interval="data.delay * 1000"
      >
        <v-carousel-item
            v-for="(image, i) in data.bookingObjectFiles"
            :key="'slide_' + i"
            eager
        >
          <v-img
              :src="getImageUrl(image)"
              eager
              :max-height="data.height ? data.height + 'px' : null"
          >
            <h3 :class="['text-center', 'white--text', 'image-caption']" v-if="data.showCaptions">{{ image.name }}</h3>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>

import api from "@/api/api";

export default {
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
  },
  methods: {
    getImageUrl(image)
    {
      return api.BASE_URL + image.fileData.src
    },
  }
}
</script>
<style scoped>
.image-caption {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.25);
}
</style>
