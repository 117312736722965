<template>
  <div>
    <v-card flat>
      <attributes-list-component
          v-if="data.listView"
          dense
          :grouped="data.grouped"
          :booking-object-attributes="attributes">
      </attributes-list-component>
      <attribute-chips-component
          v-else
          :grouped="data.grouped"
          :booking-object-attributes="attributes"></attribute-chips-component>
    </v-card>
  </div>
</template>

<script>

import AttributesListComponent from "@/components/attribute/AttributesListComponent";
import AttributeChipsComponent from "@/components/attribute/AttributeChipsComponent";

export default {
  components: {AttributeChipsComponent, AttributesListComponent},
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
    attributes()
    {
      return this.data.bookingObjectAttributes.slice(0).sort(
          (a, b) => a.attribute.attributeGroup.sortOrder > b.attribute.attributeGroup.sortOrder ? 1:-1
      )
    },
  },
}
</script>
