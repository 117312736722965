,
<template>
  <v-container grid-list-xl>
    <v-fade-transition mode="out-in">
      <v-layout v-if="!notFound" row wrap>
        <v-flex xs12>
          <v-card
              :disabled="loading"
              :loading="loading"
              class="elevation-6"
              color="white"
          >
            <v-card-title class="secondary">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on" class="mr-2" color="white" size="38">
                    <v-icon :color="availableStatuses[initStatus].color">
                      {{ availableStatuses[initStatus].icon }}
                    </v-icon>
                  </v-avatar>
                </template>
                <span>{{ availableStatuses[initStatus].text }}</span>
              </v-tooltip>
              <span class="title textfont-weight-light white--text">
                {{ title }}
              </span>
              <v-spacer></v-spacer>
              <v-fade-transition>
                <booking-period-prev-next-component class="ml-2" :booking-period="bookingPeriod"
                                                    v-if="bookingPeriod && bookingPeriod.id"
                                                    dark></booking-period-prev-next-component>
              </v-fade-transition>
            </v-card-title>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="accent"
                grow
                show-arrows
            >
              <v-tab>
                Översikt
              </v-tab>
              <v-tab>
                Filer & Dokument
              </v-tab>
              <v-tab>
                Ekonomi
              </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <booking-period-status-card-component
                class="mt-2"
                show-actions
                :booking-period="bookingPeriod"
                @statusElevation="confirmStatusElevation">
            </booking-period-status-card-component>
            <v-card-text>
              <v-form
                  ref="form"
                  v-model="validForm"

                  @submit.prevent
              >
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <booking-period-overview-tab-component
                        ref="overview"
                        :booking-period.sync="bookingPeriod"
                        :errorText="errorText"
                        :loading="saving"
                        :readOnly="readOnly"
                        :unsavedChanged.sync="unsavedChanges"
                        @saveBookingPeriod="saveBookingPeriod(false)"
                    >
                    </booking-period-overview-tab-component>
                  </v-tab-item>
                  <v-tab-item>
                    <booking-period-files-tab-component :booking-period.sync="bookingPeriod"
                                                        :loading="loading"
                                                        @saveBookingPeriod="saveBookingPeriod(false)"></booking-period-files-tab-component>
                  </v-tab-item>
                  <v-tab-item eager>
                    <booking-period-economy-tab-component
                        ref="payments"
                        :booking-period.sync="bookingPeriod"
                        :saving="saving"
                        v-if="!loading"
                        :unsavedChanged.sync="unsavedChanges"
                        @goToEconomyTab="goToEconomyTab"
                        @refreshBookingPeriod="refreshBookingPeriod"
                        @saveBookingPeriod="saveBookingPeriod"
                        @updatePaymentRequestStatus="updatePaymentRequestStatus"
                    >
                    </booking-period-economy-tab-component>
                  </v-tab-item>
                </v-tabs-items>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="saving" :to="{name: 'bookingPeriods'}"
                     color="default">
                {{ bookingPeriod.id > 0 ? 'Tillbaka' : "Avbryt" }}
              </v-btn>
              <v-btn v-if="this.$route.params.id > 0 && this.initStatus < 3" :disabled="saving || readOnly"
                     color="error"
                     @click="confirmDelete">
                Ta bort
                <v-icon right>mdi-delete</v-icon>
              </v-btn>
              <v-btn v-else-if="this.$route.params.id > 0 && this.initStatus === 3"
                     :disabled="saving || readOnly" color="error"
                     @click="confirmCancel">
                Avboka
                <v-icon right>mdi-calendar-remove</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="readOnly" :loading="saving"
                     color="primary"
                     @click="saveBookingPeriod(false)">Spara
                <v-icon right>save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <unsaved-changes-dialog-component ref="unsaved" :value.sync="unsavedChanges"></unsaved-changes-dialog-component>
        <v-dialog v-model="showConfirmStatusChangeDialog" max-width="545" persistent>
          <v-card>
            <v-card-title class="headline secondary--text">Bekräfta statusändring
              <v-spacer></v-spacer>
              <v-icon color="secondary" right>mdi-alert-circle-outline</v-icon>
            </v-card-title>
            <v-card-text class="mt-2">
              <h4>{{ confirmStatusChangeText }}</h4>
              <p class="mt-2">{{ confirmStatusChangeExtraText }}</p>
              <v-alert v-if="showEndDateNotPassedWarning" class="text-center mt-5" color="primary" outlined
                       type="error">
                Notera att det valda datumet och tiden för utcheckning har ännu inte passerat
              </v-alert>
              <v-checkbox v-if="showBookedEmailChoice" label="Skicka bekräftelse till huvvudkunden"
                          v-model="sendBookedEmail"></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="showConfirmStatusChangeDialog = false">Avbryt</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                     text
                     @click="saveBookingPeriod(true); showConfirmStatusChangeDialog = false">Bekräfta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmCancelDialog" max-width="550" persistent>
          <v-card>
            <v-card-title class="headline secondary--text">Bekräfta avbokning</v-card-title>
            <v-card-text>
              Är du säker på att du vill avboka {{ this.bookingPeriod.name }}? Notera att ett mejl kommer att skickas
              till kunden!
              <v-alert v-if="paidRequests.length > 0" border="left" class="mt-6 elevation-3" color="primary"
                       colored-border dense
                       type="warning">
                OBS! Denna period har {{ paidRequests.length }} betalningsförfrågningar som är markerade som betalda.
                <v-checkbox v-model="markPaymentsToBeRepaid"
                            label='Ändra status på samtliga till "Ska återbetalas"'></v-checkbox>
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="left" text @click="confirmCancelDialog = false">Avbryt</v-btn>
              <v-btn color="primary" text @click="cancelBookingPeriod">Avboka</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <page-not-found-component routerless v-else></page-not-found-component>
    </v-fade-transition>
  </v-container>
</template>
<script>
import api from "../../api/api";
import calendarViews from "../../models/calendarViews";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import {validateForm} from "@/util/functions";
import BookingPeriodOverviewTabComponent from "../../components/bookingperiod/tabs/BookingPeriodOverviewTabComponent";
import BookingPeriodEconomyTabComponent from "../../components/bookingperiod/tabs/BookingPeriodEconomyTabComponent";
import PageNotFoundComponent from "../../components/errors/PageNotFoundComponent";
import paymentStatuses from "../../models/paymentStatuses";
import BookingPeriodFilesTabComponent from "../../components/bookingperiod/tabs/BookingPeriodFilesTabComponent";
import BookingPeriodPrevNextComponent from "@/components/bookingperiod/BookingPeriodPrevNextComponent";
import UnsavedChangesDialogComponent from "@/components/global/UnsavedChangesDialogComponent";
import BookingPeriodStatusCardComponent from "@/components/bookingperiod/BookingPeriodStatusCardComponent";

export default {
  components: {
    BookingPeriodStatusCardComponent,
    UnsavedChangesDialogComponent,
    BookingPeriodPrevNextComponent,
    BookingPeriodFilesTabComponent,
    PageNotFoundComponent,
    BookingPeriodEconomyTabComponent,
    BookingPeriodOverviewTabComponent,
  },
  data: () => ({
    validForm: true,
    loading: false,
    saving: false,
    unsavedChanges: false,
    newConversationDialog: false,
    confirmCancelDialog: false,
    availableStatuses: bookingPeriodStatuses.STATUSES,
    initStatus: 1,
    tab: 0,
    interruptedTo: null,
    defaultView: calendarViews.views.dayGridMonth,
    bookingPeriod: {
      name: "",
      active: 1,
      notes: "",
      calendarEvent: {
        start: "",
        end: "",
      },
      bookingObject: null,
      status: 1,
      paymentsInitiatedAt: null,
      price: 0,
      currentBalance: 0,
      paymentRequests: [],
      customers: [],
      files: [],
      other: "",
    },
    bookingPeriodCalendarEvents: null,
    errorText: "",
    showEndDateNotPassedWarning: false,
    showConfirmStatusChangeDialog: false,
    confirmStatusChangeText: "",
    confirmStatusChangeExtraText: "",
    notFound: false,
    markPaymentsToBeRepaid: true,
    sendBookedEmail: true,
    showBookedEmailChoice: false,
  }),
  computed: {
    title()
    {
      return this.$route.params.id > 0 ? "Redigerar " + this.bookingPeriod.name : "Ny uthyrningsperiod"
    },
    stateUser()
    {
      return this.$store.state.user
    },
    readOnly()
    {
      // for now only cancelled and completed periods are disabled
      return this.initStatus === bookingPeriodStatuses.STATUSES_OBJ.CANCELLED.value || this.initStatus === bookingPeriodStatuses.STATUSES_OBJ.COMPLETED.value
    },
    paidRequests()
    {
      return this.bookingPeriod.paymentRequests.filter((pr) => pr.status === paymentStatuses.STATUSES.PAID.value)
    },
    areAllPaymentRequestsCancelled()
    {
      return this.bookingPeriod.paymentRequests.every((pr) => pr.status === paymentStatuses.STATUSES.CANCELLED.value)
    },
  },
  async mounted()
  {
    this.loading = true
    // check our route params
    if (this.$route.params.id > 0)
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.$route.params.id)
      if (serverResponse && !serverResponse.deleted)
      {
        Object.assign(this.bookingPeriod, serverResponse);
        this.initStatus = this.bookingPeriod.status
        let serverResponseCustomers = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.CUSTOMERS)
        if (serverResponseCustomers)
        {
          this.bookingPeriod.customers = serverResponseCustomers
        }
      }
      else
      {
        this.notFound = true
        return
      }

    }
    if (this.$route.params.start && this.$route.params.end)
    {
      this.bookingPeriod.calendarEvent.start = this.$route.params.start
      this.bookingPeriod.calendarEvent.end = this.$route.params.end
    }
    if (this.$route.params.bookingObject)
    {
      this.bookingPeriod.bookingObject = this.$route.params.bookingObject
    }

    this.loading = false
    this.$nextTick(function ()
    {
      // we need to increment one day here since we subtract to account for how fullcalendar handles date selection
      let endDate = this.$moment(this.bookingPeriod.calendarEvent.end)
      endDate.add(1, "days");
      // set default values after mount
      this.$refs.overview.updateCalendarSelection(this.$moment(this.bookingPeriod.calendarEvent.start).format("YYYY-MM-DD HH:mm"), this.$moment(endDate).format("YYYY-MM-DD HH:mm"))
      this.unsavedChanges = false

      let tabIndex = this.$route.query.tabIndex
      if (tabIndex)
      {
        window.setTimeout(() =>
        {
          this.tab = Number.parseInt(tabIndex)
        }, 50)
      }
    })
  },
  watch: {
    tab()
    {
      this.setTabIndexInRoute()
    }
  },
  methods: {
    confirmCancel()
    {
      this.confirmCancelDialog = true
    },
    confirmDelete()
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: "Är du säker på att du vill ta bort " + this.bookingPeriod.name + "?",
        actionClick: this.deleteBookingPeriod
      })
    },
    async deleteBookingPeriod()
    {
      this.loading = true
      this.unsavedChanges = false
      await api.deleteReqToEndpoint(api.ENDPOINTS.BOOKINGPERIOD + "/" + this.bookingPeriod.id)
      this.loading = false
      this.$store.commit('setSnackbar', {color: "primary", text: this.bookingPeriod.name + " borttagen"})
      this.$router.replace({name: "bookingPeriods"})
    },
    async cancelBookingPeriod()
    {
      this.loading = true
      this.unsavedChanges = false
      await api.deleteReqToEndpoint(api.ENDPOINTS.BOOKINGPERIOD + "/" + this.bookingPeriod.id + "?markPaymentsToBeRepaid=" + this.markPaymentsToBeRepaid) // delete will cancel depending on current status
      this.loading = false
      this.$store.commit('setSnackbar', {color: "primary", text: this.bookingPeriod.name + " avbokad"})
      this.$router.replace({name: "bookingPeriods"})
    },
    isPeriodValid()
    {
      let start = this.bookingPeriod.calendarEvent.start
      let end = this.bookingPeriod.calendarEvent.end
      let form = this.$refs.form

      return this.bookingPeriod.bookingObject != null &&
          this.isPaymentRequestsAndPriceValid() &&
          validateForm(form) &&
          this.validateDateInputed(start, "incheckning") && this.validateDateInputed(end, "utcheckning")
    },
    async saveBookingPeriod(elevateStatus)
    {
      this.errorText = ""

      if (this.isPeriodValid())
      {
        this.unsavedChanges = false
        let saveText = this.bookingPeriod.name + " skapad"
        let btnText = null
        let actionClick = null
        this.saving = true
        try
        {
          if (this.bookingPeriod.id > 0)
          {
            saveText = this.bookingPeriod.name + " uppdaterad"
            this.bookingPeriod = await api.putToEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id +
                "?elevateStatus=" + elevateStatus +
                "&sendBookedConfirmationEmail=" + this.sendBookedEmail,
                this.bookingPeriod)
            this.initStatus = this.bookingPeriod.status
          }
          else
          {
            this.bookingPeriod = await api.postToEndpoint(api.ENDPOINTS.BOOKINGPERIODS, this.bookingPeriod)
            this.$router.replace({name: 'bookingPeriodDetails', params: {id: this.bookingPeriod.id}})
            btnText = "Tillbaka"
            actionClick = () =>
            {
              this.$router.push({name: "bookingPeriods"})
            }
          }
          if (this.bookingPeriod != null)
          {
            this.$store.commit('setSnackbar', {
              color: "success",
              text: saveText,
              btnText: btnText,
              actionClick: actionClick
            })
            this.$route.params.id = this.bookingPeriod.id
            return true
          }
        }
        catch (e)
        {
          // eslint-disable-next-line no-console
          console.error(e)
          return false
        }
        finally
        {
          this.saving = false
        }
      }
    },
    validateDateInputed(date, inputName)
    {
      if (date && date.length > 0)
      {
        return true
      }
      this.errorText = "Du måste ange " + inputName
      return false
    },
    confirmStatusElevation()
    {
      let confirmText = "Är du säker på att du vill ändra status på denna period till " + this.availableStatuses[this.bookingPeriod.status + 1].text + "?"
      this.showEndDateNotPassedWarning = false
      this.showBookedEmailChoice = false

      if (this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.REQUESTED.value)
      {
        this.confirmStatusChangeExtraText += "När du sätter statusen som uthyrd kommer huvudkunden (hjärtmarkerad) att låsas till perioden och därmed inte gå att ändra. Du kan lägga till fler gäster även när statusen ändrats till uthyrd."
        this.showBookedEmailChoice = true
      }
      if (this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value && new Date() < new Date(this.bookingPeriod.calendarEvent.end))
      {
        this.showEndDateNotPassedWarning = true
      }
      if (this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value && new Date() < new Date(this.bookingPeriod.calendarEvent.end))
      {
        this.showEndDateNotPassedWarning = true
      }

      this.confirmStatusChangeText = confirmText
      this.showConfirmStatusChangeDialog = true
    },
    isPaymentRequestsAndPriceValid()
    {
      let valid = parseInt(this.bookingPeriod.price) >= 0
      if (valid && this.bookingPeriod.paymentRequests && this.bookingPeriod.paymentRequests.length > 0 && !this.areAllPaymentRequestsCancelled)
      {
        let total = 0
        this.bookingPeriod.paymentRequests.forEach((p) =>
        {
          if (p.status !== paymentStatuses.STATUSES.CANCELLED.value)
          {
            total += parseInt(p.amount)
          }
        })
        valid = total === parseInt(this.bookingPeriod.price)
      }
      if (!valid)
      {
        // go to economy-tab if not valid
        this.goToEconomyTab()
      }

      return valid
    },
    goToEconomyTab()
    {
      this.tab = 2
    },
    async refreshBookingPeriod()
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.$route.params.id)
      if (serverResponse)
      {
        Object.assign(this.bookingPeriod, serverResponse);
        let serverResponseCustomers = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.CUSTOMERS)
        if (serverResponseCustomers)
        {
          this.bookingPeriod.customers = serverResponseCustomers
        }
      }
    },
    async updatePaymentRequestStatus(id, newStatus)
    {
      let cancelled = newStatus.value === paymentStatuses.STATUSES.CANCELLED.value

      if (cancelled || this.isPeriodValid())
      {
        let saved = true
        if (this.unsavedChanges)
        {
          saved = await this.saveBookingPeriod(false)
          if (!saved)
          {
            return
          }
        }

        this.loading = true

        let paymentRequest = await api.putToEndpoint(api.ENDPOINTS.PAYMENTREQUESTS + "/" + id + "?status=" + newStatus.value)
        if (paymentRequest != null)
        {
          this.$store.commit('setSnackbar', {
            color: "success",
            text: "Notis skickad till huvudkund och status på betalningsförfrågan ändrad till " + newStatus.text
          })
          this.refreshBookingPeriod()
        }
        else
        {
          this.$store.commit('setSnackbar', {
            color: "error",
            text: "Något gick fel när status på betalningsförfrågan skulle ändras. Vänligen försök igen eller kontakta vår support om problemet kvarstår."
          })
        }
        this.loading = false
      }
      else
      {
        this.$store.commit('setSnackbar', {
          color: "grey",
          text: "Kan inte ändra status på betalningsförfrågan . Kontrollera att perioden inte innehåller felaktiga värden"
        })
      }
    },
    setTabIndexInRoute()
    {
      if (this.$route.params.id > 0)
      {
        window.history.replaceState(null, null, '?tabIndex=' + this.tab);
      }
    },
  },
  beforeRouteLeave(to, from, next)
  {
    if (this.$refs.unsaved)
    {
      this.$refs.unsaved.beforeRouteLeave(to, from, next)
    }
  },
}
</script>

<style scoped>
</style>
