const icons = [
    {icon: "mdi-ab-testing", text: "mdi-ab-testing"},
    {icon: "mdi-access-point", text: "mdi-access-point"},
    {icon: "mdi-access-point-network", text: "mdi-access-point-network"},
    {icon: "mdi-access-point-network-off", text: "mdi-access-point-network-off"},
    {icon: "mdi-account", text: "mdi-account"},
    {icon: "mdi-account-alert", text: "mdi-account-alert"},
    {icon: "mdi-account-alert-outline", text: "mdi-account-alert-outline"},
    {icon: "mdi-account-arrow-left", text: "mdi-account-arrow-left"},
    {icon: "mdi-account-arrow-left-outline", text: "mdi-account-arrow-left-outline"},
    {icon: "mdi-account-arrow-right", text: "mdi-account-arrow-right"},
    {icon: "mdi-account-arrow-right-outline", text: "mdi-account-arrow-right-outline"},
    {icon: "mdi-account-badge", text: "mdi-account-badge"},
    {icon: "mdi-account-badge-alert", text: "mdi-account-badge-alert"},
    {icon: "mdi-account-badge-alert-outline", text: "mdi-account-badge-alert-outline"},
    {icon: "mdi-account-badge-horizontal", text: "mdi-account-badge-horizontal"},
    {icon: "mdi-account-badge-horizontal-outline", text: "mdi-account-badge-horizontal-outline"},
    {icon: "mdi-account-badge-outline", text: "mdi-account-badge-outline"},
    {icon: "mdi-account-box", text: "mdi-account-box"},
    {icon: "mdi-account-box-multiple", text: "mdi-account-box-multiple"},
    {icon: "mdi-account-box-multiple-outline", text: "mdi-account-box-multiple-outline"},
    {icon: "mdi-account-box-outline", text: "mdi-account-box-outline"},
    {icon: "mdi-account-card-details", text: "mdi-account-card-details"},
    {icon: "mdi-account-card-details-outline", text: "mdi-account-card-details-outline"},
    {icon: "mdi-account-check", text: "mdi-account-check"},
    {icon: "mdi-account-check-outline", text: "mdi-account-check-outline"},
    {icon: "mdi-account-child", text: "mdi-account-child"},
    {icon: "mdi-account-child-circle", text: "mdi-account-child-circle"},
    {icon: "mdi-account-circle", text: "mdi-account-circle"},
    {icon: "mdi-account-circle-outline", text: "mdi-account-circle-outline"},
    {icon: "mdi-account-clock", text: "mdi-account-clock"},
    {icon: "mdi-account-clock-outline", text: "mdi-account-clock-outline"},
    {icon: "mdi-account-convert", text: "mdi-account-convert"},
    {icon: "mdi-account-details", text: "mdi-account-details"},
    {icon: "mdi-account-edit", text: "mdi-account-edit"},
    {icon: "mdi-account-edit-outline", text: "mdi-account-edit-outline"},
    {icon: "mdi-account-group", text: "mdi-account-group"},
    {icon: "mdi-account-group-outline", text: "mdi-account-group-outline"},
    {icon: "mdi-account-heart", text: "mdi-account-heart"},
    {icon: "mdi-account-heart-outline", text: "mdi-account-heart-outline"},
    {icon: "mdi-account-key", text: "mdi-account-key"},
    {icon: "mdi-account-key-outline", text: "mdi-account-key-outline"},
    {icon: "mdi-account-minus", text: "mdi-account-minus"},
    {icon: "mdi-account-minus-outline", text: "mdi-account-minus-outline"},
    {icon: "mdi-account-multiple", text: "mdi-account-multiple"},
    {icon: "mdi-account-multiple-check", text: "mdi-account-multiple-check"},
    {icon: "mdi-account-multiple-minus", text: "mdi-account-multiple-minus"},
    {icon: "mdi-account-multiple-minus-outline", text: "mdi-account-multiple-minus-outline"},
    {icon: "mdi-account-multiple-outline", text: "mdi-account-multiple-outline"},
    {icon: "mdi-account-multiple-plus", text: "mdi-account-multiple-plus"},
    {icon: "mdi-account-multiple-plus-outline", text: "mdi-account-multiple-plus-outline"},
    {icon: "mdi-account-network", text: "mdi-account-network"},
    {icon: "mdi-account-network-outline", text: "mdi-account-network-outline"},
    {icon: "mdi-account-off", text: "mdi-account-off"},
    {icon: "mdi-account-off-outline", text: "mdi-account-off-outline"},
    {icon: "mdi-account-outline", text: "mdi-account-outline"},
    {icon: "mdi-account-plus", text: "mdi-account-plus"},
    {icon: "mdi-account-plus-outline", text: "mdi-account-plus-outline"},
    {icon: "mdi-account-question", text: "mdi-account-question"},
    {icon: "mdi-account-question-outline", text: "mdi-account-question-outline"},
    {icon: "mdi-account-remove", text: "mdi-account-remove"},
    {icon: "mdi-account-remove-outline", text: "mdi-account-remove-outline"},
    {icon: "mdi-account-search", text: "mdi-account-search"},
    {icon: "mdi-account-search-outline", text: "mdi-account-search-outline"},
    {icon: "mdi-account-settings", text: "mdi-account-settings"},
    {icon: "mdi-account-star", text: "mdi-account-star"},
    {icon: "mdi-account-star-outline", text: "mdi-account-star-outline"},
    {icon: "mdi-account-supervisor", text: "mdi-account-supervisor"},
    {icon: "mdi-account-supervisor-circle", text: "mdi-account-supervisor-circle"},
    {icon: "mdi-account-switch", text: "mdi-account-switch"},
    {icon: "mdi-account-tie", text: "mdi-account-tie"},
    {icon: "mdi-accusoft", text: "mdi-accusoft"},
    {icon: "mdi-adchoices", text: "mdi-adchoices"},
    {icon: "mdi-adjust", text: "mdi-adjust"},
    {icon: "mdi-adobe", text: "mdi-adobe"},
    {icon: "mdi-adobe-acrobat", text: "mdi-adobe-acrobat"},
    {icon: "mdi-air-conditioner", text: "mdi-air-conditioner"},
    {icon: "mdi-air-filter", text: "mdi-air-filter"},
    {icon: "mdi-air-horn", text: "mdi-air-horn"},
    {icon: "mdi-air-purifier", text: "mdi-air-purifier"},
    {icon: "mdi-airbag", text: "mdi-airbag"},
    {icon: "mdi-airballoon", text: "mdi-airballoon"},
    {icon: "mdi-airballoon-outline", text: "mdi-airballoon-outline"},
    {icon: "mdi-airplane", text: "mdi-airplane"},
    {icon: "mdi-airplane-landing", text: "mdi-airplane-landing"},
    {icon: "mdi-airplane-off", text: "mdi-airplane-off"},
    {icon: "mdi-airplane-takeoff", text: "mdi-airplane-takeoff"},
    {icon: "mdi-airplay", text: "mdi-airplay"},
    {icon: "mdi-airport", text: "mdi-airport"},
    {icon: "mdi-alarm", text: "mdi-alarm"},
    {icon: "mdi-alarm-bell", text: "mdi-alarm-bell"},
    {icon: "mdi-alarm-check", text: "mdi-alarm-check"},
    {icon: "mdi-alarm-light", text: "mdi-alarm-light"},
    {icon: "mdi-alarm-light-outline", text: "mdi-alarm-light-outline"},
    {icon: "mdi-alarm-multiple", text: "mdi-alarm-multiple"},
    {icon: "mdi-alarm-note", text: "mdi-alarm-note"},
    {icon: "mdi-alarm-note-off", text: "mdi-alarm-note-off"},
    {icon: "mdi-alarm-off", text: "mdi-alarm-off"},
    {icon: "mdi-alarm-plus", text: "mdi-alarm-plus"},
    {icon: "mdi-alarm-snooze", text: "mdi-alarm-snooze"},
    {icon: "mdi-album", text: "mdi-album"},
    {icon: "mdi-alert", text: "mdi-alert"},
    {icon: "mdi-alert-box", text: "mdi-alert-box"},
    {icon: "mdi-alert-box-outline", text: "mdi-alert-box-outline"},
    {icon: "mdi-alert-circle", text: "mdi-alert-circle"},
    {icon: "mdi-alert-circle-outline", text: "mdi-alert-circle-outline"},
    {icon: "mdi-alert-decagram", text: "mdi-alert-decagram"},
    {icon: "mdi-alert-decagram-outline", text: "mdi-alert-decagram-outline"},
    {icon: "mdi-alert-octagon", text: "mdi-alert-octagon"},
    {icon: "mdi-alert-octagon-outline", text: "mdi-alert-octagon-outline"},
    {icon: "mdi-alert-octagram", text: "mdi-alert-octagram"},
    {icon: "mdi-alert-octagram-outline", text: "mdi-alert-octagram-outline"},
    {icon: "mdi-alert-outline", text: "mdi-alert-outline"},
    {icon: "mdi-alien", text: "mdi-alien"},
    {icon: "mdi-all-inclusive", text: "mdi-all-inclusive"},
    {icon: "mdi-alpha", text: "mdi-alpha"},
    {icon: "mdi-alpha-a", text: "mdi-alpha-a"},
    {icon: "mdi-alpha-a-box", text: "mdi-alpha-a-box"},
    {icon: "mdi-alpha-a-box-outline", text: "mdi-alpha-a-box-outline"},
    {icon: "mdi-alpha-a-circle", text: "mdi-alpha-a-circle"},
    {icon: "mdi-alpha-a-circle-outline", text: "mdi-alpha-a-circle-outline"},
    {icon: "mdi-alpha-b", text: "mdi-alpha-b"},
    {icon: "mdi-alpha-b-box", text: "mdi-alpha-b-box"},
    {icon: "mdi-alpha-b-box-outline", text: "mdi-alpha-b-box-outline"},
    {icon: "mdi-alpha-b-circle", text: "mdi-alpha-b-circle"},
    {icon: "mdi-alpha-b-circle-outline", text: "mdi-alpha-b-circle-outline"},
    {icon: "mdi-alpha-c", text: "mdi-alpha-c"},
    {icon: "mdi-alpha-c-box", text: "mdi-alpha-c-box"},
    {icon: "mdi-alpha-c-box-outline", text: "mdi-alpha-c-box-outline"},
    {icon: "mdi-alpha-c-circle", text: "mdi-alpha-c-circle"},
    {icon: "mdi-alpha-c-circle-outline", text: "mdi-alpha-c-circle-outline"},
    {icon: "mdi-alpha-d", text: "mdi-alpha-d"},
    {icon: "mdi-alpha-d-box", text: "mdi-alpha-d-box"},
    {icon: "mdi-alpha-d-box-outline", text: "mdi-alpha-d-box-outline"},
    {icon: "mdi-alpha-d-circle", text: "mdi-alpha-d-circle"},
    {icon: "mdi-alpha-d-circle-outline", text: "mdi-alpha-d-circle-outline"},
    {icon: "mdi-alpha-e", text: "mdi-alpha-e"},
    {icon: "mdi-alpha-e-box", text: "mdi-alpha-e-box"},
    {icon: "mdi-alpha-e-box-outline", text: "mdi-alpha-e-box-outline"},
    {icon: "mdi-alpha-e-circle", text: "mdi-alpha-e-circle"},
    {icon: "mdi-alpha-e-circle-outline", text: "mdi-alpha-e-circle-outline"},
    {icon: "mdi-alpha-f", text: "mdi-alpha-f"},
    {icon: "mdi-alpha-f-box", text: "mdi-alpha-f-box"},
    {icon: "mdi-alpha-f-box-outline", text: "mdi-alpha-f-box-outline"},
    {icon: "mdi-alpha-f-circle", text: "mdi-alpha-f-circle"},
    {icon: "mdi-alpha-f-circle-outline", text: "mdi-alpha-f-circle-outline"},
    {icon: "mdi-alpha-g", text: "mdi-alpha-g"},
    {icon: "mdi-alpha-g-box", text: "mdi-alpha-g-box"},
    {icon: "mdi-alpha-g-box-outline", text: "mdi-alpha-g-box-outline"},
    {icon: "mdi-alpha-g-circle", text: "mdi-alpha-g-circle"},
    {icon: "mdi-alpha-g-circle-outline", text: "mdi-alpha-g-circle-outline"},
    {icon: "mdi-alpha-h", text: "mdi-alpha-h"},
    {icon: "mdi-alpha-h-box", text: "mdi-alpha-h-box"},
    {icon: "mdi-alpha-h-box-outline", text: "mdi-alpha-h-box-outline"},
    {icon: "mdi-alpha-h-circle", text: "mdi-alpha-h-circle"},
    {icon: "mdi-alpha-h-circle-outline", text: "mdi-alpha-h-circle-outline"},
    {icon: "mdi-alpha-i", text: "mdi-alpha-i"},
    {icon: "mdi-alpha-i-box", text: "mdi-alpha-i-box"},
    {icon: "mdi-alpha-i-box-outline", text: "mdi-alpha-i-box-outline"},
    {icon: "mdi-alpha-i-circle", text: "mdi-alpha-i-circle"},
    {icon: "mdi-alpha-i-circle-outline", text: "mdi-alpha-i-circle-outline"},
    {icon: "mdi-alpha-j", text: "mdi-alpha-j"},
    {icon: "mdi-alpha-j-box", text: "mdi-alpha-j-box"},
    {icon: "mdi-alpha-j-box-outline", text: "mdi-alpha-j-box-outline"},
    {icon: "mdi-alpha-j-circle", text: "mdi-alpha-j-circle"},
    {icon: "mdi-alpha-j-circle-outline", text: "mdi-alpha-j-circle-outline"},
    {icon: "mdi-alpha-k", text: "mdi-alpha-k"},
    {icon: "mdi-alpha-k-box", text: "mdi-alpha-k-box"},
    {icon: "mdi-alpha-k-box-outline", text: "mdi-alpha-k-box-outline"},
    {icon: "mdi-alpha-k-circle", text: "mdi-alpha-k-circle"},
    {icon: "mdi-alpha-k-circle-outline", text: "mdi-alpha-k-circle-outline"},
    {icon: "mdi-alpha-l", text: "mdi-alpha-l"},
    {icon: "mdi-alpha-l-box", text: "mdi-alpha-l-box"},
    {icon: "mdi-alpha-l-box-outline", text: "mdi-alpha-l-box-outline"},
    {icon: "mdi-alpha-l-circle", text: "mdi-alpha-l-circle"},
    {icon: "mdi-alpha-l-circle-outline", text: "mdi-alpha-l-circle-outline"},
    {icon: "mdi-alpha-m", text: "mdi-alpha-m"},
    {icon: "mdi-alpha-m-box", text: "mdi-alpha-m-box"},
    {icon: "mdi-alpha-m-box-outline", text: "mdi-alpha-m-box-outline"},
    {icon: "mdi-alpha-m-circle", text: "mdi-alpha-m-circle"},
    {icon: "mdi-alpha-m-circle-outline", text: "mdi-alpha-m-circle-outline"},
    {icon: "mdi-alpha-n", text: "mdi-alpha-n"},
    {icon: "mdi-alpha-n-box", text: "mdi-alpha-n-box"},
    {icon: "mdi-alpha-n-box-outline", text: "mdi-alpha-n-box-outline"},
    {icon: "mdi-alpha-n-circle", text: "mdi-alpha-n-circle"},
    {icon: "mdi-alpha-n-circle-outline", text: "mdi-alpha-n-circle-outline"},
    {icon: "mdi-alpha-o", text: "mdi-alpha-o"},
    {icon: "mdi-alpha-o-box", text: "mdi-alpha-o-box"},
    {icon: "mdi-alpha-o-box-outline", text: "mdi-alpha-o-box-outline"},
    {icon: "mdi-alpha-o-circle", text: "mdi-alpha-o-circle"},
    {icon: "mdi-alpha-o-circle-outline", text: "mdi-alpha-o-circle-outline"},
    {icon: "mdi-alpha-p", text: "mdi-alpha-p"},
    {icon: "mdi-alpha-p-box", text: "mdi-alpha-p-box"},
    {icon: "mdi-alpha-p-box-outline", text: "mdi-alpha-p-box-outline"},
    {icon: "mdi-alpha-p-circle", text: "mdi-alpha-p-circle"},
    {icon: "mdi-alpha-p-circle-outline", text: "mdi-alpha-p-circle-outline"},
    {icon: "mdi-alpha-q", text: "mdi-alpha-q"},
    {icon: "mdi-alpha-q-box", text: "mdi-alpha-q-box"},
    {icon: "mdi-alpha-q-box-outline", text: "mdi-alpha-q-box-outline"},
    {icon: "mdi-alpha-q-circle", text: "mdi-alpha-q-circle"},
    {icon: "mdi-alpha-q-circle-outline", text: "mdi-alpha-q-circle-outline"},
    {icon: "mdi-alpha-r", text: "mdi-alpha-r"},
    {icon: "mdi-alpha-r-box", text: "mdi-alpha-r-box"},
    {icon: "mdi-alpha-r-box-outline", text: "mdi-alpha-r-box-outline"},
    {icon: "mdi-alpha-r-circle", text: "mdi-alpha-r-circle"},
    {icon: "mdi-alpha-r-circle-outline", text: "mdi-alpha-r-circle-outline"},
    {icon: "mdi-alpha-s", text: "mdi-alpha-s"},
    {icon: "mdi-alpha-s-box", text: "mdi-alpha-s-box"},
    {icon: "mdi-alpha-s-box-outline", text: "mdi-alpha-s-box-outline"},
    {icon: "mdi-alpha-s-circle", text: "mdi-alpha-s-circle"},
    {icon: "mdi-alpha-s-circle-outline", text: "mdi-alpha-s-circle-outline"},
    {icon: "mdi-alpha-t", text: "mdi-alpha-t"},
    {icon: "mdi-alpha-t-box", text: "mdi-alpha-t-box"},
    {icon: "mdi-alpha-t-box-outline", text: "mdi-alpha-t-box-outline"},
    {icon: "mdi-alpha-t-circle", text: "mdi-alpha-t-circle"},
    {icon: "mdi-alpha-t-circle-outline", text: "mdi-alpha-t-circle-outline"},
    {icon: "mdi-alpha-u", text: "mdi-alpha-u"},
    {icon: "mdi-alpha-u-box", text: "mdi-alpha-u-box"},
    {icon: "mdi-alpha-u-box-outline", text: "mdi-alpha-u-box-outline"},
    {icon: "mdi-alpha-u-circle", text: "mdi-alpha-u-circle"},
    {icon: "mdi-alpha-u-circle-outline", text: "mdi-alpha-u-circle-outline"},
    {icon: "mdi-alpha-v", text: "mdi-alpha-v"},
    {icon: "mdi-alpha-v-box", text: "mdi-alpha-v-box"},
    {icon: "mdi-alpha-v-box-outline", text: "mdi-alpha-v-box-outline"},
    {icon: "mdi-alpha-v-circle", text: "mdi-alpha-v-circle"},
    {icon: "mdi-alpha-v-circle-outline", text: "mdi-alpha-v-circle-outline"},
    {icon: "mdi-alpha-w", text: "mdi-alpha-w"},
    {icon: "mdi-alpha-w-box", text: "mdi-alpha-w-box"},
    {icon: "mdi-alpha-w-box-outline", text: "mdi-alpha-w-box-outline"},
    {icon: "mdi-alpha-w-circle", text: "mdi-alpha-w-circle"},
    {icon: "mdi-alpha-w-circle-outline", text: "mdi-alpha-w-circle-outline"},
    {icon: "mdi-alpha-x", text: "mdi-alpha-x"},
    {icon: "mdi-alpha-x-box", text: "mdi-alpha-x-box"},
    {icon: "mdi-alpha-x-box-outline", text: "mdi-alpha-x-box-outline"},
    {icon: "mdi-alpha-x-circle", text: "mdi-alpha-x-circle"},
    {icon: "mdi-alpha-x-circle-outline", text: "mdi-alpha-x-circle-outline"},
    {icon: "mdi-alpha-y", text: "mdi-alpha-y"},
    {icon: "mdi-alpha-y-box", text: "mdi-alpha-y-box"},
    {icon: "mdi-alpha-y-box-outline", text: "mdi-alpha-y-box-outline"},
    {icon: "mdi-alpha-y-circle", text: "mdi-alpha-y-circle"},
    {icon: "mdi-alpha-y-circle-outline", text: "mdi-alpha-y-circle-outline"},
    {icon: "mdi-alpha-z", text: "mdi-alpha-z"},
    {icon: "mdi-alpha-z-box", text: "mdi-alpha-z-box"},
    {icon: "mdi-alpha-z-box-outline", text: "mdi-alpha-z-box-outline"},
    {icon: "mdi-alpha-z-circle", text: "mdi-alpha-z-circle"},
    {icon: "mdi-alpha-z-circle-outline", text: "mdi-alpha-z-circle-outline"},
    {icon: "mdi-alphabetical", text: "mdi-alphabetical"},
    {icon: "mdi-alphabetical-off", text: "mdi-alphabetical-off"},
    {icon: "mdi-alphabetical-variant", text: "mdi-alphabetical-variant"},
    {icon: "mdi-alphabetical-variant-off", text: "mdi-alphabetical-variant-off"},
    {icon: "mdi-altimeter", text: "mdi-altimeter"},
    {icon: "mdi-amazon", text: "mdi-amazon"},
    {icon: "mdi-amazon-alexa", text: "mdi-amazon-alexa"},
    {icon: "mdi-amazon-drive", text: "mdi-amazon-drive"},
    {icon: "mdi-ambulance", text: "mdi-ambulance"},
    {icon: "mdi-ammunition", text: "mdi-ammunition"},
    {icon: "mdi-ampersand", text: "mdi-ampersand"},
    {icon: "mdi-amplifier", text: "mdi-amplifier"},
    {icon: "mdi-anchor", text: "mdi-anchor"},
    {icon: "mdi-android", text: "mdi-android"},
    {icon: "mdi-android-auto", text: "mdi-android-auto"},
    {icon: "mdi-android-debug-bridge", text: "mdi-android-debug-bridge"},
    {icon: "mdi-android-head", text: "mdi-android-head"},
    {icon: "mdi-android-messages", text: "mdi-android-messages"},
    {icon: "mdi-android-studio", text: "mdi-android-studio"},
    {icon: "mdi-angle-acute", text: "mdi-angle-acute"},
    {icon: "mdi-angle-obtuse", text: "mdi-angle-obtuse"},
    {icon: "mdi-angle-right", text: "mdi-angle-right"},
    {icon: "mdi-angular", text: "mdi-angular"},
    {icon: "mdi-angularjs", text: "mdi-angularjs"},
    {icon: "mdi-animation", text: "mdi-animation"},
    {icon: "mdi-animation-outline", text: "mdi-animation-outline"},
    {icon: "mdi-animation-play", text: "mdi-animation-play"},
    {icon: "mdi-animation-play-outline", text: "mdi-animation-play-outline"},
    {icon: "mdi-anvil", text: "mdi-anvil"},
    {icon: "mdi-apache-kafka", text: "mdi-apache-kafka"},
    {icon: "mdi-apple", text: "mdi-apple"},
    {icon: "mdi-apple-finder", text: "mdi-apple-finder"},
    {icon: "mdi-apple-icloud", text: "mdi-apple-icloud"},
    {icon: "mdi-apple-ios", text: "mdi-apple-ios"},
    {icon: "mdi-apple-keyboard-caps", text: "mdi-apple-keyboard-caps"},
    {icon: "mdi-apple-keyboard-command", text: "mdi-apple-keyboard-command"},
    {icon: "mdi-apple-keyboard-control", text: "mdi-apple-keyboard-control"},
    {icon: "mdi-apple-keyboard-option", text: "mdi-apple-keyboard-option"},
    {icon: "mdi-apple-keyboard-shift", text: "mdi-apple-keyboard-shift"},
    {icon: "mdi-apple-safari", text: "mdi-apple-safari"},
    {icon: "mdi-application", text: "mdi-application"},
    {icon: "mdi-application-export", text: "mdi-application-export"},
    {icon: "mdi-application-import", text: "mdi-application-import"},
    {icon: "mdi-approximately-equal", text: "mdi-approximately-equal"},
    {icon: "mdi-approximately-equal-box", text: "mdi-approximately-equal-box"},
    {icon: "mdi-apps", text: "mdi-apps"},
    {icon: "mdi-apps-box", text: "mdi-apps-box"},
    {icon: "mdi-arch", text: "mdi-arch"},
    {icon: "mdi-archive", text: "mdi-archive"},
    {icon: "mdi-arrange-bring-forward", text: "mdi-arrange-bring-forward"},
    {icon: "mdi-arrange-bring-to-front", text: "mdi-arrange-bring-to-front"},
    {icon: "mdi-arrange-send-backward", text: "mdi-arrange-send-backward"},
    {icon: "mdi-arrange-send-to-back", text: "mdi-arrange-send-to-back"},
    {icon: "mdi-arrow-all", text: "mdi-arrow-all"},
    {icon: "mdi-arrow-bottom-left", text: "mdi-arrow-bottom-left"},
    {icon: "mdi-arrow-bottom-left-bold-outline", text: "mdi-arrow-bottom-left-bold-outline"},
    {icon: "mdi-arrow-bottom-left-thick", text: "mdi-arrow-bottom-left-thick"},
    {icon: "mdi-arrow-bottom-right", text: "mdi-arrow-bottom-right"},
    {icon: "mdi-arrow-bottom-right-bold-outline", text: "mdi-arrow-bottom-right-bold-outline"},
    {icon: "mdi-arrow-bottom-right-thick", text: "mdi-arrow-bottom-right-thick"},
    {icon: "mdi-arrow-collapse", text: "mdi-arrow-collapse"},
    {icon: "mdi-arrow-collapse-all", text: "mdi-arrow-collapse-all"},
    {icon: "mdi-arrow-collapse-down", text: "mdi-arrow-collapse-down"},
    {icon: "mdi-arrow-collapse-horizontal", text: "mdi-arrow-collapse-horizontal"},
    {icon: "mdi-arrow-collapse-left", text: "mdi-arrow-collapse-left"},
    {icon: "mdi-arrow-collapse-right", text: "mdi-arrow-collapse-right"},
    {icon: "mdi-arrow-collapse-up", text: "mdi-arrow-collapse-up"},
    {icon: "mdi-arrow-collapse-vertical", text: "mdi-arrow-collapse-vertical"},
    {icon: "mdi-arrow-decision", text: "mdi-arrow-decision"},
    {icon: "mdi-arrow-decision-auto", text: "mdi-arrow-decision-auto"},
    {icon: "mdi-arrow-decision-auto-outline", text: "mdi-arrow-decision-auto-outline"},
    {icon: "mdi-arrow-decision-outline", text: "mdi-arrow-decision-outline"},
    {icon: "mdi-arrow-down", text: "mdi-arrow-down"},
    {icon: "mdi-arrow-down-bold", text: "mdi-arrow-down-bold"},
    {icon: "mdi-arrow-down-bold-box", text: "mdi-arrow-down-bold-box"},
    {icon: "mdi-arrow-down-bold-box-outline", text: "mdi-arrow-down-bold-box-outline"},
    {icon: "mdi-arrow-down-bold-circle", text: "mdi-arrow-down-bold-circle"},
    {icon: "mdi-arrow-down-bold-circle-outline", text: "mdi-arrow-down-bold-circle-outline"},
    {icon: "mdi-arrow-down-bold-hexagon-outline", text: "mdi-arrow-down-bold-hexagon-outline"},
    {icon: "mdi-arrow-down-bold-outline", text: "mdi-arrow-down-bold-outline"},
    {icon: "mdi-arrow-down-box", text: "mdi-arrow-down-box"},
    {icon: "mdi-arrow-down-circle", text: "mdi-arrow-down-circle"},
    {icon: "mdi-arrow-down-circle-outline", text: "mdi-arrow-down-circle-outline"},
    {icon: "mdi-arrow-down-drop-circle", text: "mdi-arrow-down-drop-circle"},
    {icon: "mdi-arrow-down-drop-circle-outline", text: "mdi-arrow-down-drop-circle-outline"},
    {icon: "mdi-arrow-down-thick", text: "mdi-arrow-down-thick"},
    {icon: "mdi-arrow-expand", text: "mdi-arrow-expand"},
    {icon: "mdi-arrow-expand-all", text: "mdi-arrow-expand-all"},
    {icon: "mdi-arrow-expand-down", text: "mdi-arrow-expand-down"},
    {icon: "mdi-arrow-expand-horizontal", text: "mdi-arrow-expand-horizontal"},
    {icon: "mdi-arrow-expand-left", text: "mdi-arrow-expand-left"},
    {icon: "mdi-arrow-expand-right", text: "mdi-arrow-expand-right"},
    {icon: "mdi-arrow-expand-up", text: "mdi-arrow-expand-up"},
    {icon: "mdi-arrow-expand-vertical", text: "mdi-arrow-expand-vertical"},
    {icon: "mdi-arrow-left", text: "mdi-arrow-left"},
    {icon: "mdi-arrow-left-bold", text: "mdi-arrow-left-bold"},
    {icon: "mdi-arrow-left-bold-box", text: "mdi-arrow-left-bold-box"},
    {icon: "mdi-arrow-left-bold-box-outline", text: "mdi-arrow-left-bold-box-outline"},
    {icon: "mdi-arrow-left-bold-circle", text: "mdi-arrow-left-bold-circle"},
    {icon: "mdi-arrow-left-bold-circle-outline", text: "mdi-arrow-left-bold-circle-outline"},
    {icon: "mdi-arrow-left-bold-hexagon-outline", text: "mdi-arrow-left-bold-hexagon-outline"},
    {icon: "mdi-arrow-left-bold-outline", text: "mdi-arrow-left-bold-outline"},
    {icon: "mdi-arrow-left-box", text: "mdi-arrow-left-box"},
    {icon: "mdi-arrow-left-circle", text: "mdi-arrow-left-circle"},
    {icon: "mdi-arrow-left-circle-outline", text: "mdi-arrow-left-circle-outline"},
    {icon: "mdi-arrow-left-drop-circle", text: "mdi-arrow-left-drop-circle"},
    {icon: "mdi-arrow-left-drop-circle-outline", text: "mdi-arrow-left-drop-circle-outline"},
    {icon: "mdi-arrow-left-right", text: "mdi-arrow-left-right"},
    {icon: "mdi-arrow-left-right-bold", text: "mdi-arrow-left-right-bold"},
    {icon: "mdi-arrow-left-right-bold-outline", text: "mdi-arrow-left-right-bold-outline"},
    {icon: "mdi-arrow-left-thick", text: "mdi-arrow-left-thick"},
    {icon: "mdi-arrow-right", text: "mdi-arrow-right"},
    {icon: "mdi-arrow-right-bold", text: "mdi-arrow-right-bold"},
    {icon: "mdi-arrow-right-bold-box", text: "mdi-arrow-right-bold-box"},
    {icon: "mdi-arrow-right-bold-box-outline", text: "mdi-arrow-right-bold-box-outline"},
    {icon: "mdi-arrow-right-bold-circle", text: "mdi-arrow-right-bold-circle"},
    {icon: "mdi-arrow-right-bold-circle-outline", text: "mdi-arrow-right-bold-circle-outline"},
    {icon: "mdi-arrow-right-bold-hexagon-outline", text: "mdi-arrow-right-bold-hexagon-outline"},
    {icon: "mdi-arrow-right-bold-outline", text: "mdi-arrow-right-bold-outline"},
    {icon: "mdi-arrow-right-box", text: "mdi-arrow-right-box"},
    {icon: "mdi-arrow-right-circle", text: "mdi-arrow-right-circle"},
    {icon: "mdi-arrow-right-circle-outline", text: "mdi-arrow-right-circle-outline"},
    {icon: "mdi-arrow-right-drop-circle", text: "mdi-arrow-right-drop-circle"},
    {icon: "mdi-arrow-right-drop-circle-outline", text: "mdi-arrow-right-drop-circle-outline"},
    {icon: "mdi-arrow-right-thick", text: "mdi-arrow-right-thick"},
    {icon: "mdi-arrow-split-horizontal", text: "mdi-arrow-split-horizontal"},
    {icon: "mdi-arrow-split-vertical", text: "mdi-arrow-split-vertical"},
    {icon: "mdi-arrow-top-left", text: "mdi-arrow-top-left"},
    {icon: "mdi-arrow-top-left-bold-outline", text: "mdi-arrow-top-left-bold-outline"},
    {icon: "mdi-arrow-top-left-bottom-right", text: "mdi-arrow-top-left-bottom-right"},
    {icon: "mdi-arrow-top-left-bottom-right-bold", text: "mdi-arrow-top-left-bottom-right-bold"},
    {icon: "mdi-arrow-top-left-thick", text: "mdi-arrow-top-left-thick"},
    {icon: "mdi-arrow-top-right", text: "mdi-arrow-top-right"},
    {icon: "mdi-arrow-top-right-bold-outline", text: "mdi-arrow-top-right-bold-outline"},
    {icon: "mdi-arrow-top-right-bottom-left", text: "mdi-arrow-top-right-bottom-left"},
    {icon: "mdi-arrow-top-right-bottom-left-bold", text: "mdi-arrow-top-right-bottom-left-bold"},
    {icon: "mdi-arrow-top-right-thick", text: "mdi-arrow-top-right-thick"},
    {icon: "mdi-arrow-up", text: "mdi-arrow-up"},
    {icon: "mdi-arrow-up-bold", text: "mdi-arrow-up-bold"},
    {icon: "mdi-arrow-up-bold-box", text: "mdi-arrow-up-bold-box"},
    {icon: "mdi-arrow-up-bold-box-outline", text: "mdi-arrow-up-bold-box-outline"},
    {icon: "mdi-arrow-up-bold-circle", text: "mdi-arrow-up-bold-circle"},
    {icon: "mdi-arrow-up-bold-circle-outline", text: "mdi-arrow-up-bold-circle-outline"},
    {icon: "mdi-arrow-up-bold-hexagon-outline", text: "mdi-arrow-up-bold-hexagon-outline"},
    {icon: "mdi-arrow-up-bold-outline", text: "mdi-arrow-up-bold-outline"},
    {icon: "mdi-arrow-up-box", text: "mdi-arrow-up-box"},
    {icon: "mdi-arrow-up-circle", text: "mdi-arrow-up-circle"},
    {icon: "mdi-arrow-up-circle-outline", text: "mdi-arrow-up-circle-outline"},
    {icon: "mdi-arrow-up-down", text: "mdi-arrow-up-down"},
    {icon: "mdi-arrow-up-down-bold", text: "mdi-arrow-up-down-bold"},
    {icon: "mdi-arrow-up-down-bold-outline", text: "mdi-arrow-up-down-bold-outline"},
    {icon: "mdi-arrow-up-drop-circle", text: "mdi-arrow-up-drop-circle"},
    {icon: "mdi-arrow-up-drop-circle-outline", text: "mdi-arrow-up-drop-circle-outline"},
    {icon: "mdi-arrow-up-thick", text: "mdi-arrow-up-thick"},
    {icon: "mdi-artist", text: "mdi-artist"},
    {icon: "mdi-artist-outline", text: "mdi-artist-outline"},
    {icon: "mdi-artstation", text: "mdi-artstation"},
    {icon: "mdi-aspect-ratio", text: "mdi-aspect-ratio"},
    {icon: "mdi-assistant", text: "mdi-assistant"},
    {icon: "mdi-asterisk", text: "mdi-asterisk"},
    {icon: "mdi-at", text: "mdi-at"},
    {icon: "mdi-atlassian", text: "mdi-atlassian"},
    {icon: "mdi-atm", text: "mdi-atm"},
    {icon: "mdi-atom", text: "mdi-atom"},
    {icon: "mdi-atom-variant", text: "mdi-atom-variant"},
    {icon: "mdi-attachment", text: "mdi-attachment"},
    {icon: "mdi-audio-video", text: "mdi-audio-video"},
    {icon: "mdi-audiobook", text: "mdi-audiobook"},
    {icon: "mdi-augmented-reality", text: "mdi-augmented-reality"},
    {icon: "mdi-auto-fix", text: "mdi-auto-fix"},
    {icon: "mdi-auto-upload", text: "mdi-auto-upload"},
    {icon: "mdi-autorenew", text: "mdi-autorenew"},
    {icon: "mdi-av-timer", text: "mdi-av-timer"},
    {icon: "mdi-aws", text: "mdi-aws"},
    {icon: "mdi-axe", text: "mdi-axe"},
    {icon: "mdi-axis", text: "mdi-axis"},
    {icon: "mdi-axis-arrow", text: "mdi-axis-arrow"},
    {icon: "mdi-axis-arrow-lock", text: "mdi-axis-arrow-lock"},
    {icon: "mdi-axis-lock", text: "mdi-axis-lock"},
    {icon: "mdi-axis-x-arrow", text: "mdi-axis-x-arrow"},
    {icon: "mdi-axis-x-arrow-lock", text: "mdi-axis-x-arrow-lock"},
    {icon: "mdi-axis-x-rotate-clockwise", text: "mdi-axis-x-rotate-clockwise"},
    {icon: "mdi-axis-x-rotate-counterclockwise", text: "mdi-axis-x-rotate-counterclockwise"},
    {icon: "mdi-axis-x-y-arrow-lock", text: "mdi-axis-x-y-arrow-lock"},
    {icon: "mdi-axis-y-arrow", text: "mdi-axis-y-arrow"},
    {icon: "mdi-axis-y-arrow-lock", text: "mdi-axis-y-arrow-lock"},
    {icon: "mdi-axis-y-rotate-clockwise", text: "mdi-axis-y-rotate-clockwise"},
    {icon: "mdi-axis-y-rotate-counterclockwise", text: "mdi-axis-y-rotate-counterclockwise"},
    {icon: "mdi-axis-z-arrow", text: "mdi-axis-z-arrow"},
    {icon: "mdi-axis-z-arrow-lock", text: "mdi-axis-z-arrow-lock"},
    {icon: "mdi-axis-z-rotate-clockwise", text: "mdi-axis-z-rotate-clockwise"},
    {icon: "mdi-axis-z-rotate-counterclockwise", text: "mdi-axis-z-rotate-counterclockwise"},
    {icon: "mdi-azure", text: "mdi-azure"},
    {icon: "mdi-babel", text: "mdi-babel"},
    {icon: "mdi-baby", text: "mdi-baby"},
    {icon: "mdi-baby-bottle", text: "mdi-baby-bottle"},
    {icon: "mdi-baby-bottle-outline", text: "mdi-baby-bottle-outline"},
    {icon: "mdi-baby-carriage", text: "mdi-baby-carriage"},
    {icon: "mdi-baby-carriage-off", text: "mdi-baby-carriage-off"},
    {icon: "mdi-baby-face", text: "mdi-baby-face"},
    {icon: "mdi-baby-face-outline", text: "mdi-baby-face-outline"},
    {icon: "mdi-backburger", text: "mdi-backburger"},
    {icon: "mdi-backspace", text: "mdi-backspace"},
    {icon: "mdi-backspace-outline", text: "mdi-backspace-outline"},
    {icon: "mdi-backspace-reverse", text: "mdi-backspace-reverse"},
    {icon: "mdi-backspace-reverse-outline", text: "mdi-backspace-reverse-outline"},
    {icon: "mdi-backup-restore", text: "mdi-backup-restore"},
    {icon: "mdi-bacteria", text: "mdi-bacteria"},
    {icon: "mdi-bacteria-outline", text: "mdi-bacteria-outline"},
    {icon: "mdi-badminton", text: "mdi-badminton"},
    {icon: "mdi-bag-carry-on", text: "mdi-bag-carry-on"},
    {icon: "mdi-bag-carry-on-check", text: "mdi-bag-carry-on-check"},
    {icon: "mdi-bag-carry-on-off", text: "mdi-bag-carry-on-off"},
    {icon: "mdi-bag-checked", text: "mdi-bag-checked"},
    {icon: "mdi-bag-personal", text: "mdi-bag-personal"},
    {icon: "mdi-bag-personal-off", text: "mdi-bag-personal-off"},
    {icon: "mdi-bag-personal-off-outline", text: "mdi-bag-personal-off-outline"},
    {icon: "mdi-bag-personal-outline", text: "mdi-bag-personal-outline"},
    {icon: "mdi-baguette", text: "mdi-baguette"},
    {icon: "mdi-balloon", text: "mdi-balloon"},
    {icon: "mdi-ballot", text: "mdi-ballot"},
    {icon: "mdi-ballot-outline", text: "mdi-ballot-outline"},
    {icon: "mdi-ballot-recount", text: "mdi-ballot-recount"},
    {icon: "mdi-ballot-recount-outline", text: "mdi-ballot-recount-outline"},
    {icon: "mdi-bandage", text: "mdi-bandage"},
    {icon: "mdi-bandcamp", text: "mdi-bandcamp"},
    {icon: "mdi-bank", text: "mdi-bank"},
    {icon: "mdi-bank-minus", text: "mdi-bank-minus"},
    {icon: "mdi-bank-outline", text: "mdi-bank-outline"},
    {icon: "mdi-bank-plus", text: "mdi-bank-plus"},
    {icon: "mdi-bank-remove", text: "mdi-bank-remove"},
    {icon: "mdi-bank-transfer", text: "mdi-bank-transfer"},
    {icon: "mdi-bank-transfer-in", text: "mdi-bank-transfer-in"},
    {icon: "mdi-bank-transfer-out", text: "mdi-bank-transfer-out"},
    {icon: "mdi-barcode", text: "mdi-barcode"},
    {icon: "mdi-barcode-scan", text: "mdi-barcode-scan"},
    {icon: "mdi-barley", text: "mdi-barley"},
    {icon: "mdi-barley-off", text: "mdi-barley-off"},
    {icon: "mdi-barn", text: "mdi-barn"},
    {icon: "mdi-barrel", text: "mdi-barrel"},
    {icon: "mdi-baseball", text: "mdi-baseball"},
    {icon: "mdi-baseball-bat", text: "mdi-baseball-bat"},
    {icon: "mdi-basecamp", text: "mdi-basecamp"},
    {icon: "mdi-basket", text: "mdi-basket"},
    {icon: "mdi-basket-fill", text: "mdi-basket-fill"},
    {icon: "mdi-basket-unfill", text: "mdi-basket-unfill"},
    {icon: "mdi-basketball", text: "mdi-basketball"},
    {icon: "mdi-basketball-hoop", text: "mdi-basketball-hoop"},
    {icon: "mdi-basketball-hoop-outline", text: "mdi-basketball-hoop-outline"},
    {icon: "mdi-bat", text: "mdi-bat"},
    {icon: "mdi-battery", text: "mdi-battery"},
    {icon: "mdi-battery-10", text: "mdi-battery-10"},
    {icon: "mdi-battery-10-bluetooth", text: "mdi-battery-10-bluetooth"},
    {icon: "mdi-battery-20", text: "mdi-battery-20"},
    {icon: "mdi-battery-20-bluetooth", text: "mdi-battery-20-bluetooth"},
    {icon: "mdi-battery-30", text: "mdi-battery-30"},
    {icon: "mdi-battery-30-bluetooth", text: "mdi-battery-30-bluetooth"},
    {icon: "mdi-battery-40", text: "mdi-battery-40"},
    {icon: "mdi-battery-40-bluetooth", text: "mdi-battery-40-bluetooth"},
    {icon: "mdi-battery-50", text: "mdi-battery-50"},
    {icon: "mdi-battery-50-bluetooth", text: "mdi-battery-50-bluetooth"},
    {icon: "mdi-battery-60", text: "mdi-battery-60"},
    {icon: "mdi-battery-60-bluetooth", text: "mdi-battery-60-bluetooth"},
    {icon: "mdi-battery-70", text: "mdi-battery-70"},
    {icon: "mdi-battery-70-bluetooth", text: "mdi-battery-70-bluetooth"},
    {icon: "mdi-battery-80", text: "mdi-battery-80"},
    {icon: "mdi-battery-80-bluetooth", text: "mdi-battery-80-bluetooth"},
    {icon: "mdi-battery-90", text: "mdi-battery-90"},
    {icon: "mdi-battery-90-bluetooth", text: "mdi-battery-90-bluetooth"},
    {icon: "mdi-battery-alert", text: "mdi-battery-alert"},
    {icon: "mdi-battery-alert-bluetooth", text: "mdi-battery-alert-bluetooth"},
    {icon: "mdi-battery-bluetooth", text: "mdi-battery-bluetooth"},
    {icon: "mdi-battery-bluetooth-variant", text: "mdi-battery-bluetooth-variant"},
    {icon: "mdi-battery-charging", text: "mdi-battery-charging"},
    {icon: "mdi-battery-charging-10", text: "mdi-battery-charging-10"},
    {icon: "mdi-battery-charging-100", text: "mdi-battery-charging-100"},
    {icon: "mdi-battery-charging-20", text: "mdi-battery-charging-20"},
    {icon: "mdi-battery-charging-30", text: "mdi-battery-charging-30"},
    {icon: "mdi-battery-charging-40", text: "mdi-battery-charging-40"},
    {icon: "mdi-battery-charging-50", text: "mdi-battery-charging-50"},
    {icon: "mdi-battery-charging-60", text: "mdi-battery-charging-60"},
    {icon: "mdi-battery-charging-70", text: "mdi-battery-charging-70"},
    {icon: "mdi-battery-charging-80", text: "mdi-battery-charging-80"},
    {icon: "mdi-battery-charging-90", text: "mdi-battery-charging-90"},
    {icon: "mdi-battery-charging-outline", text: "mdi-battery-charging-outline"},
    {icon: "mdi-battery-charging-wireless", text: "mdi-battery-charging-wireless"},
    {icon: "mdi-battery-charging-wireless-10", text: "mdi-battery-charging-wireless-10"},
    {icon: "mdi-battery-charging-wireless-20", text: "mdi-battery-charging-wireless-20"},
    {icon: "mdi-battery-charging-wireless-30", text: "mdi-battery-charging-wireless-30"},
    {icon: "mdi-battery-charging-wireless-40", text: "mdi-battery-charging-wireless-40"},
    {icon: "mdi-battery-charging-wireless-50", text: "mdi-battery-charging-wireless-50"},
    {icon: "mdi-battery-charging-wireless-60", text: "mdi-battery-charging-wireless-60"},
    {icon: "mdi-battery-charging-wireless-70", text: "mdi-battery-charging-wireless-70"},
    {icon: "mdi-battery-charging-wireless-80", text: "mdi-battery-charging-wireless-80"},
    {icon: "mdi-battery-charging-wireless-90", text: "mdi-battery-charging-wireless-90"},
    {icon: "mdi-battery-charging-wireless-alert", text: "mdi-battery-charging-wireless-alert"},
    {icon: "mdi-battery-charging-wireless-outline", text: "mdi-battery-charging-wireless-outline"},
    {icon: "mdi-battery-minus", text: "mdi-battery-minus"},
    {icon: "mdi-battery-negative", text: "mdi-battery-negative"},
    {icon: "mdi-battery-outline", text: "mdi-battery-outline"},
    {icon: "mdi-battery-plus", text: "mdi-battery-plus"},
    {icon: "mdi-battery-positive", text: "mdi-battery-positive"},
    {icon: "mdi-battery-unknown", text: "mdi-battery-unknown"},
    {icon: "mdi-battery-unknown-bluetooth", text: "mdi-battery-unknown-bluetooth"},
    {icon: "mdi-battlenet", text: "mdi-battlenet"},
    {icon: "mdi-beach", text: "mdi-beach"},
    {icon: "mdi-beaker", text: "mdi-beaker"},
    {icon: "mdi-beaker-outline", text: "mdi-beaker-outline"},
    {icon: "mdi-beats", text: "mdi-beats"},
    {icon: "mdi-bed-empty", text: "mdi-bed-empty"},
    {icon: "mdi-bee", text: "mdi-bee"},
    {icon: "mdi-bee-flower", text: "mdi-bee-flower"},
    {icon: "mdi-beer", text: "mdi-beer"},
    {icon: "mdi-behance", text: "mdi-behance"},
    {icon: "mdi-bell", text: "mdi-bell"},
    {icon: "mdi-bell-alert", text: "mdi-bell-alert"},
    {icon: "mdi-bell-alert-outline", text: "mdi-bell-alert-outline"},
    {icon: "mdi-bell-circle", text: "mdi-bell-circle"},
    {icon: "mdi-bell-circle-outline", text: "mdi-bell-circle-outline"},
    {icon: "mdi-bell-off", text: "mdi-bell-off"},
    {icon: "mdi-bell-off-outline", text: "mdi-bell-off-outline"},
    {icon: "mdi-bell-outline", text: "mdi-bell-outline"},
    {icon: "mdi-bell-plus", text: "mdi-bell-plus"},
    {icon: "mdi-bell-plus-outline", text: "mdi-bell-plus-outline"},
    {icon: "mdi-bell-ring", text: "mdi-bell-ring"},
    {icon: "mdi-bell-ring-outline", text: "mdi-bell-ring-outline"},
    {icon: "mdi-bell-sleep", text: "mdi-bell-sleep"},
    {icon: "mdi-bell-sleep-outline", text: "mdi-bell-sleep-outline"},
    {icon: "mdi-beta", text: "mdi-beta"},
    {icon: "mdi-betamax", text: "mdi-betamax"},
    {icon: "mdi-biathlon", text: "mdi-biathlon"},
    {icon: "mdi-bible", text: "mdi-bible"},
    {icon: "mdi-bike", text: "mdi-bike"},
    {icon: "mdi-billboard", text: "mdi-billboard"},
    {icon: "mdi-billiards", text: "mdi-billiards"},
    {icon: "mdi-billiards-rack", text: "mdi-billiards-rack"},
    {icon: "mdi-bing", text: "mdi-bing"},
    {icon: "mdi-binoculars", text: "mdi-binoculars"},
    {icon: "mdi-bio", text: "mdi-bio"},
    {icon: "mdi-biohazard", text: "mdi-biohazard"},
    {icon: "mdi-bitbucket", text: "mdi-bitbucket"},
    {icon: "mdi-bitcoin", text: "mdi-bitcoin"},
    {icon: "mdi-black-mesa", text: "mdi-black-mesa"},
    {icon: "mdi-blackberry", text: "mdi-blackberry"},
    {icon: "mdi-blender", text: "mdi-blender"},
    {icon: "mdi-blender-software", text: "mdi-blender-software"},
    {icon: "mdi-blinds", text: "mdi-blinds"},
    {icon: "mdi-blinds-open", text: "mdi-blinds-open"},
    {icon: "mdi-block-helper", text: "mdi-block-helper"},
    {icon: "mdi-blogger", text: "mdi-blogger"},
    {icon: "mdi-blood-bag", text: "mdi-blood-bag"},
    {icon: "mdi-bluetooth", text: "mdi-bluetooth"},
    {icon: "mdi-bluetooth-audio", text: "mdi-bluetooth-audio"},
    {icon: "mdi-bluetooth-connect", text: "mdi-bluetooth-connect"},
    {icon: "mdi-bluetooth-off", text: "mdi-bluetooth-off"},
    {icon: "mdi-bluetooth-settings", text: "mdi-bluetooth-settings"},
    {icon: "mdi-bluetooth-transfer", text: "mdi-bluetooth-transfer"},
    {icon: "mdi-blur", text: "mdi-blur"},
    {icon: "mdi-blur-linear", text: "mdi-blur-linear"},
    {icon: "mdi-blur-off", text: "mdi-blur-off"},
    {icon: "mdi-blur-radial", text: "mdi-blur-radial"},
    {icon: "mdi-bolnisi-cross", text: "mdi-bolnisi-cross"},
    {icon: "mdi-bolt", text: "mdi-bolt"},
    {icon: "mdi-bomb", text: "mdi-bomb"},
    {icon: "mdi-bomb-off", text: "mdi-bomb-off"},
    {icon: "mdi-bone", text: "mdi-bone"},
    {icon: "mdi-book", text: "mdi-book"},
    {icon: "mdi-book-lock", text: "mdi-book-lock"},
    {icon: "mdi-book-lock-open", text: "mdi-book-lock-open"},
    {icon: "mdi-book-minus", text: "mdi-book-minus"},
    {icon: "mdi-book-minus-multiple", text: "mdi-book-minus-multiple"},
    {icon: "mdi-book-multiple", text: "mdi-book-multiple"},
    {icon: "mdi-book-open", text: "mdi-book-open"},
    {icon: "mdi-book-open-outline", text: "mdi-book-open-outline"},
    {icon: "mdi-book-open-page-variant", text: "mdi-book-open-page-variant"},
    {icon: "mdi-book-open-variant", text: "mdi-book-open-variant"},
    {icon: "mdi-book-outline", text: "mdi-book-outline"},
    {icon: "mdi-book-play", text: "mdi-book-play"},
    {icon: "mdi-book-play-outline", text: "mdi-book-play-outline"},
    {icon: "mdi-book-plus", text: "mdi-book-plus"},
    {icon: "mdi-book-plus-multiple", text: "mdi-book-plus-multiple"},
    {icon: "mdi-book-remove", text: "mdi-book-remove"},
    {icon: "mdi-book-remove-multiple", text: "mdi-book-remove-multiple"},
    {icon: "mdi-book-search", text: "mdi-book-search"},
    {icon: "mdi-book-search-outline", text: "mdi-book-search-outline"},
    {icon: "mdi-book-variant", text: "mdi-book-variant"},
    {icon: "mdi-book-variant-multiple", text: "mdi-book-variant-multiple"},
    {icon: "mdi-bookmark", text: "mdi-bookmark"},
    {icon: "mdi-bookmark-check", text: "mdi-bookmark-check"},
    {icon: "mdi-bookmark-minus", text: "mdi-bookmark-minus"},
    {icon: "mdi-bookmark-minus-outline", text: "mdi-bookmark-minus-outline"},
    {icon: "mdi-bookmark-multiple", text: "mdi-bookmark-multiple"},
    {icon: "mdi-bookmark-multiple-outline", text: "mdi-bookmark-multiple-outline"},
    {icon: "mdi-bookmark-music", text: "mdi-bookmark-music"},
    {icon: "mdi-bookmark-off", text: "mdi-bookmark-off"},
    {icon: "mdi-bookmark-off-outline", text: "mdi-bookmark-off-outline"},
    {icon: "mdi-bookmark-outline", text: "mdi-bookmark-outline"},
    {icon: "mdi-bookmark-plus", text: "mdi-bookmark-plus"},
    {icon: "mdi-bookmark-plus-outline", text: "mdi-bookmark-plus-outline"},
    {icon: "mdi-bookmark-remove", text: "mdi-bookmark-remove"},
    {icon: "mdi-boom-gate", text: "mdi-boom-gate"},
    {icon: "mdi-boom-gate-alert", text: "mdi-boom-gate-alert"},
    {icon: "mdi-boom-gate-alert-outline", text: "mdi-boom-gate-alert-outline"},
    {icon: "mdi-boom-gate-down", text: "mdi-boom-gate-down"},
    {icon: "mdi-boom-gate-down-outline", text: "mdi-boom-gate-down-outline"},
    {icon: "mdi-boom-gate-outline", text: "mdi-boom-gate-outline"},
    {icon: "mdi-boom-gate-up", text: "mdi-boom-gate-up"},
    {icon: "mdi-boom-gate-up-outline", text: "mdi-boom-gate-up-outline"},
    {icon: "mdi-boombox", text: "mdi-boombox"},
    {icon: "mdi-bootstrap", text: "mdi-bootstrap"},
    {icon: "mdi-border-all", text: "mdi-border-all"},
    {icon: "mdi-border-all-variant", text: "mdi-border-all-variant"},
    {icon: "mdi-border-bottom", text: "mdi-border-bottom"},
    {icon: "mdi-border-bottom-variant", text: "mdi-border-bottom-variant"},
    {icon: "mdi-border-color", text: "mdi-border-color"},
    {icon: "mdi-border-horizontal", text: "mdi-border-horizontal"},
    {icon: "mdi-border-inside", text: "mdi-border-inside"},
    {icon: "mdi-border-left", text: "mdi-border-left"},
    {icon: "mdi-border-left-variant", text: "mdi-border-left-variant"},
    {icon: "mdi-border-none", text: "mdi-border-none"},
    {icon: "mdi-border-none-variant", text: "mdi-border-none-variant"},
    {icon: "mdi-border-outside", text: "mdi-border-outside"},
    {icon: "mdi-border-right", text: "mdi-border-right"},
    {icon: "mdi-border-right-variant", text: "mdi-border-right-variant"},
    {icon: "mdi-border-style", text: "mdi-border-style"},
    {icon: "mdi-border-top", text: "mdi-border-top"},
    {icon: "mdi-border-top-variant", text: "mdi-border-top-variant"},
    {icon: "mdi-border-vertical", text: "mdi-border-vertical"},
    {icon: "mdi-bottle-wine", text: "mdi-bottle-wine"},
    {icon: "mdi-bow-tie", text: "mdi-bow-tie"},
    {icon: "mdi-bowl", text: "mdi-bowl"},
    {icon: "mdi-bowling", text: "mdi-bowling"},
    {icon: "mdi-box", text: "mdi-box"},
    {icon: "mdi-box-cutter", text: "mdi-box-cutter"},
    {icon: "mdi-box-shadow", text: "mdi-box-shadow"},
    {icon: "mdi-boxing-glove", text: "mdi-boxing-glove"},
    {icon: "mdi-braille", text: "mdi-braille"},
    {icon: "mdi-brain", text: "mdi-brain"},
    {icon: "mdi-bread-slice", text: "mdi-bread-slice"},
    {icon: "mdi-bread-slice-outline", text: "mdi-bread-slice-outline"},
    {icon: "mdi-bridge", text: "mdi-bridge"},
    {icon: "mdi-briefcase", text: "mdi-briefcase"},
    {icon: "mdi-briefcase-account", text: "mdi-briefcase-account"},
    {icon: "mdi-briefcase-account-outline", text: "mdi-briefcase-account-outline"},
    {icon: "mdi-briefcase-check", text: "mdi-briefcase-check"},
    {icon: "mdi-briefcase-download", text: "mdi-briefcase-download"},
    {icon: "mdi-briefcase-download-outline", text: "mdi-briefcase-download-outline"},
    {icon: "mdi-briefcase-edit", text: "mdi-briefcase-edit"},
    {icon: "mdi-briefcase-edit-outline", text: "mdi-briefcase-edit-outline"},
    {icon: "mdi-briefcase-minus", text: "mdi-briefcase-minus"},
    {icon: "mdi-briefcase-minus-outline", text: "mdi-briefcase-minus-outline"},
    {icon: "mdi-briefcase-outline", text: "mdi-briefcase-outline"},
    {icon: "mdi-briefcase-plus", text: "mdi-briefcase-plus"},
    {icon: "mdi-briefcase-plus-outline", text: "mdi-briefcase-plus-outline"},
    {icon: "mdi-briefcase-remove", text: "mdi-briefcase-remove"},
    {icon: "mdi-briefcase-remove-outline", text: "mdi-briefcase-remove-outline"},
    {icon: "mdi-briefcase-search", text: "mdi-briefcase-search"},
    {icon: "mdi-briefcase-search-outline", text: "mdi-briefcase-search-outline"},
    {icon: "mdi-briefcase-upload", text: "mdi-briefcase-upload"},
    {icon: "mdi-briefcase-upload-outline", text: "mdi-briefcase-upload-outline"},
    {icon: "mdi-brightness-1", text: "mdi-brightness-1"},
    {icon: "mdi-brightness-2", text: "mdi-brightness-2"},
    {icon: "mdi-brightness-3", text: "mdi-brightness-3"},
    {icon: "mdi-brightness-4", text: "mdi-brightness-4"},
    {icon: "mdi-brightness-5", text: "mdi-brightness-5"},
    {icon: "mdi-brightness-6", text: "mdi-brightness-6"},
    {icon: "mdi-brightness-7", text: "mdi-brightness-7"},
    {icon: "mdi-brightness-auto", text: "mdi-brightness-auto"},
    {icon: "mdi-brightness-percent", text: "mdi-brightness-percent"},
    {icon: "mdi-broom", text: "mdi-broom"},
    {icon: "mdi-brush", text: "mdi-brush"},
    {icon: "mdi-buddhism", text: "mdi-buddhism"},
    {icon: "mdi-buffer", text: "mdi-buffer"},
    {icon: "mdi-bug", text: "mdi-bug"},
    {icon: "mdi-bug-check", text: "mdi-bug-check"},
    {icon: "mdi-bug-check-outline", text: "mdi-bug-check-outline"},
    {icon: "mdi-bug-outline", text: "mdi-bug-outline"},
    {icon: "mdi-bugle", text: "mdi-bugle"},
    {icon: "mdi-bulldozer", text: "mdi-bulldozer"},
    {icon: "mdi-bullet", text: "mdi-bullet"},
    {icon: "mdi-bulletin-board", text: "mdi-bulletin-board"},
    {icon: "mdi-bullhorn", text: "mdi-bullhorn"},
    {icon: "mdi-bullhorn-outline", text: "mdi-bullhorn-outline"},
    {icon: "mdi-bullseye", text: "mdi-bullseye"},
    {icon: "mdi-bullseye-arrow", text: "mdi-bullseye-arrow"},
    {icon: "mdi-bus", text: "mdi-bus"},
    {icon: "mdi-bus-alert", text: "mdi-bus-alert"},
    {icon: "mdi-bus-articulated-end", text: "mdi-bus-articulated-end"},
    {icon: "mdi-bus-articulated-front", text: "mdi-bus-articulated-front"},
    {icon: "mdi-bus-clock", text: "mdi-bus-clock"},
    {icon: "mdi-bus-double-decker", text: "mdi-bus-double-decker"},
    {icon: "mdi-bus-multiple", text: "mdi-bus-multiple"},
    {icon: "mdi-bus-school", text: "mdi-bus-school"},
    {icon: "mdi-bus-side", text: "mdi-bus-side"},
    {icon: "mdi-bus-stop", text: "mdi-bus-stop"},
    {icon: "mdi-bus-stop-covered", text: "mdi-bus-stop-covered"},
    {icon: "mdi-bus-stop-uncovered", text: "mdi-bus-stop-uncovered"},
    {icon: "mdi-cached", text: "mdi-cached"},
    {icon: "mdi-cactus", text: "mdi-cactus"},
    {icon: "mdi-cake", text: "mdi-cake"},
    {icon: "mdi-cake-layered", text: "mdi-cake-layered"},
    {icon: "mdi-cake-variant", text: "mdi-cake-variant"},
    {icon: "mdi-calculator", text: "mdi-calculator"},
    {icon: "mdi-calculator-variant", text: "mdi-calculator-variant"},
    {icon: "mdi-calendar", text: "mdi-calendar"},
    {icon: "mdi-calendar-account", text: "mdi-calendar-account"},
    {icon: "mdi-calendar-account-outline", text: "mdi-calendar-account-outline"},
    {icon: "mdi-calendar-alert", text: "mdi-calendar-alert"},
    {icon: "mdi-calendar-blank", text: "mdi-calendar-blank"},
    {icon: "mdi-calendar-blank-outline", text: "mdi-calendar-blank-outline"},
    {icon: "mdi-calendar-check", text: "mdi-calendar-check"},
    {icon: "mdi-calendar-check-outline", text: "mdi-calendar-check-outline"},
    {icon: "mdi-calendar-clock", text: "mdi-calendar-clock"},
    {icon: "mdi-calendar-edit", text: "mdi-calendar-edit"},
    {icon: "mdi-calendar-export", text: "mdi-calendar-export"},
    {icon: "mdi-calendar-heart", text: "mdi-calendar-heart"},
    {icon: "mdi-calendar-import", text: "mdi-calendar-import"},
    {icon: "mdi-calendar-minus", text: "mdi-calendar-minus"},
    {icon: "mdi-calendar-month", text: "mdi-calendar-month"},
    {icon: "mdi-calendar-month-outline", text: "mdi-calendar-month-outline"},
    {icon: "mdi-calendar-multiple", text: "mdi-calendar-multiple"},
    {icon: "mdi-calendar-multiple-check", text: "mdi-calendar-multiple-check"},
    {icon: "mdi-calendar-multiselect", text: "mdi-calendar-multiselect"},
    {icon: "mdi-calendar-outline", text: "mdi-calendar-outline"},
    {icon: "mdi-calendar-plus", text: "mdi-calendar-plus"},
    {icon: "mdi-calendar-question", text: "mdi-calendar-question"},
    {icon: "mdi-calendar-range", text: "mdi-calendar-range"},
    {icon: "mdi-calendar-range-outline", text: "mdi-calendar-range-outline"},
    {icon: "mdi-calendar-remove", text: "mdi-calendar-remove"},
    {icon: "mdi-calendar-remove-outline", text: "mdi-calendar-remove-outline"},
    {icon: "mdi-calendar-repeat", text: "mdi-calendar-repeat"},
    {icon: "mdi-calendar-repeat-outline", text: "mdi-calendar-repeat-outline"},
    {icon: "mdi-calendar-search", text: "mdi-calendar-search"},
    {icon: "mdi-calendar-star", text: "mdi-calendar-star"},
    {icon: "mdi-calendar-text", text: "mdi-calendar-text"},
    {icon: "mdi-calendar-text-outline", text: "mdi-calendar-text-outline"},
    {icon: "mdi-calendar-today", text: "mdi-calendar-today"},
    {icon: "mdi-calendar-week", text: "mdi-calendar-week"},
    {icon: "mdi-calendar-week-begin", text: "mdi-calendar-week-begin"},
    {icon: "mdi-calendar-weekend", text: "mdi-calendar-weekend"},
    {icon: "mdi-calendar-weekend-outline", text: "mdi-calendar-weekend-outline"},
    {icon: "mdi-call-made", text: "mdi-call-made"},
    {icon: "mdi-call-merge", text: "mdi-call-merge"},
    {icon: "mdi-call-missed", text: "mdi-call-missed"},
    {icon: "mdi-call-received", text: "mdi-call-received"},
    {icon: "mdi-call-split", text: "mdi-call-split"},
    {icon: "mdi-camcorder", text: "mdi-camcorder"},
    {icon: "mdi-camcorder-box", text: "mdi-camcorder-box"},
    {icon: "mdi-camcorder-box-off", text: "mdi-camcorder-box-off"},
    {icon: "mdi-camcorder-off", text: "mdi-camcorder-off"},
    {icon: "mdi-camera", text: "mdi-camera"},
    {icon: "mdi-camera-account", text: "mdi-camera-account"},
    {icon: "mdi-camera-burst", text: "mdi-camera-burst"},
    {icon: "mdi-camera-control", text: "mdi-camera-control"},
    {icon: "mdi-camera-enhance", text: "mdi-camera-enhance"},
    {icon: "mdi-camera-enhance-outline", text: "mdi-camera-enhance-outline"},
    {icon: "mdi-camera-front", text: "mdi-camera-front"},
    {icon: "mdi-camera-front-variant", text: "mdi-camera-front-variant"},
    {icon: "mdi-camera-gopro", text: "mdi-camera-gopro"},
    {icon: "mdi-camera-image", text: "mdi-camera-image"},
    {icon: "mdi-camera-iris", text: "mdi-camera-iris"},
    {icon: "mdi-camera-metering-center", text: "mdi-camera-metering-center"},
    {icon: "mdi-camera-metering-matrix", text: "mdi-camera-metering-matrix"},
    {icon: "mdi-camera-metering-partial", text: "mdi-camera-metering-partial"},
    {icon: "mdi-camera-metering-spot", text: "mdi-camera-metering-spot"},
    {icon: "mdi-camera-off", text: "mdi-camera-off"},
    {icon: "mdi-camera-outline", text: "mdi-camera-outline"},
    {icon: "mdi-camera-party-mode", text: "mdi-camera-party-mode"},
    {icon: "mdi-camera-plus", text: "mdi-camera-plus"},
    {icon: "mdi-camera-plus-outline", text: "mdi-camera-plus-outline"},
    {icon: "mdi-camera-rear", text: "mdi-camera-rear"},
    {icon: "mdi-camera-rear-variant", text: "mdi-camera-rear-variant"},
    {icon: "mdi-camera-retake", text: "mdi-camera-retake"},
    {icon: "mdi-camera-retake-outline", text: "mdi-camera-retake-outline"},
    {icon: "mdi-camera-switch", text: "mdi-camera-switch"},
    {icon: "mdi-camera-timer", text: "mdi-camera-timer"},
    {icon: "mdi-camera-wireless", text: "mdi-camera-wireless"},
    {icon: "mdi-camera-wireless-outline", text: "mdi-camera-wireless-outline"},
    {icon: "mdi-campfire", text: "mdi-campfire"},
    {icon: "mdi-cancel", text: "mdi-cancel"},
    {icon: "mdi-candle", text: "mdi-candle"},
    {icon: "mdi-candycane", text: "mdi-candycane"},
    {icon: "mdi-cannabis", text: "mdi-cannabis"},
    {icon: "mdi-caps-lock", text: "mdi-caps-lock"},
    {icon: "mdi-car", text: "mdi-car"},
    {icon: "mdi-car-2-plus", text: "mdi-car-2-plus"},
    {icon: "mdi-car-3-plus", text: "mdi-car-3-plus"},
    {icon: "mdi-car-back", text: "mdi-car-back"},
    {icon: "mdi-car-battery", text: "mdi-car-battery"},
    {icon: "mdi-car-brake-abs", text: "mdi-car-brake-abs"},
    {icon: "mdi-car-brake-alert", text: "mdi-car-brake-alert"},
    {icon: "mdi-car-brake-hold", text: "mdi-car-brake-hold"},
    {icon: "mdi-car-brake-parking", text: "mdi-car-brake-parking"},
    {icon: "mdi-car-brake-retarder", text: "mdi-car-brake-retarder"},
    {icon: "mdi-car-child-seat", text: "mdi-car-child-seat"},
    {icon: "mdi-car-clutch", text: "mdi-car-clutch"},
    {icon: "mdi-car-connected", text: "mdi-car-connected"},
    {icon: "mdi-car-convertible", text: "mdi-car-convertible"},
    {icon: "mdi-car-coolant-level", text: "mdi-car-coolant-level"},
    {icon: "mdi-car-cruise-control", text: "mdi-car-cruise-control"},
    {icon: "mdi-car-defrost-front", text: "mdi-car-defrost-front"},
    {icon: "mdi-car-defrost-rear", text: "mdi-car-defrost-rear"},
    {icon: "mdi-car-door", text: "mdi-car-door"},
    {icon: "mdi-car-electric", text: "mdi-car-electric"},
    {icon: "mdi-car-esp", text: "mdi-car-esp"},
    {icon: "mdi-car-estate", text: "mdi-car-estate"},
    {icon: "mdi-car-hatchback", text: "mdi-car-hatchback"},
    {icon: "mdi-car-key", text: "mdi-car-key"},
    {icon: "mdi-car-light-dimmed", text: "mdi-car-light-dimmed"},
    {icon: "mdi-car-light-fog", text: "mdi-car-light-fog"},
    {icon: "mdi-car-light-high", text: "mdi-car-light-high"},
    {icon: "mdi-car-limousine", text: "mdi-car-limousine"},
    {icon: "mdi-car-multiple", text: "mdi-car-multiple"},
    {icon: "mdi-car-off", text: "mdi-car-off"},
    {icon: "mdi-car-parking-lights", text: "mdi-car-parking-lights"},
    {icon: "mdi-car-pickup", text: "mdi-car-pickup"},
    {icon: "mdi-car-seat", text: "mdi-car-seat"},
    {icon: "mdi-car-seat-cooler", text: "mdi-car-seat-cooler"},
    {icon: "mdi-car-seat-heater", text: "mdi-car-seat-heater"},
    {icon: "mdi-car-shift-pattern", text: "mdi-car-shift-pattern"},
    {icon: "mdi-car-side", text: "mdi-car-side"},
    {icon: "mdi-car-sports", text: "mdi-car-sports"},
    {icon: "mdi-car-tire-alert", text: "mdi-car-tire-alert"},
    {icon: "mdi-car-traction-control", text: "mdi-car-traction-control"},
    {icon: "mdi-car-turbocharger", text: "mdi-car-turbocharger"},
    {icon: "mdi-car-wash", text: "mdi-car-wash"},
    {icon: "mdi-car-windshield", text: "mdi-car-windshield"},
    {icon: "mdi-car-windshield-outline", text: "mdi-car-windshield-outline"},
    {icon: "mdi-caravan", text: "mdi-caravan"},
    {icon: "mdi-card", text: "mdi-card"},
    {icon: "mdi-card-bulleted", text: "mdi-card-bulleted"},
    {icon: "mdi-card-bulleted-off", text: "mdi-card-bulleted-off"},
    {icon: "mdi-card-bulleted-off-outline", text: "mdi-card-bulleted-off-outline"},
    {icon: "mdi-card-bulleted-outline", text: "mdi-card-bulleted-outline"},
    {icon: "mdi-card-bulleted-settings", text: "mdi-card-bulleted-settings"},
    {icon: "mdi-card-bulleted-settings-outline", text: "mdi-card-bulleted-settings-outline"},
    {icon: "mdi-card-outline", text: "mdi-card-outline"},
    {icon: "mdi-card-text", text: "mdi-card-text"},
    {icon: "mdi-card-text-outline", text: "mdi-card-text-outline"},
    {icon: "mdi-cards", text: "mdi-cards"},
    {icon: "mdi-cards-club", text: "mdi-cards-club"},
    {icon: "mdi-cards-diamond", text: "mdi-cards-diamond"},
    {icon: "mdi-cards-diamond-outline", text: "mdi-cards-diamond-outline"},
    {icon: "mdi-cards-heart", text: "mdi-cards-heart"},
    {icon: "mdi-cards-outline", text: "mdi-cards-outline"},
    {icon: "mdi-cards-playing-outline", text: "mdi-cards-playing-outline"},
    {icon: "mdi-cards-spade", text: "mdi-cards-spade"},
    {icon: "mdi-cards-variant", text: "mdi-cards-variant"},
    {icon: "mdi-carrot", text: "mdi-carrot"},
    {icon: "mdi-cart", text: "mdi-cart"},
    {icon: "mdi-cart-arrow-down", text: "mdi-cart-arrow-down"},
    {icon: "mdi-cart-arrow-right", text: "mdi-cart-arrow-right"},
    {icon: "mdi-cart-arrow-up", text: "mdi-cart-arrow-up"},
    {icon: "mdi-cart-minus", text: "mdi-cart-minus"},
    {icon: "mdi-cart-off", text: "mdi-cart-off"},
    {icon: "mdi-cart-outline", text: "mdi-cart-outline"},
    {icon: "mdi-cart-plus", text: "mdi-cart-plus"},
    {icon: "mdi-cart-remove", text: "mdi-cart-remove"},
    {icon: "mdi-case-sensitive-alt", text: "mdi-case-sensitive-alt"},
    {icon: "mdi-cash", text: "mdi-cash"},
    {icon: "mdi-cash-100", text: "mdi-cash-100"},
    {icon: "mdi-cash-marker", text: "mdi-cash-marker"},
    {icon: "mdi-cash-multiple", text: "mdi-cash-multiple"},
    {icon: "mdi-cash-refund", text: "mdi-cash-refund"},
    {icon: "mdi-cash-register", text: "mdi-cash-register"},
    {icon: "mdi-cash-usd", text: "mdi-cash-usd"},
    {icon: "mdi-cassette", text: "mdi-cassette"},
    {icon: "mdi-cast", text: "mdi-cast"},
    {icon: "mdi-cast-audio", text: "mdi-cast-audio"},
    {icon: "mdi-cast-connected", text: "mdi-cast-connected"},
    {icon: "mdi-cast-education", text: "mdi-cast-education"},
    {icon: "mdi-cast-off", text: "mdi-cast-off"},
    {icon: "mdi-castle", text: "mdi-castle"},
    {icon: "mdi-cat", text: "mdi-cat"},
    {icon: "mdi-cctv", text: "mdi-cctv"},
    {icon: "mdi-ceiling-light", text: "mdi-ceiling-light"},
    {icon: "mdi-cellphone", text: "mdi-cellphone"},
    {icon: "mdi-cellphone-android", text: "mdi-cellphone-android"},
    {icon: "mdi-cellphone-arrow-down", text: "mdi-cellphone-arrow-down"},
    {icon: "mdi-cellphone-basic", text: "mdi-cellphone-basic"},
    {icon: "mdi-cellphone-dock", text: "mdi-cellphone-dock"},
    {icon: "mdi-cellphone-erase", text: "mdi-cellphone-erase"},
    {icon: "mdi-cellphone-information", text: "mdi-cellphone-information"},
    {icon: "mdi-cellphone-iphone", text: "mdi-cellphone-iphone"},
    {icon: "mdi-cellphone-key", text: "mdi-cellphone-key"},
    {icon: "mdi-cellphone-link", text: "mdi-cellphone-link"},
    {icon: "mdi-cellphone-link-off", text: "mdi-cellphone-link-off"},
    {icon: "mdi-cellphone-lock", text: "mdi-cellphone-lock"},
    {icon: "mdi-cellphone-message", text: "mdi-cellphone-message"},
    {icon: "mdi-cellphone-nfc", text: "mdi-cellphone-nfc"},
    {icon: "mdi-cellphone-off", text: "mdi-cellphone-off"},
    {icon: "mdi-cellphone-play", text: "mdi-cellphone-play"},
    {icon: "mdi-cellphone-screenshot", text: "mdi-cellphone-screenshot"},
    {icon: "mdi-cellphone-settings", text: "mdi-cellphone-settings"},
    {icon: "mdi-cellphone-settings-variant", text: "mdi-cellphone-settings-variant"},
    {icon: "mdi-cellphone-sound", text: "mdi-cellphone-sound"},
    {icon: "mdi-cellphone-text", text: "mdi-cellphone-text"},
    {icon: "mdi-cellphone-wireless", text: "mdi-cellphone-wireless"},
    {icon: "mdi-celtic-cross", text: "mdi-celtic-cross"},
    {icon: "mdi-certificate", text: "mdi-certificate"},
    {icon: "mdi-chair-rolling", text: "mdi-chair-rolling"},
    {icon: "mdi-chair-school", text: "mdi-chair-school"},
    {icon: "mdi-charity", text: "mdi-charity"},
    {icon: "mdi-chart-arc", text: "mdi-chart-arc"},
    {icon: "mdi-chart-areaspline", text: "mdi-chart-areaspline"},
    {icon: "mdi-chart-areaspline-variant", text: "mdi-chart-areaspline-variant"},
    {icon: "mdi-chart-bar", text: "mdi-chart-bar"},
    {icon: "mdi-chart-bar-stacked", text: "mdi-chart-bar-stacked"},
    {icon: "mdi-chart-bell-curve", text: "mdi-chart-bell-curve"},
    {icon: "mdi-chart-bell-curve-cumulative", text: "mdi-chart-bell-curve-cumulative"},
    {icon: "mdi-chart-bubble", text: "mdi-chart-bubble"},
    {icon: "mdi-chart-donut", text: "mdi-chart-donut"},
    {icon: "mdi-chart-donut-variant", text: "mdi-chart-donut-variant"},
    {icon: "mdi-chart-gantt", text: "mdi-chart-gantt"},
    {icon: "mdi-chart-histogram", text: "mdi-chart-histogram"},
    {icon: "mdi-chart-line", text: "mdi-chart-line"},
    {icon: "mdi-chart-line-stacked", text: "mdi-chart-line-stacked"},
    {icon: "mdi-chart-line-variant", text: "mdi-chart-line-variant"},
    {icon: "mdi-chart-multiline", text: "mdi-chart-multiline"},
    {icon: "mdi-chart-pie", text: "mdi-chart-pie"},
    {icon: "mdi-chart-scatter-plot", text: "mdi-chart-scatter-plot"},
    {icon: "mdi-chart-scatter-plot-hexbin", text: "mdi-chart-scatter-plot-hexbin"},
    {icon: "mdi-chart-timeline", text: "mdi-chart-timeline"},
    {icon: "mdi-chart-timeline-variant", text: "mdi-chart-timeline-variant"},
    {icon: "mdi-chart-tree", text: "mdi-chart-tree"},
    {icon: "mdi-chat", text: "mdi-chat"},
    {icon: "mdi-chat-alert", text: "mdi-chat-alert"},
    {icon: "mdi-chat-outline", text: "mdi-chat-outline"},
    {icon: "mdi-chat-processing", text: "mdi-chat-processing"},
    {icon: "mdi-check", text: "mdi-check"},
    {icon: "mdi-check-all", text: "mdi-check-all"},
    {icon: "mdi-check-bold", text: "mdi-check-bold"},
    {icon: "mdi-check-box-multiple-outline", text: "mdi-check-box-multiple-outline"},
    {icon: "mdi-check-box-outline", text: "mdi-check-box-outline"},
    {icon: "mdi-check-circle", text: "mdi-check-circle"},
    {icon: "mdi-check-circle-outline", text: "mdi-check-circle-outline"},
    {icon: "mdi-check-decagram", text: "mdi-check-decagram"},
    {icon: "mdi-check-network", text: "mdi-check-network"},
    {icon: "mdi-check-network-outline", text: "mdi-check-network-outline"},
    {icon: "mdi-check-outline", text: "mdi-check-outline"},
    {icon: "mdi-check-underline", text: "mdi-check-underline"},
    {icon: "mdi-check-underline-circle", text: "mdi-check-underline-circle"},
    {icon: "mdi-check-underline-circle-outline", text: "mdi-check-underline-circle-outline"},
    {icon: "mdi-checkbook", text: "mdi-checkbook"},
    {icon: "mdi-checkbox-blank", text: "mdi-checkbox-blank"},
    {icon: "mdi-checkbox-blank-circle", text: "mdi-checkbox-blank-circle"},
    {icon: "mdi-checkbox-blank-circle-outline", text: "mdi-checkbox-blank-circle-outline"},
    {icon: "mdi-checkbox-blank-outline", text: "mdi-checkbox-blank-outline"},
    {icon: "mdi-checkbox-intermediate", text: "mdi-checkbox-intermediate"},
    {icon: "mdi-checkbox-marked", text: "mdi-checkbox-marked"},
    {icon: "mdi-checkbox-marked-circle", text: "mdi-checkbox-marked-circle"},
    {icon: "mdi-checkbox-marked-circle-outline", text: "mdi-checkbox-marked-circle-outline"},
    {icon: "mdi-checkbox-marked-outline", text: "mdi-checkbox-marked-outline"},
    {icon: "mdi-checkbox-multiple-blank", text: "mdi-checkbox-multiple-blank"},
    {icon: "mdi-checkbox-multiple-blank-circle", text: "mdi-checkbox-multiple-blank-circle"},
    {icon: "mdi-checkbox-multiple-blank-circle-outline", text: "mdi-checkbox-multiple-blank-circle-outline"},
    {icon: "mdi-checkbox-multiple-blank-outline", text: "mdi-checkbox-multiple-blank-outline"},
    {icon: "mdi-checkbox-multiple-marked", text: "mdi-checkbox-multiple-marked"},
    {icon: "mdi-checkbox-multiple-marked-circle", text: "mdi-checkbox-multiple-marked-circle"},
    {icon: "mdi-checkbox-multiple-marked-circle-outline", text: "mdi-checkbox-multiple-marked-circle-outline"},
    {icon: "mdi-checkbox-multiple-marked-outline", text: "mdi-checkbox-multiple-marked-outline"},
    {icon: "mdi-checkerboard", text: "mdi-checkerboard"},
    {icon: "mdi-chef-hat", text: "mdi-chef-hat"},
    {icon: "mdi-chemical-weapon", text: "mdi-chemical-weapon"},
    {icon: "mdi-chess-bishop", text: "mdi-chess-bishop"},
    {icon: "mdi-chess-king", text: "mdi-chess-king"},
    {icon: "mdi-chess-knight", text: "mdi-chess-knight"},
    {icon: "mdi-chess-pawn", text: "mdi-chess-pawn"},
    {icon: "mdi-chess-queen", text: "mdi-chess-queen"},
    {icon: "mdi-chess-rook", text: "mdi-chess-rook"},
    {icon: "mdi-chevron-double-down", text: "mdi-chevron-double-down"},
    {icon: "mdi-chevron-double-left", text: "mdi-chevron-double-left"},
    {icon: "mdi-chevron-double-right", text: "mdi-chevron-double-right"},
    {icon: "mdi-chevron-double-up", text: "mdi-chevron-double-up"},
    {icon: "mdi-chevron-down", text: "mdi-chevron-down"},
    {icon: "mdi-chevron-down-box", text: "mdi-chevron-down-box"},
    {icon: "mdi-chevron-down-box-outline", text: "mdi-chevron-down-box-outline"},
    {icon: "mdi-chevron-down-circle", text: "mdi-chevron-down-circle"},
    {icon: "mdi-chevron-down-circle-outline", text: "mdi-chevron-down-circle-outline"},
    {icon: "mdi-chevron-left", text: "mdi-chevron-left"},
    {icon: "mdi-chevron-left-box", text: "mdi-chevron-left-box"},
    {icon: "mdi-chevron-left-box-outline", text: "mdi-chevron-left-box-outline"},
    {icon: "mdi-chevron-left-circle", text: "mdi-chevron-left-circle"},
    {icon: "mdi-chevron-left-circle-outline", text: "mdi-chevron-left-circle-outline"},
    {icon: "mdi-chevron-right", text: "mdi-chevron-right"},
    {icon: "mdi-chevron-right-box", text: "mdi-chevron-right-box"},
    {icon: "mdi-chevron-right-box-outline", text: "mdi-chevron-right-box-outline"},
    {icon: "mdi-chevron-right-circle", text: "mdi-chevron-right-circle"},
    {icon: "mdi-chevron-right-circle-outline", text: "mdi-chevron-right-circle-outline"},
    {icon: "mdi-chevron-triple-down", text: "mdi-chevron-triple-down"},
    {icon: "mdi-chevron-triple-left", text: "mdi-chevron-triple-left"},
    {icon: "mdi-chevron-triple-right", text: "mdi-chevron-triple-right"},
    {icon: "mdi-chevron-triple-up", text: "mdi-chevron-triple-up"},
    {icon: "mdi-chevron-up", text: "mdi-chevron-up"},
    {icon: "mdi-chevron-up-box", text: "mdi-chevron-up-box"},
    {icon: "mdi-chevron-up-box-outline", text: "mdi-chevron-up-box-outline"},
    {icon: "mdi-chevron-up-circle", text: "mdi-chevron-up-circle"},
    {icon: "mdi-chevron-up-circle-outline", text: "mdi-chevron-up-circle-outline"},
    {icon: "mdi-chili-hot", text: "mdi-chili-hot"},
    {icon: "mdi-chili-medium", text: "mdi-chili-medium"},
    {icon: "mdi-chili-mild", text: "mdi-chili-mild"},
    {icon: "mdi-chip", text: "mdi-chip"},
    {icon: "mdi-christianity", text: "mdi-christianity"},
    {icon: "mdi-christianity-outline", text: "mdi-christianity-outline"},
    {icon: "mdi-church", text: "mdi-church"},
    {icon: "mdi-circle", text: "mdi-circle"},
    {icon: "mdi-circle-double", text: "mdi-circle-double"},
    {icon: "mdi-circle-edit-outline", text: "mdi-circle-edit-outline"},
    {icon: "mdi-circle-expand", text: "mdi-circle-expand"},
    {icon: "mdi-circle-medium", text: "mdi-circle-medium"},
    {icon: "mdi-circle-outline", text: "mdi-circle-outline"},
    {icon: "mdi-circle-slice-1", text: "mdi-circle-slice-1"},
    {icon: "mdi-circle-slice-2", text: "mdi-circle-slice-2"},
    {icon: "mdi-circle-slice-3", text: "mdi-circle-slice-3"},
    {icon: "mdi-circle-slice-4", text: "mdi-circle-slice-4"},
    {icon: "mdi-circle-slice-5", text: "mdi-circle-slice-5"},
    {icon: "mdi-circle-slice-6", text: "mdi-circle-slice-6"},
    {icon: "mdi-circle-slice-7", text: "mdi-circle-slice-7"},
    {icon: "mdi-circle-slice-8", text: "mdi-circle-slice-8"},
    {icon: "mdi-circle-small", text: "mdi-circle-small"},
    {icon: "mdi-circular-saw", text: "mdi-circular-saw"},
    {icon: "mdi-cisco-webex", text: "mdi-cisco-webex"},
    {icon: "mdi-city", text: "mdi-city"},
    {icon: "mdi-city-variant", text: "mdi-city-variant"},
    {icon: "mdi-city-variant-outline", text: "mdi-city-variant-outline"},
    {icon: "mdi-clipboard", text: "mdi-clipboard"},
    {icon: "mdi-clipboard-account", text: "mdi-clipboard-account"},
    {icon: "mdi-clipboard-account-outline", text: "mdi-clipboard-account-outline"},
    {icon: "mdi-clipboard-alert", text: "mdi-clipboard-alert"},
    {icon: "mdi-clipboard-alert-outline", text: "mdi-clipboard-alert-outline"},
    {icon: "mdi-clipboard-arrow-down", text: "mdi-clipboard-arrow-down"},
    {icon: "mdi-clipboard-arrow-down-outline", text: "mdi-clipboard-arrow-down-outline"},
    {icon: "mdi-clipboard-arrow-left", text: "mdi-clipboard-arrow-left"},
    {icon: "mdi-clipboard-arrow-left-outline", text: "mdi-clipboard-arrow-left-outline"},
    {icon: "mdi-clipboard-arrow-right", text: "mdi-clipboard-arrow-right"},
    {icon: "mdi-clipboard-arrow-right-outline", text: "mdi-clipboard-arrow-right-outline"},
    {icon: "mdi-clipboard-arrow-up", text: "mdi-clipboard-arrow-up"},
    {icon: "mdi-clipboard-arrow-up-outline", text: "mdi-clipboard-arrow-up-outline"},
    {icon: "mdi-clipboard-check", text: "mdi-clipboard-check"},
    {icon: "mdi-clipboard-check-outline", text: "mdi-clipboard-check-outline"},
    {icon: "mdi-clipboard-flow", text: "mdi-clipboard-flow"},
    {icon: "mdi-clipboard-outline", text: "mdi-clipboard-outline"},
    {icon: "mdi-clipboard-play", text: "mdi-clipboard-play"},
    {icon: "mdi-clipboard-play-outline", text: "mdi-clipboard-play-outline"},
    {icon: "mdi-clipboard-plus", text: "mdi-clipboard-plus"},
    {icon: "mdi-clipboard-pulse", text: "mdi-clipboard-pulse"},
    {icon: "mdi-clipboard-pulse-outline", text: "mdi-clipboard-pulse-outline"},
    {icon: "mdi-clipboard-text", text: "mdi-clipboard-text"},
    {icon: "mdi-clipboard-text-outline", text: "mdi-clipboard-text-outline"},
    {icon: "mdi-clipboard-text-play", text: "mdi-clipboard-text-play"},
    {icon: "mdi-clipboard-text-play-outline", text: "mdi-clipboard-text-play-outline"},
    {icon: "mdi-clippy", text: "mdi-clippy"},
    {icon: "mdi-clock", text: "mdi-clock"},
    {icon: "mdi-clock-alert", text: "mdi-clock-alert"},
    {icon: "mdi-clock-alert-outline", text: "mdi-clock-alert-outline"},
    {icon: "mdi-clock-check", text: "mdi-clock-check"},
    {icon: "mdi-clock-check-outline", text: "mdi-clock-check-outline"},
    {icon: "mdi-clock-digital", text: "mdi-clock-digital"},
    {icon: "mdi-clock-end", text: "mdi-clock-end"},
    {icon: "mdi-clock-fast", text: "mdi-clock-fast"},
    {icon: "mdi-clock-in", text: "mdi-clock-in"},
    {icon: "mdi-clock-out", text: "mdi-clock-out"},
    {icon: "mdi-clock-outline", text: "mdi-clock-outline"},
    {icon: "mdi-clock-start", text: "mdi-clock-start"},
    {icon: "mdi-close", text: "mdi-close"},
    {icon: "mdi-close-box", text: "mdi-close-box"},
    {icon: "mdi-close-box-multiple", text: "mdi-close-box-multiple"},
    {icon: "mdi-close-box-multiple-outline", text: "mdi-close-box-multiple-outline"},
    {icon: "mdi-close-box-outline", text: "mdi-close-box-outline"},
    {icon: "mdi-close-circle", text: "mdi-close-circle"},
    {icon: "mdi-close-circle-outline", text: "mdi-close-circle-outline"},
    {icon: "mdi-close-network", text: "mdi-close-network"},
    {icon: "mdi-close-network-outline", text: "mdi-close-network-outline"},
    {icon: "mdi-close-octagon", text: "mdi-close-octagon"},
    {icon: "mdi-close-octagon-outline", text: "mdi-close-octagon-outline"},
    {icon: "mdi-close-outline", text: "mdi-close-outline"},
    {icon: "mdi-closed-caption", text: "mdi-closed-caption"},
    {icon: "mdi-closed-caption-outline", text: "mdi-closed-caption-outline"},
    {icon: "mdi-cloud", text: "mdi-cloud"},
    {icon: "mdi-cloud-alert", text: "mdi-cloud-alert"},
    {icon: "mdi-cloud-braces", text: "mdi-cloud-braces"},
    {icon: "mdi-cloud-check", text: "mdi-cloud-check"},
    {icon: "mdi-cloud-circle", text: "mdi-cloud-circle"},
    {icon: "mdi-cloud-download", text: "mdi-cloud-download"},
    {icon: "mdi-cloud-download-outline", text: "mdi-cloud-download-outline"},
    {icon: "mdi-cloud-off-outline", text: "mdi-cloud-off-outline"},
    {icon: "mdi-cloud-outline", text: "mdi-cloud-outline"},
    {icon: "mdi-cloud-print", text: "mdi-cloud-print"},
    {icon: "mdi-cloud-print-outline", text: "mdi-cloud-print-outline"},
    {icon: "mdi-cloud-question", text: "mdi-cloud-question"},
    {icon: "mdi-cloud-search", text: "mdi-cloud-search"},
    {icon: "mdi-cloud-search-outline", text: "mdi-cloud-search-outline"},
    {icon: "mdi-cloud-sync", text: "mdi-cloud-sync"},
    {icon: "mdi-cloud-tags", text: "mdi-cloud-tags"},
    {icon: "mdi-cloud-upload", text: "mdi-cloud-upload"},
    {icon: "mdi-cloud-upload-outline", text: "mdi-cloud-upload-outline"},
    {icon: "mdi-clover", text: "mdi-clover"},
    {icon: "mdi-coach-lamp", text: "mdi-coach-lamp"},
    {icon: "mdi-code-array", text: "mdi-code-array"},
    {icon: "mdi-code-braces", text: "mdi-code-braces"},
    {icon: "mdi-code-brackets", text: "mdi-code-brackets"},
    {icon: "mdi-code-equal", text: "mdi-code-equal"},
    {icon: "mdi-code-greater-than", text: "mdi-code-greater-than"},
    {icon: "mdi-code-greater-than-or-equal", text: "mdi-code-greater-than-or-equal"},
    {icon: "mdi-code-less-than", text: "mdi-code-less-than"},
    {icon: "mdi-code-less-than-or-equal", text: "mdi-code-less-than-or-equal"},
    {icon: "mdi-code-not-equal", text: "mdi-code-not-equal"},
    {icon: "mdi-code-not-equal-variant", text: "mdi-code-not-equal-variant"},
    {icon: "mdi-code-parentheses", text: "mdi-code-parentheses"},
    {icon: "mdi-code-string", text: "mdi-code-string"},
    {icon: "mdi-code-tags", text: "mdi-code-tags"},
    {icon: "mdi-code-tags-check", text: "mdi-code-tags-check"},
    {icon: "mdi-codepen", text: "mdi-codepen"},
    {icon: "mdi-coffee", text: "mdi-coffee"},
    {icon: "mdi-coffee-off", text: "mdi-coffee-off"},
    {icon: "mdi-coffee-off-outline", text: "mdi-coffee-off-outline"},
    {icon: "mdi-coffee-outline", text: "mdi-coffee-outline"},
    {icon: "mdi-coffee-to-go", text: "mdi-coffee-to-go"},
    {icon: "mdi-coffin", text: "mdi-coffin"},
    {icon: "mdi-cogs", text: "mdi-cogs"},
    {icon: "mdi-coin", text: "mdi-coin"},
    {icon: "mdi-coins", text: "mdi-coins"},
    {icon: "mdi-collage", text: "mdi-collage"},
    {icon: "mdi-collapse-all", text: "mdi-collapse-all"},
    {icon: "mdi-collapse-all-outline", text: "mdi-collapse-all-outline"},
    {icon: "mdi-color-helper", text: "mdi-color-helper"},
    {icon: "mdi-comma", text: "mdi-comma"},
    {icon: "mdi-comma-box", text: "mdi-comma-box"},
    {icon: "mdi-comma-box-outline", text: "mdi-comma-box-outline"},
    {icon: "mdi-comma-circle", text: "mdi-comma-circle"},
    {icon: "mdi-comma-circle-outline", text: "mdi-comma-circle-outline"},
    {icon: "mdi-comment", text: "mdi-comment"},
    {icon: "mdi-comment-account", text: "mdi-comment-account"},
    {icon: "mdi-comment-account-outline", text: "mdi-comment-account-outline"},
    {icon: "mdi-comment-alert", text: "mdi-comment-alert"},
    {icon: "mdi-comment-alert-outline", text: "mdi-comment-alert-outline"},
    {icon: "mdi-comment-arrow-left", text: "mdi-comment-arrow-left"},
    {icon: "mdi-comment-arrow-left-outline", text: "mdi-comment-arrow-left-outline"},
    {icon: "mdi-comment-arrow-right", text: "mdi-comment-arrow-right"},
    {icon: "mdi-comment-arrow-right-outline", text: "mdi-comment-arrow-right-outline"},
    {icon: "mdi-comment-check", text: "mdi-comment-check"},
    {icon: "mdi-comment-check-outline", text: "mdi-comment-check-outline"},
    {icon: "mdi-comment-eye", text: "mdi-comment-eye"},
    {icon: "mdi-comment-eye-outline", text: "mdi-comment-eye-outline"},
    {icon: "mdi-comment-multiple", text: "mdi-comment-multiple"},
    {icon: "mdi-comment-multiple-outline", text: "mdi-comment-multiple-outline"},
    {icon: "mdi-comment-outline", text: "mdi-comment-outline"},
    {icon: "mdi-comment-plus", text: "mdi-comment-plus"},
    {icon: "mdi-comment-plus-outline", text: "mdi-comment-plus-outline"},
    {icon: "mdi-comment-processing", text: "mdi-comment-processing"},
    {icon: "mdi-comment-processing-outline", text: "mdi-comment-processing-outline"},
    {icon: "mdi-comment-question", text: "mdi-comment-question"},
    {icon: "mdi-comment-question-outline", text: "mdi-comment-question-outline"},
    {icon: "mdi-comment-quote", text: "mdi-comment-quote"},
    {icon: "mdi-comment-quote-outline", text: "mdi-comment-quote-outline"},
    {icon: "mdi-comment-remove", text: "mdi-comment-remove"},
    {icon: "mdi-comment-remove-outline", text: "mdi-comment-remove-outline"},
    {icon: "mdi-comment-search", text: "mdi-comment-search"},
    {icon: "mdi-comment-search-outline", text: "mdi-comment-search-outline"},
    {icon: "mdi-comment-text", text: "mdi-comment-text"},
    {icon: "mdi-comment-text-multiple", text: "mdi-comment-text-multiple"},
    {icon: "mdi-comment-text-multiple-outline", text: "mdi-comment-text-multiple-outline"},
    {icon: "mdi-comment-text-outline", text: "mdi-comment-text-outline"},
    {icon: "mdi-compare", text: "mdi-compare"},
    {icon: "mdi-compass", text: "mdi-compass"},
    {icon: "mdi-compass-off", text: "mdi-compass-off"},
    {icon: "mdi-compass-off-outline", text: "mdi-compass-off-outline"},
    {icon: "mdi-compass-outline", text: "mdi-compass-outline"},
    {icon: "mdi-console", text: "mdi-console"},
    {icon: "mdi-console-line", text: "mdi-console-line"},
    {icon: "mdi-console-network", text: "mdi-console-network"},
    {icon: "mdi-console-network-outline", text: "mdi-console-network-outline"},
    {icon: "mdi-contact-mail", text: "mdi-contact-mail"},
    {icon: "mdi-contact-mail-outline", text: "mdi-contact-mail-outline"},
    {icon: "mdi-contact-phone", text: "mdi-contact-phone"},
    {icon: "mdi-contact-phone-outline", text: "mdi-contact-phone-outline"},
    {icon: "mdi-contactless-payment", text: "mdi-contactless-payment"},
    {icon: "mdi-contacts", text: "mdi-contacts"},
    {icon: "mdi-contain", text: "mdi-contain"},
    {icon: "mdi-contain-end", text: "mdi-contain-end"},
    {icon: "mdi-contain-start", text: "mdi-contain-start"},
    {icon: "mdi-content-copy", text: "mdi-content-copy"},
    {icon: "mdi-content-cut", text: "mdi-content-cut"},
    {icon: "mdi-content-duplicate", text: "mdi-content-duplicate"},
    {icon: "mdi-content-paste", text: "mdi-content-paste"},
    {icon: "mdi-content-save", text: "mdi-content-save"},
    {icon: "mdi-content-save-alert", text: "mdi-content-save-alert"},
    {icon: "mdi-content-save-alert-outline", text: "mdi-content-save-alert-outline"},
    {icon: "mdi-content-save-all", text: "mdi-content-save-all"},
    {icon: "mdi-content-save-all-outline", text: "mdi-content-save-all-outline"},
    {icon: "mdi-content-save-edit", text: "mdi-content-save-edit"},
    {icon: "mdi-content-save-edit-outline", text: "mdi-content-save-edit-outline"},
    {icon: "mdi-content-save-move", text: "mdi-content-save-move"},
    {icon: "mdi-content-save-move-outline", text: "mdi-content-save-move-outline"},
    {icon: "mdi-content-save-outline", text: "mdi-content-save-outline"},
    {icon: "mdi-content-save-settings", text: "mdi-content-save-settings"},
    {icon: "mdi-content-save-settings-outline", text: "mdi-content-save-settings-outline"},
    {icon: "mdi-contrast", text: "mdi-contrast"},
    {icon: "mdi-contrast-box", text: "mdi-contrast-box"},
    {icon: "mdi-contrast-circle", text: "mdi-contrast-circle"},
    {icon: "mdi-controller-classic", text: "mdi-controller-classic"},
    {icon: "mdi-controller-classic-outline", text: "mdi-controller-classic-outline"},
    {icon: "mdi-cookie", text: "mdi-cookie"},
    {icon: "mdi-coolant-temperature", text: "mdi-coolant-temperature"},
    {icon: "mdi-copyright", text: "mdi-copyright"},
    {icon: "mdi-cordova", text: "mdi-cordova"},
    {icon: "mdi-corn", text: "mdi-corn"},
    {icon: "mdi-counter", text: "mdi-counter"},
    {icon: "mdi-cow", text: "mdi-cow"},
    {icon: "mdi-cowboy", text: "mdi-cowboy"},
    {icon: "mdi-cpu-32-bit", text: "mdi-cpu-32-bit"},
    {icon: "mdi-cpu-64-bit", text: "mdi-cpu-64-bit"},
    {icon: "mdi-crane", text: "mdi-crane"},
    {icon: "mdi-creation", text: "mdi-creation"},
    {icon: "mdi-creative-commons", text: "mdi-creative-commons"},
    {icon: "mdi-credit-card", text: "mdi-credit-card"},
    {icon: "mdi-credit-card-clock", text: "mdi-credit-card-clock"},
    {icon: "mdi-credit-card-clock-outline", text: "mdi-credit-card-clock-outline"},
    {icon: "mdi-credit-card-marker", text: "mdi-credit-card-marker"},
    {icon: "mdi-credit-card-marker-outline", text: "mdi-credit-card-marker-outline"},
    {icon: "mdi-credit-card-minus", text: "mdi-credit-card-minus"},
    {icon: "mdi-credit-card-minus-outline", text: "mdi-credit-card-minus-outline"},
    {icon: "mdi-credit-card-multiple", text: "mdi-credit-card-multiple"},
    {icon: "mdi-credit-card-multiple-outline", text: "mdi-credit-card-multiple-outline"},
    {icon: "mdi-credit-card-off", text: "mdi-credit-card-off"},
    {icon: "mdi-credit-card-off-outline", text: "mdi-credit-card-off-outline"},
    {icon: "mdi-credit-card-outline", text: "mdi-credit-card-outline"},
    {icon: "mdi-credit-card-plus", text: "mdi-credit-card-plus"},
    {icon: "mdi-credit-card-plus-outline", text: "mdi-credit-card-plus-outline"},
    {icon: "mdi-credit-card-refund", text: "mdi-credit-card-refund"},
    {icon: "mdi-credit-card-refund-outline", text: "mdi-credit-card-refund-outline"},
    {icon: "mdi-credit-card-remove", text: "mdi-credit-card-remove"},
    {icon: "mdi-credit-card-remove-outline", text: "mdi-credit-card-remove-outline"},
    {icon: "mdi-credit-card-scan", text: "mdi-credit-card-scan"},
    {icon: "mdi-credit-card-scan-outline", text: "mdi-credit-card-scan-outline"},
    {icon: "mdi-credit-card-settings", text: "mdi-credit-card-settings"},
    {icon: "mdi-credit-card-settings-outline", text: "mdi-credit-card-settings-outline"},
    {icon: "mdi-credit-card-wireless", text: "mdi-credit-card-wireless"},
    {icon: "mdi-credit-card-wireless-outline", text: "mdi-credit-card-wireless-outline"},
    {icon: "mdi-cricket", text: "mdi-cricket"},
    {icon: "mdi-crop", text: "mdi-crop"},
    {icon: "mdi-crop-free", text: "mdi-crop-free"},
    {icon: "mdi-crop-landscape", text: "mdi-crop-landscape"},
    {icon: "mdi-crop-portrait", text: "mdi-crop-portrait"},
    {icon: "mdi-crop-rotate", text: "mdi-crop-rotate"},
    {icon: "mdi-crop-square", text: "mdi-crop-square"},
    {icon: "mdi-crosshairs", text: "mdi-crosshairs"},
    {icon: "mdi-crosshairs-gps", text: "mdi-crosshairs-gps"},
    {icon: "mdi-crosshairs-off", text: "mdi-crosshairs-off"},
    {icon: "mdi-crown", text: "mdi-crown"},
    {icon: "mdi-cryengine", text: "mdi-cryengine"},
    {icon: "mdi-crystal-ball", text: "mdi-crystal-ball"},
    {icon: "mdi-cube", text: "mdi-cube"},
    {icon: "mdi-cube-outline", text: "mdi-cube-outline"},
    {icon: "mdi-cube-scan", text: "mdi-cube-scan"},
    {icon: "mdi-cube-send", text: "mdi-cube-send"},
    {icon: "mdi-cube-unfolded", text: "mdi-cube-unfolded"},
    {icon: "mdi-cup", text: "mdi-cup"},
    {icon: "mdi-cup-off", text: "mdi-cup-off"},
    {icon: "mdi-cup-water", text: "mdi-cup-water"},
    {icon: "mdi-cupboard", text: "mdi-cupboard"},
    {icon: "mdi-cupboard-outline", text: "mdi-cupboard-outline"},
    {icon: "mdi-cupcake", text: "mdi-cupcake"},
    {icon: "mdi-curling", text: "mdi-curling"},
    {icon: "mdi-currency-bdt", text: "mdi-currency-bdt"},
    {icon: "mdi-currency-brl", text: "mdi-currency-brl"},
    {icon: "mdi-currency-btc", text: "mdi-currency-btc"},
    {icon: "mdi-currency-cny", text: "mdi-currency-cny"},
    {icon: "mdi-currency-eth", text: "mdi-currency-eth"},
    {icon: "mdi-currency-eur", text: "mdi-currency-eur"},
    {icon: "mdi-currency-gbp", text: "mdi-currency-gbp"},
    {icon: "mdi-currency-ils", text: "mdi-currency-ils"},
    {icon: "mdi-currency-inr", text: "mdi-currency-inr"},
    {icon: "mdi-currency-jpy", text: "mdi-currency-jpy"},
    {icon: "mdi-currency-krw", text: "mdi-currency-krw"},
    {icon: "mdi-currency-kzt", text: "mdi-currency-kzt"},
    {icon: "mdi-currency-ngn", text: "mdi-currency-ngn"},
    {icon: "mdi-currency-php", text: "mdi-currency-php"},
    {icon: "mdi-currency-rial", text: "mdi-currency-rial"},
    {icon: "mdi-currency-rub", text: "mdi-currency-rub"},
    {icon: "mdi-currency-sign", text: "mdi-currency-sign"},
    {icon: "mdi-currency-try", text: "mdi-currency-try"},
    {icon: "mdi-currency-twd", text: "mdi-currency-twd"},
    {icon: "mdi-currency-usd", text: "mdi-currency-usd"},
    {icon: "mdi-currency-usd-off", text: "mdi-currency-usd-off"},
    {icon: "mdi-current-ac", text: "mdi-current-ac"},
    {icon: "mdi-current-dc", text: "mdi-current-dc"},
    {icon: "mdi-cursor-default", text: "mdi-cursor-default"},
    {icon: "mdi-cursor-default-click", text: "mdi-cursor-default-click"},
    {icon: "mdi-cursor-default-click-outline", text: "mdi-cursor-default-click-outline"},
    {icon: "mdi-cursor-default-outline", text: "mdi-cursor-default-outline"},
    {icon: "mdi-cursor-move", text: "mdi-cursor-move"},
    {icon: "mdi-cursor-pointer", text: "mdi-cursor-pointer"},
    {icon: "mdi-cursor-text", text: "mdi-cursor-text"},
    {icon: "mdi-database", text: "mdi-database"},
    {icon: "mdi-database-check", text: "mdi-database-check"},
    {icon: "mdi-database-edit", text: "mdi-database-edit"},
    {icon: "mdi-database-export", text: "mdi-database-export"},
    {icon: "mdi-database-import", text: "mdi-database-import"},
    {icon: "mdi-database-lock", text: "mdi-database-lock"},
    {icon: "mdi-database-minus", text: "mdi-database-minus"},
    {icon: "mdi-database-plus", text: "mdi-database-plus"},
    {icon: "mdi-database-refresh", text: "mdi-database-refresh"},
    {icon: "mdi-database-remove", text: "mdi-database-remove"},
    {icon: "mdi-database-search", text: "mdi-database-search"},
    {icon: "mdi-database-settings", text: "mdi-database-settings"},
    {icon: "mdi-death-star", text: "mdi-death-star"},
    {icon: "mdi-death-star-variant", text: "mdi-death-star-variant"},
    {icon: "mdi-deathly-hallows", text: "mdi-deathly-hallows"},
    {icon: "mdi-debian", text: "mdi-debian"},
    {icon: "mdi-debug-step-into", text: "mdi-debug-step-into"},
    {icon: "mdi-debug-step-out", text: "mdi-debug-step-out"},
    {icon: "mdi-debug-step-over", text: "mdi-debug-step-over"},
    {icon: "mdi-decagram", text: "mdi-decagram"},
    {icon: "mdi-decagram-outline", text: "mdi-decagram-outline"},
    {icon: "mdi-decimal-decrease", text: "mdi-decimal-decrease"},
    {icon: "mdi-decimal-increase", text: "mdi-decimal-increase"},
    {icon: "mdi-delete", text: "mdi-delete"},
    {icon: "mdi-delete-circle", text: "mdi-delete-circle"},
    {icon: "mdi-delete-circle-outline", text: "mdi-delete-circle-outline"},
    {icon: "mdi-delete-empty", text: "mdi-delete-empty"},
    {icon: "mdi-delete-empty-outline", text: "mdi-delete-empty-outline"},
    {icon: "mdi-delete-forever", text: "mdi-delete-forever"},
    {icon: "mdi-delete-forever-outline", text: "mdi-delete-forever-outline"},
    {icon: "mdi-delete-outline", text: "mdi-delete-outline"},
    {icon: "mdi-delete-restore", text: "mdi-delete-restore"},
    {icon: "mdi-delete-sweep", text: "mdi-delete-sweep"},
    {icon: "mdi-delete-sweep-outline", text: "mdi-delete-sweep-outline"},
    {icon: "mdi-delete-variant", text: "mdi-delete-variant"},
    {icon: "mdi-delta", text: "mdi-delta"},
    {icon: "mdi-desk-lamp", text: "mdi-desk-lamp"},
    {icon: "mdi-deskphone", text: "mdi-deskphone"},
    {icon: "mdi-desktop-classic", text: "mdi-desktop-classic"},
    {icon: "mdi-desktop-mac", text: "mdi-desktop-mac"},
    {icon: "mdi-desktop-mac-dashboard", text: "mdi-desktop-mac-dashboard"},
    {icon: "mdi-desktop-tower", text: "mdi-desktop-tower"},
    {icon: "mdi-desktop-tower-monitor", text: "mdi-desktop-tower-monitor"},
    {icon: "mdi-details", text: "mdi-details"},
    {icon: "mdi-dev-to", text: "mdi-dev-to"},
    {icon: "mdi-developer-board", text: "mdi-developer-board"},
    {icon: "mdi-deviantart", text: "mdi-deviantart"},
    {icon: "mdi-devices", text: "mdi-devices"},
    {icon: "mdi-dialpad", text: "mdi-dialpad"},
    {icon: "mdi-diameter", text: "mdi-diameter"},
    {icon: "mdi-diameter-outline", text: "mdi-diameter-outline"},
    {icon: "mdi-diameter-variant", text: "mdi-diameter-variant"},
    {icon: "mdi-diamond", text: "mdi-diamond"},
    {icon: "mdi-diamond-outline", text: "mdi-diamond-outline"},
    {icon: "mdi-diamond-stone", text: "mdi-diamond-stone"},
    {icon: "mdi-dice-1", text: "mdi-dice-1"},
    {icon: "mdi-dice-2", text: "mdi-dice-2"},
    {icon: "mdi-dice-3", text: "mdi-dice-3"},
    {icon: "mdi-dice-4", text: "mdi-dice-4"},
    {icon: "mdi-dice-5", text: "mdi-dice-5"},
    {icon: "mdi-dice-6", text: "mdi-dice-6"},
    {icon: "mdi-dice-d10-outline", text: "mdi-dice-d10-outline"},
    {icon: "mdi-dice-d12-outline", text: "mdi-dice-d12-outline"},
    {icon: "mdi-dice-d20-outline", text: "mdi-dice-d20-outline"},
    {icon: "mdi-dice-d4-outline", text: "mdi-dice-d4-outline"},
    {icon: "mdi-dice-d6-outline", text: "mdi-dice-d6-outline"},
    {icon: "mdi-dice-d8-outline", text: "mdi-dice-d8-outline"},
    {icon: "mdi-dice-multiple", text: "mdi-dice-multiple"},
    {icon: "mdi-dictionary", text: "mdi-dictionary"},
    {icon: "mdi-dip-switch", text: "mdi-dip-switch"},
    {icon: "mdi-directions", text: "mdi-directions"},
    {icon: "mdi-directions-fork", text: "mdi-directions-fork"},
    {icon: "mdi-disc", text: "mdi-disc"},
    {icon: "mdi-disc-alert", text: "mdi-disc-alert"},
    {icon: "mdi-disc-player", text: "mdi-disc-player"},
    {icon: "mdi-discord", text: "mdi-discord"},
    {icon: "mdi-dishwasher", text: "mdi-dishwasher"},
    {icon: "mdi-disqus", text: "mdi-disqus"},
    {icon: "mdi-disqus-outline", text: "mdi-disqus-outline"},
    {icon: "mdi-diving-flippers", text: "mdi-diving-flippers"},
    {icon: "mdi-diving-helmet", text: "mdi-diving-helmet"},
    {icon: "mdi-diving-scuba", text: "mdi-diving-scuba"},
    {icon: "mdi-diving-scuba-flag", text: "mdi-diving-scuba-flag"},
    {icon: "mdi-diving-scuba-tank", text: "mdi-diving-scuba-tank"},
    {icon: "mdi-diving-scuba-tank-multiple", text: "mdi-diving-scuba-tank-multiple"},
    {icon: "mdi-diving-snorkel", text: "mdi-diving-snorkel"},
    {icon: "mdi-division", text: "mdi-division"},
    {icon: "mdi-division-box", text: "mdi-division-box"},
    {icon: "mdi-dlna", text: "mdi-dlna"},
    {icon: "mdi-dna", text: "mdi-dna"},
    {icon: "mdi-dns", text: "mdi-dns"},
    {icon: "mdi-dns-outline", text: "mdi-dns-outline"},
    {icon: "mdi-do-not-disturb", text: "mdi-do-not-disturb"},
    {icon: "mdi-do-not-disturb-off", text: "mdi-do-not-disturb-off"},
    {icon: "mdi-docker", text: "mdi-docker"},
    {icon: "mdi-doctor", text: "mdi-doctor"},
    {icon: "mdi-dog", text: "mdi-dog"},
    {icon: "mdi-dog-service", text: "mdi-dog-service"},
    {icon: "mdi-dog-side", text: "mdi-dog-side"},
    {icon: "mdi-dolby", text: "mdi-dolby"},
    {icon: "mdi-dolly", text: "mdi-dolly"},
    {icon: "mdi-domain", text: "mdi-domain"},
    {icon: "mdi-domain-off", text: "mdi-domain-off"},
    {icon: "mdi-domino-mask", text: "mdi-domino-mask"},
    {icon: "mdi-donkey", text: "mdi-donkey"},
    {icon: "mdi-door", text: "mdi-door"},
    {icon: "mdi-door-closed", text: "mdi-door-closed"},
    {icon: "mdi-door-open", text: "mdi-door-open"},
    {icon: "mdi-doorbell-video", text: "mdi-doorbell-video"},
    {icon: "mdi-dot-net", text: "mdi-dot-net"},
    {icon: "mdi-dots-horizontal", text: "mdi-dots-horizontal"},
    {icon: "mdi-dots-horizontal-circle", text: "mdi-dots-horizontal-circle"},
    {icon: "mdi-dots-horizontal-circle-outline", text: "mdi-dots-horizontal-circle-outline"},
    {icon: "mdi-dots-vertical", text: "mdi-dots-vertical"},
    {icon: "mdi-dots-vertical-circle", text: "mdi-dots-vertical-circle"},
    {icon: "mdi-dots-vertical-circle-outline", text: "mdi-dots-vertical-circle-outline"},
    {icon: "mdi-douban", text: "mdi-douban"},
    {icon: "mdi-download", text: "mdi-download"},
    {icon: "mdi-download-multiple", text: "mdi-download-multiple"},
    {icon: "mdi-download-network", text: "mdi-download-network"},
    {icon: "mdi-download-network-outline", text: "mdi-download-network-outline"},
    {icon: "mdi-download-outline", text: "mdi-download-outline"},
    {icon: "mdi-drag", text: "mdi-drag"},
    {icon: "mdi-drag-horizontal", text: "mdi-drag-horizontal"},
    {icon: "mdi-drag-variant", text: "mdi-drag-variant"},
    {icon: "mdi-drag-vertical", text: "mdi-drag-vertical"},
    {icon: "mdi-drama-masks", text: "mdi-drama-masks"},
    {icon: "mdi-draw", text: "mdi-draw"},
    {icon: "mdi-drawing", text: "mdi-drawing"},
    {icon: "mdi-drawing-box", text: "mdi-drawing-box"},
    {icon: "mdi-dresser", text: "mdi-dresser"},
    {icon: "mdi-dresser-outline", text: "mdi-dresser-outline"},
    {icon: "mdi-dribbble", text: "mdi-dribbble"},
    {icon: "mdi-dribbble-box", text: "mdi-dribbble-box"},
    {icon: "mdi-drone", text: "mdi-drone"},
    {icon: "mdi-dropbox", text: "mdi-dropbox"},
    {icon: "mdi-drupal", text: "mdi-drupal"},
    {icon: "mdi-duck", text: "mdi-duck"},
    {icon: "mdi-dumbbell", text: "mdi-dumbbell"},
    {icon: "mdi-dump-truck", text: "mdi-dump-truck"},
    {icon: "mdi-ear-hearing", text: "mdi-ear-hearing"},
    {icon: "mdi-ear-hearing-off", text: "mdi-ear-hearing-off"},
    {icon: "mdi-earth", text: "mdi-earth"},
    {icon: "mdi-earth-box", text: "mdi-earth-box"},
    {icon: "mdi-earth-box-off", text: "mdi-earth-box-off"},
    {icon: "mdi-earth-off", text: "mdi-earth-off"},
    {icon: "mdi-edge", text: "mdi-edge"},
    {icon: "mdi-egg", text: "mdi-egg"},
    {icon: "mdi-egg-easter", text: "mdi-egg-easter"},
    {icon: "mdi-eight-track", text: "mdi-eight-track"},
    {icon: "mdi-eject", text: "mdi-eject"},
    {icon: "mdi-eject-outline", text: "mdi-eject-outline"},
    {icon: "mdi-electric-switch", text: "mdi-electric-switch"},
    {icon: "mdi-electron-framework", text: "mdi-electron-framework"},
    {icon: "mdi-elephant", text: "mdi-elephant"},
    {icon: "mdi-elevation-decline", text: "mdi-elevation-decline"},
    {icon: "mdi-elevation-rise", text: "mdi-elevation-rise"},
    {icon: "mdi-elevator", text: "mdi-elevator"},
    {icon: "mdi-ellipse", text: "mdi-ellipse"},
    {icon: "mdi-ellipse-outline", text: "mdi-ellipse-outline"},
    {icon: "mdi-email", text: "mdi-email"},
    {icon: "mdi-email-alert", text: "mdi-email-alert"},
    {icon: "mdi-email-box", text: "mdi-email-box"},
    {icon: "mdi-email-check", text: "mdi-email-check"},
    {icon: "mdi-email-check-outline", text: "mdi-email-check-outline"},
    {icon: "mdi-email-edit", text: "mdi-email-edit"},
    {icon: "mdi-email-edit-outline", text: "mdi-email-edit-outline"},
    {icon: "mdi-email-lock", text: "mdi-email-lock"},
    {icon: "mdi-email-mark-as-unread", text: "mdi-email-mark-as-unread"},
    {icon: "mdi-email-minus", text: "mdi-email-minus"},
    {icon: "mdi-email-minus-outline", text: "mdi-email-minus-outline"},
    {icon: "mdi-email-multiple", text: "mdi-email-multiple"},
    {icon: "mdi-email-multiple-outline", text: "mdi-email-multiple-outline"},
    {icon: "mdi-email-newsletter", text: "mdi-email-newsletter"},
    {icon: "mdi-email-open", text: "mdi-email-open"},
    {icon: "mdi-email-open-multiple", text: "mdi-email-open-multiple"},
    {icon: "mdi-email-open-multiple-outline", text: "mdi-email-open-multiple-outline"},
    {icon: "mdi-email-open-outline", text: "mdi-email-open-outline"},
    {icon: "mdi-email-outline", text: "mdi-email-outline"},
    {icon: "mdi-email-plus", text: "mdi-email-plus"},
    {icon: "mdi-email-plus-outline", text: "mdi-email-plus-outline"},
    {icon: "mdi-email-search", text: "mdi-email-search"},
    {icon: "mdi-email-search-outline", text: "mdi-email-search-outline"},
    {icon: "mdi-email-variant", text: "mdi-email-variant"},
    {icon: "mdi-ember", text: "mdi-ember"},
    {icon: "mdi-emby", text: "mdi-emby"},
    {icon: "mdi-emoticon", text: "mdi-emoticon"},
    {icon: "mdi-emoticon-angry", text: "mdi-emoticon-angry"},
    {icon: "mdi-emoticon-angry-outline", text: "mdi-emoticon-angry-outline"},
    {icon: "mdi-emoticon-cool", text: "mdi-emoticon-cool"},
    {icon: "mdi-emoticon-cool-outline", text: "mdi-emoticon-cool-outline"},
    {icon: "mdi-emoticon-cry", text: "mdi-emoticon-cry"},
    {icon: "mdi-emoticon-cry-outline", text: "mdi-emoticon-cry-outline"},
    {icon: "mdi-emoticon-dead", text: "mdi-emoticon-dead"},
    {icon: "mdi-emoticon-dead-outline", text: "mdi-emoticon-dead-outline"},
    {icon: "mdi-emoticon-devil", text: "mdi-emoticon-devil"},
    {icon: "mdi-emoticon-devil-outline", text: "mdi-emoticon-devil-outline"},
    {icon: "mdi-emoticon-excited", text: "mdi-emoticon-excited"},
    {icon: "mdi-emoticon-excited-outline", text: "mdi-emoticon-excited-outline"},
    {icon: "mdi-emoticon-frown", text: "mdi-emoticon-frown"},
    {icon: "mdi-emoticon-frown-outline", text: "mdi-emoticon-frown-outline"},
    {icon: "mdi-emoticon-happy", text: "mdi-emoticon-happy"},
    {icon: "mdi-emoticon-happy-outline", text: "mdi-emoticon-happy-outline"},
    {icon: "mdi-emoticon-kiss", text: "mdi-emoticon-kiss"},
    {icon: "mdi-emoticon-kiss-outline", text: "mdi-emoticon-kiss-outline"},
    {icon: "mdi-emoticon-neutral", text: "mdi-emoticon-neutral"},
    {icon: "mdi-emoticon-neutral-outline", text: "mdi-emoticon-neutral-outline"},
    {icon: "mdi-emoticon-outline", text: "mdi-emoticon-outline"},
    {icon: "mdi-emoticon-poop", text: "mdi-emoticon-poop"},
    {icon: "mdi-emoticon-poop-outline", text: "mdi-emoticon-poop-outline"},
    {icon: "mdi-emoticon-sad", text: "mdi-emoticon-sad"},
    {icon: "mdi-emoticon-sad-outline", text: "mdi-emoticon-sad-outline"},
    {icon: "mdi-emoticon-tongue", text: "mdi-emoticon-tongue"},
    {icon: "mdi-emoticon-tongue-outline", text: "mdi-emoticon-tongue-outline"},
    {icon: "mdi-emoticon-wink", text: "mdi-emoticon-wink"},
    {icon: "mdi-emoticon-wink-outline", text: "mdi-emoticon-wink-outline"},
    {icon: "mdi-engine", text: "mdi-engine"},
    {icon: "mdi-engine-off", text: "mdi-engine-off"},
    {icon: "mdi-engine-off-outline", text: "mdi-engine-off-outline"},
    {icon: "mdi-engine-outline", text: "mdi-engine-outline"},
    {icon: "mdi-equal", text: "mdi-equal"},
    {icon: "mdi-equal-box", text: "mdi-equal-box"},
    {icon: "mdi-equalizer", text: "mdi-equalizer"},
    {icon: "mdi-equalizer-outline", text: "mdi-equalizer-outline"},
    {icon: "mdi-eraser", text: "mdi-eraser"},
    {icon: "mdi-eraser-variant", text: "mdi-eraser-variant"},
    {icon: "mdi-escalator", text: "mdi-escalator"},
    {icon: "mdi-eslint", text: "mdi-eslint"},
    {icon: "mdi-et", text: "mdi-et"},
    {icon: "mdi-ethereum", text: "mdi-ethereum"},
    {icon: "mdi-ethernet", text: "mdi-ethernet"},
    {icon: "mdi-ethernet-cable", text: "mdi-ethernet-cable"},
    {icon: "mdi-ethernet-cable-off", text: "mdi-ethernet-cable-off"},
    {icon: "mdi-etsy", text: "mdi-etsy"},
    {icon: "mdi-ev-station", text: "mdi-ev-station"},
    {icon: "mdi-eventbrite", text: "mdi-eventbrite"},
    {icon: "mdi-evernote", text: "mdi-evernote"},
    {icon: "mdi-excavator", text: "mdi-excavator"},
    {icon: "mdi-exclamation", text: "mdi-exclamation"},
    {icon: "mdi-exit-run", text: "mdi-exit-run"},
    {icon: "mdi-exit-to-app", text: "mdi-exit-to-app"},
    {icon: "mdi-expand-all", text: "mdi-expand-all"},
    {icon: "mdi-expand-all-outline", text: "mdi-expand-all-outline"},
    {icon: "mdi-expansion-card", text: "mdi-expansion-card"},
    {icon: "mdi-expansion-card-variant", text: "mdi-expansion-card-variant"},
    {icon: "mdi-exponent", text: "mdi-exponent"},
    {icon: "mdi-exponent-box", text: "mdi-exponent-box"},
    {icon: "mdi-export", text: "mdi-export"},
    {icon: "mdi-export-variant", text: "mdi-export-variant"},
    {icon: "mdi-eye", text: "mdi-eye"},
    {icon: "mdi-eye-check", text: "mdi-eye-check"},
    {icon: "mdi-eye-check-outline", text: "mdi-eye-check-outline"},
    {icon: "mdi-eye-circle", text: "mdi-eye-circle"},
    {icon: "mdi-eye-circle-outline", text: "mdi-eye-circle-outline"},
    {icon: "mdi-eye-minus", text: "mdi-eye-minus"},
    {icon: "mdi-eye-minus-outline", text: "mdi-eye-minus-outline"},
    {icon: "mdi-eye-off", text: "mdi-eye-off"},
    {icon: "mdi-eye-off-outline", text: "mdi-eye-off-outline"},
    {icon: "mdi-eye-outline", text: "mdi-eye-outline"},
    {icon: "mdi-eye-plus", text: "mdi-eye-plus"},
    {icon: "mdi-eye-plus-outline", text: "mdi-eye-plus-outline"},
    {icon: "mdi-eye-settings", text: "mdi-eye-settings"},
    {icon: "mdi-eye-settings-outline", text: "mdi-eye-settings-outline"},
    {icon: "mdi-eyedropper", text: "mdi-eyedropper"},
    {icon: "mdi-eyedropper-variant", text: "mdi-eyedropper-variant"},
    {icon: "mdi-face", text: "mdi-face"},
    {icon: "mdi-face-agent", text: "mdi-face-agent"},
    {icon: "mdi-face-outline", text: "mdi-face-outline"},
    {icon: "mdi-face-profile", text: "mdi-face-profile"},
    {icon: "mdi-face-recognition", text: "mdi-face-recognition"},
    {icon: "mdi-facebook", text: "mdi-facebook"},
    {icon: "mdi-facebook-box", text: "mdi-facebook-box"},
    {icon: "mdi-facebook-messenger", text: "mdi-facebook-messenger"},
    {icon: "mdi-facebook-workplace", text: "mdi-facebook-workplace"},
    {icon: "mdi-factory", text: "mdi-factory"},
    {icon: "mdi-fan", text: "mdi-fan"},
    {icon: "mdi-fan-off", text: "mdi-fan-off"},
    {icon: "mdi-fast-forward", text: "mdi-fast-forward"},
    {icon: "mdi-fast-forward-10", text: "mdi-fast-forward-10"},
    {icon: "mdi-fast-forward-30", text: "mdi-fast-forward-30"},
    {icon: "mdi-fast-forward-outline", text: "mdi-fast-forward-outline"},
    {icon: "mdi-fax", text: "mdi-fax"},
    {icon: "mdi-feather", text: "mdi-feather"},
    {icon: "mdi-feature-search", text: "mdi-feature-search"},
    {icon: "mdi-feature-search-outline", text: "mdi-feature-search-outline"},
    {icon: "mdi-fedora", text: "mdi-fedora"},
    {icon: "mdi-ferris-wheel", text: "mdi-ferris-wheel"},
    {icon: "mdi-ferry", text: "mdi-ferry"},
    {icon: "mdi-file", text: "mdi-file"},
    {icon: "mdi-file-account", text: "mdi-file-account"},
    {icon: "mdi-file-account-outline", text: "mdi-file-account-outline"},
    {icon: "mdi-file-alert", text: "mdi-file-alert"},
    {icon: "mdi-file-alert-outline", text: "mdi-file-alert-outline"},
    {icon: "mdi-file-cabinet", text: "mdi-file-cabinet"},
    {icon: "mdi-file-cad", text: "mdi-file-cad"},
    {icon: "mdi-file-cad-box", text: "mdi-file-cad-box"},
    {icon: "mdi-file-cancel", text: "mdi-file-cancel"},
    {icon: "mdi-file-cancel-outline", text: "mdi-file-cancel-outline"},
    {icon: "mdi-file-chart", text: "mdi-file-chart"},
    {icon: "mdi-file-chart-outline", text: "mdi-file-chart-outline"},
    {icon: "mdi-file-check", text: "mdi-file-check"},
    {icon: "mdi-file-check-outline", text: "mdi-file-check-outline"},
    {icon: "mdi-file-cloud", text: "mdi-file-cloud"},
    {icon: "mdi-file-cloud-outline", text: "mdi-file-cloud-outline"},
    {icon: "mdi-file-code", text: "mdi-file-code"},
    {icon: "mdi-file-code-outline", text: "mdi-file-code-outline"},
    {icon: "mdi-file-compare", text: "mdi-file-compare"},
    {icon: "mdi-file-delimited", text: "mdi-file-delimited"},
    {icon: "mdi-file-delimited-outline", text: "mdi-file-delimited-outline"},
    {icon: "mdi-file-document", text: "mdi-file-document"},
    {icon: "mdi-file-document-box", text: "mdi-file-document-box"},
    {icon: "mdi-file-document-box-check", text: "mdi-file-document-box-check"},
    {icon: "mdi-file-document-box-check-outline", text: "mdi-file-document-box-check-outline"},
    {icon: "mdi-file-document-box-minus", text: "mdi-file-document-box-minus"},
    {icon: "mdi-file-document-box-minus-outline", text: "mdi-file-document-box-minus-outline"},
    {icon: "mdi-file-document-box-multiple", text: "mdi-file-document-box-multiple"},
    {icon: "mdi-file-document-box-multiple-outline", text: "mdi-file-document-box-multiple-outline"},
    {icon: "mdi-file-document-box-outline", text: "mdi-file-document-box-outline"},
    {icon: "mdi-file-document-box-plus", text: "mdi-file-document-box-plus"},
    {icon: "mdi-file-document-box-plus-outline", text: "mdi-file-document-box-plus-outline"},
    {icon: "mdi-file-document-box-remove", text: "mdi-file-document-box-remove"},
    {icon: "mdi-file-document-box-remove-outline", text: "mdi-file-document-box-remove-outline"},
    {icon: "mdi-file-document-box-search", text: "mdi-file-document-box-search"},
    {icon: "mdi-file-document-box-search-outline", text: "mdi-file-document-box-search-outline"},
    {icon: "mdi-file-document-edit", text: "mdi-file-document-edit"},
    {icon: "mdi-file-document-edit-outline", text: "mdi-file-document-edit-outline"},
    {icon: "mdi-file-document-outline", text: "mdi-file-document-outline"},
    {icon: "mdi-file-download", text: "mdi-file-download"},
    {icon: "mdi-file-download-outline", text: "mdi-file-download-outline"},
    {icon: "mdi-file-excel", text: "mdi-file-excel"},
    {icon: "mdi-file-excel-box", text: "mdi-file-excel-box"},
    {icon: "mdi-file-excel-box-outline", text: "mdi-file-excel-box-outline"},
    {icon: "mdi-file-excel-outline", text: "mdi-file-excel-outline"},
    {icon: "mdi-file-export", text: "mdi-file-export"},
    {icon: "mdi-file-export-outline", text: "mdi-file-export-outline"},
    {icon: "mdi-file-eye", text: "mdi-file-eye"},
    {icon: "mdi-file-eye-outline", text: "mdi-file-eye-outline"},
    {icon: "mdi-file-find", text: "mdi-file-find"},
    {icon: "mdi-file-find-outline", text: "mdi-file-find-outline"},
    {icon: "mdi-file-hidden", text: "mdi-file-hidden"},
    {icon: "mdi-file-image", text: "mdi-file-image"},
    {icon: "mdi-file-image-outline", text: "mdi-file-image-outline"},
    {icon: "mdi-file-import", text: "mdi-file-import"},
    {icon: "mdi-file-import-outline", text: "mdi-file-import-outline"},
    {icon: "mdi-file-lock", text: "mdi-file-lock"},
    {icon: "mdi-file-lock-outline", text: "mdi-file-lock-outline"},
    {icon: "mdi-file-move", text: "mdi-file-move"},
    {icon: "mdi-file-move-outline", text: "mdi-file-move-outline"},
    {icon: "mdi-file-multiple", text: "mdi-file-multiple"},
    {icon: "mdi-file-multiple-outline", text: "mdi-file-multiple-outline"},
    {icon: "mdi-file-music", text: "mdi-file-music"},
    {icon: "mdi-file-music-outline", text: "mdi-file-music-outline"},
    {icon: "mdi-file-outline", text: "mdi-file-outline"},
    {icon: "mdi-file-pdf", text: "mdi-file-pdf"},
    {icon: "mdi-file-pdf-box", text: "mdi-file-pdf-box"},
    {icon: "mdi-file-pdf-box-outline", text: "mdi-file-pdf-box-outline"},
    {icon: "mdi-file-pdf-outline", text: "mdi-file-pdf-outline"},
    {icon: "mdi-file-percent", text: "mdi-file-percent"},
    {icon: "mdi-file-percent-outline", text: "mdi-file-percent-outline"},
    {icon: "mdi-file-plus", text: "mdi-file-plus"},
    {icon: "mdi-file-plus-outline", text: "mdi-file-plus-outline"},
    {icon: "mdi-file-powerpoint", text: "mdi-file-powerpoint"},
    {icon: "mdi-file-powerpoint-box", text: "mdi-file-powerpoint-box"},
    {icon: "mdi-file-powerpoint-box-outline", text: "mdi-file-powerpoint-box-outline"},
    {icon: "mdi-file-powerpoint-outline", text: "mdi-file-powerpoint-outline"},
    {icon: "mdi-file-presentation-box", text: "mdi-file-presentation-box"},
    {icon: "mdi-file-question", text: "mdi-file-question"},
    {icon: "mdi-file-question-outline", text: "mdi-file-question-outline"},
    {icon: "mdi-file-remove", text: "mdi-file-remove"},
    {icon: "mdi-file-remove-outline", text: "mdi-file-remove-outline"},
    {icon: "mdi-file-replace", text: "mdi-file-replace"},
    {icon: "mdi-file-replace-outline", text: "mdi-file-replace-outline"},
    {icon: "mdi-file-restore", text: "mdi-file-restore"},
    {icon: "mdi-file-restore-outline", text: "mdi-file-restore-outline"},
    {icon: "mdi-file-search", text: "mdi-file-search"},
    {icon: "mdi-file-search-outline", text: "mdi-file-search-outline"},
    {icon: "mdi-file-send", text: "mdi-file-send"},
    {icon: "mdi-file-send-outline", text: "mdi-file-send-outline"},
    {icon: "mdi-file-star", text: "mdi-file-star"},
    {icon: "mdi-file-star-outline", text: "mdi-file-star-outline"},
    {icon: "mdi-file-swap", text: "mdi-file-swap"},
    {icon: "mdi-file-swap-outline", text: "mdi-file-swap-outline"},
    {icon: "mdi-file-table", text: "mdi-file-table"},
    {icon: "mdi-file-table-outline", text: "mdi-file-table-outline"},
    {icon: "mdi-file-tree", text: "mdi-file-tree"},
    {icon: "mdi-file-undo", text: "mdi-file-undo"},
    {icon: "mdi-file-undo-outline", text: "mdi-file-undo-outline"},
    {icon: "mdi-file-upload", text: "mdi-file-upload"},
    {icon: "mdi-file-upload-outline", text: "mdi-file-upload-outline"},
    {icon: "mdi-file-video", text: "mdi-file-video"},
    {icon: "mdi-file-video-outline", text: "mdi-file-video-outline"},
    {icon: "mdi-file-word", text: "mdi-file-word"},
    {icon: "mdi-file-word-box", text: "mdi-file-word-box"},
    {icon: "mdi-file-word-box-outline", text: "mdi-file-word-box-outline"},
    {icon: "mdi-file-word-outline", text: "mdi-file-word-outline"},
    {icon: "mdi-film", text: "mdi-film"},
    {icon: "mdi-filmstrip", text: "mdi-filmstrip"},
    {icon: "mdi-filmstrip-off", text: "mdi-filmstrip-off"},
    {icon: "mdi-filter", text: "mdi-filter"},
    {icon: "mdi-filter-minus", text: "mdi-filter-minus"},
    {icon: "mdi-filter-minus-outline", text: "mdi-filter-minus-outline"},
    {icon: "mdi-filter-outline", text: "mdi-filter-outline"},
    {icon: "mdi-filter-plus", text: "mdi-filter-plus"},
    {icon: "mdi-filter-plus-outline", text: "mdi-filter-plus-outline"},
    {icon: "mdi-filter-remove", text: "mdi-filter-remove"},
    {icon: "mdi-filter-remove-outline", text: "mdi-filter-remove-outline"},
    {icon: "mdi-filter-variant", text: "mdi-filter-variant"},
    {icon: "mdi-filter-variant-remove", text: "mdi-filter-variant-remove"},
    {icon: "mdi-finance", text: "mdi-finance"},
    {icon: "mdi-find-replace", text: "mdi-find-replace"},
    {icon: "mdi-fingerprint", text: "mdi-fingerprint"},
    {icon: "mdi-fingerprint-off", text: "mdi-fingerprint-off"},
    {icon: "mdi-fire", text: "mdi-fire"},
    {icon: "mdi-fire-extinguisher", text: "mdi-fire-extinguisher"},
    {icon: "mdi-fire-truck", text: "mdi-fire-truck"},
    {icon: "mdi-firebase", text: "mdi-firebase"},
    {icon: "mdi-firefox", text: "mdi-firefox"},
    {icon: "mdi-fireplace", text: "mdi-fireplace"},
    {icon: "mdi-fireplace-off", text: "mdi-fireplace-off"},
    {icon: "mdi-firework", text: "mdi-firework"},
    {icon: "mdi-fish", text: "mdi-fish"},
    {icon: "mdi-fishbowl", text: "mdi-fishbowl"},
    {icon: "mdi-fishbowl-outline", text: "mdi-fishbowl-outline"},
    {icon: "mdi-fit-to-page", text: "mdi-fit-to-page"},
    {icon: "mdi-fit-to-page-outline", text: "mdi-fit-to-page-outline"},
    {icon: "mdi-flag", text: "mdi-flag"},
    {icon: "mdi-flag-checkered", text: "mdi-flag-checkered"},
    {icon: "mdi-flag-minus", text: "mdi-flag-minus"},
    {icon: "mdi-flag-outline", text: "mdi-flag-outline"},
    {icon: "mdi-flag-plus", text: "mdi-flag-plus"},
    {icon: "mdi-flag-remove", text: "mdi-flag-remove"},
    {icon: "mdi-flag-triangle", text: "mdi-flag-triangle"},
    {icon: "mdi-flag-variant", text: "mdi-flag-variant"},
    {icon: "mdi-flag-variant-outline", text: "mdi-flag-variant-outline"},
    {icon: "mdi-flare", text: "mdi-flare"},
    {icon: "mdi-flash", text: "mdi-flash"},
    {icon: "mdi-flash-alert", text: "mdi-flash-alert"},
    {icon: "mdi-flash-alert-outline", text: "mdi-flash-alert-outline"},
    {icon: "mdi-flash-auto", text: "mdi-flash-auto"},
    {icon: "mdi-flash-circle", text: "mdi-flash-circle"},
    {icon: "mdi-flash-off", text: "mdi-flash-off"},
    {icon: "mdi-flash-outline", text: "mdi-flash-outline"},
    {icon: "mdi-flash-red-eye", text: "mdi-flash-red-eye"},
    {icon: "mdi-flashlight", text: "mdi-flashlight"},
    {icon: "mdi-flashlight-off", text: "mdi-flashlight-off"},
    {icon: "mdi-flask", text: "mdi-flask"},
    {icon: "mdi-flask-empty", text: "mdi-flask-empty"},
    {icon: "mdi-flask-empty-outline", text: "mdi-flask-empty-outline"},
    {icon: "mdi-flask-outline", text: "mdi-flask-outline"},
    {icon: "mdi-flattr", text: "mdi-flattr"},
    {icon: "mdi-flickr", text: "mdi-flickr"},
    {icon: "mdi-flip-to-back", text: "mdi-flip-to-back"},
    {icon: "mdi-flip-to-front", text: "mdi-flip-to-front"},
    {icon: "mdi-floor-lamp", text: "mdi-floor-lamp"},
    {icon: "mdi-floor-lamp-dual", text: "mdi-floor-lamp-dual"},
    {icon: "mdi-floor-lamp-variant", text: "mdi-floor-lamp-variant"},
    {icon: "mdi-floor-plan", text: "mdi-floor-plan"},
    {icon: "mdi-floppy", text: "mdi-floppy"},
    {icon: "mdi-floppy-variant", text: "mdi-floppy-variant"},
    {icon: "mdi-flower", text: "mdi-flower"},
    {icon: "mdi-flower-outline", text: "mdi-flower-outline"},
    {icon: "mdi-flower-poppy", text: "mdi-flower-poppy"},
    {icon: "mdi-flower-tulip", text: "mdi-flower-tulip"},
    {icon: "mdi-flower-tulip-outline", text: "mdi-flower-tulip-outline"},
    {icon: "mdi-focus-auto", text: "mdi-focus-auto"},
    {icon: "mdi-focus-field", text: "mdi-focus-field"},
    {icon: "mdi-focus-field-horizontal", text: "mdi-focus-field-horizontal"},
    {icon: "mdi-focus-field-vertical", text: "mdi-focus-field-vertical"},
    {icon: "mdi-folder", text: "mdi-folder"},
    {icon: "mdi-folder-account", text: "mdi-folder-account"},
    {icon: "mdi-folder-account-outline", text: "mdi-folder-account-outline"},
    {icon: "mdi-folder-alert", text: "mdi-folder-alert"},
    {icon: "mdi-folder-alert-outline", text: "mdi-folder-alert-outline"},
    {icon: "mdi-folder-clock", text: "mdi-folder-clock"},
    {icon: "mdi-folder-clock-outline", text: "mdi-folder-clock-outline"},
    {icon: "mdi-folder-download", text: "mdi-folder-download"},
    {icon: "mdi-folder-edit", text: "mdi-folder-edit"},
    {icon: "mdi-folder-edit-outline", text: "mdi-folder-edit-outline"},
    {icon: "mdi-folder-google-drive", text: "mdi-folder-google-drive"},
    {icon: "mdi-folder-image", text: "mdi-folder-image"},
    {icon: "mdi-folder-key", text: "mdi-folder-key"},
    {icon: "mdi-folder-key-network", text: "mdi-folder-key-network"},
    {icon: "mdi-folder-key-network-outline", text: "mdi-folder-key-network-outline"},
    {icon: "mdi-folder-lock", text: "mdi-folder-lock"},
    {icon: "mdi-folder-lock-open", text: "mdi-folder-lock-open"},
    {icon: "mdi-folder-move", text: "mdi-folder-move"},
    {icon: "mdi-folder-multiple", text: "mdi-folder-multiple"},
    {icon: "mdi-folder-multiple-image", text: "mdi-folder-multiple-image"},
    {icon: "mdi-folder-multiple-outline", text: "mdi-folder-multiple-outline"},
    {icon: "mdi-folder-network", text: "mdi-folder-network"},
    {icon: "mdi-folder-network-outline", text: "mdi-folder-network-outline"},
    {icon: "mdi-folder-open", text: "mdi-folder-open"},
    {icon: "mdi-folder-open-outline", text: "mdi-folder-open-outline"},
    {icon: "mdi-folder-outline", text: "mdi-folder-outline"},
    {icon: "mdi-folder-plus", text: "mdi-folder-plus"},
    {icon: "mdi-folder-plus-outline", text: "mdi-folder-plus-outline"},
    {icon: "mdi-folder-pound", text: "mdi-folder-pound"},
    {icon: "mdi-folder-pound-outline", text: "mdi-folder-pound-outline"},
    {icon: "mdi-folder-remove", text: "mdi-folder-remove"},
    {icon: "mdi-folder-remove-outline", text: "mdi-folder-remove-outline"},
    {icon: "mdi-folder-search", text: "mdi-folder-search"},
    {icon: "mdi-folder-search-outline", text: "mdi-folder-search-outline"},
    {icon: "mdi-folder-star", text: "mdi-folder-star"},
    {icon: "mdi-folder-star-outline", text: "mdi-folder-star-outline"},
    {icon: "mdi-folder-swap", text: "mdi-folder-swap"},
    {icon: "mdi-folder-swap-outline", text: "mdi-folder-swap-outline"},
    {icon: "mdi-folder-sync", text: "mdi-folder-sync"},
    {icon: "mdi-folder-sync-outline", text: "mdi-folder-sync-outline"},
    {icon: "mdi-folder-text", text: "mdi-folder-text"},
    {icon: "mdi-folder-text-outline", text: "mdi-folder-text-outline"},
    {icon: "mdi-folder-upload", text: "mdi-folder-upload"},
    {icon: "mdi-folder-zip", text: "mdi-folder-zip"},
    {icon: "mdi-folder-zip-outline", text: "mdi-folder-zip-outline"},
    {icon: "mdi-font-awesome", text: "mdi-font-awesome"},
    {icon: "mdi-food", text: "mdi-food"},
    {icon: "mdi-food-apple", text: "mdi-food-apple"},
    {icon: "mdi-food-apple-outline", text: "mdi-food-apple-outline"},
    {icon: "mdi-food-croissant", text: "mdi-food-croissant"},
    {icon: "mdi-food-fork-drink", text: "mdi-food-fork-drink"},
    {icon: "mdi-food-off", text: "mdi-food-off"},
    {icon: "mdi-food-variant", text: "mdi-food-variant"},
    {icon: "mdi-foot-print", text: "mdi-foot-print"},
    {icon: "mdi-football", text: "mdi-football"},
    {icon: "mdi-football-australian", text: "mdi-football-australian"},
    {icon: "mdi-football-helmet", text: "mdi-football-helmet"},
    {icon: "mdi-forklift", text: "mdi-forklift"},
    {icon: "mdi-format-align-bottom", text: "mdi-format-align-bottom"},
    {icon: "mdi-format-align-center", text: "mdi-format-align-center"},
    {icon: "mdi-format-align-justify", text: "mdi-format-align-justify"},
    {icon: "mdi-format-align-left", text: "mdi-format-align-left"},
    {icon: "mdi-format-align-middle", text: "mdi-format-align-middle"},
    {icon: "mdi-format-align-right", text: "mdi-format-align-right"},
    {icon: "mdi-format-align-top", text: "mdi-format-align-top"},
    {icon: "mdi-format-annotation-minus", text: "mdi-format-annotation-minus"},
    {icon: "mdi-format-annotation-plus", text: "mdi-format-annotation-plus"},
    {icon: "mdi-format-bold", text: "mdi-format-bold"},
    {icon: "mdi-format-clear", text: "mdi-format-clear"},
    {icon: "mdi-format-color-fill", text: "mdi-format-color-fill"},
    {icon: "mdi-format-color-highlight", text: "mdi-format-color-highlight"},
    {icon: "mdi-format-color-text", text: "mdi-format-color-text"},
    {icon: "mdi-format-columns", text: "mdi-format-columns"},
    {icon: "mdi-format-float-center", text: "mdi-format-float-center"},
    {icon: "mdi-format-float-left", text: "mdi-format-float-left"},
    {icon: "mdi-format-float-none", text: "mdi-format-float-none"},
    {icon: "mdi-format-float-right", text: "mdi-format-float-right"},
    {icon: "mdi-format-font", text: "mdi-format-font"},
    {icon: "mdi-format-font-size-decrease", text: "mdi-format-font-size-decrease"},
    {icon: "mdi-format-font-size-increase", text: "mdi-format-font-size-increase"},
    {icon: "mdi-format-header-1", text: "mdi-format-header-1"},
    {icon: "mdi-format-header-2", text: "mdi-format-header-2"},
    {icon: "mdi-format-header-3", text: "mdi-format-header-3"},
    {icon: "mdi-format-header-4", text: "mdi-format-header-4"},
    {icon: "mdi-format-header-5", text: "mdi-format-header-5"},
    {icon: "mdi-format-header-6", text: "mdi-format-header-6"},
    {icon: "mdi-format-header-decrease", text: "mdi-format-header-decrease"},
    {icon: "mdi-format-header-equal", text: "mdi-format-header-equal"},
    {icon: "mdi-format-header-increase", text: "mdi-format-header-increase"},
    {icon: "mdi-format-header-pound", text: "mdi-format-header-pound"},
    {icon: "mdi-format-horizontal-align-center", text: "mdi-format-horizontal-align-center"},
    {icon: "mdi-format-horizontal-align-left", text: "mdi-format-horizontal-align-left"},
    {icon: "mdi-format-horizontal-align-right", text: "mdi-format-horizontal-align-right"},
    {icon: "mdi-format-indent-decrease", text: "mdi-format-indent-decrease"},
    {icon: "mdi-format-indent-increase", text: "mdi-format-indent-increase"},
    {icon: "mdi-format-italic", text: "mdi-format-italic"},
    {icon: "mdi-format-letter-case", text: "mdi-format-letter-case"},
    {icon: "mdi-format-letter-case-lower", text: "mdi-format-letter-case-lower"},
    {icon: "mdi-format-letter-case-upper", text: "mdi-format-letter-case-upper"},
    {icon: "mdi-format-letter-ends-with", text: "mdi-format-letter-ends-with"},
    {icon: "mdi-format-letter-matches", text: "mdi-format-letter-matches"},
    {icon: "mdi-format-letter-starts-with", text: "mdi-format-letter-starts-with"},
    {icon: "mdi-format-line-spacing", text: "mdi-format-line-spacing"},
    {icon: "mdi-format-line-style", text: "mdi-format-line-style"},
    {icon: "mdi-format-line-weight", text: "mdi-format-line-weight"},
    {icon: "mdi-format-list-bulleted", text: "mdi-format-list-bulleted"},
    {icon: "mdi-format-list-bulleted-square", text: "mdi-format-list-bulleted-square"},
    {icon: "mdi-format-list-bulleted-triangle", text: "mdi-format-list-bulleted-triangle"},
    {icon: "mdi-format-list-bulleted-type", text: "mdi-format-list-bulleted-type"},
    {icon: "mdi-format-list-checkbox", text: "mdi-format-list-checkbox"},
    {icon: "mdi-format-list-checks", text: "mdi-format-list-checks"},
    {icon: "mdi-format-list-numbered", text: "mdi-format-list-numbered"},
    {icon: "mdi-format-list-numbered-rtl", text: "mdi-format-list-numbered-rtl"},
    {icon: "mdi-format-overline", text: "mdi-format-overline"},
    {icon: "mdi-format-page-break", text: "mdi-format-page-break"},
    {icon: "mdi-format-paint", text: "mdi-format-paint"},
    {icon: "mdi-format-paragraph", text: "mdi-format-paragraph"},
    {icon: "mdi-format-pilcrow", text: "mdi-format-pilcrow"},
    {icon: "mdi-format-quote-close", text: "mdi-format-quote-close"},
    {icon: "mdi-format-quote-open", text: "mdi-format-quote-open"},
    {icon: "mdi-format-rotate-90", text: "mdi-format-rotate-90"},
    {icon: "mdi-format-section", text: "mdi-format-section"},
    {icon: "mdi-format-size", text: "mdi-format-size"},
    {icon: "mdi-format-strikethrough", text: "mdi-format-strikethrough"},
    {icon: "mdi-format-strikethrough-variant", text: "mdi-format-strikethrough-variant"},
    {icon: "mdi-format-subscript", text: "mdi-format-subscript"},
    {icon: "mdi-format-superscript", text: "mdi-format-superscript"},
    {icon: "mdi-format-text", text: "mdi-format-text"},
    {icon: "mdi-format-text-rotation-angle-down", text: "mdi-format-text-rotation-angle-down"},
    {icon: "mdi-format-text-rotation-angle-up", text: "mdi-format-text-rotation-angle-up"},
    {icon: "mdi-format-text-rotation-down", text: "mdi-format-text-rotation-down"},
    {icon: "mdi-format-text-rotation-down-vertical", text: "mdi-format-text-rotation-down-vertical"},
    {icon: "mdi-format-text-rotation-none", text: "mdi-format-text-rotation-none"},
    {icon: "mdi-format-text-rotation-up", text: "mdi-format-text-rotation-up"},
    {icon: "mdi-format-text-rotation-vertical", text: "mdi-format-text-rotation-vertical"},
    {icon: "mdi-format-text-variant", text: "mdi-format-text-variant"},
    {icon: "mdi-format-text-wrapping-clip", text: "mdi-format-text-wrapping-clip"},
    {icon: "mdi-format-text-wrapping-overflow", text: "mdi-format-text-wrapping-overflow"},
    {icon: "mdi-format-text-wrapping-wrap", text: "mdi-format-text-wrapping-wrap"},
    {icon: "mdi-format-textbox", text: "mdi-format-textbox"},
    {icon: "mdi-format-textdirection-l-to-r", text: "mdi-format-textdirection-l-to-r"},
    {icon: "mdi-format-textdirection-r-to-l", text: "mdi-format-textdirection-r-to-l"},
    {icon: "mdi-format-title", text: "mdi-format-title"},
    {icon: "mdi-format-underline", text: "mdi-format-underline"},
    {icon: "mdi-format-vertical-align-bottom", text: "mdi-format-vertical-align-bottom"},
    {icon: "mdi-format-vertical-align-center", text: "mdi-format-vertical-align-center"},
    {icon: "mdi-format-vertical-align-top", text: "mdi-format-vertical-align-top"},
    {icon: "mdi-format-wrap-inline", text: "mdi-format-wrap-inline"},
    {icon: "mdi-format-wrap-square", text: "mdi-format-wrap-square"},
    {icon: "mdi-format-wrap-tight", text: "mdi-format-wrap-tight"},
    {icon: "mdi-format-wrap-top-bottom", text: "mdi-format-wrap-top-bottom"},
    {icon: "mdi-forum", text: "mdi-forum"},
    {icon: "mdi-forum-outline", text: "mdi-forum-outline"},
    {icon: "mdi-forward", text: "mdi-forward"},
    {icon: "mdi-forwardburger", text: "mdi-forwardburger"},
    {icon: "mdi-fountain", text: "mdi-fountain"},
    {icon: "mdi-fountain-pen", text: "mdi-fountain-pen"},
    {icon: "mdi-fountain-pen-tip", text: "mdi-fountain-pen-tip"},
    {icon: "mdi-foursquare", text: "mdi-foursquare"},
    {icon: "mdi-freebsd", text: "mdi-freebsd"},
    {icon: "mdi-frequently-asked-questions", text: "mdi-frequently-asked-questions"},
    {icon: "mdi-fridge", text: "mdi-fridge"},
    {icon: "mdi-fridge-bottom", text: "mdi-fridge-bottom"},
    {icon: "mdi-fridge-outline", text: "mdi-fridge-outline"},
    {icon: "mdi-fridge-top", text: "mdi-fridge-top"},
    {icon: "mdi-fruit-cherries", text: "mdi-fruit-cherries"},
    {icon: "mdi-fruit-citrus", text: "mdi-fruit-citrus"},
    {icon: "mdi-fruit-grapes", text: "mdi-fruit-grapes"},
    {icon: "mdi-fruit-grapes-outline", text: "mdi-fruit-grapes-outline"},
    {icon: "mdi-fruit-pineapple", text: "mdi-fruit-pineapple"},
    {icon: "mdi-fruit-watermelon", text: "mdi-fruit-watermelon"},
    {icon: "mdi-fuel", text: "mdi-fuel"},
    {icon: "mdi-fullscreen", text: "mdi-fullscreen"},
    {icon: "mdi-fullscreen-exit", text: "mdi-fullscreen-exit"},
    {icon: "mdi-function", text: "mdi-function"},
    {icon: "mdi-function-variant", text: "mdi-function-variant"},
    {icon: "mdi-fuse", text: "mdi-fuse"},
    {icon: "mdi-fuse-blade", text: "mdi-fuse-blade"},
    {icon: "mdi-gamepad", text: "mdi-gamepad"},
    {icon: "mdi-gamepad-circle", text: "mdi-gamepad-circle"},
    {icon: "mdi-gamepad-circle-down", text: "mdi-gamepad-circle-down"},
    {icon: "mdi-gamepad-circle-left", text: "mdi-gamepad-circle-left"},
    {icon: "mdi-gamepad-circle-outline", text: "mdi-gamepad-circle-outline"},
    {icon: "mdi-gamepad-circle-right", text: "mdi-gamepad-circle-right"},
    {icon: "mdi-gamepad-circle-up", text: "mdi-gamepad-circle-up"},
    {icon: "mdi-gamepad-down", text: "mdi-gamepad-down"},
    {icon: "mdi-gamepad-left", text: "mdi-gamepad-left"},
    {icon: "mdi-gamepad-right", text: "mdi-gamepad-right"},
    {icon: "mdi-gamepad-round", text: "mdi-gamepad-round"},
    {icon: "mdi-gamepad-round-down", text: "mdi-gamepad-round-down"},
    {icon: "mdi-gamepad-round-left", text: "mdi-gamepad-round-left"},
    {icon: "mdi-gamepad-round-outline", text: "mdi-gamepad-round-outline"},
    {icon: "mdi-gamepad-round-right", text: "mdi-gamepad-round-right"},
    {icon: "mdi-gamepad-round-up", text: "mdi-gamepad-round-up"},
    {icon: "mdi-gamepad-square", text: "mdi-gamepad-square"},
    {icon: "mdi-gamepad-square-outline", text: "mdi-gamepad-square-outline"},
    {icon: "mdi-gamepad-up", text: "mdi-gamepad-up"},
    {icon: "mdi-gamepad-variant", text: "mdi-gamepad-variant"},
    {icon: "mdi-gamepad-variant-outline", text: "mdi-gamepad-variant-outline"},
    {icon: "mdi-gantry-crane", text: "mdi-gantry-crane"},
    {icon: "mdi-garage", text: "mdi-garage"},
    {icon: "mdi-garage-alert", text: "mdi-garage-alert"},
    {icon: "mdi-garage-open", text: "mdi-garage-open"},
    {icon: "mdi-gas-cylinder", text: "mdi-gas-cylinder"},
    {icon: "mdi-gas-station", text: "mdi-gas-station"},
    {icon: "mdi-gas-station-outline", text: "mdi-gas-station-outline"},
    {icon: "mdi-gate", text: "mdi-gate"},
    {icon: "mdi-gate-and", text: "mdi-gate-and"},
    {icon: "mdi-gate-nand", text: "mdi-gate-nand"},
    {icon: "mdi-gate-nor", text: "mdi-gate-nor"},
    {icon: "mdi-gate-not", text: "mdi-gate-not"},
    {icon: "mdi-gate-or", text: "mdi-gate-or"},
    {icon: "mdi-gate-xnor", text: "mdi-gate-xnor"},
    {icon: "mdi-gate-xor", text: "mdi-gate-xor"},
    {icon: "mdi-gatsby", text: "mdi-gatsby"},
    {icon: "mdi-gauge", text: "mdi-gauge"},
    {icon: "mdi-gauge-empty", text: "mdi-gauge-empty"},
    {icon: "mdi-gauge-full", text: "mdi-gauge-full"},
    {icon: "mdi-gauge-low", text: "mdi-gauge-low"},
    {icon: "mdi-gavel", text: "mdi-gavel"},
    {icon: "mdi-gender-female", text: "mdi-gender-female"},
    {icon: "mdi-gender-male", text: "mdi-gender-male"},
    {icon: "mdi-gender-male-female", text: "mdi-gender-male-female"},
    {icon: "mdi-gender-transgender", text: "mdi-gender-transgender"},
    {icon: "mdi-gentoo", text: "mdi-gentoo"},
    {icon: "mdi-gesture", text: "mdi-gesture"},
    {icon: "mdi-gesture-double-tap", text: "mdi-gesture-double-tap"},
    {icon: "mdi-gesture-pinch", text: "mdi-gesture-pinch"},
    {icon: "mdi-gesture-spread", text: "mdi-gesture-spread"},
    {icon: "mdi-gesture-swipe", text: "mdi-gesture-swipe"},
    {icon: "mdi-gesture-swipe-down", text: "mdi-gesture-swipe-down"},
    {icon: "mdi-gesture-swipe-horizontal", text: "mdi-gesture-swipe-horizontal"},
    {icon: "mdi-gesture-swipe-left", text: "mdi-gesture-swipe-left"},
    {icon: "mdi-gesture-swipe-right", text: "mdi-gesture-swipe-right"},
    {icon: "mdi-gesture-swipe-up", text: "mdi-gesture-swipe-up"},
    {icon: "mdi-gesture-swipe-vertical", text: "mdi-gesture-swipe-vertical"},
    {icon: "mdi-gesture-tap", text: "mdi-gesture-tap"},
    {icon: "mdi-gesture-tap-hold", text: "mdi-gesture-tap-hold"},
    {icon: "mdi-gesture-two-double-tap", text: "mdi-gesture-two-double-tap"},
    {icon: "mdi-gesture-two-tap", text: "mdi-gesture-two-tap"},
    {icon: "mdi-ghost", text: "mdi-ghost"},
    {icon: "mdi-ghost-off", text: "mdi-ghost-off"},
    {icon: "mdi-gif", text: "mdi-gif"},
    {icon: "mdi-gift", text: "mdi-gift"},
    {icon: "mdi-gift-outline", text: "mdi-gift-outline"},
    {icon: "mdi-git", text: "mdi-git"},
    {icon: "mdi-github-box", text: "mdi-github-box"},
    {icon: "mdi-github-circle", text: "mdi-github-circle"},
    {icon: "mdi-github-face", text: "mdi-github-face"},
    {icon: "mdi-gitlab", text: "mdi-gitlab"},
    {icon: "mdi-glass-cocktail", text: "mdi-glass-cocktail"},
    {icon: "mdi-glass-flute", text: "mdi-glass-flute"},
    {icon: "mdi-glass-mug", text: "mdi-glass-mug"},
    {icon: "mdi-glass-stange", text: "mdi-glass-stange"},
    {icon: "mdi-glass-tulip", text: "mdi-glass-tulip"},
    {icon: "mdi-glass-wine", text: "mdi-glass-wine"},
    {icon: "mdi-glassdoor", text: "mdi-glassdoor"},
    {icon: "mdi-glasses", text: "mdi-glasses"},
    {icon: "mdi-globe-model", text: "mdi-globe-model"},
    {icon: "mdi-gmail", text: "mdi-gmail"},
    {icon: "mdi-gnome", text: "mdi-gnome"},
    {icon: "mdi-go-kart", text: "mdi-go-kart"},
    {icon: "mdi-go-kart-track", text: "mdi-go-kart-track"},
    {icon: "mdi-gog", text: "mdi-gog"},
    {icon: "mdi-golf", text: "mdi-golf"},
    {icon: "mdi-gondola", text: "mdi-gondola"},
    {icon: "mdi-goodreads", text: "mdi-goodreads"},
    {icon: "mdi-google", text: "mdi-google"},
    {icon: "mdi-google-adwords", text: "mdi-google-adwords"},
    {icon: "mdi-google-analytics", text: "mdi-google-analytics"},
    {icon: "mdi-google-assistant", text: "mdi-google-assistant"},
    {icon: "mdi-google-cardboard", text: "mdi-google-cardboard"},
    {icon: "mdi-google-chrome", text: "mdi-google-chrome"},
    {icon: "mdi-google-circles", text: "mdi-google-circles"},
    {icon: "mdi-google-circles-communities", text: "mdi-google-circles-communities"},
    {icon: "mdi-google-circles-extended", text: "mdi-google-circles-extended"},
    {icon: "mdi-google-circles-group", text: "mdi-google-circles-group"},
    {icon: "mdi-google-classroom", text: "mdi-google-classroom"},
    {icon: "mdi-google-controller", text: "mdi-google-controller"},
    {icon: "mdi-google-controller-off", text: "mdi-google-controller-off"},
    {icon: "mdi-google-drive", text: "mdi-google-drive"},
    {icon: "mdi-google-earth", text: "mdi-google-earth"},
    {icon: "mdi-google-fit", text: "mdi-google-fit"},
    {icon: "mdi-google-glass", text: "mdi-google-glass"},
    {icon: "mdi-google-hangouts", text: "mdi-google-hangouts"},
    {icon: "mdi-google-home", text: "mdi-google-home"},
    {icon: "mdi-google-keep", text: "mdi-google-keep"},
    {icon: "mdi-google-lens", text: "mdi-google-lens"},
    {icon: "mdi-google-maps", text: "mdi-google-maps"},
    {icon: "mdi-google-my-business", text: "mdi-google-my-business"},
    {icon: "mdi-google-nearby", text: "mdi-google-nearby"},
    {icon: "mdi-google-pages", text: "mdi-google-pages"},
    {icon: "mdi-google-photos", text: "mdi-google-photos"},
    {icon: "mdi-google-physical-web", text: "mdi-google-physical-web"},
    {icon: "mdi-google-play", text: "mdi-google-play"},
    {icon: "mdi-google-plus", text: "mdi-google-plus"},
    {icon: "mdi-google-plus-box", text: "mdi-google-plus-box"},
    {icon: "mdi-google-podcast", text: "mdi-google-podcast"},
    {icon: "mdi-google-spreadsheet", text: "mdi-google-spreadsheet"},
    {icon: "mdi-google-street-view", text: "mdi-google-street-view"},
    {icon: "mdi-google-translate", text: "mdi-google-translate"},
    {icon: "mdi-gradient", text: "mdi-gradient"},
    {icon: "mdi-grain", text: "mdi-grain"},
    {icon: "mdi-graph", text: "mdi-graph"},
    {icon: "mdi-graph-outline", text: "mdi-graph-outline"},
    {icon: "mdi-graphql", text: "mdi-graphql"},
    {icon: "mdi-grave-stone", text: "mdi-grave-stone"},
    {icon: "mdi-grease-pencil", text: "mdi-grease-pencil"},
    {icon: "mdi-greater-than", text: "mdi-greater-than"},
    {icon: "mdi-greater-than-or-equal", text: "mdi-greater-than-or-equal"},
    {icon: "mdi-grid", text: "mdi-grid"},
    {icon: "mdi-grid-large", text: "mdi-grid-large"},
    {icon: "mdi-grid-off", text: "mdi-grid-off"},
    {icon: "mdi-grill", text: "mdi-grill"},
    {icon: "mdi-group", text: "mdi-group"},
    {icon: "mdi-guitar-acoustic", text: "mdi-guitar-acoustic"},
    {icon: "mdi-guitar-electric", text: "mdi-guitar-electric"},
    {icon: "mdi-guitar-pick", text: "mdi-guitar-pick"},
    {icon: "mdi-guitar-pick-outline", text: "mdi-guitar-pick-outline"},
    {icon: "mdi-guy-fawkes-mask", text: "mdi-guy-fawkes-mask"},
    {icon: "mdi-hackernews", text: "mdi-hackernews"},
    {icon: "mdi-hail", text: "mdi-hail"},
    {icon: "mdi-halloween", text: "mdi-halloween"},
    {icon: "mdi-hamburger", text: "mdi-hamburger"},
    {icon: "mdi-hammer", text: "mdi-hammer"},
    {icon: "mdi-hand", text: "mdi-hand"},
    {icon: "mdi-hand-left", text: "mdi-hand-left"},
    {icon: "mdi-hand-okay", text: "mdi-hand-okay"},
    {icon: "mdi-hand-peace", text: "mdi-hand-peace"},
    {icon: "mdi-hand-peace-variant", text: "mdi-hand-peace-variant"},
    {icon: "mdi-hand-pointing-down", text: "mdi-hand-pointing-down"},
    {icon: "mdi-hand-pointing-left", text: "mdi-hand-pointing-left"},
    {icon: "mdi-hand-pointing-right", text: "mdi-hand-pointing-right"},
    {icon: "mdi-hand-pointing-up", text: "mdi-hand-pointing-up"},
    {icon: "mdi-hand-right", text: "mdi-hand-right"},
    {icon: "mdi-hand-saw", text: "mdi-hand-saw"},
    {icon: "mdi-handball", text: "mdi-handball"},
    {icon: "mdi-hanger", text: "mdi-hanger"},
    {icon: "mdi-hard-hat", text: "mdi-hard-hat"},
    {icon: "mdi-harddisk", text: "mdi-harddisk"},
    {icon: "mdi-harddisk-plus", text: "mdi-harddisk-plus"},
    {icon: "mdi-harddisk-remove", text: "mdi-harddisk-remove"},
    {icon: "mdi-hat-fedora", text: "mdi-hat-fedora"},
    {icon: "mdi-hazard-lights", text: "mdi-hazard-lights"},
    {icon: "mdi-hdr", text: "mdi-hdr"},
    {icon: "mdi-hdr-off", text: "mdi-hdr-off"},
    {icon: "mdi-headphones", text: "mdi-headphones"},
    {icon: "mdi-headphones-bluetooth", text: "mdi-headphones-bluetooth"},
    {icon: "mdi-headphones-box", text: "mdi-headphones-box"},
    {icon: "mdi-headphones-off", text: "mdi-headphones-off"},
    {icon: "mdi-headphones-settings", text: "mdi-headphones-settings"},
    {icon: "mdi-headset", text: "mdi-headset"},
    {icon: "mdi-headset-dock", text: "mdi-headset-dock"},
    {icon: "mdi-headset-off", text: "mdi-headset-off"},
    {icon: "mdi-heart", text: "mdi-heart"},
    {icon: "mdi-heart-box", text: "mdi-heart-box"},
    {icon: "mdi-heart-box-outline", text: "mdi-heart-box-outline"},
    {icon: "mdi-heart-broken", text: "mdi-heart-broken"},
    {icon: "mdi-heart-broken-outline", text: "mdi-heart-broken-outline"},
    {icon: "mdi-heart-circle", text: "mdi-heart-circle"},
    {icon: "mdi-heart-circle-outline", text: "mdi-heart-circle-outline"},
    {icon: "mdi-heart-flash", text: "mdi-heart-flash"},
    {icon: "mdi-heart-half", text: "mdi-heart-half"},
    {icon: "mdi-heart-half-full", text: "mdi-heart-half-full"},
    {icon: "mdi-heart-half-outline", text: "mdi-heart-half-outline"},
    {icon: "mdi-heart-multiple", text: "mdi-heart-multiple"},
    {icon: "mdi-heart-multiple-outline", text: "mdi-heart-multiple-outline"},
    {icon: "mdi-heart-off", text: "mdi-heart-off"},
    {icon: "mdi-heart-outline", text: "mdi-heart-outline"},
    {icon: "mdi-heart-pulse", text: "mdi-heart-pulse"},
    {icon: "mdi-helicopter", text: "mdi-helicopter"},
    {icon: "mdi-help", text: "mdi-help"},
    {icon: "mdi-help-box", text: "mdi-help-box"},
    {icon: "mdi-help-circle", text: "mdi-help-circle"},
    {icon: "mdi-help-circle-outline", text: "mdi-help-circle-outline"},
    {icon: "mdi-help-network", text: "mdi-help-network"},
    {icon: "mdi-help-network-outline", text: "mdi-help-network-outline"},
    {icon: "mdi-help-rhombus", text: "mdi-help-rhombus"},
    {icon: "mdi-help-rhombus-outline", text: "mdi-help-rhombus-outline"},
    {icon: "mdi-hexagon", text: "mdi-hexagon"},
    {icon: "mdi-hexagon-multiple", text: "mdi-hexagon-multiple"},
    {icon: "mdi-hexagon-outline", text: "mdi-hexagon-outline"},
    {icon: "mdi-hexagon-slice-1", text: "mdi-hexagon-slice-1"},
    {icon: "mdi-hexagon-slice-2", text: "mdi-hexagon-slice-2"},
    {icon: "mdi-hexagon-slice-3", text: "mdi-hexagon-slice-3"},
    {icon: "mdi-hexagon-slice-4", text: "mdi-hexagon-slice-4"},
    {icon: "mdi-hexagon-slice-5", text: "mdi-hexagon-slice-5"},
    {icon: "mdi-hexagon-slice-6", text: "mdi-hexagon-slice-6"},
    {icon: "mdi-hexagram", text: "mdi-hexagram"},
    {icon: "mdi-hexagram-outline", text: "mdi-hexagram-outline"},
    {icon: "mdi-high-definition", text: "mdi-high-definition"},
    {icon: "mdi-high-definition-box", text: "mdi-high-definition-box"},
    {icon: "mdi-highway", text: "mdi-highway"},
    {icon: "mdi-hiking", text: "mdi-hiking"},
    {icon: "mdi-hinduism", text: "mdi-hinduism"},
    {icon: "mdi-history", text: "mdi-history"},
    {icon: "mdi-hockey-puck", text: "mdi-hockey-puck"},
    {icon: "mdi-hockey-sticks", text: "mdi-hockey-sticks"},
    {icon: "mdi-hololens", text: "mdi-hololens"},
    {icon: "mdi-home", text: "mdi-home"},
    {icon: "mdi-home-account", text: "mdi-home-account"},
    {icon: "mdi-home-alert", text: "mdi-home-alert"},
    {icon: "mdi-home-analytics", text: "mdi-home-analytics"},
    {icon: "mdi-home-assistant", text: "mdi-home-assistant"},
    {icon: "mdi-home-automation", text: "mdi-home-automation"},
    {icon: "mdi-home-circle", text: "mdi-home-circle"},
    {icon: "mdi-home-circle-outline", text: "mdi-home-circle-outline"},
    {icon: "mdi-home-city", text: "mdi-home-city"},
    {icon: "mdi-home-city-outline", text: "mdi-home-city-outline"},
    {icon: "mdi-home-currency-usd", text: "mdi-home-currency-usd"},
    {icon: "mdi-home-export-outline", text: "mdi-home-export-outline"},
    {icon: "mdi-home-flood", text: "mdi-home-flood"},
    {icon: "mdi-home-floor-0", text: "mdi-home-floor-0"},
    {icon: "mdi-home-floor-1", text: "mdi-home-floor-1"},
    {icon: "mdi-home-floor-2", text: "mdi-home-floor-2"},
    {icon: "mdi-home-floor-3", text: "mdi-home-floor-3"},
    {icon: "mdi-home-floor-a", text: "mdi-home-floor-a"},
    {icon: "mdi-home-floor-b", text: "mdi-home-floor-b"},
    {icon: "mdi-home-floor-g", text: "mdi-home-floor-g"},
    {icon: "mdi-home-floor-l", text: "mdi-home-floor-l"},
    {icon: "mdi-home-floor-negative-1", text: "mdi-home-floor-negative-1"},
    {icon: "mdi-home-group", text: "mdi-home-group"},
    {icon: "mdi-home-heart", text: "mdi-home-heart"},
    {icon: "mdi-home-import-outline", text: "mdi-home-import-outline"},
    {icon: "mdi-home-lock", text: "mdi-home-lock"},
    {icon: "mdi-home-lock-open", text: "mdi-home-lock-open"},
    {icon: "mdi-home-map-marker", text: "mdi-home-map-marker"},
    {icon: "mdi-home-minus", text: "mdi-home-minus"},
    {icon: "mdi-home-modern", text: "mdi-home-modern"},
    {icon: "mdi-home-outline", text: "mdi-home-outline"},
    {icon: "mdi-home-plus", text: "mdi-home-plus"},
    {icon: "mdi-home-thermometer", text: "mdi-home-thermometer"},
    {icon: "mdi-home-thermometer-outline", text: "mdi-home-thermometer-outline"},
    {icon: "mdi-home-variant", text: "mdi-home-variant"},
    {icon: "mdi-home-variant-outline", text: "mdi-home-variant-outline"},
    {icon: "mdi-hook", text: "mdi-hook"},
    {icon: "mdi-hook-off", text: "mdi-hook-off"},
    {icon: "mdi-hops", text: "mdi-hops"},
    {icon: "mdi-horseshoe", text: "mdi-horseshoe"},
    {icon: "mdi-hospital", text: "mdi-hospital"},
    {icon: "mdi-hospital-box", text: "mdi-hospital-box"},
    {icon: "mdi-hospital-box-outline", text: "mdi-hospital-box-outline"},
    {icon: "mdi-hospital-building", text: "mdi-hospital-building"},
    {icon: "mdi-hospital-marker", text: "mdi-hospital-marker"},
    {icon: "mdi-hot-tub", text: "mdi-hot-tub"},
    {icon: "mdi-hotel", text: "mdi-hotel"},
    {icon: "mdi-houzz", text: "mdi-houzz"},
    {icon: "mdi-houzz-box", text: "mdi-houzz-box"},
    {icon: "mdi-hubspot", text: "mdi-hubspot"},
    {icon: "mdi-hulu", text: "mdi-hulu"},
    {icon: "mdi-human", text: "mdi-human"},
    {icon: "mdi-human-child", text: "mdi-human-child"},
    {icon: "mdi-human-female", text: "mdi-human-female"},
    {icon: "mdi-human-female-boy", text: "mdi-human-female-boy"},
    {icon: "mdi-human-female-female", text: "mdi-human-female-female"},
    {icon: "mdi-human-female-girl", text: "mdi-human-female-girl"},
    {icon: "mdi-human-greeting", text: "mdi-human-greeting"},
    {icon: "mdi-human-handsdown", text: "mdi-human-handsdown"},
    {icon: "mdi-human-handsup", text: "mdi-human-handsup"},
    {icon: "mdi-human-male", text: "mdi-human-male"},
    {icon: "mdi-human-male-boy", text: "mdi-human-male-boy"},
    {icon: "mdi-human-male-female", text: "mdi-human-male-female"},
    {icon: "mdi-human-male-girl", text: "mdi-human-male-girl"},
    {icon: "mdi-human-male-height", text: "mdi-human-male-height"},
    {icon: "mdi-human-male-height-variant", text: "mdi-human-male-height-variant"},
    {icon: "mdi-human-male-male", text: "mdi-human-male-male"},
    {icon: "mdi-human-pregnant", text: "mdi-human-pregnant"},
    {icon: "mdi-humble-bundle", text: "mdi-humble-bundle"},
    {icon: "mdi-ice-cream", text: "mdi-ice-cream"},
    {icon: "mdi-ice-pop", text: "mdi-ice-pop"},
    {icon: "mdi-id-card", text: "mdi-id-card"},
    {icon: "mdi-identifier", text: "mdi-identifier"},
    {icon: "mdi-iframe", text: "mdi-iframe"},
    {icon: "mdi-iframe-outline", text: "mdi-iframe-outline"},
    {icon: "mdi-image", text: "mdi-image"},
    {icon: "mdi-image-album", text: "mdi-image-album"},
    {icon: "mdi-image-area", text: "mdi-image-area"},
    {icon: "mdi-image-area-close", text: "mdi-image-area-close"},
    {icon: "mdi-image-auto-adjust", text: "mdi-image-auto-adjust"},
    {icon: "mdi-image-broken", text: "mdi-image-broken"},
    {icon: "mdi-image-broken-variant", text: "mdi-image-broken-variant"},
    {icon: "mdi-image-filter", text: "mdi-image-filter"},
    {icon: "mdi-image-filter-black-white", text: "mdi-image-filter-black-white"},
    {icon: "mdi-image-filter-center-focus", text: "mdi-image-filter-center-focus"},
    {icon: "mdi-image-filter-center-focus-strong", text: "mdi-image-filter-center-focus-strong"},
    {icon: "mdi-image-filter-center-focus-strong-outline", text: "mdi-image-filter-center-focus-strong-outline"},
    {icon: "mdi-image-filter-center-focus-weak", text: "mdi-image-filter-center-focus-weak"},
    {icon: "mdi-image-filter-drama", text: "mdi-image-filter-drama"},
    {icon: "mdi-image-filter-frames", text: "mdi-image-filter-frames"},
    {icon: "mdi-image-filter-hdr", text: "mdi-image-filter-hdr"},
    {icon: "mdi-image-filter-none", text: "mdi-image-filter-none"},
    {icon: "mdi-image-filter-tilt-shift", text: "mdi-image-filter-tilt-shift"},
    {icon: "mdi-image-filter-vintage", text: "mdi-image-filter-vintage"},
    {icon: "mdi-image-frame", text: "mdi-image-frame"},
    {icon: "mdi-image-move", text: "mdi-image-move"},
    {icon: "mdi-image-multiple", text: "mdi-image-multiple"},
    {icon: "mdi-image-off", text: "mdi-image-off"},
    {icon: "mdi-image-outline", text: "mdi-image-outline"},
    {icon: "mdi-image-plus", text: "mdi-image-plus"},
    {icon: "mdi-image-search", text: "mdi-image-search"},
    {icon: "mdi-image-search-outline", text: "mdi-image-search-outline"},
    {icon: "mdi-image-size-select-actual", text: "mdi-image-size-select-actual"},
    {icon: "mdi-image-size-select-large", text: "mdi-image-size-select-large"},
    {icon: "mdi-image-size-select-small", text: "mdi-image-size-select-small"},
    {icon: "mdi-import", text: "mdi-import"},
    {icon: "mdi-inbox", text: "mdi-inbox"},
    {icon: "mdi-inbox-arrow-down", text: "mdi-inbox-arrow-down"},
    {icon: "mdi-inbox-arrow-up", text: "mdi-inbox-arrow-up"},
    {icon: "mdi-inbox-multiple", text: "mdi-inbox-multiple"},
    {icon: "mdi-inbox-multiple-outline", text: "mdi-inbox-multiple-outline"},
    {icon: "mdi-incognito", text: "mdi-incognito"},
    {icon: "mdi-infinity", text: "mdi-infinity"},
    {icon: "mdi-information", text: "mdi-information"},
    {icon: "mdi-information-outline", text: "mdi-information-outline"},
    {icon: "mdi-information-variant", text: "mdi-information-variant"},
    {icon: "mdi-instagram", text: "mdi-instagram"},
    {icon: "mdi-instapaper", text: "mdi-instapaper"},
    {icon: "mdi-instrument-triangle", text: "mdi-instrument-triangle"},
    {icon: "mdi-internet-explorer", text: "mdi-internet-explorer"},
    {icon: "mdi-invert-colors", text: "mdi-invert-colors"},
    {icon: "mdi-invert-colors-off", text: "mdi-invert-colors-off"},
    {icon: "mdi-ip", text: "mdi-ip"},
    {icon: "mdi-ip-network", text: "mdi-ip-network"},
    {icon: "mdi-ip-network-outline", text: "mdi-ip-network-outline"},
    {icon: "mdi-ipod", text: "mdi-ipod"},
    {icon: "mdi-islam", text: "mdi-islam"},
    {icon: "mdi-island", text: "mdi-island"},
    {icon: "mdi-itunes", text: "mdi-itunes"},
    {icon: "mdi-jabber", text: "mdi-jabber"},
    {icon: "mdi-jeepney", text: "mdi-jeepney"},
    {icon: "mdi-jellyfish", text: "mdi-jellyfish"},
    {icon: "mdi-jellyfish-outline", text: "mdi-jellyfish-outline"},
    {icon: "mdi-jira", text: "mdi-jira"},
    {icon: "mdi-jquery", text: "mdi-jquery"},
    {icon: "mdi-jsfiddle", text: "mdi-jsfiddle"},
    {icon: "mdi-json", text: "mdi-json"},
    {icon: "mdi-judaism", text: "mdi-judaism"},
    {icon: "mdi-kabaddi", text: "mdi-kabaddi"},
    {icon: "mdi-karate", text: "mdi-karate"},
    {icon: "mdi-keg", text: "mdi-keg"},
    {icon: "mdi-kettle", text: "mdi-kettle"},
    {icon: "mdi-kettle-outline", text: "mdi-kettle-outline"},
    {icon: "mdi-key", text: "mdi-key"},
    {icon: "mdi-key-change", text: "mdi-key-change"},
    {icon: "mdi-key-minus", text: "mdi-key-minus"},
    {icon: "mdi-key-outline", text: "mdi-key-outline"},
    {icon: "mdi-key-plus", text: "mdi-key-plus"},
    {icon: "mdi-key-remove", text: "mdi-key-remove"},
    {icon: "mdi-key-variant", text: "mdi-key-variant"},
    {icon: "mdi-key-wireless", text: "mdi-key-wireless"},
    {icon: "mdi-keyboard", text: "mdi-keyboard"},
    {icon: "mdi-keyboard-backspace", text: "mdi-keyboard-backspace"},
    {icon: "mdi-keyboard-caps", text: "mdi-keyboard-caps"},
    {icon: "mdi-keyboard-close", text: "mdi-keyboard-close"},
    {icon: "mdi-keyboard-off", text: "mdi-keyboard-off"},
    {icon: "mdi-keyboard-off-outline", text: "mdi-keyboard-off-outline"},
    {icon: "mdi-keyboard-outline", text: "mdi-keyboard-outline"},
    {icon: "mdi-keyboard-return", text: "mdi-keyboard-return"},
    {icon: "mdi-keyboard-settings", text: "mdi-keyboard-settings"},
    {icon: "mdi-keyboard-settings-outline", text: "mdi-keyboard-settings-outline"},
    {icon: "mdi-keyboard-space", text: "mdi-keyboard-space"},
    {icon: "mdi-keyboard-tab", text: "mdi-keyboard-tab"},
    {icon: "mdi-keyboard-variant", text: "mdi-keyboard-variant"},
    {icon: "mdi-kickstarter", text: "mdi-kickstarter"},
    {icon: "mdi-knife", text: "mdi-knife"},
    {icon: "mdi-knife-military", text: "mdi-knife-military"},
    {icon: "mdi-kodi", text: "mdi-kodi"},
    {icon: "mdi-label", text: "mdi-label"},
    {icon: "mdi-label-off", text: "mdi-label-off"},
    {icon: "mdi-label-off-outline", text: "mdi-label-off-outline"},
    {icon: "mdi-label-outline", text: "mdi-label-outline"},
    {icon: "mdi-label-variant", text: "mdi-label-variant"},
    {icon: "mdi-label-variant-outline", text: "mdi-label-variant-outline"},
    {icon: "mdi-ladybug", text: "mdi-ladybug"},
    {icon: "mdi-lambda", text: "mdi-lambda"},
    {icon: "mdi-lamp", text: "mdi-lamp"},
    {icon: "mdi-lan", text: "mdi-lan"},
    {icon: "mdi-lan-connect", text: "mdi-lan-connect"},
    {icon: "mdi-lan-disconnect", text: "mdi-lan-disconnect"},
    {icon: "mdi-lan-pending", text: "mdi-lan-pending"},
    {icon: "mdi-language-c", text: "mdi-language-c"},
    {icon: "mdi-language-cpp", text: "mdi-language-cpp"},
    {icon: "mdi-language-csharp", text: "mdi-language-csharp"},
    {icon: "mdi-language-css3", text: "mdi-language-css3"},
    {icon: "mdi-language-go", text: "mdi-language-go"},
    {icon: "mdi-language-haskell", text: "mdi-language-haskell"},
    {icon: "mdi-language-html5", text: "mdi-language-html5"},
    {icon: "mdi-language-java", text: "mdi-language-java"},
    {icon: "mdi-language-javascript", text: "mdi-language-javascript"},
    {icon: "mdi-language-lua", text: "mdi-language-lua"},
    {icon: "mdi-language-php", text: "mdi-language-php"},
    {icon: "mdi-language-python", text: "mdi-language-python"},
    {icon: "mdi-language-python-text", text: "mdi-language-python-text"},
    {icon: "mdi-language-r", text: "mdi-language-r"},
    {icon: "mdi-language-ruby-on-rails", text: "mdi-language-ruby-on-rails"},
    {icon: "mdi-language-swift", text: "mdi-language-swift"},
    {icon: "mdi-language-typescript", text: "mdi-language-typescript"},
    {icon: "mdi-laptop", text: "mdi-laptop"},
    {icon: "mdi-laptop-chromebook", text: "mdi-laptop-chromebook"},
    {icon: "mdi-laptop-mac", text: "mdi-laptop-mac"},
    {icon: "mdi-laptop-off", text: "mdi-laptop-off"},
    {icon: "mdi-laptop-windows", text: "mdi-laptop-windows"},
    {icon: "mdi-laravel", text: "mdi-laravel"},
    {icon: "mdi-lasso", text: "mdi-lasso"},
    {icon: "mdi-lastfm", text: "mdi-lastfm"},
    {icon: "mdi-lastpass", text: "mdi-lastpass"},
    {icon: "mdi-latitude", text: "mdi-latitude"},
    {icon: "mdi-launch", text: "mdi-launch"},
    {icon: "mdi-lava-lamp", text: "mdi-lava-lamp"},
    {icon: "mdi-layers", text: "mdi-layers"},
    {icon: "mdi-layers-minus", text: "mdi-layers-minus"},
    {icon: "mdi-layers-off", text: "mdi-layers-off"},
    {icon: "mdi-layers-off-outline", text: "mdi-layers-off-outline"},
    {icon: "mdi-layers-outline", text: "mdi-layers-outline"},
    {icon: "mdi-layers-plus", text: "mdi-layers-plus"},
    {icon: "mdi-layers-remove", text: "mdi-layers-remove"},
    {icon: "mdi-layers-triple", text: "mdi-layers-triple"},
    {icon: "mdi-layers-triple-outline", text: "mdi-layers-triple-outline"},
    {icon: "mdi-lead-pencil", text: "mdi-lead-pencil"},
    {icon: "mdi-leaf", text: "mdi-leaf"},
    {icon: "mdi-leaf-maple", text: "mdi-leaf-maple"},
    {icon: "mdi-leak", text: "mdi-leak"},
    {icon: "mdi-leak-off", text: "mdi-leak-off"},
    {icon: "mdi-led-off", text: "mdi-led-off"},
    {icon: "mdi-led-on", text: "mdi-led-on"},
    {icon: "mdi-led-outline", text: "mdi-led-outline"},
    {icon: "mdi-led-strip", text: "mdi-led-strip"},
    {icon: "mdi-led-strip-variant", text: "mdi-led-strip-variant"},
    {icon: "mdi-led-variant-off", text: "mdi-led-variant-off"},
    {icon: "mdi-led-variant-on", text: "mdi-led-variant-on"},
    {icon: "mdi-led-variant-outline", text: "mdi-led-variant-outline"},
    {icon: "mdi-less-than", text: "mdi-less-than"},
    {icon: "mdi-less-than-or-equal", text: "mdi-less-than-or-equal"},
    {icon: "mdi-library", text: "mdi-library"},
    {icon: "mdi-library-books", text: "mdi-library-books"},
    {icon: "mdi-library-movie", text: "mdi-library-movie"},
    {icon: "mdi-library-music", text: "mdi-library-music"},
    {icon: "mdi-library-music-outline", text: "mdi-library-music-outline"},
    {icon: "mdi-library-shelves", text: "mdi-library-shelves"},
    {icon: "mdi-library-video", text: "mdi-library-video"},
    {icon: "mdi-license", text: "mdi-license"},
    {icon: "mdi-lifebuoy", text: "mdi-lifebuoy"},
    {icon: "mdi-light-switch", text: "mdi-light-switch"},
    {icon: "mdi-lightbulb", text: "mdi-lightbulb"},
    {icon: "mdi-lightbulb-off", text: "mdi-lightbulb-off"},
    {icon: "mdi-lightbulb-off-outline", text: "mdi-lightbulb-off-outline"},
    {icon: "mdi-lightbulb-on", text: "mdi-lightbulb-on"},
    {icon: "mdi-lightbulb-on-outline", text: "mdi-lightbulb-on-outline"},
    {icon: "mdi-lightbulb-outline", text: "mdi-lightbulb-outline"},
    {icon: "mdi-lighthouse", text: "mdi-lighthouse"},
    {icon: "mdi-lighthouse-on", text: "mdi-lighthouse-on"},
    {icon: "mdi-link", text: "mdi-link"},
    {icon: "mdi-link-box", text: "mdi-link-box"},
    {icon: "mdi-link-box-outline", text: "mdi-link-box-outline"},
    {icon: "mdi-link-box-variant", text: "mdi-link-box-variant"},
    {icon: "mdi-link-box-variant-outline", text: "mdi-link-box-variant-outline"},
    {icon: "mdi-link-off", text: "mdi-link-off"},
    {icon: "mdi-link-plus", text: "mdi-link-plus"},
    {icon: "mdi-link-variant", text: "mdi-link-variant"},
    {icon: "mdi-link-variant-off", text: "mdi-link-variant-off"},
    {icon: "mdi-linkedin", text: "mdi-linkedin"},
    {icon: "mdi-linkedin-box", text: "mdi-linkedin-box"},
    {icon: "mdi-linux", text: "mdi-linux"},
    {icon: "mdi-linux-mint", text: "mdi-linux-mint"},
    {icon: "mdi-litecoin", text: "mdi-litecoin"},
    {icon: "mdi-loading", text: "mdi-loading"},
    {icon: "mdi-location-enter", text: "mdi-location-enter"},
    {icon: "mdi-location-exit", text: "mdi-location-exit"},
    {icon: "mdi-lock", text: "mdi-lock"},
    {icon: "mdi-lock-alert", text: "mdi-lock-alert"},
    {icon: "mdi-lock-clock", text: "mdi-lock-clock"},
    {icon: "mdi-lock-open", text: "mdi-lock-open"},
    {icon: "mdi-lock-open-outline", text: "mdi-lock-open-outline"},
    {icon: "mdi-lock-open-variant", text: "mdi-lock-open-variant"},
    {icon: "mdi-lock-open-variant-outline", text: "mdi-lock-open-variant-outline"},
    {icon: "mdi-lock-outline", text: "mdi-lock-outline"},
    {icon: "mdi-lock-pattern", text: "mdi-lock-pattern"},
    {icon: "mdi-lock-plus", text: "mdi-lock-plus"},
    {icon: "mdi-lock-question", text: "mdi-lock-question"},
    {icon: "mdi-lock-reset", text: "mdi-lock-reset"},
    {icon: "mdi-lock-smart", text: "mdi-lock-smart"},
    {icon: "mdi-locker", text: "mdi-locker"},
    {icon: "mdi-locker-multiple", text: "mdi-locker-multiple"},
    {icon: "mdi-login", text: "mdi-login"},
    {icon: "mdi-login-variant", text: "mdi-login-variant"},
    {icon: "mdi-logout", text: "mdi-logout"},
    {icon: "mdi-logout-variant", text: "mdi-logout-variant"},
    {icon: "mdi-longitude", text: "mdi-longitude"},
    {icon: "mdi-looks", text: "mdi-looks"},
    {icon: "mdi-loupe", text: "mdi-loupe"},
    {icon: "mdi-lumx", text: "mdi-lumx"},
    {icon: "mdi-lyft", text: "mdi-lyft"},
    {icon: "mdi-magnet", text: "mdi-magnet"},
    {icon: "mdi-magnet-on", text: "mdi-magnet-on"},
    {icon: "mdi-magnify", text: "mdi-magnify"},
    {icon: "mdi-magnify-close", text: "mdi-magnify-close"},
    {icon: "mdi-magnify-minus", text: "mdi-magnify-minus"},
    {icon: "mdi-magnify-minus-cursor", text: "mdi-magnify-minus-cursor"},
    {icon: "mdi-magnify-minus-outline", text: "mdi-magnify-minus-outline"},
    {icon: "mdi-magnify-plus", text: "mdi-magnify-plus"},
    {icon: "mdi-magnify-plus-cursor", text: "mdi-magnify-plus-cursor"},
    {icon: "mdi-magnify-plus-outline", text: "mdi-magnify-plus-outline"},
    {icon: "mdi-mail", text: "mdi-mail"},
    {icon: "mdi-mail-ru", text: "mdi-mail-ru"},
    {icon: "mdi-mailbox", text: "mdi-mailbox"},
    {icon: "mdi-mailbox-open", text: "mdi-mailbox-open"},
    {icon: "mdi-mailbox-open-outline", text: "mdi-mailbox-open-outline"},
    {icon: "mdi-mailbox-open-up", text: "mdi-mailbox-open-up"},
    {icon: "mdi-mailbox-open-up-outline", text: "mdi-mailbox-open-up-outline"},
    {icon: "mdi-mailbox-outline", text: "mdi-mailbox-outline"},
    {icon: "mdi-mailbox-up", text: "mdi-mailbox-up"},
    {icon: "mdi-mailbox-up-outline", text: "mdi-mailbox-up-outline"},
    {icon: "mdi-map", text: "mdi-map"},
    {icon: "mdi-map-check", text: "mdi-map-check"},
    {icon: "mdi-map-check-outline", text: "mdi-map-check-outline"},
    {icon: "mdi-map-clock", text: "mdi-map-clock"},
    {icon: "mdi-map-clock-outline", text: "mdi-map-clock-outline"},
    {icon: "mdi-map-legend", text: "mdi-map-legend"},
    {icon: "mdi-map-marker", text: "mdi-map-marker"},
    {icon: "mdi-map-marker-alert", text: "mdi-map-marker-alert"},
    {icon: "mdi-map-marker-alert-outline", text: "mdi-map-marker-alert-outline"},
    {icon: "mdi-map-marker-check", text: "mdi-map-marker-check"},
    {icon: "mdi-map-marker-circle", text: "mdi-map-marker-circle"},
    {icon: "mdi-map-marker-distance", text: "mdi-map-marker-distance"},
    {icon: "mdi-map-marker-minus", text: "mdi-map-marker-minus"},
    {icon: "mdi-map-marker-multiple", text: "mdi-map-marker-multiple"},
    {icon: "mdi-map-marker-off", text: "mdi-map-marker-off"},
    {icon: "mdi-map-marker-outline", text: "mdi-map-marker-outline"},
    {icon: "mdi-map-marker-path", text: "mdi-map-marker-path"},
    {icon: "mdi-map-marker-plus", text: "mdi-map-marker-plus"},
    {icon: "mdi-map-marker-question", text: "mdi-map-marker-question"},
    {icon: "mdi-map-marker-question-outline", text: "mdi-map-marker-question-outline"},
    {icon: "mdi-map-marker-radius", text: "mdi-map-marker-radius"},
    {icon: "mdi-map-marker-remove", text: "mdi-map-marker-remove"},
    {icon: "mdi-map-marker-remove-variant", text: "mdi-map-marker-remove-variant"},
    {icon: "mdi-map-minus", text: "mdi-map-minus"},
    {icon: "mdi-map-outline", text: "mdi-map-outline"},
    {icon: "mdi-map-plus", text: "mdi-map-plus"},
    {icon: "mdi-map-search", text: "mdi-map-search"},
    {icon: "mdi-map-search-outline", text: "mdi-map-search-outline"},
    {icon: "mdi-mapbox", text: "mdi-mapbox"},
    {icon: "mdi-margin", text: "mdi-margin"},
    {icon: "mdi-markdown", text: "mdi-markdown"},
    {icon: "mdi-markdown-outline", text: "mdi-markdown-outline"},
    {icon: "mdi-marker", text: "mdi-marker"},
    {icon: "mdi-marker-cancel", text: "mdi-marker-cancel"},
    {icon: "mdi-marker-check", text: "mdi-marker-check"},
    {icon: "mdi-mastodon", text: "mdi-mastodon"},
    {icon: "mdi-mastodon-variant", text: "mdi-mastodon-variant"},
    {icon: "mdi-material-design", text: "mdi-material-design"},
    {icon: "mdi-material-ui", text: "mdi-material-ui"},
    {icon: "mdi-math-compass", text: "mdi-math-compass"},
    {icon: "mdi-math-cos", text: "mdi-math-cos"},
    {icon: "mdi-math-integral", text: "mdi-math-integral"},
    {icon: "mdi-math-integral-box", text: "mdi-math-integral-box"},
    {icon: "mdi-math-norm", text: "mdi-math-norm"},
    {icon: "mdi-math-norm-box", text: "mdi-math-norm-box"},
    {icon: "mdi-math-sin", text: "mdi-math-sin"},
    {icon: "mdi-math-tan", text: "mdi-math-tan"},
    {icon: "mdi-matrix", text: "mdi-matrix"},
    {icon: "mdi-maxcdn", text: "mdi-maxcdn"},
    {icon: "mdi-medal", text: "mdi-medal"},
    {icon: "mdi-medical-bag", text: "mdi-medical-bag"},
    {icon: "mdi-medium", text: "mdi-medium"},
    {icon: "mdi-meetup", text: "mdi-meetup"},
    {icon: "mdi-memory", text: "mdi-memory"},
    {icon: "mdi-menu", text: "mdi-menu"},
    {icon: "mdi-menu-down", text: "mdi-menu-down"},
    {icon: "mdi-menu-down-outline", text: "mdi-menu-down-outline"},
    {icon: "mdi-menu-left", text: "mdi-menu-left"},
    {icon: "mdi-menu-left-outline", text: "mdi-menu-left-outline"},
    {icon: "mdi-menu-open", text: "mdi-menu-open"},
    {icon: "mdi-menu-right", text: "mdi-menu-right"},
    {icon: "mdi-menu-right-outline", text: "mdi-menu-right-outline"},
    {icon: "mdi-menu-swap", text: "mdi-menu-swap"},
    {icon: "mdi-menu-swap-outline", text: "mdi-menu-swap-outline"},
    {icon: "mdi-menu-up", text: "mdi-menu-up"},
    {icon: "mdi-menu-up-outline", text: "mdi-menu-up-outline"},
    {icon: "mdi-merge", text: "mdi-merge"},
    {icon: "mdi-message", text: "mdi-message"},
    {icon: "mdi-message-alert", text: "mdi-message-alert"},
    {icon: "mdi-message-alert-outline", text: "mdi-message-alert-outline"},
    {icon: "mdi-message-bulleted", text: "mdi-message-bulleted"},
    {icon: "mdi-message-bulleted-off", text: "mdi-message-bulleted-off"},
    {icon: "mdi-message-draw", text: "mdi-message-draw"},
    {icon: "mdi-message-image", text: "mdi-message-image"},
    {icon: "mdi-message-lock", text: "mdi-message-lock"},
    {icon: "mdi-message-outline", text: "mdi-message-outline"},
    {icon: "mdi-message-plus", text: "mdi-message-plus"},
    {icon: "mdi-message-processing", text: "mdi-message-processing"},
    {icon: "mdi-message-reply", text: "mdi-message-reply"},
    {icon: "mdi-message-reply-text", text: "mdi-message-reply-text"},
    {icon: "mdi-message-settings", text: "mdi-message-settings"},
    {icon: "mdi-message-settings-variant", text: "mdi-message-settings-variant"},
    {icon: "mdi-message-text", text: "mdi-message-text"},
    {icon: "mdi-message-text-lock", text: "mdi-message-text-lock"},
    {icon: "mdi-message-text-outline", text: "mdi-message-text-outline"},
    {icon: "mdi-message-video", text: "mdi-message-video"},
    {icon: "mdi-meteor", text: "mdi-meteor"},
    {icon: "mdi-metronome", text: "mdi-metronome"},
    {icon: "mdi-metronome-tick", text: "mdi-metronome-tick"},
    {icon: "mdi-micro-sd", text: "mdi-micro-sd"},
    {icon: "mdi-microphone", text: "mdi-microphone"},
    {icon: "mdi-microphone-minus", text: "mdi-microphone-minus"},
    {icon: "mdi-microphone-off", text: "mdi-microphone-off"},
    {icon: "mdi-microphone-outline", text: "mdi-microphone-outline"},
    {icon: "mdi-microphone-plus", text: "mdi-microphone-plus"},
    {icon: "mdi-microphone-settings", text: "mdi-microphone-settings"},
    {icon: "mdi-microphone-variant", text: "mdi-microphone-variant"},
    {icon: "mdi-microphone-variant-off", text: "mdi-microphone-variant-off"},
    {icon: "mdi-microscope", text: "mdi-microscope"},
    {icon: "mdi-microsoft", text: "mdi-microsoft"},
    {icon: "mdi-microsoft-dynamics", text: "mdi-microsoft-dynamics"},
    {icon: "mdi-microwave", text: "mdi-microwave"},
    {icon: "mdi-middleware", text: "mdi-middleware"},
    {icon: "mdi-middleware-outline", text: "mdi-middleware-outline"},
    {icon: "mdi-midi", text: "mdi-midi"},
    {icon: "mdi-midi-port", text: "mdi-midi-port"},
    {icon: "mdi-mine", text: "mdi-mine"},
    {icon: "mdi-minecraft", text: "mdi-minecraft"},
    {icon: "mdi-mini-sd", text: "mdi-mini-sd"},
    {icon: "mdi-minidisc", text: "mdi-minidisc"},
    {icon: "mdi-minus", text: "mdi-minus"},
    {icon: "mdi-minus-box", text: "mdi-minus-box"},
    {icon: "mdi-minus-box-outline", text: "mdi-minus-box-outline"},
    {icon: "mdi-minus-circle", text: "mdi-minus-circle"},
    {icon: "mdi-minus-circle-outline", text: "mdi-minus-circle-outline"},
    {icon: "mdi-minus-network", text: "mdi-minus-network"},
    {icon: "mdi-minus-network-outline", text: "mdi-minus-network-outline"},
    {icon: "mdi-mixcloud", text: "mdi-mixcloud"},
    {icon: "mdi-mixed-martial-arts", text: "mdi-mixed-martial-arts"},
    {icon: "mdi-mixed-reality", text: "mdi-mixed-reality"},
    {icon: "mdi-mixer", text: "mdi-mixer"},
    {icon: "mdi-molecule", text: "mdi-molecule"},
    {icon: "mdi-monitor", text: "mdi-monitor"},
    {icon: "mdi-monitor-cellphone", text: "mdi-monitor-cellphone"},
    {icon: "mdi-monitor-cellphone-star", text: "mdi-monitor-cellphone-star"},
    {icon: "mdi-monitor-dashboard", text: "mdi-monitor-dashboard"},
    {icon: "mdi-monitor-lock", text: "mdi-monitor-lock"},
    {icon: "mdi-monitor-multiple", text: "mdi-monitor-multiple"},
    {icon: "mdi-monitor-off", text: "mdi-monitor-off"},
    {icon: "mdi-monitor-screenshot", text: "mdi-monitor-screenshot"},
    {icon: "mdi-monitor-speaker", text: "mdi-monitor-speaker"},
    {icon: "mdi-monitor-speaker-off", text: "mdi-monitor-speaker-off"},
    {icon: "mdi-monitor-star", text: "mdi-monitor-star"},
    {icon: "mdi-moon-first-quarter", text: "mdi-moon-first-quarter"},
    {icon: "mdi-moon-full", text: "mdi-moon-full"},
    {icon: "mdi-moon-last-quarter", text: "mdi-moon-last-quarter"},
    {icon: "mdi-moon-new", text: "mdi-moon-new"},
    {icon: "mdi-moon-waning-crescent", text: "mdi-moon-waning-crescent"},
    {icon: "mdi-moon-waning-gibbous", text: "mdi-moon-waning-gibbous"},
    {icon: "mdi-moon-waxing-crescent", text: "mdi-moon-waxing-crescent"},
    {icon: "mdi-moon-waxing-gibbous", text: "mdi-moon-waxing-gibbous"},
    {icon: "mdi-more", text: "mdi-more"},
    {icon: "mdi-mother-nurse", text: "mdi-mother-nurse"},
    {icon: "mdi-motion-sensor", text: "mdi-motion-sensor"},
    {icon: "mdi-motorbike", text: "mdi-motorbike"},
    {icon: "mdi-mouse", text: "mdi-mouse"},
    {icon: "mdi-mouse-bluetooth", text: "mdi-mouse-bluetooth"},
    {icon: "mdi-mouse-off", text: "mdi-mouse-off"},
    {icon: "mdi-mouse-variant", text: "mdi-mouse-variant"},
    {icon: "mdi-mouse-variant-off", text: "mdi-mouse-variant-off"},
    {icon: "mdi-move-resize", text: "mdi-move-resize"},
    {icon: "mdi-move-resize-variant", text: "mdi-move-resize-variant"},
    {icon: "mdi-movie", text: "mdi-movie"},
    {icon: "mdi-movie-open", text: "mdi-movie-open"},
    {icon: "mdi-movie-open-outline", text: "mdi-movie-open-outline"},
    {icon: "mdi-movie-outline", text: "mdi-movie-outline"},
    {icon: "mdi-movie-roll", text: "mdi-movie-roll"},
    {icon: "mdi-muffin", text: "mdi-muffin"},
    {icon: "mdi-multiplication", text: "mdi-multiplication"},
    {icon: "mdi-multiplication-box", text: "mdi-multiplication-box"},
    {icon: "mdi-mushroom", text: "mdi-mushroom"},
    {icon: "mdi-mushroom-outline", text: "mdi-mushroom-outline"},
    {icon: "mdi-music", text: "mdi-music"},
    {icon: "mdi-music-accidental-double-flat", text: "mdi-music-accidental-double-flat"},
    {icon: "mdi-music-accidental-double-sharp", text: "mdi-music-accidental-double-sharp"},
    {icon: "mdi-music-accidental-flat", text: "mdi-music-accidental-flat"},
    {icon: "mdi-music-accidental-natural", text: "mdi-music-accidental-natural"},
    {icon: "mdi-music-accidental-sharp", text: "mdi-music-accidental-sharp"},
    {icon: "mdi-music-box", text: "mdi-music-box"},
    {icon: "mdi-music-box-outline", text: "mdi-music-box-outline"},
    {icon: "mdi-music-circle", text: "mdi-music-circle"},
    {icon: "mdi-music-circle-outline", text: "mdi-music-circle-outline"},
    {icon: "mdi-music-clef-alto", text: "mdi-music-clef-alto"},
    {icon: "mdi-music-clef-bass", text: "mdi-music-clef-bass"},
    {icon: "mdi-music-clef-treble", text: "mdi-music-clef-treble"},
    {icon: "mdi-music-note", text: "mdi-music-note"},
    {icon: "mdi-music-note-bluetooth", text: "mdi-music-note-bluetooth"},
    {icon: "mdi-music-note-bluetooth-off", text: "mdi-music-note-bluetooth-off"},
    {icon: "mdi-music-note-eighth", text: "mdi-music-note-eighth"},
    {icon: "mdi-music-note-eighth-dotted", text: "mdi-music-note-eighth-dotted"},
    {icon: "mdi-music-note-half", text: "mdi-music-note-half"},
    {icon: "mdi-music-note-half-dotted", text: "mdi-music-note-half-dotted"},
    {icon: "mdi-music-note-off", text: "mdi-music-note-off"},
    {icon: "mdi-music-note-off-outline", text: "mdi-music-note-off-outline"},
    {icon: "mdi-music-note-outline", text: "mdi-music-note-outline"},
    {icon: "mdi-music-note-plus", text: "mdi-music-note-plus"},
    {icon: "mdi-music-note-quarter", text: "mdi-music-note-quarter"},
    {icon: "mdi-music-note-quarter-dotted", text: "mdi-music-note-quarter-dotted"},
    {icon: "mdi-music-note-sixteenth", text: "mdi-music-note-sixteenth"},
    {icon: "mdi-music-note-sixteenth-dotted", text: "mdi-music-note-sixteenth-dotted"},
    {icon: "mdi-music-note-whole", text: "mdi-music-note-whole"},
    {icon: "mdi-music-note-whole-dotted", text: "mdi-music-note-whole-dotted"},
    {icon: "mdi-music-off", text: "mdi-music-off"},
    {icon: "mdi-music-rest-eighth", text: "mdi-music-rest-eighth"},
    {icon: "mdi-music-rest-half", text: "mdi-music-rest-half"},
    {icon: "mdi-music-rest-quarter", text: "mdi-music-rest-quarter"},
    {icon: "mdi-music-rest-sixteenth", text: "mdi-music-rest-sixteenth"},
    {icon: "mdi-music-rest-whole", text: "mdi-music-rest-whole"},
    {icon: "mdi-nail", text: "mdi-nail"},
    {icon: "mdi-nas", text: "mdi-nas"},
    {icon: "mdi-nativescript", text: "mdi-nativescript"},
    {icon: "mdi-nature", text: "mdi-nature"},
    {icon: "mdi-nature-people", text: "mdi-nature-people"},
    {icon: "mdi-navigation", text: "mdi-navigation"},
    {icon: "mdi-near-me", text: "mdi-near-me"},
    {icon: "mdi-necklace", text: "mdi-necklace"},
    {icon: "mdi-needle", text: "mdi-needle"},
    {icon: "mdi-netflix", text: "mdi-netflix"},
    {icon: "mdi-network", text: "mdi-network"},
    {icon: "mdi-network-off", text: "mdi-network-off"},
    {icon: "mdi-network-off-outline", text: "mdi-network-off-outline"},
    {icon: "mdi-network-outline", text: "mdi-network-outline"},
    {icon: "mdi-network-strength-1", text: "mdi-network-strength-1"},
    {icon: "mdi-network-strength-1-alert", text: "mdi-network-strength-1-alert"},
    {icon: "mdi-network-strength-2", text: "mdi-network-strength-2"},
    {icon: "mdi-network-strength-2-alert", text: "mdi-network-strength-2-alert"},
    {icon: "mdi-network-strength-3", text: "mdi-network-strength-3"},
    {icon: "mdi-network-strength-3-alert", text: "mdi-network-strength-3-alert"},
    {icon: "mdi-network-strength-4", text: "mdi-network-strength-4"},
    {icon: "mdi-network-strength-4-alert", text: "mdi-network-strength-4-alert"},
    {icon: "mdi-network-strength-off", text: "mdi-network-strength-off"},
    {icon: "mdi-network-strength-off-outline", text: "mdi-network-strength-off-outline"},
    {icon: "mdi-network-strength-outline", text: "mdi-network-strength-outline"},
    {icon: "mdi-new-box", text: "mdi-new-box"},
    {icon: "mdi-newspaper", text: "mdi-newspaper"},
    {icon: "mdi-newspaper-minus", text: "mdi-newspaper-minus"},
    {icon: "mdi-newspaper-plus", text: "mdi-newspaper-plus"},
    {icon: "mdi-newspaper-variant", text: "mdi-newspaper-variant"},
    {icon: "mdi-newspaper-variant-multiple", text: "mdi-newspaper-variant-multiple"},
    {icon: "mdi-newspaper-variant-multiple-outline", text: "mdi-newspaper-variant-multiple-outline"},
    {icon: "mdi-newspaper-variant-outline", text: "mdi-newspaper-variant-outline"},
    {icon: "mdi-nfc", text: "mdi-nfc"},
    {icon: "mdi-nfc-off", text: "mdi-nfc-off"},
    {icon: "mdi-nfc-search-variant", text: "mdi-nfc-search-variant"},
    {icon: "mdi-nfc-tap", text: "mdi-nfc-tap"},
    {icon: "mdi-nfc-variant", text: "mdi-nfc-variant"},
    {icon: "mdi-nfc-variant-off", text: "mdi-nfc-variant-off"},
    {icon: "mdi-ninja", text: "mdi-ninja"},
    {icon: "mdi-nintendo-switch", text: "mdi-nintendo-switch"},
    {icon: "mdi-nodejs", text: "mdi-nodejs"},
    {icon: "mdi-not-equal", text: "mdi-not-equal"},
    {icon: "mdi-not-equal-variant", text: "mdi-not-equal-variant"},
    {icon: "mdi-note", text: "mdi-note"},
    {icon: "mdi-note-multiple", text: "mdi-note-multiple"},
    {icon: "mdi-note-multiple-outline", text: "mdi-note-multiple-outline"},
    {icon: "mdi-note-outline", text: "mdi-note-outline"},
    {icon: "mdi-note-plus", text: "mdi-note-plus"},
    {icon: "mdi-note-plus-outline", text: "mdi-note-plus-outline"},
    {icon: "mdi-note-text", text: "mdi-note-text"},
    {icon: "mdi-notebook", text: "mdi-notebook"},
    {icon: "mdi-notebook-multiple", text: "mdi-notebook-multiple"},
    {icon: "mdi-notebook-outline", text: "mdi-notebook-outline"},
    {icon: "mdi-notification-clear-all", text: "mdi-notification-clear-all"},
    {icon: "mdi-npm", text: "mdi-npm"},
    {icon: "mdi-npm-variant", text: "mdi-npm-variant"},
    {icon: "mdi-npm-variant-outline", text: "mdi-npm-variant-outline"},
    {icon: "mdi-nuke", text: "mdi-nuke"},
    {icon: "mdi-null", text: "mdi-null"},
    {icon: "mdi-numeric", text: "mdi-numeric"},
    {icon: "mdi-numeric-0", text: "mdi-numeric-0"},
    {icon: "mdi-numeric-0-box", text: "mdi-numeric-0-box"},
    {icon: "mdi-numeric-0-box-multiple", text: "mdi-numeric-0-box-multiple"},
    {icon: "mdi-numeric-0-box-multiple-outline", text: "mdi-numeric-0-box-multiple-outline"},
    {icon: "mdi-numeric-0-box-outline", text: "mdi-numeric-0-box-outline"},
    {icon: "mdi-numeric-0-circle", text: "mdi-numeric-0-circle"},
    {icon: "mdi-numeric-0-circle-outline", text: "mdi-numeric-0-circle-outline"},
    {icon: "mdi-numeric-1", text: "mdi-numeric-1"},
    {icon: "mdi-numeric-1-box", text: "mdi-numeric-1-box"},
    {icon: "mdi-numeric-1-box-multiple", text: "mdi-numeric-1-box-multiple"},
    {icon: "mdi-numeric-1-box-multiple-outline", text: "mdi-numeric-1-box-multiple-outline"},
    {icon: "mdi-numeric-1-box-outline", text: "mdi-numeric-1-box-outline"},
    {icon: "mdi-numeric-1-circle", text: "mdi-numeric-1-circle"},
    {icon: "mdi-numeric-1-circle-outline", text: "mdi-numeric-1-circle-outline"},
    {icon: "mdi-numeric-10", text: "mdi-numeric-10"},
    {icon: "mdi-numeric-10-box", text: "mdi-numeric-10-box"},
    {icon: "mdi-numeric-10-box-multiple", text: "mdi-numeric-10-box-multiple"},
    {icon: "mdi-numeric-10-box-multiple-outline", text: "mdi-numeric-10-box-multiple-outline"},
    {icon: "mdi-numeric-10-box-outline", text: "mdi-numeric-10-box-outline"},
    {icon: "mdi-numeric-10-circle", text: "mdi-numeric-10-circle"},
    {icon: "mdi-numeric-10-circle-outline", text: "mdi-numeric-10-circle-outline"},
    {icon: "mdi-numeric-2", text: "mdi-numeric-2"},
    {icon: "mdi-numeric-2-box", text: "mdi-numeric-2-box"},
    {icon: "mdi-numeric-2-box-multiple", text: "mdi-numeric-2-box-multiple"},
    {icon: "mdi-numeric-2-box-multiple-outline", text: "mdi-numeric-2-box-multiple-outline"},
    {icon: "mdi-numeric-2-box-outline", text: "mdi-numeric-2-box-outline"},
    {icon: "mdi-numeric-2-circle", text: "mdi-numeric-2-circle"},
    {icon: "mdi-numeric-2-circle-outline", text: "mdi-numeric-2-circle-outline"},
    {icon: "mdi-numeric-3", text: "mdi-numeric-3"},
    {icon: "mdi-numeric-3-box", text: "mdi-numeric-3-box"},
    {icon: "mdi-numeric-3-box-multiple", text: "mdi-numeric-3-box-multiple"},
    {icon: "mdi-numeric-3-box-multiple-outline", text: "mdi-numeric-3-box-multiple-outline"},
    {icon: "mdi-numeric-3-box-outline", text: "mdi-numeric-3-box-outline"},
    {icon: "mdi-numeric-3-circle", text: "mdi-numeric-3-circle"},
    {icon: "mdi-numeric-3-circle-outline", text: "mdi-numeric-3-circle-outline"},
    {icon: "mdi-numeric-4", text: "mdi-numeric-4"},
    {icon: "mdi-numeric-4-box", text: "mdi-numeric-4-box"},
    {icon: "mdi-numeric-4-box-multiple", text: "mdi-numeric-4-box-multiple"},
    {icon: "mdi-numeric-4-box-multiple-outline", text: "mdi-numeric-4-box-multiple-outline"},
    {icon: "mdi-numeric-4-box-outline", text: "mdi-numeric-4-box-outline"},
    {icon: "mdi-numeric-4-circle", text: "mdi-numeric-4-circle"},
    {icon: "mdi-numeric-4-circle-outline", text: "mdi-numeric-4-circle-outline"},
    {icon: "mdi-numeric-5", text: "mdi-numeric-5"},
    {icon: "mdi-numeric-5-box", text: "mdi-numeric-5-box"},
    {icon: "mdi-numeric-5-box-multiple", text: "mdi-numeric-5-box-multiple"},
    {icon: "mdi-numeric-5-box-multiple-outline", text: "mdi-numeric-5-box-multiple-outline"},
    {icon: "mdi-numeric-5-box-outline", text: "mdi-numeric-5-box-outline"},
    {icon: "mdi-numeric-5-circle", text: "mdi-numeric-5-circle"},
    {icon: "mdi-numeric-5-circle-outline", text: "mdi-numeric-5-circle-outline"},
    {icon: "mdi-numeric-6", text: "mdi-numeric-6"},
    {icon: "mdi-numeric-6-box", text: "mdi-numeric-6-box"},
    {icon: "mdi-numeric-6-box-multiple", text: "mdi-numeric-6-box-multiple"},
    {icon: "mdi-numeric-6-box-multiple-outline", text: "mdi-numeric-6-box-multiple-outline"},
    {icon: "mdi-numeric-6-box-outline", text: "mdi-numeric-6-box-outline"},
    {icon: "mdi-numeric-6-circle", text: "mdi-numeric-6-circle"},
    {icon: "mdi-numeric-6-circle-outline", text: "mdi-numeric-6-circle-outline"},
    {icon: "mdi-numeric-7", text: "mdi-numeric-7"},
    {icon: "mdi-numeric-7-box", text: "mdi-numeric-7-box"},
    {icon: "mdi-numeric-7-box-multiple", text: "mdi-numeric-7-box-multiple"},
    {icon: "mdi-numeric-7-box-multiple-outline", text: "mdi-numeric-7-box-multiple-outline"},
    {icon: "mdi-numeric-7-box-outline", text: "mdi-numeric-7-box-outline"},
    {icon: "mdi-numeric-7-circle", text: "mdi-numeric-7-circle"},
    {icon: "mdi-numeric-7-circle-outline", text: "mdi-numeric-7-circle-outline"},
    {icon: "mdi-numeric-8", text: "mdi-numeric-8"},
    {icon: "mdi-numeric-8-box", text: "mdi-numeric-8-box"},
    {icon: "mdi-numeric-8-box-multiple", text: "mdi-numeric-8-box-multiple"},
    {icon: "mdi-numeric-8-box-multiple-outline", text: "mdi-numeric-8-box-multiple-outline"},
    {icon: "mdi-numeric-8-box-outline", text: "mdi-numeric-8-box-outline"},
    {icon: "mdi-numeric-8-circle", text: "mdi-numeric-8-circle"},
    {icon: "mdi-numeric-8-circle-outline", text: "mdi-numeric-8-circle-outline"},
    {icon: "mdi-numeric-9", text: "mdi-numeric-9"},
    {icon: "mdi-numeric-9-box", text: "mdi-numeric-9-box"},
    {icon: "mdi-numeric-9-box-multiple", text: "mdi-numeric-9-box-multiple"},
    {icon: "mdi-numeric-9-box-multiple-outline", text: "mdi-numeric-9-box-multiple-outline"},
    {icon: "mdi-numeric-9-box-outline", text: "mdi-numeric-9-box-outline"},
    {icon: "mdi-numeric-9-circle", text: "mdi-numeric-9-circle"},
    {icon: "mdi-numeric-9-circle-outline", text: "mdi-numeric-9-circle-outline"},
    {icon: "mdi-numeric-9-plus", text: "mdi-numeric-9-plus"},
    {icon: "mdi-numeric-9-plus-box", text: "mdi-numeric-9-plus-box"},
    {icon: "mdi-numeric-9-plus-box-multiple", text: "mdi-numeric-9-plus-box-multiple"},
    {icon: "mdi-numeric-9-plus-box-multiple-outline", text: "mdi-numeric-9-plus-box-multiple-outline"},
    {icon: "mdi-numeric-9-plus-box-outline", text: "mdi-numeric-9-plus-box-outline"},
    {icon: "mdi-numeric-9-plus-circle", text: "mdi-numeric-9-plus-circle"},
    {icon: "mdi-numeric-9-plus-circle-outline", text: "mdi-numeric-9-plus-circle-outline"},
    {icon: "mdi-numeric-negative-1", text: "mdi-numeric-negative-1"},
    {icon: "mdi-nut", text: "mdi-nut"},
    {icon: "mdi-nutrition", text: "mdi-nutrition"},
    {icon: "mdi-oar", text: "mdi-oar"},
    {icon: "mdi-ocarina", text: "mdi-ocarina"},
    {icon: "mdi-octagon", text: "mdi-octagon"},
    {icon: "mdi-octagon-outline", text: "mdi-octagon-outline"},
    {icon: "mdi-octagram", text: "mdi-octagram"},
    {icon: "mdi-octagram-outline", text: "mdi-octagram-outline"},
    {icon: "mdi-odnoklassniki", text: "mdi-odnoklassniki"},
    {icon: "mdi-office", text: "mdi-office"},
    {icon: "mdi-office-building", text: "mdi-office-building"},
    {icon: "mdi-oil", text: "mdi-oil"},
    {icon: "mdi-oil-lamp", text: "mdi-oil-lamp"},
    {icon: "mdi-oil-level", text: "mdi-oil-level"},
    {icon: "mdi-oil-temperature", text: "mdi-oil-temperature"},
    {icon: "mdi-omega", text: "mdi-omega"},
    {icon: "mdi-one-up", text: "mdi-one-up"},
    {icon: "mdi-onedrive", text: "mdi-onedrive"},
    {icon: "mdi-onenote", text: "mdi-onenote"},
    {icon: "mdi-onepassword", text: "mdi-onepassword"},
    {icon: "mdi-opacity", text: "mdi-opacity"},
    {icon: "mdi-open-in-app", text: "mdi-open-in-app"},
    {icon: "mdi-open-in-new", text: "mdi-open-in-new"},
    {icon: "mdi-open-source-initiative", text: "mdi-open-source-initiative"},
    {icon: "mdi-openid", text: "mdi-openid"},
    {icon: "mdi-opera", text: "mdi-opera"},
    {icon: "mdi-orbit", text: "mdi-orbit"},
    {icon: "mdi-origin", text: "mdi-origin"},
    {icon: "mdi-ornament", text: "mdi-ornament"},
    {icon: "mdi-ornament-variant", text: "mdi-ornament-variant"},
    {icon: "mdi-outdoor-lamp", text: "mdi-outdoor-lamp"},
    {icon: "mdi-outlook", text: "mdi-outlook"},
    {icon: "mdi-overscan", text: "mdi-overscan"},
    {icon: "mdi-owl", text: "mdi-owl"},
    {icon: "mdi-pac-man", text: "mdi-pac-man"},
    {icon: "mdi-package", text: "mdi-package"},
    {icon: "mdi-package-down", text: "mdi-package-down"},
    {icon: "mdi-package-up", text: "mdi-package-up"},
    {icon: "mdi-package-variant", text: "mdi-package-variant"},
    {icon: "mdi-package-variant-closed", text: "mdi-package-variant-closed"},
    {icon: "mdi-page-first", text: "mdi-page-first"},
    {icon: "mdi-page-last", text: "mdi-page-last"},
    {icon: "mdi-page-layout-body", text: "mdi-page-layout-body"},
    {icon: "mdi-page-layout-footer", text: "mdi-page-layout-footer"},
    {icon: "mdi-page-layout-header", text: "mdi-page-layout-header"},
    {icon: "mdi-page-layout-header-footer", text: "mdi-page-layout-header-footer"},
    {icon: "mdi-page-layout-sidebar-left", text: "mdi-page-layout-sidebar-left"},
    {icon: "mdi-page-layout-sidebar-right", text: "mdi-page-layout-sidebar-right"},
    {icon: "mdi-page-next", text: "mdi-page-next"},
    {icon: "mdi-page-next-outline", text: "mdi-page-next-outline"},
    {icon: "mdi-page-previous", text: "mdi-page-previous"},
    {icon: "mdi-page-previous-outline", text: "mdi-page-previous-outline"},
    {icon: "mdi-palette", text: "mdi-palette"},
    {icon: "mdi-palette-advanced", text: "mdi-palette-advanced"},
    {icon: "mdi-palette-outline", text: "mdi-palette-outline"},
    {icon: "mdi-palette-swatch", text: "mdi-palette-swatch"},
    {icon: "mdi-palm-tree", text: "mdi-palm-tree"},
    {icon: "mdi-pan", text: "mdi-pan"},
    {icon: "mdi-pan-bottom-left", text: "mdi-pan-bottom-left"},
    {icon: "mdi-pan-bottom-right", text: "mdi-pan-bottom-right"},
    {icon: "mdi-pan-down", text: "mdi-pan-down"},
    {icon: "mdi-pan-horizontal", text: "mdi-pan-horizontal"},
    {icon: "mdi-pan-left", text: "mdi-pan-left"},
    {icon: "mdi-pan-right", text: "mdi-pan-right"},
    {icon: "mdi-pan-top-left", text: "mdi-pan-top-left"},
    {icon: "mdi-pan-top-right", text: "mdi-pan-top-right"},
    {icon: "mdi-pan-up", text: "mdi-pan-up"},
    {icon: "mdi-pan-vertical", text: "mdi-pan-vertical"},
    {icon: "mdi-panda", text: "mdi-panda"},
    {icon: "mdi-pandora", text: "mdi-pandora"},
    {icon: "mdi-panorama", text: "mdi-panorama"},
    {icon: "mdi-panorama-fisheye", text: "mdi-panorama-fisheye"},
    {icon: "mdi-panorama-horizontal", text: "mdi-panorama-horizontal"},
    {icon: "mdi-panorama-vertical", text: "mdi-panorama-vertical"},
    {icon: "mdi-panorama-wide-angle", text: "mdi-panorama-wide-angle"},
    {icon: "mdi-paper-cut-vertical", text: "mdi-paper-cut-vertical"},
    {icon: "mdi-paperclip", text: "mdi-paperclip"},
    {icon: "mdi-parachute", text: "mdi-parachute"},
    {icon: "mdi-parachute-outline", text: "mdi-parachute-outline"},
    {icon: "mdi-parking", text: "mdi-parking"},
    {icon: "mdi-party-popper", text: "mdi-party-popper"},
    {icon: "mdi-passport", text: "mdi-passport"},
    {icon: "mdi-passport-biometric", text: "mdi-passport-biometric"},
    {icon: "mdi-patio-heater", text: "mdi-patio-heater"},
    {icon: "mdi-patreon", text: "mdi-patreon"},
    {icon: "mdi-pause", text: "mdi-pause"},
    {icon: "mdi-pause-circle", text: "mdi-pause-circle"},
    {icon: "mdi-pause-circle-outline", text: "mdi-pause-circle-outline"},
    {icon: "mdi-pause-octagon", text: "mdi-pause-octagon"},
    {icon: "mdi-pause-octagon-outline", text: "mdi-pause-octagon-outline"},
    {icon: "mdi-paw", text: "mdi-paw"},
    {icon: "mdi-paw-off", text: "mdi-paw-off"},
    {icon: "mdi-paypal", text: "mdi-paypal"},
    {icon: "mdi-pdf-box", text: "mdi-pdf-box"},
    {icon: "mdi-peace", text: "mdi-peace"},
    {icon: "mdi-peanut", text: "mdi-peanut"},
    {icon: "mdi-peanut-off", text: "mdi-peanut-off"},
    {icon: "mdi-peanut-off-outline", text: "mdi-peanut-off-outline"},
    {icon: "mdi-peanut-outline", text: "mdi-peanut-outline"},
    {icon: "mdi-pen", text: "mdi-pen"},
    {icon: "mdi-pen-lock", text: "mdi-pen-lock"},
    {icon: "mdi-pen-minus", text: "mdi-pen-minus"},
    {icon: "mdi-pen-off", text: "mdi-pen-off"},
    {icon: "mdi-pen-plus", text: "mdi-pen-plus"},
    {icon: "mdi-pen-remove", text: "mdi-pen-remove"},
    {icon: "mdi-pencil", text: "mdi-pencil"},
    {icon: "mdi-pencil-box", text: "mdi-pencil-box"},
    {icon: "mdi-pencil-box-outline", text: "mdi-pencil-box-outline"},
    {icon: "mdi-pencil-circle", text: "mdi-pencil-circle"},
    {icon: "mdi-pencil-circle-outline", text: "mdi-pencil-circle-outline"},
    {icon: "mdi-pencil-lock", text: "mdi-pencil-lock"},
    {icon: "mdi-pencil-lock-outline", text: "mdi-pencil-lock-outline"},
    {icon: "mdi-pencil-minus", text: "mdi-pencil-minus"},
    {icon: "mdi-pencil-minus-outline", text: "mdi-pencil-minus-outline"},
    {icon: "mdi-pencil-off", text: "mdi-pencil-off"},
    {icon: "mdi-pencil-off-outline", text: "mdi-pencil-off-outline"},
    {icon: "mdi-pencil-outline", text: "mdi-pencil-outline"},
    {icon: "mdi-pencil-plus", text: "mdi-pencil-plus"},
    {icon: "mdi-pencil-plus-outline", text: "mdi-pencil-plus-outline"},
    {icon: "mdi-pencil-remove", text: "mdi-pencil-remove"},
    {icon: "mdi-pencil-remove-outline", text: "mdi-pencil-remove-outline"},
    {icon: "mdi-penguin", text: "mdi-penguin"},
    {icon: "mdi-pentagon", text: "mdi-pentagon"},
    {icon: "mdi-pentagon-outline", text: "mdi-pentagon-outline"},
    {icon: "mdi-percent", text: "mdi-percent"},
    {icon: "mdi-periodic-table", text: "mdi-periodic-table"},
    {icon: "mdi-periodic-table-co2", text: "mdi-periodic-table-co2"},
    {icon: "mdi-periscope", text: "mdi-periscope"},
    {icon: "mdi-perspective-less", text: "mdi-perspective-less"},
    {icon: "mdi-perspective-more", text: "mdi-perspective-more"},
    {icon: "mdi-pharmacy", text: "mdi-pharmacy"},
    {icon: "mdi-phone", text: "mdi-phone"},
    {icon: "mdi-phone-alert", text: "mdi-phone-alert"},
    {icon: "mdi-phone-bluetooth", text: "mdi-phone-bluetooth"},
    {icon: "mdi-phone-classic", text: "mdi-phone-classic"},
    {icon: "mdi-phone-forward", text: "mdi-phone-forward"},
    {icon: "mdi-phone-hangup", text: "mdi-phone-hangup"},
    {icon: "mdi-phone-in-talk", text: "mdi-phone-in-talk"},
    {icon: "mdi-phone-incoming", text: "mdi-phone-incoming"},
    {icon: "mdi-phone-lock", text: "mdi-phone-lock"},
    {icon: "mdi-phone-log", text: "mdi-phone-log"},
    {icon: "mdi-phone-minus", text: "mdi-phone-minus"},
    {icon: "mdi-phone-missed", text: "mdi-phone-missed"},
    {icon: "mdi-phone-off", text: "mdi-phone-off"},
    {icon: "mdi-phone-outgoing", text: "mdi-phone-outgoing"},
    {icon: "mdi-phone-outline", text: "mdi-phone-outline"},
    {icon: "mdi-phone-paused", text: "mdi-phone-paused"},
    {icon: "mdi-phone-plus", text: "mdi-phone-plus"},
    {icon: "mdi-phone-return", text: "mdi-phone-return"},
    {icon: "mdi-phone-rotate-landscape", text: "mdi-phone-rotate-landscape"},
    {icon: "mdi-phone-rotate-portrait", text: "mdi-phone-rotate-portrait"},
    {icon: "mdi-phone-settings", text: "mdi-phone-settings"},
    {icon: "mdi-phone-voip", text: "mdi-phone-voip"},
    {icon: "mdi-pi", text: "mdi-pi"},
    {icon: "mdi-pi-box", text: "mdi-pi-box"},
    {icon: "mdi-pi-hole", text: "mdi-pi-hole"},
    {icon: "mdi-piano", text: "mdi-piano"},
    {icon: "mdi-pickaxe", text: "mdi-pickaxe"},
    {icon: "mdi-picture-in-picture-bottom-right", text: "mdi-picture-in-picture-bottom-right"},
    {icon: "mdi-picture-in-picture-bottom-right-outline", text: "mdi-picture-in-picture-bottom-right-outline"},
    {icon: "mdi-picture-in-picture-top-right", text: "mdi-picture-in-picture-top-right"},
    {icon: "mdi-picture-in-picture-top-right-outline", text: "mdi-picture-in-picture-top-right-outline"},
    {icon: "mdi-pier", text: "mdi-pier"},
    {icon: "mdi-pier-crane", text: "mdi-pier-crane"},
    {icon: "mdi-pig", text: "mdi-pig"},
    {icon: "mdi-pig-variant", text: "mdi-pig-variant"},
    {icon: "mdi-piggy-bank", text: "mdi-piggy-bank"},
    {icon: "mdi-pill", text: "mdi-pill"},
    {icon: "mdi-pillar", text: "mdi-pillar"},
    {icon: "mdi-pin", text: "mdi-pin"},
    {icon: "mdi-pin-off", text: "mdi-pin-off"},
    {icon: "mdi-pin-off-outline", text: "mdi-pin-off-outline"},
    {icon: "mdi-pin-outline", text: "mdi-pin-outline"},
    {icon: "mdi-pine-tree", text: "mdi-pine-tree"},
    {icon: "mdi-pine-tree-box", text: "mdi-pine-tree-box"},
    {icon: "mdi-pinterest", text: "mdi-pinterest"},
    {icon: "mdi-pinterest-box", text: "mdi-pinterest-box"},
    {icon: "mdi-pinwheel", text: "mdi-pinwheel"},
    {icon: "mdi-pinwheel-outline", text: "mdi-pinwheel-outline"},
    {icon: "mdi-pipe", text: "mdi-pipe"},
    {icon: "mdi-pipe-disconnected", text: "mdi-pipe-disconnected"},
    {icon: "mdi-pipe-leak", text: "mdi-pipe-leak"},
    {icon: "mdi-pirate", text: "mdi-pirate"},
    {icon: "mdi-pistol", text: "mdi-pistol"},
    {icon: "mdi-piston", text: "mdi-piston"},
    {icon: "mdi-pizza", text: "mdi-pizza"},
    {icon: "mdi-play", text: "mdi-play"},
    {icon: "mdi-play-box-outline", text: "mdi-play-box-outline"},
    {icon: "mdi-play-circle", text: "mdi-play-circle"},
    {icon: "mdi-play-circle-outline", text: "mdi-play-circle-outline"},
    {icon: "mdi-play-network", text: "mdi-play-network"},
    {icon: "mdi-play-network-outline", text: "mdi-play-network-outline"},
    {icon: "mdi-play-outline", text: "mdi-play-outline"},
    {icon: "mdi-play-pause", text: "mdi-play-pause"},
    {icon: "mdi-play-protected-content", text: "mdi-play-protected-content"},
    {icon: "mdi-play-speed", text: "mdi-play-speed"},
    {icon: "mdi-playlist-check", text: "mdi-playlist-check"},
    {icon: "mdi-playlist-edit", text: "mdi-playlist-edit"},
    {icon: "mdi-playlist-minus", text: "mdi-playlist-minus"},
    {icon: "mdi-playlist-music", text: "mdi-playlist-music"},
    {icon: "mdi-playlist-music-outline", text: "mdi-playlist-music-outline"},
    {icon: "mdi-playlist-play", text: "mdi-playlist-play"},
    {icon: "mdi-playlist-plus", text: "mdi-playlist-plus"},
    {icon: "mdi-playlist-remove", text: "mdi-playlist-remove"},
    {icon: "mdi-playlist-star", text: "mdi-playlist-star"},
    {icon: "mdi-playstation", text: "mdi-playstation"},
    {icon: "mdi-plex", text: "mdi-plex"},
    {icon: "mdi-plus", text: "mdi-plus"},
    {icon: "mdi-plus-box", text: "mdi-plus-box"},
    {icon: "mdi-plus-box-multiple", text: "mdi-plus-box-multiple"},
    {icon: "mdi-plus-box-outline", text: "mdi-plus-box-outline"},
    {icon: "mdi-plus-circle", text: "mdi-plus-circle"},
    {icon: "mdi-plus-circle-multiple-outline", text: "mdi-plus-circle-multiple-outline"},
    {icon: "mdi-plus-circle-outline", text: "mdi-plus-circle-outline"},
    {icon: "mdi-plus-minus", text: "mdi-plus-minus"},
    {icon: "mdi-plus-minus-box", text: "mdi-plus-minus-box"},
    {icon: "mdi-plus-network", text: "mdi-plus-network"},
    {icon: "mdi-plus-network-outline", text: "mdi-plus-network-outline"},
    {icon: "mdi-plus-one", text: "mdi-plus-one"},
    {icon: "mdi-plus-outline", text: "mdi-plus-outline"},
    {icon: "mdi-pocket", text: "mdi-pocket"},
    {icon: "mdi-podcast", text: "mdi-podcast"},
    {icon: "mdi-podium", text: "mdi-podium"},
    {icon: "mdi-podium-bronze", text: "mdi-podium-bronze"},
    {icon: "mdi-podium-gold", text: "mdi-podium-gold"},
    {icon: "mdi-podium-silver", text: "mdi-podium-silver"},
    {icon: "mdi-point-of-sale", text: "mdi-point-of-sale"},
    {icon: "mdi-pokeball", text: "mdi-pokeball"},
    {icon: "mdi-pokemon-go", text: "mdi-pokemon-go"},
    {icon: "mdi-poker-chip", text: "mdi-poker-chip"},
    {icon: "mdi-polaroid", text: "mdi-polaroid"},
    {icon: "mdi-poll", text: "mdi-poll"},
    {icon: "mdi-poll-box", text: "mdi-poll-box"},
    {icon: "mdi-polymer", text: "mdi-polymer"},
    {icon: "mdi-pool", text: "mdi-pool"},
    {icon: "mdi-popcorn", text: "mdi-popcorn"},
    {icon: "mdi-post", text: "mdi-post"},
    {icon: "mdi-post-outline", text: "mdi-post-outline"},
    {icon: "mdi-postage-stamp", text: "mdi-postage-stamp"},
    {icon: "mdi-pot", text: "mdi-pot"},
    {icon: "mdi-pot-mix", text: "mdi-pot-mix"},
    {icon: "mdi-pound", text: "mdi-pound"},
    {icon: "mdi-pound-box", text: "mdi-pound-box"},
    {icon: "mdi-power", text: "mdi-power"},
    {icon: "mdi-power-cycle", text: "mdi-power-cycle"},
    {icon: "mdi-power-off", text: "mdi-power-off"},
    {icon: "mdi-power-on", text: "mdi-power-on"},
    {icon: "mdi-power-plug", text: "mdi-power-plug"},
    {icon: "mdi-power-plug-off", text: "mdi-power-plug-off"},
    {icon: "mdi-power-settings", text: "mdi-power-settings"},
    {icon: "mdi-power-sleep", text: "mdi-power-sleep"},
    {icon: "mdi-power-socket", text: "mdi-power-socket"},
    {icon: "mdi-power-socket-au", text: "mdi-power-socket-au"},
    {icon: "mdi-power-socket-eu", text: "mdi-power-socket-eu"},
    {icon: "mdi-power-socket-uk", text: "mdi-power-socket-uk"},
    {icon: "mdi-power-socket-us", text: "mdi-power-socket-us"},
    {icon: "mdi-power-standby", text: "mdi-power-standby"},
    {icon: "mdi-powershell", text: "mdi-powershell"},
    {icon: "mdi-prescription", text: "mdi-prescription"},
    {icon: "mdi-presentation", text: "mdi-presentation"},
    {icon: "mdi-presentation-play", text: "mdi-presentation-play"},
    {icon: "mdi-printer", text: "mdi-printer"},
    {icon: "mdi-printer-3d", text: "mdi-printer-3d"},
    {icon: "mdi-printer-3d-nozzle", text: "mdi-printer-3d-nozzle"},
    {icon: "mdi-printer-3d-nozzle-outline", text: "mdi-printer-3d-nozzle-outline"},
    {icon: "mdi-printer-alert", text: "mdi-printer-alert"},
    {icon: "mdi-printer-off", text: "mdi-printer-off"},
    {icon: "mdi-printer-pos", text: "mdi-printer-pos"},
    {icon: "mdi-printer-settings", text: "mdi-printer-settings"},
    {icon: "mdi-printer-wireless", text: "mdi-printer-wireless"},
    {icon: "mdi-priority-high", text: "mdi-priority-high"},
    {icon: "mdi-priority-low", text: "mdi-priority-low"},
    {icon: "mdi-professional-hexagon", text: "mdi-professional-hexagon"},
    {icon: "mdi-progress-alert", text: "mdi-progress-alert"},
    {icon: "mdi-progress-check", text: "mdi-progress-check"},
    {icon: "mdi-progress-clock", text: "mdi-progress-clock"},
    {icon: "mdi-progress-download", text: "mdi-progress-download"},
    {icon: "mdi-progress-upload", text: "mdi-progress-upload"},
    {icon: "mdi-progress-wrench", text: "mdi-progress-wrench"},
    {icon: "mdi-projector", text: "mdi-projector"},
    {icon: "mdi-projector-screen", text: "mdi-projector-screen"},
    {icon: "mdi-protocol", text: "mdi-protocol"},
    {icon: "mdi-publish", text: "mdi-publish"},
    {icon: "mdi-pulse", text: "mdi-pulse"},
    {icon: "mdi-pumpkin", text: "mdi-pumpkin"},
    {icon: "mdi-purse", text: "mdi-purse"},
    {icon: "mdi-purse-outline", text: "mdi-purse-outline"},
    {icon: "mdi-puzzle", text: "mdi-puzzle"},
    {icon: "mdi-puzzle-outline", text: "mdi-puzzle-outline"},
    {icon: "mdi-qi", text: "mdi-qi"},
    {icon: "mdi-qqchat", text: "mdi-qqchat"},
    {icon: "mdi-qrcode", text: "mdi-qrcode"},
    {icon: "mdi-qrcode-edit", text: "mdi-qrcode-edit"},
    {icon: "mdi-qrcode-scan", text: "mdi-qrcode-scan"},
    {icon: "mdi-quadcopter", text: "mdi-quadcopter"},
    {icon: "mdi-quality-high", text: "mdi-quality-high"},
    {icon: "mdi-quality-low", text: "mdi-quality-low"},
    {icon: "mdi-quality-medium", text: "mdi-quality-medium"},
    {icon: "mdi-quicktime", text: "mdi-quicktime"},
    {icon: "mdi-quora", text: "mdi-quora"},
    {icon: "mdi-rabbit", text: "mdi-rabbit"},
    {icon: "mdi-racing-helmet", text: "mdi-racing-helmet"},
    {icon: "mdi-racquetball", text: "mdi-racquetball"},
    {icon: "mdi-radar", text: "mdi-radar"},
    {icon: "mdi-radiator", text: "mdi-radiator"},
    {icon: "mdi-radiator-disabled", text: "mdi-radiator-disabled"},
    {icon: "mdi-radiator-off", text: "mdi-radiator-off"},
    {icon: "mdi-radio", text: "mdi-radio"},
    {icon: "mdi-radio-am", text: "mdi-radio-am"},
    {icon: "mdi-radio-fm", text: "mdi-radio-fm"},
    {icon: "mdi-radio-handheld", text: "mdi-radio-handheld"},
    {icon: "mdi-radio-tower", text: "mdi-radio-tower"},
    {icon: "mdi-radioactive", text: "mdi-radioactive"},
    {icon: "mdi-radioactive-off", text: "mdi-radioactive-off"},
    {icon: "mdi-radiobox-blank", text: "mdi-radiobox-blank"},
    {icon: "mdi-radiobox-marked", text: "mdi-radiobox-marked"},
    {icon: "mdi-radius", text: "mdi-radius"},
    {icon: "mdi-radius-outline", text: "mdi-radius-outline"},
    {icon: "mdi-railroad-light", text: "mdi-railroad-light"},
    {icon: "mdi-raspberry-pi", text: "mdi-raspberry-pi"},
    {icon: "mdi-ray-end", text: "mdi-ray-end"},
    {icon: "mdi-ray-end-arrow", text: "mdi-ray-end-arrow"},
    {icon: "mdi-ray-start", text: "mdi-ray-start"},
    {icon: "mdi-ray-start-arrow", text: "mdi-ray-start-arrow"},
    {icon: "mdi-ray-start-end", text: "mdi-ray-start-end"},
    {icon: "mdi-ray-vertex", text: "mdi-ray-vertex"},
    {icon: "mdi-react", text: "mdi-react"},
    {icon: "mdi-read", text: "mdi-read"},
    {icon: "mdi-receipt", text: "mdi-receipt"},
    {icon: "mdi-record", text: "mdi-record"},
    {icon: "mdi-record-circle", text: "mdi-record-circle"},
    {icon: "mdi-record-circle-outline", text: "mdi-record-circle-outline"},
    {icon: "mdi-record-player", text: "mdi-record-player"},
    {icon: "mdi-record-rec", text: "mdi-record-rec"},
    {icon: "mdi-rectangle", text: "mdi-rectangle"},
    {icon: "mdi-rectangle-outline", text: "mdi-rectangle-outline"},
    {icon: "mdi-recycle", text: "mdi-recycle"},
    {icon: "mdi-reddit", text: "mdi-reddit"},
    {icon: "mdi-redo", text: "mdi-redo"},
    {icon: "mdi-redo-variant", text: "mdi-redo-variant"},
    {icon: "mdi-reflect-horizontal", text: "mdi-reflect-horizontal"},
    {icon: "mdi-reflect-vertical", text: "mdi-reflect-vertical"},
    {icon: "mdi-refresh", text: "mdi-refresh"},
    {icon: "mdi-regex", text: "mdi-regex"},
    {icon: "mdi-registered-trademark", text: "mdi-registered-trademark"},
    {icon: "mdi-relative-scale", text: "mdi-relative-scale"},
    {icon: "mdi-reload", text: "mdi-reload"},
    {icon: "mdi-reminder", text: "mdi-reminder"},
    {icon: "mdi-remote", text: "mdi-remote"},
    {icon: "mdi-remote-desktop", text: "mdi-remote-desktop"},
    {icon: "mdi-remote-off", text: "mdi-remote-off"},
    {icon: "mdi-remote-tv", text: "mdi-remote-tv"},
    {icon: "mdi-remote-tv-off", text: "mdi-remote-tv-off"},
    {icon: "mdi-rename-box", text: "mdi-rename-box"},
    {icon: "mdi-reorder-horizontal", text: "mdi-reorder-horizontal"},
    {icon: "mdi-reorder-vertical", text: "mdi-reorder-vertical"},
    {icon: "mdi-repeat", text: "mdi-repeat"},
    {icon: "mdi-repeat-off", text: "mdi-repeat-off"},
    {icon: "mdi-repeat-once", text: "mdi-repeat-once"},
    {icon: "mdi-replay", text: "mdi-replay"},
    {icon: "mdi-reply", text: "mdi-reply"},
    {icon: "mdi-reply-all", text: "mdi-reply-all"},
    {icon: "mdi-reply-all-outline", text: "mdi-reply-all-outline"},
    {icon: "mdi-reply-outline", text: "mdi-reply-outline"},
    {icon: "mdi-reproduction", text: "mdi-reproduction"},
    {icon: "mdi-resistor", text: "mdi-resistor"},
    {icon: "mdi-resistor-nodes", text: "mdi-resistor-nodes"},
    {icon: "mdi-resize", text: "mdi-resize"},
    {icon: "mdi-resize-bottom-right", text: "mdi-resize-bottom-right"},
    {icon: "mdi-responsive", text: "mdi-responsive"},
    {icon: "mdi-restart", text: "mdi-restart"},
    {icon: "mdi-restart-off", text: "mdi-restart-off"},
    {icon: "mdi-restore", text: "mdi-restore"},
    {icon: "mdi-rewind", text: "mdi-rewind"},
    {icon: "mdi-rewind-10", text: "mdi-rewind-10"},
    {icon: "mdi-rewind-30", text: "mdi-rewind-30"},
    {icon: "mdi-rewind-outline", text: "mdi-rewind-outline"},
    {icon: "mdi-rhombus", text: "mdi-rhombus"},
    {icon: "mdi-rhombus-medium", text: "mdi-rhombus-medium"},
    {icon: "mdi-rhombus-outline", text: "mdi-rhombus-outline"},
    {icon: "mdi-rhombus-split", text: "mdi-rhombus-split"},
    {icon: "mdi-ribbon", text: "mdi-ribbon"},
    {icon: "mdi-rice", text: "mdi-rice"},
    {icon: "mdi-ring", text: "mdi-ring"},
    {icon: "mdi-rivet", text: "mdi-rivet"},
    {icon: "mdi-road", text: "mdi-road"},
    {icon: "mdi-road-variant", text: "mdi-road-variant"},
    {icon: "mdi-robber", text: "mdi-robber"},
    {icon: "mdi-robot", text: "mdi-robot"},
    {icon: "mdi-robot-industrial", text: "mdi-robot-industrial"},
    {icon: "mdi-robot-vacuum", text: "mdi-robot-vacuum"},
    {icon: "mdi-robot-vacuum-variant", text: "mdi-robot-vacuum-variant"},
    {icon: "mdi-rocket", text: "mdi-rocket"},
    {icon: "mdi-roller-skate", text: "mdi-roller-skate"},
    {icon: "mdi-rollerblade", text: "mdi-rollerblade"},
    {icon: "mdi-rollupjs", text: "mdi-rollupjs"},
    {icon: "mdi-room-service", text: "mdi-room-service"},
    {icon: "mdi-room-service-outline", text: "mdi-room-service-outline"},
    {icon: "mdi-rotate-3d", text: "mdi-rotate-3d"},
    {icon: "mdi-rotate-3d-variant", text: "mdi-rotate-3d-variant"},
    {icon: "mdi-rotate-left", text: "mdi-rotate-left"},
    {icon: "mdi-rotate-left-variant", text: "mdi-rotate-left-variant"},
    {icon: "mdi-rotate-orbit", text: "mdi-rotate-orbit"},
    {icon: "mdi-rotate-right", text: "mdi-rotate-right"},
    {icon: "mdi-rotate-right-variant", text: "mdi-rotate-right-variant"},
    {icon: "mdi-rounded-corner", text: "mdi-rounded-corner"},
    {icon: "mdi-router-wireless", text: "mdi-router-wireless"},
    {icon: "mdi-router-wireless-settings", text: "mdi-router-wireless-settings"},
    {icon: "mdi-routes", text: "mdi-routes"},
    {icon: "mdi-routes-clock", text: "mdi-routes-clock"},
    {icon: "mdi-rowing", text: "mdi-rowing"},
    {icon: "mdi-rss", text: "mdi-rss"},
    {icon: "mdi-rss-box", text: "mdi-rss-box"},
    {icon: "mdi-rss-off", text: "mdi-rss-off"},
    {icon: "mdi-ruby", text: "mdi-ruby"},
    {icon: "mdi-rugby", text: "mdi-rugby"},
    {icon: "mdi-ruler", text: "mdi-ruler"},
    {icon: "mdi-ruler-square", text: "mdi-ruler-square"},
    {icon: "mdi-ruler-square-compass", text: "mdi-ruler-square-compass"},
    {icon: "mdi-run", text: "mdi-run"},
    {icon: "mdi-run-fast", text: "mdi-run-fast"},
    {icon: "mdi-sack", text: "mdi-sack"},
    {icon: "mdi-sack-percent", text: "mdi-sack-percent"},
    {icon: "mdi-safe", text: "mdi-safe"},
    {icon: "mdi-safety-goggles", text: "mdi-safety-goggles"},
    {icon: "mdi-sailing", text: "mdi-sailing"},
    {icon: "mdi-sale", text: "mdi-sale"},
    {icon: "mdi-salesforce", text: "mdi-salesforce"},
    {icon: "mdi-sass", text: "mdi-sass"},
    {icon: "mdi-satellite", text: "mdi-satellite"},
    {icon: "mdi-satellite-uplink", text: "mdi-satellite-uplink"},
    {icon: "mdi-satellite-variant", text: "mdi-satellite-variant"},
    {icon: "mdi-sausage", text: "mdi-sausage"},
    {icon: "mdi-saw-blade", text: "mdi-saw-blade"},
    {icon: "mdi-saxophone", text: "mdi-saxophone"},
    {icon: "mdi-scale", text: "mdi-scale"},
    {icon: "mdi-scale-balance", text: "mdi-scale-balance"},
    {icon: "mdi-scale-bathroom", text: "mdi-scale-bathroom"},
    {icon: "mdi-scale-off", text: "mdi-scale-off"},
    {icon: "mdi-scanner", text: "mdi-scanner"},
    {icon: "mdi-scanner-off", text: "mdi-scanner-off"},
    {icon: "mdi-scatter-plot", text: "mdi-scatter-plot"},
    {icon: "mdi-scatter-plot-outline", text: "mdi-scatter-plot-outline"},
    {icon: "mdi-school", text: "mdi-school"},
    {icon: "mdi-scissors-cutting", text: "mdi-scissors-cutting"},
    {icon: "mdi-screen-rotation", text: "mdi-screen-rotation"},
    {icon: "mdi-screen-rotation-lock", text: "mdi-screen-rotation-lock"},
    {icon: "mdi-screw-flat-top", text: "mdi-screw-flat-top"},
    {icon: "mdi-screw-lag", text: "mdi-screw-lag"},
    {icon: "mdi-screw-machine-flat-top", text: "mdi-screw-machine-flat-top"},
    {icon: "mdi-screw-machine-round-top", text: "mdi-screw-machine-round-top"},
    {icon: "mdi-screw-round-top", text: "mdi-screw-round-top"},
    {icon: "mdi-screwdriver", text: "mdi-screwdriver"},
    {icon: "mdi-script", text: "mdi-script"},
    {icon: "mdi-script-outline", text: "mdi-script-outline"},
    {icon: "mdi-script-text", text: "mdi-script-text"},
    {icon: "mdi-script-text-outline", text: "mdi-script-text-outline"},
    {icon: "mdi-sd", text: "mdi-sd"},
    {icon: "mdi-seal", text: "mdi-seal"},
    {icon: "mdi-seal-variant", text: "mdi-seal-variant"},
    {icon: "mdi-search-web", text: "mdi-search-web"},
    {icon: "mdi-seat", text: "mdi-seat"},
    {icon: "mdi-seat-flat", text: "mdi-seat-flat"},
    {icon: "mdi-seat-flat-angled", text: "mdi-seat-flat-angled"},
    {icon: "mdi-seat-individual-suite", text: "mdi-seat-individual-suite"},
    {icon: "mdi-seat-legroom-extra", text: "mdi-seat-legroom-extra"},
    {icon: "mdi-seat-legroom-normal", text: "mdi-seat-legroom-normal"},
    {icon: "mdi-seat-legroom-reduced", text: "mdi-seat-legroom-reduced"},
    {icon: "mdi-seat-outline", text: "mdi-seat-outline"},
    {icon: "mdi-seat-recline-extra", text: "mdi-seat-recline-extra"},
    {icon: "mdi-seat-recline-normal", text: "mdi-seat-recline-normal"},
    {icon: "mdi-seatbelt", text: "mdi-seatbelt"},
    {icon: "mdi-security", text: "mdi-security"},
    {icon: "mdi-security-network", text: "mdi-security-network"},
    {icon: "mdi-seed", text: "mdi-seed"},
    {icon: "mdi-seed-outline", text: "mdi-seed-outline"},
    {icon: "mdi-segment", text: "mdi-segment"},
    {icon: "mdi-select", text: "mdi-select"},
    {icon: "mdi-select-all", text: "mdi-select-all"},
    {icon: "mdi-select-color", text: "mdi-select-color"},
    {icon: "mdi-select-compare", text: "mdi-select-compare"},
    {icon: "mdi-select-drag", text: "mdi-select-drag"},
    {icon: "mdi-select-group", text: "mdi-select-group"},
    {icon: "mdi-select-inverse", text: "mdi-select-inverse"},
    {icon: "mdi-select-off", text: "mdi-select-off"},
    {icon: "mdi-select-place", text: "mdi-select-place"},
    {icon: "mdi-selection", text: "mdi-selection"},
    {icon: "mdi-selection-drag", text: "mdi-selection-drag"},
    {icon: "mdi-selection-ellipse", text: "mdi-selection-ellipse"},
    {icon: "mdi-selection-ellipse-arrow-inside", text: "mdi-selection-ellipse-arrow-inside"},
    {icon: "mdi-selection-off", text: "mdi-selection-off"},
    {icon: "mdi-send", text: "mdi-send"},
    {icon: "mdi-send-circle", text: "mdi-send-circle"},
    {icon: "mdi-send-circle-outline", text: "mdi-send-circle-outline"},
    {icon: "mdi-send-lock", text: "mdi-send-lock"},
    {icon: "mdi-serial-port", text: "mdi-serial-port"},
    {icon: "mdi-server", text: "mdi-server"},
    {icon: "mdi-server-minus", text: "mdi-server-minus"},
    {icon: "mdi-server-network", text: "mdi-server-network"},
    {icon: "mdi-server-network-off", text: "mdi-server-network-off"},
    {icon: "mdi-server-off", text: "mdi-server-off"},
    {icon: "mdi-server-plus", text: "mdi-server-plus"},
    {icon: "mdi-server-remove", text: "mdi-server-remove"},
    {icon: "mdi-server-security", text: "mdi-server-security"},
    {icon: "mdi-set-all", text: "mdi-set-all"},
    {icon: "mdi-set-center", text: "mdi-set-center"},
    {icon: "mdi-set-center-right", text: "mdi-set-center-right"},
    {icon: "mdi-set-left", text: "mdi-set-left"},
    {icon: "mdi-set-left-center", text: "mdi-set-left-center"},
    {icon: "mdi-set-left-right", text: "mdi-set-left-right"},
    {icon: "mdi-set-none", text: "mdi-set-none"},
    {icon: "mdi-set-right", text: "mdi-set-right"},
    {icon: "mdi-set-top-box", text: "mdi-set-top-box"},
    {icon: "mdi-settings", text: "mdi-settings"},
    {icon: "mdi-settings-box", text: "mdi-settings-box"},
    {icon: "mdi-settings-helper", text: "mdi-settings-helper"},
    {icon: "mdi-settings-outline", text: "mdi-settings-outline"},
    {icon: "mdi-settings-transfer", text: "mdi-settings-transfer"},
    {icon: "mdi-settings-transfer-outline", text: "mdi-settings-transfer-outline"},
    {icon: "mdi-shape", text: "mdi-shape"},
    {icon: "mdi-shape-circle-plus", text: "mdi-shape-circle-plus"},
    {icon: "mdi-shape-outline", text: "mdi-shape-outline"},
    {icon: "mdi-shape-plus", text: "mdi-shape-plus"},
    {icon: "mdi-shape-polygon-plus", text: "mdi-shape-polygon-plus"},
    {icon: "mdi-shape-rectangle-plus", text: "mdi-shape-rectangle-plus"},
    {icon: "mdi-shape-square-plus", text: "mdi-shape-square-plus"},
    {icon: "mdi-share", text: "mdi-share"},
    {icon: "mdi-share-off", text: "mdi-share-off"},
    {icon: "mdi-share-off-outline", text: "mdi-share-off-outline"},
    {icon: "mdi-share-outline", text: "mdi-share-outline"},
    {icon: "mdi-share-variant", text: "mdi-share-variant"},
    {icon: "mdi-sheep", text: "mdi-sheep"},
    {icon: "mdi-shield", text: "mdi-shield"},
    {icon: "mdi-shield-account", text: "mdi-shield-account"},
    {icon: "mdi-shield-account-outline", text: "mdi-shield-account-outline"},
    {icon: "mdi-shield-airplane", text: "mdi-shield-airplane"},
    {icon: "mdi-shield-airplane-outline", text: "mdi-shield-airplane-outline"},
    {icon: "mdi-shield-alert", text: "mdi-shield-alert"},
    {icon: "mdi-shield-alert-outline", text: "mdi-shield-alert-outline"},
    {icon: "mdi-shield-car", text: "mdi-shield-car"},
    {icon: "mdi-shield-check", text: "mdi-shield-check"},
    {icon: "mdi-shield-check-outline", text: "mdi-shield-check-outline"},
    {icon: "mdi-shield-cross", text: "mdi-shield-cross"},
    {icon: "mdi-shield-cross-outline", text: "mdi-shield-cross-outline"},
    {icon: "mdi-shield-half-full", text: "mdi-shield-half-full"},
    {icon: "mdi-shield-home", text: "mdi-shield-home"},
    {icon: "mdi-shield-home-outline", text: "mdi-shield-home-outline"},
    {icon: "mdi-shield-key", text: "mdi-shield-key"},
    {icon: "mdi-shield-key-outline", text: "mdi-shield-key-outline"},
    {icon: "mdi-shield-link-variant", text: "mdi-shield-link-variant"},
    {icon: "mdi-shield-link-variant-outline", text: "mdi-shield-link-variant-outline"},
    {icon: "mdi-shield-lock", text: "mdi-shield-lock"},
    {icon: "mdi-shield-lock-outline", text: "mdi-shield-lock-outline"},
    {icon: "mdi-shield-off", text: "mdi-shield-off"},
    {icon: "mdi-shield-off-outline", text: "mdi-shield-off-outline"},
    {icon: "mdi-shield-outline", text: "mdi-shield-outline"},
    {icon: "mdi-shield-plus", text: "mdi-shield-plus"},
    {icon: "mdi-shield-plus-outline", text: "mdi-shield-plus-outline"},
    {icon: "mdi-shield-remove", text: "mdi-shield-remove"},
    {icon: "mdi-shield-remove-outline", text: "mdi-shield-remove-outline"},
    {icon: "mdi-shield-search", text: "mdi-shield-search"},
    {icon: "mdi-shield-sun", text: "mdi-shield-sun"},
    {icon: "mdi-shield-sun-outline", text: "mdi-shield-sun-outline"},
    {icon: "mdi-ship-wheel", text: "mdi-ship-wheel"},
    {icon: "mdi-shoe-formal", text: "mdi-shoe-formal"},
    {icon: "mdi-shoe-heel", text: "mdi-shoe-heel"},
    {icon: "mdi-shoe-print", text: "mdi-shoe-print"},
    {icon: "mdi-shopify", text: "mdi-shopify"},
    {icon: "mdi-shopping", text: "mdi-shopping"},
    {icon: "mdi-shopping-music", text: "mdi-shopping-music"},
    {icon: "mdi-shopping-search", text: "mdi-shopping-search"},
    {icon: "mdi-shovel", text: "mdi-shovel"},
    {icon: "mdi-shovel-off", text: "mdi-shovel-off"},
    {icon: "mdi-shower", text: "mdi-shower"},
    {icon: "mdi-shower-head", text: "mdi-shower-head"},
    {icon: "mdi-shredder", text: "mdi-shredder"},
    {icon: "mdi-shuffle", text: "mdi-shuffle"},
    {icon: "mdi-shuffle-disabled", text: "mdi-shuffle-disabled"},
    {icon: "mdi-shuffle-variant", text: "mdi-shuffle-variant"},
    {icon: "mdi-sigma", text: "mdi-sigma"},
    {icon: "mdi-sigma-lower", text: "mdi-sigma-lower"},
    {icon: "mdi-sign-caution", text: "mdi-sign-caution"},
    {icon: "mdi-sign-direction", text: "mdi-sign-direction"},
    {icon: "mdi-sign-direction-minus", text: "mdi-sign-direction-minus"},
    {icon: "mdi-sign-direction-plus", text: "mdi-sign-direction-plus"},
    {icon: "mdi-sign-direction-remove", text: "mdi-sign-direction-remove"},
    {icon: "mdi-sign-text", text: "mdi-sign-text"},
    {icon: "mdi-signal", text: "mdi-signal"},
    {icon: "mdi-signal-2g", text: "mdi-signal-2g"},
    {icon: "mdi-signal-3g", text: "mdi-signal-3g"},
    {icon: "mdi-signal-4g", text: "mdi-signal-4g"},
    {icon: "mdi-signal-5g", text: "mdi-signal-5g"},
    {icon: "mdi-signal-cellular-1", text: "mdi-signal-cellular-1"},
    {icon: "mdi-signal-cellular-2", text: "mdi-signal-cellular-2"},
    {icon: "mdi-signal-cellular-3", text: "mdi-signal-cellular-3"},
    {icon: "mdi-signal-cellular-outline", text: "mdi-signal-cellular-outline"},
    {icon: "mdi-signal-distance-variant", text: "mdi-signal-distance-variant"},
    {icon: "mdi-signal-hspa", text: "mdi-signal-hspa"},
    {icon: "mdi-signal-hspa-plus", text: "mdi-signal-hspa-plus"},
    {icon: "mdi-signal-off", text: "mdi-signal-off"},
    {icon: "mdi-signal-variant", text: "mdi-signal-variant"},
    {icon: "mdi-signature", text: "mdi-signature"},
    {icon: "mdi-signature-freehand", text: "mdi-signature-freehand"},
    {icon: "mdi-signature-image", text: "mdi-signature-image"},
    {icon: "mdi-signature-text", text: "mdi-signature-text"},
    {icon: "mdi-silo", text: "mdi-silo"},
    {icon: "mdi-silverware", text: "mdi-silverware"},
    {icon: "mdi-silverware-clean", text: "mdi-silverware-clean"},
    {icon: "mdi-silverware-fork", text: "mdi-silverware-fork"},
    {icon: "mdi-silverware-fork-knife", text: "mdi-silverware-fork-knife"},
    {icon: "mdi-silverware-spoon", text: "mdi-silverware-spoon"},
    {icon: "mdi-silverware-variant", text: "mdi-silverware-variant"},
    {icon: "mdi-sim", text: "mdi-sim"},
    {icon: "mdi-sim-alert", text: "mdi-sim-alert"},
    {icon: "mdi-sim-off", text: "mdi-sim-off"},
    {icon: "mdi-sina-weibo", text: "mdi-sina-weibo"},
    {icon: "mdi-sitemap", text: "mdi-sitemap"},
    {icon: "mdi-skate", text: "mdi-skate"},
    {icon: "mdi-skew-less", text: "mdi-skew-less"},
    {icon: "mdi-skew-more", text: "mdi-skew-more"},
    {icon: "mdi-skip-backward", text: "mdi-skip-backward"},
    {icon: "mdi-skip-backward-outline", text: "mdi-skip-backward-outline"},
    {icon: "mdi-skip-forward", text: "mdi-skip-forward"},
    {icon: "mdi-skip-forward-outline", text: "mdi-skip-forward-outline"},
    {icon: "mdi-skip-next", text: "mdi-skip-next"},
    {icon: "mdi-skip-next-circle", text: "mdi-skip-next-circle"},
    {icon: "mdi-skip-next-circle-outline", text: "mdi-skip-next-circle-outline"},
    {icon: "mdi-skip-next-outline", text: "mdi-skip-next-outline"},
    {icon: "mdi-skip-previous", text: "mdi-skip-previous"},
    {icon: "mdi-skip-previous-circle", text: "mdi-skip-previous-circle"},
    {icon: "mdi-skip-previous-circle-outline", text: "mdi-skip-previous-circle-outline"},
    {icon: "mdi-skip-previous-outline", text: "mdi-skip-previous-outline"},
    {icon: "mdi-skull", text: "mdi-skull"},
    {icon: "mdi-skull-crossbones", text: "mdi-skull-crossbones"},
    {icon: "mdi-skull-crossbones-outline", text: "mdi-skull-crossbones-outline"},
    {icon: "mdi-skull-outline", text: "mdi-skull-outline"},
    {icon: "mdi-skype", text: "mdi-skype"},
    {icon: "mdi-skype-business", text: "mdi-skype-business"},
    {icon: "mdi-slack", text: "mdi-slack"},
    {icon: "mdi-slackware", text: "mdi-slackware"},
    {icon: "mdi-slash-forward", text: "mdi-slash-forward"},
    {icon: "mdi-slash-forward-box", text: "mdi-slash-forward-box"},
    {icon: "mdi-sleep", text: "mdi-sleep"},
    {icon: "mdi-sleep-off", text: "mdi-sleep-off"},
    {icon: "mdi-slope-downhill", text: "mdi-slope-downhill"},
    {icon: "mdi-slope-uphill", text: "mdi-slope-uphill"},
    {icon: "mdi-smog", text: "mdi-smog"},
    {icon: "mdi-smoke-detector", text: "mdi-smoke-detector"},
    {icon: "mdi-smoking", text: "mdi-smoking"},
    {icon: "mdi-smoking-off", text: "mdi-smoking-off"},
    {icon: "mdi-snapchat", text: "mdi-snapchat"},
    {icon: "mdi-snowflake", text: "mdi-snowflake"},
    {icon: "mdi-snowflake-alert", text: "mdi-snowflake-alert"},
    {icon: "mdi-snowflake-variant", text: "mdi-snowflake-variant"},
    {icon: "mdi-snowman", text: "mdi-snowman"},
    {icon: "mdi-soccer", text: "mdi-soccer"},
    {icon: "mdi-soccer-field", text: "mdi-soccer-field"},
    {icon: "mdi-sofa", text: "mdi-sofa"},
    {icon: "mdi-solar-panel", text: "mdi-solar-panel"},
    {icon: "mdi-solar-panel-large", text: "mdi-solar-panel-large"},
    {icon: "mdi-solar-power", text: "mdi-solar-power"},
    {icon: "mdi-solid", text: "mdi-solid"},
    {icon: "mdi-sort", text: "mdi-sort"},
    {icon: "mdi-sort-alphabetical", text: "mdi-sort-alphabetical"},
    {icon: "mdi-sort-ascending", text: "mdi-sort-ascending"},
    {icon: "mdi-sort-descending", text: "mdi-sort-descending"},
    {icon: "mdi-sort-numeric", text: "mdi-sort-numeric"},
    {icon: "mdi-sort-variant", text: "mdi-sort-variant"},
    {icon: "mdi-sort-variant-lock", text: "mdi-sort-variant-lock"},
    {icon: "mdi-sort-variant-lock-open", text: "mdi-sort-variant-lock-open"},
    {icon: "mdi-soundcloud", text: "mdi-soundcloud"},
    {icon: "mdi-source-branch", text: "mdi-source-branch"},
    {icon: "mdi-source-commit", text: "mdi-source-commit"},
    {icon: "mdi-source-commit-end", text: "mdi-source-commit-end"},
    {icon: "mdi-source-commit-end-local", text: "mdi-source-commit-end-local"},
    {icon: "mdi-source-commit-local", text: "mdi-source-commit-local"},
    {icon: "mdi-source-commit-next-local", text: "mdi-source-commit-next-local"},
    {icon: "mdi-source-commit-start", text: "mdi-source-commit-start"},
    {icon: "mdi-source-commit-start-next-local", text: "mdi-source-commit-start-next-local"},
    {icon: "mdi-source-fork", text: "mdi-source-fork"},
    {icon: "mdi-source-merge", text: "mdi-source-merge"},
    {icon: "mdi-source-pull", text: "mdi-source-pull"},
    {icon: "mdi-source-repository", text: "mdi-source-repository"},
    {icon: "mdi-source-repository-multiple", text: "mdi-source-repository-multiple"},
    {icon: "mdi-soy-sauce", text: "mdi-soy-sauce"},
    {icon: "mdi-spa", text: "mdi-spa"},
    {icon: "mdi-spa-outline", text: "mdi-spa-outline"},
    {icon: "mdi-space-invaders", text: "mdi-space-invaders"},
    {icon: "mdi-spade", text: "mdi-spade"},
    {icon: "mdi-speaker", text: "mdi-speaker"},
    {icon: "mdi-speaker-bluetooth", text: "mdi-speaker-bluetooth"},
    {icon: "mdi-speaker-multiple", text: "mdi-speaker-multiple"},
    {icon: "mdi-speaker-off", text: "mdi-speaker-off"},
    {icon: "mdi-speaker-wireless", text: "mdi-speaker-wireless"},
    {icon: "mdi-speedometer", text: "mdi-speedometer"},
    {icon: "mdi-speedometer-medium", text: "mdi-speedometer-medium"},
    {icon: "mdi-speedometer-slow", text: "mdi-speedometer-slow"},
    {icon: "mdi-spellcheck", text: "mdi-spellcheck"},
    {icon: "mdi-spider-web", text: "mdi-spider-web"},
    {icon: "mdi-spotify", text: "mdi-spotify"},
    {icon: "mdi-spotlight", text: "mdi-spotlight"},
    {icon: "mdi-spotlight-beam", text: "mdi-spotlight-beam"},
    {icon: "mdi-spray", text: "mdi-spray"},
    {icon: "mdi-spray-bottle", text: "mdi-spray-bottle"},
    {icon: "mdi-sprinkler", text: "mdi-sprinkler"},
    {icon: "mdi-sprinkler-variant", text: "mdi-sprinkler-variant"},
    {icon: "mdi-sprout", text: "mdi-sprout"},
    {icon: "mdi-sprout-outline", text: "mdi-sprout-outline"},
    {icon: "mdi-square", text: "mdi-square"},
    {icon: "mdi-square-edit-outline", text: "mdi-square-edit-outline"},
    {icon: "mdi-square-inc", text: "mdi-square-inc"},
    {icon: "mdi-square-inc-cash", text: "mdi-square-inc-cash"},
    {icon: "mdi-square-medium", text: "mdi-square-medium"},
    {icon: "mdi-square-medium-outline", text: "mdi-square-medium-outline"},
    {icon: "mdi-square-outline", text: "mdi-square-outline"},
    {icon: "mdi-square-root", text: "mdi-square-root"},
    {icon: "mdi-square-root-box", text: "mdi-square-root-box"},
    {icon: "mdi-square-small", text: "mdi-square-small"},
    {icon: "mdi-squeegee", text: "mdi-squeegee"},
    {icon: "mdi-ssh", text: "mdi-ssh"},
    {icon: "mdi-stack-exchange", text: "mdi-stack-exchange"},
    {icon: "mdi-stack-overflow", text: "mdi-stack-overflow"},
    {icon: "mdi-stadium", text: "mdi-stadium"},
    {icon: "mdi-stadium-variant", text: "mdi-stadium-variant"},
    {icon: "mdi-stairs", text: "mdi-stairs"},
    {icon: "mdi-stamper", text: "mdi-stamper"},
    {icon: "mdi-standard-definition", text: "mdi-standard-definition"},
    {icon: "mdi-star", text: "mdi-star"},
    {icon: "mdi-star-box", text: "mdi-star-box"},
    {icon: "mdi-star-box-outline", text: "mdi-star-box-outline"},
    {icon: "mdi-star-circle", text: "mdi-star-circle"},
    {icon: "mdi-star-circle-outline", text: "mdi-star-circle-outline"},
    {icon: "mdi-star-face", text: "mdi-star-face"},
    {icon: "mdi-star-four-points", text: "mdi-star-four-points"},
    {icon: "mdi-star-four-points-outline", text: "mdi-star-four-points-outline"},
    {icon: "mdi-star-half", text: "mdi-star-half"},
    {icon: "mdi-star-off", text: "mdi-star-off"},
    {icon: "mdi-star-outline", text: "mdi-star-outline"},
    {icon: "mdi-star-three-points", text: "mdi-star-three-points"},
    {icon: "mdi-star-three-points-outline", text: "mdi-star-three-points-outline"},
    {icon: "mdi-steam", text: "mdi-steam"},
    {icon: "mdi-steam-box", text: "mdi-steam-box"},
    {icon: "mdi-steering", text: "mdi-steering"},
    {icon: "mdi-steering-off", text: "mdi-steering-off"},
    {icon: "mdi-step-backward", text: "mdi-step-backward"},
    {icon: "mdi-step-backward-2", text: "mdi-step-backward-2"},
    {icon: "mdi-step-forward", text: "mdi-step-forward"},
    {icon: "mdi-step-forward-2", text: "mdi-step-forward-2"},
    {icon: "mdi-stethoscope", text: "mdi-stethoscope"},
    {icon: "mdi-sticker", text: "mdi-sticker"},
    {icon: "mdi-sticker-emoji", text: "mdi-sticker-emoji"},
    {icon: "mdi-stocking", text: "mdi-stocking"},
    {icon: "mdi-stop", text: "mdi-stop"},
    {icon: "mdi-stop-circle", text: "mdi-stop-circle"},
    {icon: "mdi-stop-circle-outline", text: "mdi-stop-circle-outline"},
    {icon: "mdi-store", text: "mdi-store"},
    {icon: "mdi-store-24-hour", text: "mdi-store-24-hour"},
    {icon: "mdi-stove", text: "mdi-stove"},
    {icon: "mdi-strava", text: "mdi-strava"},
    {icon: "mdi-stretch-to-page", text: "mdi-stretch-to-page"},
    {icon: "mdi-stretch-to-page-outline", text: "mdi-stretch-to-page-outline"},
    {icon: "mdi-subdirectory-arrow-left", text: "mdi-subdirectory-arrow-left"},
    {icon: "mdi-subdirectory-arrow-right", text: "mdi-subdirectory-arrow-right"},
    {icon: "mdi-subtitles", text: "mdi-subtitles"},
    {icon: "mdi-subtitles-outline", text: "mdi-subtitles-outline"},
    {icon: "mdi-subway", text: "mdi-subway"},
    {icon: "mdi-subway-alert-variant", text: "mdi-subway-alert-variant"},
    {icon: "mdi-subway-variant", text: "mdi-subway-variant"},
    {icon: "mdi-summit", text: "mdi-summit"},
    {icon: "mdi-sunglasses", text: "mdi-sunglasses"},
    {icon: "mdi-surround-sound", text: "mdi-surround-sound"},
    {icon: "mdi-surround-sound-2-0", text: "mdi-surround-sound-2-0"},
    {icon: "mdi-surround-sound-3-1", text: "mdi-surround-sound-3-1"},
    {icon: "mdi-surround-sound-5-1", text: "mdi-surround-sound-5-1"},
    {icon: "mdi-surround-sound-7-1", text: "mdi-surround-sound-7-1"},
    {icon: "mdi-svg", text: "mdi-svg"},
    {icon: "mdi-swap-horizontal", text: "mdi-swap-horizontal"},
    {icon: "mdi-swap-horizontal-bold", text: "mdi-swap-horizontal-bold"},
    {icon: "mdi-swap-horizontal-circle", text: "mdi-swap-horizontal-circle"},
    {icon: "mdi-swap-horizontal-circle-outline", text: "mdi-swap-horizontal-circle-outline"},
    {icon: "mdi-swap-horizontal-variant", text: "mdi-swap-horizontal-variant"},
    {icon: "mdi-swap-vertical", text: "mdi-swap-vertical"},
    {icon: "mdi-swap-vertical-bold", text: "mdi-swap-vertical-bold"},
    {icon: "mdi-swap-vertical-circle", text: "mdi-swap-vertical-circle"},
    {icon: "mdi-swap-vertical-circle-outline", text: "mdi-swap-vertical-circle-outline"},
    {icon: "mdi-swap-vertical-variant", text: "mdi-swap-vertical-variant"},
    {icon: "mdi-swim", text: "mdi-swim"},
    {icon: "mdi-switch", text: "mdi-switch"},
    {icon: "mdi-sword", text: "mdi-sword"},
    {icon: "mdi-sword-cross", text: "mdi-sword-cross"},
    {icon: "mdi-symfony", text: "mdi-symfony"},
    {icon: "mdi-sync", text: "mdi-sync"},
    {icon: "mdi-sync-alert", text: "mdi-sync-alert"},
    {icon: "mdi-sync-off", text: "mdi-sync-off"},
    {icon: "mdi-tab", text: "mdi-tab"},
    {icon: "mdi-tab-minus", text: "mdi-tab-minus"},
    {icon: "mdi-tab-plus", text: "mdi-tab-plus"},
    {icon: "mdi-tab-remove", text: "mdi-tab-remove"},
    {icon: "mdi-tab-unselected", text: "mdi-tab-unselected"},
    {icon: "mdi-table", text: "mdi-table"},
    {icon: "mdi-table-border", text: "mdi-table-border"},
    {icon: "mdi-table-chair", text: "mdi-table-chair"},
    {icon: "mdi-table-column", text: "mdi-table-column"},
    {icon: "mdi-table-column-plus-after", text: "mdi-table-column-plus-after"},
    {icon: "mdi-table-column-plus-before", text: "mdi-table-column-plus-before"},
    {icon: "mdi-table-column-remove", text: "mdi-table-column-remove"},
    {icon: "mdi-table-column-width", text: "mdi-table-column-width"},
    {icon: "mdi-table-edit", text: "mdi-table-edit"},
    {icon: "mdi-table-large", text: "mdi-table-large"},
    {icon: "mdi-table-large-plus", text: "mdi-table-large-plus"},
    {icon: "mdi-table-large-remove", text: "mdi-table-large-remove"},
    {icon: "mdi-table-merge-cells", text: "mdi-table-merge-cells"},
    {icon: "mdi-table-of-contents", text: "mdi-table-of-contents"},
    {icon: "mdi-table-plus", text: "mdi-table-plus"},
    {icon: "mdi-table-remove", text: "mdi-table-remove"},
    {icon: "mdi-table-row", text: "mdi-table-row"},
    {icon: "mdi-table-row-height", text: "mdi-table-row-height"},
    {icon: "mdi-table-row-plus-after", text: "mdi-table-row-plus-after"},
    {icon: "mdi-table-row-plus-before", text: "mdi-table-row-plus-before"},
    {icon: "mdi-table-row-remove", text: "mdi-table-row-remove"},
    {icon: "mdi-table-search", text: "mdi-table-search"},
    {icon: "mdi-table-settings", text: "mdi-table-settings"},
    {icon: "mdi-table-tennis", text: "mdi-table-tennis"},
    {icon: "mdi-tablet", text: "mdi-tablet"},
    {icon: "mdi-tablet-android", text: "mdi-tablet-android"},
    {icon: "mdi-tablet-cellphone", text: "mdi-tablet-cellphone"},
    {icon: "mdi-tablet-dashboard", text: "mdi-tablet-dashboard"},
    {icon: "mdi-tablet-ipad", text: "mdi-tablet-ipad"},
    {icon: "mdi-taco", text: "mdi-taco"},
    {icon: "mdi-tag", text: "mdi-tag"},
    {icon: "mdi-tag-faces", text: "mdi-tag-faces"},
    {icon: "mdi-tag-heart", text: "mdi-tag-heart"},
    {icon: "mdi-tag-heart-outline", text: "mdi-tag-heart-outline"},
    {icon: "mdi-tag-minus", text: "mdi-tag-minus"},
    {icon: "mdi-tag-multiple", text: "mdi-tag-multiple"},
    {icon: "mdi-tag-outline", text: "mdi-tag-outline"},
    {icon: "mdi-tag-plus", text: "mdi-tag-plus"},
    {icon: "mdi-tag-remove", text: "mdi-tag-remove"},
    {icon: "mdi-tag-text-outline", text: "mdi-tag-text-outline"},
    {icon: "mdi-tank", text: "mdi-tank"},
    {icon: "mdi-tanker-truck", text: "mdi-tanker-truck"},
    {icon: "mdi-tape-measure", text: "mdi-tape-measure"},
    {icon: "mdi-target", text: "mdi-target"},
    {icon: "mdi-target-account", text: "mdi-target-account"},
    {icon: "mdi-target-variant", text: "mdi-target-variant"},
    {icon: "mdi-taxi", text: "mdi-taxi"},
    {icon: "mdi-tea", text: "mdi-tea"},
    {icon: "mdi-tea-outline", text: "mdi-tea-outline"},
    {icon: "mdi-teach", text: "mdi-teach"},
    {icon: "mdi-teamviewer", text: "mdi-teamviewer"},
    {icon: "mdi-telegram", text: "mdi-telegram"},
    {icon: "mdi-telescope", text: "mdi-telescope"},
    {icon: "mdi-television", text: "mdi-television"},
    {icon: "mdi-television-box", text: "mdi-television-box"},
    {icon: "mdi-television-classic", text: "mdi-television-classic"},
    {icon: "mdi-television-classic-off", text: "mdi-television-classic-off"},
    {icon: "mdi-television-guide", text: "mdi-television-guide"},
    {icon: "mdi-television-off", text: "mdi-television-off"},
    {icon: "mdi-television-pause", text: "mdi-television-pause"},
    {icon: "mdi-television-play", text: "mdi-television-play"},
    {icon: "mdi-television-stop", text: "mdi-television-stop"},
    {icon: "mdi-temperature-celsius", text: "mdi-temperature-celsius"},
    {icon: "mdi-temperature-fahrenheit", text: "mdi-temperature-fahrenheit"},
    {icon: "mdi-temperature-kelvin", text: "mdi-temperature-kelvin"},
    {icon: "mdi-tennis", text: "mdi-tennis"},
    {icon: "mdi-tennis-ball", text: "mdi-tennis-ball"},
    {icon: "mdi-tent", text: "mdi-tent"},
    {icon: "mdi-terraform", text: "mdi-terraform"},
    {icon: "mdi-terrain", text: "mdi-terrain"},
    {icon: "mdi-test-tube", text: "mdi-test-tube"},
    {icon: "mdi-test-tube-empty", text: "mdi-test-tube-empty"},
    {icon: "mdi-test-tube-off", text: "mdi-test-tube-off"},
    {icon: "mdi-text", text: "mdi-text"},
    {icon: "mdi-text-shadow", text: "mdi-text-shadow"},
    {icon: "mdi-text-short", text: "mdi-text-short"},
    {icon: "mdi-text-subject", text: "mdi-text-subject"},
    {icon: "mdi-text-to-speech", text: "mdi-text-to-speech"},
    {icon: "mdi-text-to-speech-off", text: "mdi-text-to-speech-off"},
    {icon: "mdi-textbox", text: "mdi-textbox"},
    {icon: "mdi-textbox-password", text: "mdi-textbox-password"},
    {icon: "mdi-texture", text: "mdi-texture"},
    {icon: "mdi-texture-box", text: "mdi-texture-box"},
    {icon: "mdi-theater", text: "mdi-theater"},
    {icon: "mdi-theme-light-dark", text: "mdi-theme-light-dark"},
    {icon: "mdi-thermometer", text: "mdi-thermometer"},
    {icon: "mdi-thermometer-alert", text: "mdi-thermometer-alert"},
    {icon: "mdi-thermometer-chevron-down", text: "mdi-thermometer-chevron-down"},
    {icon: "mdi-thermometer-chevron-up", text: "mdi-thermometer-chevron-up"},
    {icon: "mdi-thermometer-lines", text: "mdi-thermometer-lines"},
    {icon: "mdi-thermometer-minus", text: "mdi-thermometer-minus"},
    {icon: "mdi-thermometer-plus", text: "mdi-thermometer-plus"},
    {icon: "mdi-thermostat", text: "mdi-thermostat"},
    {icon: "mdi-thermostat-box", text: "mdi-thermostat-box"},
    {icon: "mdi-thought-bubble", text: "mdi-thought-bubble"},
    {icon: "mdi-thought-bubble-outline", text: "mdi-thought-bubble-outline"},
    {icon: "mdi-thumb-down", text: "mdi-thumb-down"},
    {icon: "mdi-thumb-down-outline", text: "mdi-thumb-down-outline"},
    {icon: "mdi-thumb-up", text: "mdi-thumb-up"},
    {icon: "mdi-thumb-up-outline", text: "mdi-thumb-up-outline"},
    {icon: "mdi-thumbs-up-down", text: "mdi-thumbs-up-down"},
    {icon: "mdi-ticket", text: "mdi-ticket"},
    {icon: "mdi-ticket-account", text: "mdi-ticket-account"},
    {icon: "mdi-ticket-confirmation", text: "mdi-ticket-confirmation"},
    {icon: "mdi-ticket-outline", text: "mdi-ticket-outline"},
    {icon: "mdi-ticket-percent", text: "mdi-ticket-percent"},
    {icon: "mdi-tie", text: "mdi-tie"},
    {icon: "mdi-tilde", text: "mdi-tilde"},
    {icon: "mdi-timelapse", text: "mdi-timelapse"},
    {icon: "mdi-timeline", text: "mdi-timeline"},
    {icon: "mdi-timeline-alert", text: "mdi-timeline-alert"},
    {icon: "mdi-timeline-alert-outline", text: "mdi-timeline-alert-outline"},
    {icon: "mdi-timeline-help", text: "mdi-timeline-help"},
    {icon: "mdi-timeline-help-outline", text: "mdi-timeline-help-outline"},
    {icon: "mdi-timeline-outline", text: "mdi-timeline-outline"},
    {icon: "mdi-timeline-plus", text: "mdi-timeline-plus"},
    {icon: "mdi-timeline-plus-outline", text: "mdi-timeline-plus-outline"},
    {icon: "mdi-timeline-text", text: "mdi-timeline-text"},
    {icon: "mdi-timeline-text-outline", text: "mdi-timeline-text-outline"},
    {icon: "mdi-timer", text: "mdi-timer"},
    {icon: "mdi-timer-10", text: "mdi-timer-10"},
    {icon: "mdi-timer-3", text: "mdi-timer-3"},
    {icon: "mdi-timer-off", text: "mdi-timer-off"},
    {icon: "mdi-timer-sand", text: "mdi-timer-sand"},
    {icon: "mdi-timer-sand-empty", text: "mdi-timer-sand-empty"},
    {icon: "mdi-timer-sand-full", text: "mdi-timer-sand-full"},
    {icon: "mdi-timetable", text: "mdi-timetable"},
    {icon: "mdi-toaster", text: "mdi-toaster"},
    {icon: "mdi-toaster-oven", text: "mdi-toaster-oven"},
    {icon: "mdi-toggle-switch", text: "mdi-toggle-switch"},
    {icon: "mdi-toggle-switch-off", text: "mdi-toggle-switch-off"},
    {icon: "mdi-toggle-switch-off-outline", text: "mdi-toggle-switch-off-outline"},
    {icon: "mdi-toggle-switch-outline", text: "mdi-toggle-switch-outline"},
    {icon: "mdi-toilet", text: "mdi-toilet"},
    {icon: "mdi-toolbox", text: "mdi-toolbox"},
    {icon: "mdi-toolbox-outline", text: "mdi-toolbox-outline"},
    {icon: "mdi-tools", text: "mdi-tools"},
    {icon: "mdi-tooltip", text: "mdi-tooltip"},
    {icon: "mdi-tooltip-account", text: "mdi-tooltip-account"},
    {icon: "mdi-tooltip-edit", text: "mdi-tooltip-edit"},
    {icon: "mdi-tooltip-image", text: "mdi-tooltip-image"},
    {icon: "mdi-tooltip-image-outline", text: "mdi-tooltip-image-outline"},
    {icon: "mdi-tooltip-outline", text: "mdi-tooltip-outline"},
    {icon: "mdi-tooltip-plus", text: "mdi-tooltip-plus"},
    {icon: "mdi-tooltip-plus-outline", text: "mdi-tooltip-plus-outline"},
    {icon: "mdi-tooltip-text", text: "mdi-tooltip-text"},
    {icon: "mdi-tooltip-text-outline", text: "mdi-tooltip-text-outline"},
    {icon: "mdi-tooth", text: "mdi-tooth"},
    {icon: "mdi-tooth-outline", text: "mdi-tooth-outline"},
    {icon: "mdi-tor", text: "mdi-tor"},
    {icon: "mdi-tortoise", text: "mdi-tortoise"},
    {icon: "mdi-tournament", text: "mdi-tournament"},
    {icon: "mdi-tower-beach", text: "mdi-tower-beach"},
    {icon: "mdi-tower-fire", text: "mdi-tower-fire"},
    {icon: "mdi-towing", text: "mdi-towing"},
    {icon: "mdi-track-light", text: "mdi-track-light"},
    {icon: "mdi-trackpad", text: "mdi-trackpad"},
    {icon: "mdi-trackpad-lock", text: "mdi-trackpad-lock"},
    {icon: "mdi-tractor", text: "mdi-tractor"},
    {icon: "mdi-trademark", text: "mdi-trademark"},
    {icon: "mdi-traffic-light", text: "mdi-traffic-light"},
    {icon: "mdi-train", text: "mdi-train"},
    {icon: "mdi-train-car", text: "mdi-train-car"},
    {icon: "mdi-train-variant", text: "mdi-train-variant"},
    {icon: "mdi-tram", text: "mdi-tram"},
    {icon: "mdi-tram-side", text: "mdi-tram-side"},
    {icon: "mdi-transcribe", text: "mdi-transcribe"},
    {icon: "mdi-transcribe-close", text: "mdi-transcribe-close"},
    {icon: "mdi-transfer", text: "mdi-transfer"},
    {icon: "mdi-transfer-down", text: "mdi-transfer-down"},
    {icon: "mdi-transfer-left", text: "mdi-transfer-left"},
    {icon: "mdi-transfer-right", text: "mdi-transfer-right"},
    {icon: "mdi-transfer-up", text: "mdi-transfer-up"},
    {icon: "mdi-transit-connection", text: "mdi-transit-connection"},
    {icon: "mdi-transit-connection-variant", text: "mdi-transit-connection-variant"},
    {icon: "mdi-transit-detour", text: "mdi-transit-detour"},
    {icon: "mdi-transit-transfer", text: "mdi-transit-transfer"},
    {icon: "mdi-transition", text: "mdi-transition"},
    {icon: "mdi-transition-masked", text: "mdi-transition-masked"},
    {icon: "mdi-translate", text: "mdi-translate"},
    {icon: "mdi-translate-off", text: "mdi-translate-off"},
    {icon: "mdi-transmission-tower", text: "mdi-transmission-tower"},
    {icon: "mdi-trash-can", text: "mdi-trash-can"},
    {icon: "mdi-trash-can-outline", text: "mdi-trash-can-outline"},
    {icon: "mdi-treasure-chest", text: "mdi-treasure-chest"},
    {icon: "mdi-tree", text: "mdi-tree"},
    {icon: "mdi-tree-outline", text: "mdi-tree-outline"},
    {icon: "mdi-trello", text: "mdi-trello"},
    {icon: "mdi-trending-down", text: "mdi-trending-down"},
    {icon: "mdi-trending-neutral", text: "mdi-trending-neutral"},
    {icon: "mdi-trending-up", text: "mdi-trending-up"},
    {icon: "mdi-triangle", text: "mdi-triangle"},
    {icon: "mdi-triangle-outline", text: "mdi-triangle-outline"},
    {icon: "mdi-triforce", text: "mdi-triforce"},
    {icon: "mdi-trophy", text: "mdi-trophy"},
    {icon: "mdi-trophy-award", text: "mdi-trophy-award"},
    {icon: "mdi-trophy-broken", text: "mdi-trophy-broken"},
    {icon: "mdi-trophy-outline", text: "mdi-trophy-outline"},
    {icon: "mdi-trophy-variant", text: "mdi-trophy-variant"},
    {icon: "mdi-trophy-variant-outline", text: "mdi-trophy-variant-outline"},
    {icon: "mdi-truck", text: "mdi-truck"},
    {icon: "mdi-truck-check", text: "mdi-truck-check"},
    {icon: "mdi-truck-delivery", text: "mdi-truck-delivery"},
    {icon: "mdi-truck-fast", text: "mdi-truck-fast"},
    {icon: "mdi-truck-trailer", text: "mdi-truck-trailer"},
    {icon: "mdi-tshirt-crew", text: "mdi-tshirt-crew"},
    {icon: "mdi-tshirt-crew-outline", text: "mdi-tshirt-crew-outline"},
    {icon: "mdi-tshirt-v", text: "mdi-tshirt-v"},
    {icon: "mdi-tshirt-v-outline", text: "mdi-tshirt-v-outline"},
    {icon: "mdi-tumble-dryer", text: "mdi-tumble-dryer"},
    {icon: "mdi-tumblr", text: "mdi-tumblr"},
    {icon: "mdi-tumblr-box", text: "mdi-tumblr-box"},
    {icon: "mdi-tumblr-reblog", text: "mdi-tumblr-reblog"},
    {icon: "mdi-tune", text: "mdi-tune"},
    {icon: "mdi-tune-vertical", text: "mdi-tune-vertical"},
    {icon: "mdi-turnstile", text: "mdi-turnstile"},
    {icon: "mdi-turnstile-outline", text: "mdi-turnstile-outline"},
    {icon: "mdi-turtle", text: "mdi-turtle"},
    {icon: "mdi-twitch", text: "mdi-twitch"},
    {icon: "mdi-twitter", text: "mdi-twitter"},
    {icon: "mdi-twitter-box", text: "mdi-twitter-box"},
    {icon: "mdi-twitter-circle", text: "mdi-twitter-circle"},
    {icon: "mdi-twitter-retweet", text: "mdi-twitter-retweet"},
    {icon: "mdi-two-factor-authentication", text: "mdi-two-factor-authentication"},
    {icon: "mdi-typewriter", text: "mdi-typewriter"},
    {icon: "mdi-uber", text: "mdi-uber"},
    {icon: "mdi-ubisoft", text: "mdi-ubisoft"},
    {icon: "mdi-ubuntu", text: "mdi-ubuntu"},
    {icon: "mdi-ultra-high-definition", text: "mdi-ultra-high-definition"},
    {icon: "mdi-umbraco", text: "mdi-umbraco"},
    {icon: "mdi-umbrella", text: "mdi-umbrella"},
    {icon: "mdi-umbrella-closed", text: "mdi-umbrella-closed"},
    {icon: "mdi-umbrella-outline", text: "mdi-umbrella-outline"},
    {icon: "mdi-undo", text: "mdi-undo"},
    {icon: "mdi-undo-variant", text: "mdi-undo-variant"},
    {icon: "mdi-unfold-less-horizontal", text: "mdi-unfold-less-horizontal"},
    {icon: "mdi-unfold-less-vertical", text: "mdi-unfold-less-vertical"},
    {icon: "mdi-unfold-more-horizontal", text: "mdi-unfold-more-horizontal"},
    {icon: "mdi-unfold-more-vertical", text: "mdi-unfold-more-vertical"},
    {icon: "mdi-ungroup", text: "mdi-ungroup"},
    {icon: "mdi-unicode", text: "mdi-unicode"},
    {icon: "mdi-unity", text: "mdi-unity"},
    {icon: "mdi-unreal", text: "mdi-unreal"},
    {icon: "mdi-untappd", text: "mdi-untappd"},
    {icon: "mdi-update", text: "mdi-update"},
    {icon: "mdi-upload", text: "mdi-upload"},
    {icon: "mdi-upload-multiple", text: "mdi-upload-multiple"},
    {icon: "mdi-upload-network", text: "mdi-upload-network"},
    {icon: "mdi-upload-network-outline", text: "mdi-upload-network-outline"},
    {icon: "mdi-upload-outline", text: "mdi-upload-outline"},
    {icon: "mdi-usb", text: "mdi-usb"},
    {icon: "mdi-valve", text: "mdi-valve"},
    {icon: "mdi-valve-closed", text: "mdi-valve-closed"},
    {icon: "mdi-valve-open", text: "mdi-valve-open"},
    {icon: "mdi-van-passenger", text: "mdi-van-passenger"},
    {icon: "mdi-van-utility", text: "mdi-van-utility"},
    {icon: "mdi-vanish", text: "mdi-vanish"},
    {icon: "mdi-variable", text: "mdi-variable"},
    {icon: "mdi-vector-arrange-above", text: "mdi-vector-arrange-above"},
    {icon: "mdi-vector-arrange-below", text: "mdi-vector-arrange-below"},
    {icon: "mdi-vector-bezier", text: "mdi-vector-bezier"},
    {icon: "mdi-vector-circle", text: "mdi-vector-circle"},
    {icon: "mdi-vector-circle-variant", text: "mdi-vector-circle-variant"},
    {icon: "mdi-vector-combine", text: "mdi-vector-combine"},
    {icon: "mdi-vector-curve", text: "mdi-vector-curve"},
    {icon: "mdi-vector-difference", text: "mdi-vector-difference"},
    {icon: "mdi-vector-difference-ab", text: "mdi-vector-difference-ab"},
    {icon: "mdi-vector-difference-ba", text: "mdi-vector-difference-ba"},
    {icon: "mdi-vector-ellipse", text: "mdi-vector-ellipse"},
    {icon: "mdi-vector-intersection", text: "mdi-vector-intersection"},
    {icon: "mdi-vector-line", text: "mdi-vector-line"},
    {icon: "mdi-vector-link", text: "mdi-vector-link"},
    {icon: "mdi-vector-point", text: "mdi-vector-point"},
    {icon: "mdi-vector-polygon", text: "mdi-vector-polygon"},
    {icon: "mdi-vector-polyline", text: "mdi-vector-polyline"},
    {icon: "mdi-vector-radius", text: "mdi-vector-radius"},
    {icon: "mdi-vector-rectangle", text: "mdi-vector-rectangle"},
    {icon: "mdi-vector-selection", text: "mdi-vector-selection"},
    {icon: "mdi-vector-square", text: "mdi-vector-square"},
    {icon: "mdi-vector-triangle", text: "mdi-vector-triangle"},
    {icon: "mdi-vector-union", text: "mdi-vector-union"},
    {icon: "mdi-venmo", text: "mdi-venmo"},
    {icon: "mdi-vhs", text: "mdi-vhs"},
    {icon: "mdi-vibrate", text: "mdi-vibrate"},
    {icon: "mdi-vibrate-off", text: "mdi-vibrate-off"},
    {icon: "mdi-video", text: "mdi-video"},
    {icon: "mdi-video-3d", text: "mdi-video-3d"},
    {icon: "mdi-video-3d-variant", text: "mdi-video-3d-variant"},
    {icon: "mdi-video-4k-box", text: "mdi-video-4k-box"},
    {icon: "mdi-video-account", text: "mdi-video-account"},
    {icon: "mdi-video-check", text: "mdi-video-check"},
    {icon: "mdi-video-check-outline", text: "mdi-video-check-outline"},
    {icon: "mdi-video-image", text: "mdi-video-image"},
    {icon: "mdi-video-input-antenna", text: "mdi-video-input-antenna"},
    {icon: "mdi-video-input-component", text: "mdi-video-input-component"},
    {icon: "mdi-video-input-hdmi", text: "mdi-video-input-hdmi"},
    {icon: "mdi-video-input-scart", text: "mdi-video-input-scart"},
    {icon: "mdi-video-input-svideo", text: "mdi-video-input-svideo"},
    {icon: "mdi-video-minus", text: "mdi-video-minus"},
    {icon: "mdi-video-off", text: "mdi-video-off"},
    {icon: "mdi-video-off-outline", text: "mdi-video-off-outline"},
    {icon: "mdi-video-outline", text: "mdi-video-outline"},
    {icon: "mdi-video-plus", text: "mdi-video-plus"},
    {icon: "mdi-video-stabilization", text: "mdi-video-stabilization"},
    {icon: "mdi-video-switch", text: "mdi-video-switch"},
    {icon: "mdi-video-vintage", text: "mdi-video-vintage"},
    {icon: "mdi-video-wireless", text: "mdi-video-wireless"},
    {icon: "mdi-video-wireless-outline", text: "mdi-video-wireless-outline"},
    {icon: "mdi-view-agenda", text: "mdi-view-agenda"},
    {icon: "mdi-view-array", text: "mdi-view-array"},
    {icon: "mdi-view-carousel", text: "mdi-view-carousel"},
    {icon: "mdi-view-column", text: "mdi-view-column"},
    {icon: "mdi-view-comfy", text: "mdi-view-comfy"},
    {icon: "mdi-view-compact", text: "mdi-view-compact"},
    {icon: "mdi-view-compact-outline", text: "mdi-view-compact-outline"},
    {icon: "mdi-view-dashboard", text: "mdi-view-dashboard"},
    {icon: "mdi-view-dashboard-outline", text: "mdi-view-dashboard-outline"},
    {icon: "mdi-view-dashboard-variant", text: "mdi-view-dashboard-variant"},
    {icon: "mdi-view-day", text: "mdi-view-day"},
    {icon: "mdi-view-grid", text: "mdi-view-grid"},
    {icon: "mdi-view-grid-plus", text: "mdi-view-grid-plus"},
    {icon: "mdi-view-headline", text: "mdi-view-headline"},
    {icon: "mdi-view-list", text: "mdi-view-list"},
    {icon: "mdi-view-module", text: "mdi-view-module"},
    {icon: "mdi-view-parallel", text: "mdi-view-parallel"},
    {icon: "mdi-view-quilt", text: "mdi-view-quilt"},
    {icon: "mdi-view-sequential", text: "mdi-view-sequential"},
    {icon: "mdi-view-split-horizontal", text: "mdi-view-split-horizontal"},
    {icon: "mdi-view-split-vertical", text: "mdi-view-split-vertical"},
    {icon: "mdi-view-stream", text: "mdi-view-stream"},
    {icon: "mdi-view-week", text: "mdi-view-week"},
    {icon: "mdi-vimeo", text: "mdi-vimeo"},
    {icon: "mdi-violin", text: "mdi-violin"},
    {icon: "mdi-virtual-reality", text: "mdi-virtual-reality"},
    {icon: "mdi-visual-studio", text: "mdi-visual-studio"},
    {icon: "mdi-visual-studio-code", text: "mdi-visual-studio-code"},
    {icon: "mdi-vk", text: "mdi-vk"},
    {icon: "mdi-vk-box", text: "mdi-vk-box"},
    {icon: "mdi-vk-circle", text: "mdi-vk-circle"},
    {icon: "mdi-vlc", text: "mdi-vlc"},
    {icon: "mdi-voice", text: "mdi-voice"},
    {icon: "mdi-voice-off", text: "mdi-voice-off"},
    {icon: "mdi-voicemail", text: "mdi-voicemail"},
    {icon: "mdi-volleyball", text: "mdi-volleyball"},
    {icon: "mdi-volume-high", text: "mdi-volume-high"},
    {icon: "mdi-volume-low", text: "mdi-volume-low"},
    {icon: "mdi-volume-medium", text: "mdi-volume-medium"},
    {icon: "mdi-volume-minus", text: "mdi-volume-minus"},
    {icon: "mdi-volume-mute", text: "mdi-volume-mute"},
    {icon: "mdi-volume-off", text: "mdi-volume-off"},
    {icon: "mdi-volume-plus", text: "mdi-volume-plus"},
    {icon: "mdi-volume-variant-off", text: "mdi-volume-variant-off"},
    {icon: "mdi-vote", text: "mdi-vote"},
    {icon: "mdi-vote-outline", text: "mdi-vote-outline"},
    {icon: "mdi-vpn", text: "mdi-vpn"},
    {icon: "mdi-vuejs", text: "mdi-vuejs"},
    {icon: "mdi-vuetify", text: "mdi-vuetify"},
    {icon: "mdi-walk", text: "mdi-walk"},
    {icon: "mdi-wall", text: "mdi-wall"},
    {icon: "mdi-wall-sconce", text: "mdi-wall-sconce"},
    {icon: "mdi-wall-sconce-flat", text: "mdi-wall-sconce-flat"},
    {icon: "mdi-wall-sconce-variant", text: "mdi-wall-sconce-variant"},
    {icon: "mdi-wallet", text: "mdi-wallet"},
    {icon: "mdi-wallet-giftcard", text: "mdi-wallet-giftcard"},
    {icon: "mdi-wallet-membership", text: "mdi-wallet-membership"},
    {icon: "mdi-wallet-outline", text: "mdi-wallet-outline"},
    {icon: "mdi-wallet-plus", text: "mdi-wallet-plus"},
    {icon: "mdi-wallet-plus-outline", text: "mdi-wallet-plus-outline"},
    {icon: "mdi-wallet-travel", text: "mdi-wallet-travel"},
    {icon: "mdi-wallpaper", text: "mdi-wallpaper"},
    {icon: "mdi-wan", text: "mdi-wan"},
    {icon: "mdi-wardrobe", text: "mdi-wardrobe"},
    {icon: "mdi-wardrobe-outline", text: "mdi-wardrobe-outline"},
    {icon: "mdi-warehouse", text: "mdi-warehouse"},
    {icon: "mdi-washing-machine", text: "mdi-washing-machine"},
    {icon: "mdi-watch", text: "mdi-watch"},
    {icon: "mdi-watch-export", text: "mdi-watch-export"},
    {icon: "mdi-watch-export-variant", text: "mdi-watch-export-variant"},
    {icon: "mdi-watch-import", text: "mdi-watch-import"},
    {icon: "mdi-watch-import-variant", text: "mdi-watch-import-variant"},
    {icon: "mdi-watch-variant", text: "mdi-watch-variant"},
    {icon: "mdi-watch-vibrate", text: "mdi-watch-vibrate"},
    {icon: "mdi-watch-vibrate-off", text: "mdi-watch-vibrate-off"},
    {icon: "mdi-water", text: "mdi-water"},
    {icon: "mdi-water-boiler", text: "mdi-water-boiler"},
    {icon: "mdi-water-off", text: "mdi-water-off"},
    {icon: "mdi-water-outline", text: "mdi-water-outline"},
    {icon: "mdi-water-percent", text: "mdi-water-percent"},
    {icon: "mdi-water-pump", text: "mdi-water-pump"},
    {icon: "mdi-water-pump-off", text: "mdi-water-pump-off"},
    {icon: "mdi-water-well", text: "mdi-water-well"},
    {icon: "mdi-water-well-outline", text: "mdi-water-well-outline"},
    {icon: "mdi-watermark", text: "mdi-watermark"},
    {icon: "mdi-wave", text: "mdi-wave"},
    {icon: "mdi-waves", text: "mdi-waves"},
    {icon: "mdi-waze", text: "mdi-waze"},
    {icon: "mdi-weather-cloudy", text: "mdi-weather-cloudy"},
    {icon: "mdi-weather-cloudy-alert", text: "mdi-weather-cloudy-alert"},
    {icon: "mdi-weather-cloudy-arrow-right", text: "mdi-weather-cloudy-arrow-right"},
    {icon: "mdi-weather-fog", text: "mdi-weather-fog"},
    {icon: "mdi-weather-hail", text: "mdi-weather-hail"},
    {icon: "mdi-weather-hazy", text: "mdi-weather-hazy"},
    {icon: "mdi-weather-hurricane", text: "mdi-weather-hurricane"},
    {icon: "mdi-weather-lightning", text: "mdi-weather-lightning"},
    {icon: "mdi-weather-lightning-rainy", text: "mdi-weather-lightning-rainy"},
    {icon: "mdi-weather-night", text: "mdi-weather-night"},
    {icon: "mdi-weather-night-partly-cloudy", text: "mdi-weather-night-partly-cloudy"},
    {icon: "mdi-weather-partly-cloudy", text: "mdi-weather-partly-cloudy"},
    {icon: "mdi-weather-partly-lightning", text: "mdi-weather-partly-lightning"},
    {icon: "mdi-weather-partly-rainy", text: "mdi-weather-partly-rainy"},
    {icon: "mdi-weather-partly-snowy", text: "mdi-weather-partly-snowy"},
    {icon: "mdi-weather-partly-snowy-rainy", text: "mdi-weather-partly-snowy-rainy"},
    {icon: "mdi-weather-pouring", text: "mdi-weather-pouring"},
    {icon: "mdi-weather-rainy", text: "mdi-weather-rainy"},
    {icon: "mdi-weather-snowy", text: "mdi-weather-snowy"},
    {icon: "mdi-weather-snowy-heavy", text: "mdi-weather-snowy-heavy"},
    {icon: "mdi-weather-snowy-rainy", text: "mdi-weather-snowy-rainy"},
    {icon: "mdi-weather-sunny", text: "mdi-weather-sunny"},
    {icon: "mdi-weather-sunny-alert", text: "mdi-weather-sunny-alert"},
    {icon: "mdi-weather-sunset", text: "mdi-weather-sunset"},
    {icon: "mdi-weather-sunset-down", text: "mdi-weather-sunset-down"},
    {icon: "mdi-weather-sunset-up", text: "mdi-weather-sunset-up"},
    {icon: "mdi-weather-tornado", text: "mdi-weather-tornado"},
    {icon: "mdi-weather-windy", text: "mdi-weather-windy"},
    {icon: "mdi-weather-windy-variant", text: "mdi-weather-windy-variant"},
    {icon: "mdi-web", text: "mdi-web"},
    {icon: "mdi-web-box", text: "mdi-web-box"},
    {icon: "mdi-webcam", text: "mdi-webcam"},
    {icon: "mdi-webhook", text: "mdi-webhook"},
    {icon: "mdi-webpack", text: "mdi-webpack"},
    {icon: "mdi-wechat", text: "mdi-wechat"},
    {icon: "mdi-weight", text: "mdi-weight"},
    {icon: "mdi-weight-gram", text: "mdi-weight-gram"},
    {icon: "mdi-weight-kilogram", text: "mdi-weight-kilogram"},
    {icon: "mdi-weight-pound", text: "mdi-weight-pound"},
    {icon: "mdi-whatsapp", text: "mdi-whatsapp"},
    {icon: "mdi-wheelchair-accessibility", text: "mdi-wheelchair-accessibility"},
    {icon: "mdi-whistle", text: "mdi-whistle"},
    {icon: "mdi-white-balance-auto", text: "mdi-white-balance-auto"},
    {icon: "mdi-white-balance-incandescent", text: "mdi-white-balance-incandescent"},
    {icon: "mdi-white-balance-iridescent", text: "mdi-white-balance-iridescent"},
    {icon: "mdi-white-balance-sunny", text: "mdi-white-balance-sunny"},
    {icon: "mdi-widgets", text: "mdi-widgets"},
    {icon: "mdi-wifi", text: "mdi-wifi"},
    {icon: "mdi-wifi-off", text: "mdi-wifi-off"},
    {icon: "mdi-wifi-star", text: "mdi-wifi-star"},
    {icon: "mdi-wifi-strength-1", text: "mdi-wifi-strength-1"},
    {icon: "mdi-wifi-strength-1-alert", text: "mdi-wifi-strength-1-alert"},
    {icon: "mdi-wifi-strength-1-lock", text: "mdi-wifi-strength-1-lock"},
    {icon: "mdi-wifi-strength-2", text: "mdi-wifi-strength-2"},
    {icon: "mdi-wifi-strength-2-alert", text: "mdi-wifi-strength-2-alert"},
    {icon: "mdi-wifi-strength-2-lock", text: "mdi-wifi-strength-2-lock"},
    {icon: "mdi-wifi-strength-3", text: "mdi-wifi-strength-3"},
    {icon: "mdi-wifi-strength-3-alert", text: "mdi-wifi-strength-3-alert"},
    {icon: "mdi-wifi-strength-3-lock", text: "mdi-wifi-strength-3-lock"},
    {icon: "mdi-wifi-strength-4", text: "mdi-wifi-strength-4"},
    {icon: "mdi-wifi-strength-4-alert", text: "mdi-wifi-strength-4-alert"},
    {icon: "mdi-wifi-strength-4-lock", text: "mdi-wifi-strength-4-lock"},
    {icon: "mdi-wifi-strength-alert-outline", text: "mdi-wifi-strength-alert-outline"},
    {icon: "mdi-wifi-strength-lock-outline", text: "mdi-wifi-strength-lock-outline"},
    {icon: "mdi-wifi-strength-off", text: "mdi-wifi-strength-off"},
    {icon: "mdi-wifi-strength-off-outline", text: "mdi-wifi-strength-off-outline"},
    {icon: "mdi-wifi-strength-outline", text: "mdi-wifi-strength-outline"},
    {icon: "mdi-wii", text: "mdi-wii"},
    {icon: "mdi-wiiu", text: "mdi-wiiu"},
    {icon: "mdi-wikipedia", text: "mdi-wikipedia"},
    {icon: "mdi-wind-turbine", text: "mdi-wind-turbine"},
    {icon: "mdi-window-close", text: "mdi-window-close"},
    {icon: "mdi-window-closed", text: "mdi-window-closed"},
    {icon: "mdi-window-maximize", text: "mdi-window-maximize"},
    {icon: "mdi-window-minimize", text: "mdi-window-minimize"},
    {icon: "mdi-window-open", text: "mdi-window-open"},
    {icon: "mdi-window-restore", text: "mdi-window-restore"},
    {icon: "mdi-windows", text: "mdi-windows"},
    {icon: "mdi-windows-classic", text: "mdi-windows-classic"},
    {icon: "mdi-wiper", text: "mdi-wiper"},
    {icon: "mdi-wiper-wash", text: "mdi-wiper-wash"},
    {icon: "mdi-wordpress", text: "mdi-wordpress"},
    {icon: "mdi-worker", text: "mdi-worker"},
    {icon: "mdi-wrap", text: "mdi-wrap"},
    {icon: "mdi-wrap-disabled", text: "mdi-wrap-disabled"},
    {icon: "mdi-wrench", text: "mdi-wrench"},
    {icon: "mdi-wrench-outline", text: "mdi-wrench-outline"},
    {icon: "mdi-wunderlist", text: "mdi-wunderlist"},
    {icon: "mdi-xamarin", text: "mdi-xamarin"},
    {icon: "mdi-xamarin-outline", text: "mdi-xamarin-outline"},
    {icon: "mdi-xaml", text: "mdi-xaml"},
    {icon: "mdi-xbox", text: "mdi-xbox"},
    {icon: "mdi-xbox-controller", text: "mdi-xbox-controller"},
    {icon: "mdi-xbox-controller-battery-alert", text: "mdi-xbox-controller-battery-alert"},
    {icon: "mdi-xbox-controller-battery-charging", text: "mdi-xbox-controller-battery-charging"},
    {icon: "mdi-xbox-controller-battery-empty", text: "mdi-xbox-controller-battery-empty"},
    {icon: "mdi-xbox-controller-battery-full", text: "mdi-xbox-controller-battery-full"},
    {icon: "mdi-xbox-controller-battery-low", text: "mdi-xbox-controller-battery-low"},
    {icon: "mdi-xbox-controller-battery-medium", text: "mdi-xbox-controller-battery-medium"},
    {icon: "mdi-xbox-controller-battery-unknown", text: "mdi-xbox-controller-battery-unknown"},
    {icon: "mdi-xbox-controller-menu", text: "mdi-xbox-controller-menu"},
    {icon: "mdi-xbox-controller-off", text: "mdi-xbox-controller-off"},
    {icon: "mdi-xbox-controller-view", text: "mdi-xbox-controller-view"},
    {icon: "mdi-xda", text: "mdi-xda"},
    {icon: "mdi-xing", text: "mdi-xing"},
    {icon: "mdi-xing-box", text: "mdi-xing-box"},
    {icon: "mdi-xing-circle", text: "mdi-xing-circle"},
    {icon: "mdi-xml", text: "mdi-xml"},
    {icon: "mdi-xmpp", text: "mdi-xmpp"},
    {icon: "mdi-yahoo", text: "mdi-yahoo"},
    {icon: "mdi-yammer", text: "mdi-yammer"},
    {icon: "mdi-yeast", text: "mdi-yeast"},
    {icon: "mdi-yelp", text: "mdi-yelp"},
    {icon: "mdi-yin-yang", text: "mdi-yin-yang"},
    {icon: "mdi-youtube", text: "mdi-youtube"},
    {icon: "mdi-youtube-creator-studio", text: "mdi-youtube-creator-studio"},
    {icon: "mdi-youtube-gaming", text: "mdi-youtube-gaming"},
    {icon: "mdi-youtube-subscription", text: "mdi-youtube-subscription"},
    {icon: "mdi-youtube-tv", text: "mdi-youtube-tv"},
    {icon: "mdi-z-wave", text: "mdi-z-wave"},
    {icon: "mdi-zend", text: "mdi-zend"},
    {icon: "mdi-zigbee", text: "mdi-zigbee"},
    {icon: "mdi-zip-box", text: "mdi-zip-box"},
    {icon: "mdi-zip-box-outline", text: "mdi-zip-box-outline"},
    {icon: "mdi-zip-disk", text: "mdi-zip-disk"},
    {icon: "mdi-zodiac-aquarius", text: "mdi-zodiac-aquarius"},
    {icon: "mdi-zodiac-aries", text: "mdi-zodiac-aries"},
    {icon: "mdi-zodiac-cancer", text: "mdi-zodiac-cancer"},
    {icon: "mdi-zodiac-capricorn", text: "mdi-zodiac-capricorn"},
    {icon: "mdi-zodiac-gemini", text: "mdi-zodiac-gemini"},
    {icon: "mdi-zodiac-leo", text: "mdi-zodiac-leo"},
    {icon: "mdi-zodiac-libra", text: "mdi-zodiac-libra"},
    {icon: "mdi-zodiac-pisces", text: "mdi-zodiac-pisces"},
    {icon: "mdi-zodiac-sagittarius", text: "mdi-zodiac-sagittarius"},
    {icon: "mdi-zodiac-scorpio", text: "mdi-zodiac-scorpio"},
    {icon: "mdi-zodiac-taurus", text: "mdi-zodiac-taurus"},
    {icon: "mdi-zodiac-virgo", text: "mdi-zodiac-virgo"},
    {icon: "mdi-blank", text: "mdi-blank"}
]

export default {
    icons
}
