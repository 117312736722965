,
<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card :loading="loadingSubscriptions" color="white">
                    <v-toolbar color="secondary" dark>
                        <v-toolbar-title class="text-center">
                            Kalenderprenumerationer
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn :class="{'highlight-pulse': calendarSubscriptionTokens.length <= 0}"
                                       @click="newCalendarSubscriptionToken" color="primary"
                                       fab
                                       small
                                       v-on="on">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Skapa en ny kalenderprenumeration</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card-text>
                        <calendar-subscription-help-dialog-component></calendar-subscription-help-dialog-component>
                        <v-list>
                            <v-list-item :key="item.token" v-for="item in calendarSubscriptionTokens">
                                <v-list-item-icon class="mr-8">
                                    <span class="grey--text text--darken-2">
                                        <span class="font-weight-medium">Skapad</span>
                                    <p>{{$moment(item.createdAt).format('YYYY-MM-DD')}}</p>
                                    </span>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-menu :activator="'#link_' + item.id" offset-y>
                                            <v-list>
                                                <v-list-item :href="getEndpointUrl(item.token)" target="_blank">
                                                    <v-list-item-icon><v-icon>mdi-dock-window</v-icon></v-list-item-icon>
                                                    <v-list-item-title>Öppna länk i ditt kalenderprogram</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="copyToClipboard(item.token)">
                                                    <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
                                                    <v-list-item-title>Kopiera länk</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom open-delay="250">
                                            <template v-slot:activator="{ on }">
                                                <v-chip
                                                        :id="'link_' + item.id"
                                                        outlined
                                                        v-on="on"
                                                        class="clickable"
                                                >
                                                    <v-icon
                                                            class="mr-2">mdi-link
                                                    </v-icon>
                                                    <span>{{getEndpointUrl(item.token)}}</span>
                                                </v-chip>
                                            </template>
                                            <span>Klicka för att öppna eller kopiera länken</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="pl-1" style="justify-content: center;">
                                    <v-speed-dial
                                            class="remove-padding"
                                            direction="left"
                                            transition="slide-x-reverse-transition"
                                            v-model="item.fab"
                                    >
                                        <template v-slot:activator>
                                            <v-btn icon>
                                                <v-icon color="grey">{{item.fab ? 'mdi-close': 'mdi-dots-vertical'}}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <div>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            @click="selectSubscriptionToken(item)"
                                                            class="force-on-top"
                                                            color="primary"
                                                            dark
                                                            fab
                                                            small
                                                            v-on="on"
                                                    >
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Redigera denna kalenderprenumeration</span>
                                            </v-tooltip>
                                        </div>
                                        <div>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            @click="confirmDelete(item)"
                                                            class="force-on-top"
                                                            color="error"
                                                            dark
                                                            fab
                                                            small
                                                            v-on="on"
                                                    >
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Radera denna kalenderprenumeration</span>
                                            </v-tooltip>
                                        </div>
                                    </v-speed-dial>
                                </v-list-item-action>
                            </v-list-item>
                            <no-data-component icon="arrow_upward" pulse text="Du har inte skapat några kalenderprenumerationer än. Använd
            plus-knappen längst upp till höger för att göra det"
                                               v-if="calendarSubscriptionTokens.length <= 0"></no-data-component>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog max-width="600px" persistent v-model="dialogIsOpen">
            <calendar-subscription-details-component :shown="dialogIsOpen"
                                                     :subscription-token-id="selectedCalendarSubscriptionToken.id"
                                                     @cancel="closeDialog"
                                                     @saved="calendarSubscriptionTokenSaved">
            </calendar-subscription-details-component>
        </v-dialog>
    </v-container>
</template>

<script>

    import api from "../../api/api"
    import NoDataComponent from "../../components/global/NoDataComponent";
    import CalendarSubscriptionHelpDialogComponent
        from "../../components/calendarsubscription/CalendarSubscriptionHelpDialogComponent";
    import CalendarSubscriptionDetailsComponent
        from "../../components/calendarsubscription/CalendarSubscriptionDetailsComponent";

    export default {
        components: {
            CalendarSubscriptionDetailsComponent,
            CalendarSubscriptionHelpDialogComponent,
            NoDataComponent
        },
        data: () => ({
            loadingSubscriptions: true,
            calendarSubscriptionTokens: [],
            selectedCalendarSubscriptionToken: {
                id: 0
            },
            dialogIsOpen: false
        }),
        computed: {
            stateUser()
            {
                return this.$store.state.user
            },
        },
        mounted()
        {
            this.getCalendarSubscriptionTokens()
        },
        methods: {
            async getCalendarSubscriptionTokens()
            {
                this.loadingSubscriptions = true
                let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKENS)
                if (serverResponse)
                {
                    this.calendarSubscriptionTokens = serverResponse
                }
                this.loadingSubscriptions = false
            },
            newCalendarSubscriptionToken()
            {
                this.selectedCalendarSubscriptionToken = {id: 0}
                this.dialogIsOpen = true
            },
            selectSubscriptionToken(item)
            {
                this.selectedCalendarSubscriptionToken = item
                this.dialogIsOpen = true
            },
            closeDialog()
            {
                this.dialogIsOpen = false
            },
            calendarSubscriptionTokenSaved()
            {
                this.getCalendarSubscriptionTokens()
                this.closeDialog()
            },
            confirmDelete(item)
            {
                this.$store.commit('setDialog', {
                    active: true,
                    closeBtnText: "Avbryt",
                    actionBtnText: "Ta bort",
                    title: "Bekräfta borttagning",
                    text: "Är du säker på att du vill ta bort denna kalenderprenumeration?",
                    actionClick: () =>
                    {
                        this.deleteRequest(item)
                    }
                })
            },
            copyToClipboard(text)
            {
                var tempInput = document.createElement("input");
                tempInput.style = "position: absolute; left: -1000px; top: -1000px";
                tempInput.value = this.getEndpointUrl(text);
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand("copy");
                document.body.removeChild(tempInput);
                this.$store.commit('setSnackbar', {
                    color: "primary",
                    text: "Kopierade länken till urklipp!"
                })
            },
            getEndpointUrl(token)
            {
                return "webcal://" + api.BASE_DOMAIN + "/" + api.API + api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKENS + "/" + token + "/" + api.ENDPOINTS.CALENDAREVENTS + "/ical"
            },
            async deleteRequest(item)
            {
                this.$store.commit('setAppIsLoading', true)
                await api.deleteReqToEndpoint(api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKENS + "/" + item.id)
                this.$store.commit('setAppIsLoading', false)
                this.$store.commit('setSnackbar', {color: "primary", text: "Kalenderprenumeration borttagen"})
                this.getCalendarSubscriptionTokens()
            },
        }
    }
</script>
