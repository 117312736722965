<template>
  <div>
    <v-card
        :disabled="loading"
        :flat="flat"
        :loading="loading"
        :outlined="flat"
    >
      <v-card-title class="secondary">
        <v-icon
            dark
            left
        >
          mdi-file-document-outline
        </v-icon>
        <span class="title textfont-weight-light white--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            v-model="validForm"

            @submit.prevent
        >
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-subheader class="pl-0">Grundläggande information</v-subheader>
                <v-text-field
                    v-model="dynamicDocument.name"
                    :counter="100"
                    :rules="[v => !!v || 'Du måste ange ett namn']"
                    autofocus
                    class="mb-3"
                    label="Namn"
                    required
                    @keyup="unsavedChangesValue = true"
                    @keyup.enter="saveDynamicDocument"
                ></v-text-field>
                <v-textarea
                    v-model="dynamicDocument.description"
                    :counter="1024"
                    auto-grow
                    label="Beskrivning"
                    outlined
                    rows="2"
                    @keyup="unsavedChangesValue = true"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="dynamicDocument.id && dynamicDocument.id > 0" cols="12" md="6">
                <dynamic-document-revisions-component ref="revisions" :dynamic-document="dynamicDocument"
                                                      :opened-index="1" flat outlined
                                                      @itemClick="revisionClick"></dynamic-document-revisions-component>
              </v-col>
              <v-col :md="dynamicDocument.id && dynamicDocument.id > 0 ? 6:12" cols="12">
                <dynamic-document-object-data-component :opened-index="1" flat outlined
                                                        @itemInsert="insertValueInContent"></dynamic-document-object-data-component>
              </v-col>
              <v-col cols="12">
                <v-subheader class="pl-0">
                  Innehåll
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="contentFullscreen = true">
                        <v-icon>mdi-fullscreen</v-icon>
                      </v-btn>
                    </template>
                    <span>Öppna i helskärm</span>
                  </v-tooltip>
                </v-subheader>
                <text-editor-component ref="editor" :value.sync="dynamicDocument.content"
                                       placeholder="Här skriver du innehållet i dokumentet. I verktygsraden ovan hittar du användbara verktyg såsom teckenstorlek. Dynamisk data kan användas för att hämta in dynamisk text från perioden när dokumentet genereras, t.ex: kundens namn, incheckningsdatum och mycket mer!"></text-editor-component>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions id="cardActions">
        <v-btn :disabled="loading" color="default"
               @click="cancel">
          Avbryt
        </v-btn>
        <v-btn v-if="this.objectId > 0" :disabled="loading" color="error"
               @click="confirmDelete">
          Ta bort
          <v-icon right>delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" :disabled="dynamicDocument.content.length === 0" color="accent"
               @click="showPreview">Förhandsgranska
          <v-icon right>mdi-file-eye</v-icon>
        </v-btn>
        <v-btn :loading="loading" color="primary" :disabled="dynamicDocument.content.length === 0"
               @click="saveDynamicDocument()">Spara
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="contentFullscreen"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
            dense
        >
          <v-btn
              dark
              icon
              @click="contentFullscreen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Innehåll</v-toolbar-title>
        </v-toolbar>
        <text-editor-component :value.sync="dynamicDocument.content"
                               placeholder="Här skriver du innehållet i dokumentet. I verktygsraden ovan hittar du användbara verktyg såsom teckenstorlek. Dynamisk data kan användas för att hämta in dynamisk text från perioden när dokumentet genereras, t.ex: kundens namn, incheckningsdatum och mycket mer!"></text-editor-component>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="previewDialog"
        eager
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
            dense
        >
          <v-btn
              dark
              icon
              @click="previewDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Förhandsgranskning
          </v-toolbar-title>
          <booking-period-autocomplete-component
              ref="periodselect"
              v-model="bookingPeriodPreview"
              class="ml-4"
              clearable
              hide-details
              solo
              @change="showPreview"
          ></booking-period-autocomplete-component>
        </v-toolbar>
        <dynamic-document-render-component ref="preview"
                                           :booking-period-id="bookingPeriodId"
                                           :content="dynamicDocument.content"></dynamic-document-render-component>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../api/api";
import {validateForm} from "@/util/functions";
import TextEditorComponent from "@/components/global/TextEditorComponent";
import DynamicDocumentRevisionsComponent from "@/components/dynamicdocument/DynamicDocumentRevisionsComponent";
import DynamicDocumentRenderComponent from "@/components/dynamicdocument/DynamicDocumentRenderComponent";
import DynamicDocumentObjectDataComponent from "@/components/dynamicdocument/DynamicDocumentObjectDataComponent";
import BookingPeriodAutocompleteComponent from "@/components/bookingperiod/BookingPeriodAutocompleteComponent";

export default {
  components: {
    BookingPeriodAutocompleteComponent,
    DynamicDocumentObjectDataComponent,
    DynamicDocumentRenderComponent, DynamicDocumentRevisionsComponent, TextEditorComponent
  },
  data: () => ({
    validForm: true,
    loading: false,
    contentFullscreen: false,
    previewDialog: false,
    bookingPeriodPreview: { // structure must be set up in this way for booking-period-autocomplete-component to work
      bookingPeriod: null
    },
    dynamicDocument: {
      userAccountId: 0,
      content: "",
      name: "",
      active: true,
      description: "",
    },
  }),
  props: {
    editId: {
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    unsavedChanges: Boolean
  },
  watch: {
    'dynamicDocument.content'()
    {
      this.unsavedChangesValue = true
    }
  },
  computed: {
    title()
    {
      return this.objectId > 0 ? `Redigerar ${this.dynamicDocument.name}` : "Nytt dynamiskt dokument"
    },
    objectId()
    {
      if (this.editId)
      {
        return this.editId
      }
      else
      {
        return this.$route.params.id
      }
    },
    templateId()
    {
      return this.$route.query.templateId
    },
    stateUser()
    {
      return this.$store.state.user
    },
    unsavedChangesValue: {
      get()
      {
        return this.unsavedChanges
      },
      set(val)
      {
        this.$emit('update:unsavedChanges', val)
      }
    },
    bookingPeriodId()
    {
      return this.bookingPeriodPreview.bookingPeriod ? this.bookingPeriodPreview.bookingPeriod.id : null
    }
  },
  async mounted()
  {
    this.dynamicDocument.userAccountId = this.stateUser.id
    if (this.objectId > 0)
    {
      let serverResponse = await this.getDynamicDocument(this.objectId)
      if (serverResponse)
      {
        Object.assign(this.dynamicDocument, serverResponse)
      }
    }
    else if (this.templateId)
    {
      // templates are dynamicDocuments aswell
      let serverResponse = await this.getDynamicDocument(this.templateId)
      if (serverResponse)
      {
        Object.assign(this.dynamicDocument, serverResponse)
        this.dynamicDocument.userAccountId = this.stateUser.id
        this.dynamicDocument.id = 0
      }
    }
    this.$nextTick(function ()
    {
      this.unsavedChangesValue = false // reset unsavedChanges after loading
    })
  },
  methods: {
    async getDynamicDocument(id)
    {
      return await api.getFromEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + id)
    },
    cancel()
    {
      this.$router.replace({name: "dynamicDocuments"})
    },
    confirmDelete()
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill ta bort ${this.dynamicDocument.name}?`,
        actionClick: this.deleteDynamicDocument
      })
    },
    async deleteDynamicDocument()
    {
      this.loading = true
      await api.deleteReqToEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + this.dynamicDocument.id)
      this.loading = false
      this.$store.commit('setSnackbar', {color: "primary", text: `${this.dynamicDocument.name} borttagen`})
      this.$router.replace({name: "dynamicDocuments"})
    },
    async saveDynamicDocument()
    {
      let form = this.$refs.form

      if (validateForm(form))
      {
        let saveText = `${this.dynamicDocument.name} skapad`
        this.loading = true
        if (this.dynamicDocument.id > 0) // updating existing
        {
          saveText = `${this.dynamicDocument.name} uppdaterad`
          this.dynamicDocument = await api.putToEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + this.dynamicDocument.id, this.dynamicDocument)
          // refresh revisions when updating an existing DynamicDocument
          this.$refs.revisions.getRevisionsForDynamicDocument()
        }
        else // creating a new
        {
          this.dynamicDocument = await api.postToEndpoint(api.ENDPOINTS.DYNAMICDOCUMENT, this.dynamicDocument)
          this.$router.replace({name: "dynamicDocuments"})
        }
        this.loading = false
        if (this.dynamicDocument != null)
        {
          this.$store.commit('setSnackbar', {color: "success", text: saveText})
        }
        this.unsavedChangesValue = false // reset unsavedChanges after saving
      }
    },
    revisionClick(revisedContent)
    {
      this.dynamicDocument.content = revisedContent
    },
    showPreview()
    {
      this.previewDialog = true
      this.$nextTick(function ()
      {
        this.$refs.preview.refreshRender()
      })
    },
    insertValueInContent(value)
    {
      this.$refs.editor.insertTextAtCursorPosition(value)
      // Scroll to the bottom element
      document.getElementById('cardActions').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>
