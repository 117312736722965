<template>
  <v-item v-slot="{ active, toggle }">
    <div>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card elevation="4" shaped raised class="text-center" @click="toggle" height="300" width="100%">
            <div class="pt-6">
              <v-icon :color="item.color" x-large>{{ item.icon }}</v-icon>
            </div>
            <h2 class="mt-5">{{ item.name }}</h2>
            <v-card-text>{{ item.description }}</v-card-text>
            <v-fade-transition>
              <v-overlay
                  absolute
                  color="secondary"
                  opacity="0.7"
                  v-show="hover && !active"
              >
                <span><b>Välj</b></span>
              </v-overlay>
            </v-fade-transition>
            <v-scroll-y-transition>
              <v-overlay
                  absolute
                  color="success"
                  opacity="0.65"
                  v-if="active"
              >
                <span><b><v-icon dark>mdi-check-circle</v-icon></b></span>
              </v-overlay>
            </v-scroll-y-transition>
          </v-card>
        </template>
      </v-hover>
    </div>
  </v-item>
</template>

<script>

export default {
  props: {
    template: Object,
    active: Boolean
  },
  computed: {
    item: {
      get: function () {
        return this.template;
      },
      set: function (newValue) {
        this.$emit('update:template', newValue)
      }
    },
  },
}
</script>
