<template>
    <v-list-item
            @click="fileClicked(file)"
    >
        <v-list-item-icon>
            <v-icon color="primary">{{fileTypes.properties[fileData.filetype].icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                <span>{{file.name}}</span>
            </v-list-item-title>
            <v-list-item-subtitle><b>Filtyp: </b> {{fileData.extension}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar v-if="fileData.filetype === fileTypes.IMAGE" tile>
            <v-img :src="getFileUrl(file.fileData)"></v-img>
        </v-list-item-avatar>
    </v-list-item>
</template>

<script>

    import filetypes from "../../../models/filetypes";
    import api from "../../../api/api";

    export default {
        components: {},
        data: () => ({
            fileTypes: filetypes.TYPES,
        }),
        props: {
            file: Object,
        },
        computed: {
            fileData()
            {
                return this.file.fileData
            }
        },
        methods: {
            getFileUrl(file)
            {
                return api.BASE_URL + file.src
            },
            fileClicked(file)
            {
                this.$emit("fileClicked", file)
            }
        }
    }
</script>
<style scoped>
    .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
        display: inline;
    }
</style>
