<template>
  <v-autocomplete
      :items="bookingObjects"
      v-model="bookingObjectValue"
      @keyup.enter="keyupEnter"
      :required="required"
      :rules="rules"
      :return-object="returnObject"
      placeholder="Välj uthyrningsobjekt"
      item-text="name"
      item-value="id"
      no-data-text="Din sökning gav inga resultat"
      :disabled="readOnly"
      :loading="loading"
      @change="change"
      :multiple="multiple"
      :clearable="clearable"
  >
    <template v-slot:selection="data">
      <v-avatar left>
        <initials-avatar-component
            :imgSrc="getImageUrl(data.item)"
            :size="30"
            :fullname="data.item.name"
        ></initials-avatar-component>
      </v-avatar>
      {{ data.item.name }}
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <initials-avatar-component
              :imgSrc="getImageUrl(data.item)"
              :size="30"
              :fullname="data.item.name"
          ></initials-avatar-component>
        </v-list-item-avatar>
        <v-list-item-content style="max-width: 280px;">
          <v-list-item-title>{{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {InitialsAvatarComponent},
  data: () =>
  {
    return {
      bookingObjects: [],
      loading: false
    }
  },
  props: {
    value: Object, // value.bookingObjects must be an array if multiple, otherwise value.bookingObject should be an object
    readOnly: Boolean,
    required: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    returnObject: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rules()
    {
      return this.required ? [v => !!v || 'Du måste välja ett uthyrningsobjekt'] : []
    },
    bookingObjectValue: {
      get()
      {
        return this.multiple ? this.value.bookingObjects : this.value.bookingObject
      },
      set(val)
      {
        if (this.multiple)
        {
          this.value.bookingObjects = val
        }
        else
        {
          this.value.bookingObject = val
        }
      }
    }
  },
  watch: {
    bookingObjectValue()
    {
      this.selectFirstIfOnlyOne()
    }
  },
  async mounted()
  {
    this.loading = true
    let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGOBJECTS)
    if (serverResponse)
    {
      this.bookingObjects = serverResponse
      this.selectFirstIfOnlyOne()
    }
    this.loading = false
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    change(data)
    {
      this.$emit("change", data)
    },
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    setBookingObjectValue(obj) // replaces BookingObjectValue with the provided object according to returnObject and multiple props
    {
      if (this.returnObject)
      {
        if (this.multiple)
        {
          this.bookingObjectValue = [obj]
        }
        else
        {
          this.bookingObjectValue = obj
        }
      }
      else
      {
        if (this.multiple)
        {
          this.bookingObjectValue = [obj.id]
        }
        else
        {
          this.bookingObjectValue = obj.id
        }
      }
    },
    selectFirstIfOnlyOne()
    {
      if (this.required && this.bookingObjects.length === 1 && !this.bookingObjectValue)
      {
        this.setBookingObjectValue(this.bookingObjects[0])
        if (this.bookingObjectValue)
        {
          this.change(this.bookingObjectValue)
        }
      }
    }
  }
}
</script>
