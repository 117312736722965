<template>
    <v-container fill-height fluid>
        <v-layout align-center justify-center>
            <v-flex md4 sm8 xs12>
                <v-slide-y-transition appear mode="out-in">
                    <div class="text-center" v-if="loading">
                        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
                    </div>
                    <v-alert class="elevation-6" prominent type="success" v-else>
                        <h3 data-cy="emailConfirmedHeader">E-postadress bekräftad!</h3>
                        <p>Ditt konto är nu registrerat och din e-postadress är bekräftad. Använd knappen nedan för att
                            logga in och sätta igång</p>
                        <v-btn class="float-right" href="/login" color="white" outlined>Logga in</v-btn>
                    </v-alert>
                </v-slide-y-transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import api from "../../api/api";
    import Axios from "axios";

    export default {
        components: {},
        computed: {
            registrationToken()
            {
                return this.$route.params.token
            },
        },
        data: () => ({
            loading: true,
        }),
        async mounted()
        {
            this.loading = true
            // Check to see if the token is valid
            // custom API call here to handle errors
            try
            {
                await Axios.put(api.BASE_URL + api.API + api.ENDPOINTS.ACCOUNTLOGINS + "/" + api.ENDPOINTS.REGISTRATION + "/" + this.registrationToken)
            }
            catch (error)
            {
                // ignore forbidden error since most email clients pre-render link when clicked which means user will always see error otherwise
            }
            this.loading = false
        },
    }
</script>

<style scoped>
    .align-middle {
        vertical-align: middle;
    }

    .toolbar-logo {
        position: absolute;
        right: 20px;
    }

    .highlight-pulse {
        box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
        animation: highlight-pulse 2s infinite;
    }

    .highlight-pulse:hover {
        animation: none;
    }

    @-webkit-keyframes highlight-pulse {
        0% {
            box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
        }
        70% {
            -webkit-box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
        }
    }

    @keyframes highlight-pulse {
        0% {
            -moz-box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
            box-shadow: 0 0 0 rgba(255, 228, 234, 0.8);
        }
        70% {
            -moz-box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
            box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
            box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
        }
    }


    .pulse-up {
        -webkit-animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
        animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
    }

    @-webkit-keyframes pulse-up {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }
    }

    @keyframes pulse-up {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }
    }
</style>
