<template>
  <v-dialog max-width="450px" persistent v-model="dialog">
    <v-card>
      <v-card-title class="headline secondary--text">Ej sparade ändringar
        <v-spacer></v-spacer>
        <v-icon color="secondary" right>mdi-content-save-alert-outline</v-icon>
      </v-card-title>
      <v-card-text class="mt-2">
        <p class="body-1">Du har gjort ändringar men ännu inte sparat
          de.</p>
        <p class="mt-1"> Är du säker på att du vill navigera bort från denna vy?</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancel" text>Avbryt</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="continueNav" color="primary" text>
          Fortsätt
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    interruptedTo: null,
    dialog: false
  }),
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    unsavedChanges: {
      get: function ()
      {
        return this.value;
      },
      set: function (newValue)
      {
        this.$emit('update:value', newValue)
      }
    },
  },
  methods: {
    hasUnsavedChanges()
    {
      if (this.unsavedChanges)
      {
        this.dialog = true
        return true
      }
      return false
    },
    preventNav(event)
    {
      if (!this.unsavedChanges)
      {
        return
      }
      event.preventDefault()
      event.returnValue = ""
    },
    beforeRouteLeave(to, from, next)
    {
      this.interruptedTo = to
      if (!this.hasUnsavedChanges())
      {
        next();
      }
    },
    cancel() {
      this.$emit('cancel')
      this.dialog = false
    },
    continueNav() {
      this.$emit('continue')
      this.unsavedChanges = false
      this.$nextTick(() => this.$router.push(this.interruptedTo))
    }
  },
  beforeMount()
  {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () =>
    {
      window.removeEventListener("beforeunload", this.preventNav)
    })
  },
}
</script>
