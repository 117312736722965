// keeps track of which component is used for inputs when administrating a section
const COMPONENTS = {
    TEXT_PARAGRAPH: "ParagraphSectionComponent",
    TEXT_HEADER: "HeaderSectionComponent",
    IMAGE: "ImageSectionComponent",
    IMAGE_SLIDESHOW: "SlideshowSectionComponent",
    VIDEO_EMBED: "",
    ATTRIBUTE_SHOWCASE: "AttributesSectionComponent",
    EXTERNAL_BOOKER: "ExternalBookerSectionComponent",
    EMPTY_SECTION: "EmptySectionComponent",
    GOOGLE_MAPS_EMBED: "GoogleMapsSectionComponent",
}

export default {
    COMPONENTS
}
