<template>
  <v-container class="white" :fluid="fluid">
    <v-row>
      <v-col>
        <section-render-component :key="'section:' + index" v-for="(section, index) in website.sections"
                                  :website="website" :section="section"
                                  :read-only="readOnly"></section-render-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionRenderComponent from "@/components/website/render/SectionRenderComponent";

export default {
  components: {SectionRenderComponent},
  props: {
    website: Object,
    readOnly: Boolean,
    fluid: Boolean
  }
}
</script>

<style scoped>

</style>
