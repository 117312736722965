<template>
    <span v-if="customer.id && !customer.detailsProvidedByCustomer">
        <v-tooltip activator="#sendDetailsRequestBtn" bottom v-if="isDetailsRequestSent">
            <span>En förfrågan om kontaktuppgifter har redan skickats till denna kund</span>
        </v-tooltip>
        <v-tooltip activator="#sendDetailsRequestBtn" bottom v-if="!customer.email || customer.email.indexOf('@') < 0">
            <span>Det finns ingen giltig e-post inlagd på denna kund</span>
        </v-tooltip>
        <span id="sendDetailsRequestBtn">
                <v-btn
                        :loading="loading"
                        @click="confirmSendDetailsRequest"
                        :color="buttonColor"
                        :dark="dark"
                        :min-width="minWidth"
                        :small="small"
                        :disabled="!customer.email || customer.email.indexOf('@') < 0"
                        outlined>
                    Be om kunduppgifter
                    <v-icon :small="small" :color="isDetailsRequestSent ? iconColorSent : iconColor"
                            class="ml-2">{{isDetailsRequestSent ? 'mdi-account-check-outline' : 'mdi-account-arrow-right-outline'}}</v-icon>
                </v-btn>
            </span>
    </span>
</template>

<script>
    import api from "../../api/api";

    export default {
        components: {},
        data: () => ({
            loading: false
        }),
        props: {
            customer: Object,
            conversationId: Number,
            buttonColor: String,
            iconColorSent: String,
            iconColor: String,
            dark: Boolean,
            small: Boolean,
            minWidth: String
        },
        computed: {
            fullname()
            {
                return this.customer.firstname + " " + this.customer.lastname
            },
            isDetailsRequestSent()
            {
                return this.customer.uuid && this.customer.uuid.length > 0
            }
        },
        methods: {
            confirmSendDetailsRequest()
            {
                let title = "Bekräfta utskick"
                let text = "Är du säker på att du vill skicka en förfrågan om kontaktuppgifter till " + this.fullname + " (" + this.customer.email + ")?"
                if (this.isDetailsRequestSent)
                {
                    title = "Förfrågan redan skickad - Bekräfta nytt utskick"
                    text = "En förfrågan om kontaktuppgifter har redan skickats till denna kund. Är du säker på att du vill skicka en ny förfrågan om kontaktuppgifter till " + this.fullname + " (" + this.customer.email + ")?"
                }
                this.$store.commit('setDialog', {
                    active: true,
                    closeBtnText: "Avbryt",
                    actionBtnText: "Skicka förfrågan",
                    title: title,
                    text: text,
                    actionClick: this.sendDetailsRequest
                })
            },
            async sendDetailsRequest()
            {
                this.loading = true
                let url = api.ENDPOINTS.CUSTOMERS + "/" + this.customer.id + "/" + api.ENDPOINTS.REQUEST
                if(this.conversationId)
                {
                    url += "?conversationId=" + this.conversationId
                }

                this.customer = await api.postToEndpoint(url)
                this.loading = false
                this.$store.commit('setSnackbar', {
                    color: "primary",
                    text: "Förfrågan har skickats till " + this.fullname + " (" + this.customer.email + ")"
                })
                this.$emit("requestSent")
            },
        }
    }
</script>
