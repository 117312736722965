<template>

  <v-tooltip :key="item.id + 'tooltip'" open-delay="500" bottom
             :disabled="item.attribute.hasValue === 0">
    <template v-slot:activator="{ on }">
      <v-chip :color="color"
              :key="item.id + 'attr'"
              label
              outlined
              v-on="on"
      >
        <v-icon
            left v-if="item.attribute.icon && item.attribute.icon.length > 0">
          {{ item.attribute.icon }}
        </v-icon>
        <span>
         {{ item.attribute.name }}
        </span>
        <v-chip class="ml-2 mr-0" x-small label v-if="item.attribute.hasValue === 1">{{ item.value }}</v-chip>
      </v-chip>
    </template>
    <span>{{ item.attribute.valueUnit }}</span>
  </v-tooltip>

</template>

<script>

export default {
  components: {},
  props: {
    item: Object,
    color: String,
  },
}
</script>
