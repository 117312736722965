<template>
  <v-dialog
      transition="slide-y-transition"
      max-width="600px"
      scrollable
      v-model="active"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             v-on="on"
             text
             color="primary"
      >
        Vad är detta/Hur gör man
        <v-icon right>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title avatar class="secondary white--text">
        <span class="headline">Hjälp - Betalning</span>
        <v-spacer></v-spacer>
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-card-title>
      <v-card-text style="height: 650px">
        <v-subheader class="pl-0 font-weight-bold">Vad är detta?</v-subheader>
        <p>
          Här ställer du in hur du vill ha betalt när du hyr ut. Exempelvis handpenning/slutbetalning.
        </p>
        <p>
          Du anger ett <b>standardpris</b> och skapar <b>betalningspunkter</b> som påminner både dig och den som hyr när
          det är dags att betala.
        </p>
        <p>
          Allt du ställer in här blir standardvärdena som sätts när en period skapas men det går alltid att ändra senare
          för varje period.
        </p>
        <v-subheader class="pl-0 font-weight-bold">
          <v-icon left color="primary">mdi-credit-card-outline</v-icon>
          Standardpris
        </v-subheader>
        <p>Priset som kunden ska betala som standard när en period skapas.</p>
        <v-subheader class="pl-0 font-weight-bold">
          <v-icon left color="primary">mdi-credit-card-clock-outline</v-icon>
          Betalningspunkter
        </v-subheader>
        <p>En betalningspunkt är när systemet ska skicka förfrågan till hyresgästen om betalning.</p>
        <p>Du väljer om du vill skicka ut dessa manuellt eller om Uthyrd ska göra det åt dig.</p>
        <p>Datumen sätts med en förskjutning utifrån viktiga datum som finns varje i period. Tex uthyrningsdatum och
          in/ut-checkningsdatum.</p>
        <p class="font-weight-medium">Betalningspunkter består utav tre delar:</p>
        <h5>
          <v-icon left small color="primary">mdi-currency-usd</v-icon>
          Belopp
        </h5>
        <p>Beloppet anges i procent och är hur många procent av standardpriset som kunden skall påminnas att betala.</p>
        <h5>
          <v-icon left small color="primary">mdi-calendar-question</v-icon>
          Förfrågningsdatum
        </h5>
        <p>Genom att slå på detta alternativ så skickar systemet automatiskt ut förfrågningen utifrån det datum du
          ställt in.</p>
        <h5>
          <v-icon left small color="primary">mdi-calendar-clock</v-icon>
          Förfallodatum
        </h5>
        <p>På detta datum får du en påminnelse från Uthyrd att betalningen har förfallit och måste betalas omgående. </p>
        <p class="mt-4">Både förfrågnings- och förfallodatumet utgår ifrån ett specifikt datum i uthyrningsperioden (exempelvis incheckningsdatumet) och du anger en förskjutning i antal
          dagar innan (negativt värde) eller efter det datumet som utskicket skall ske.</p>
        <div class="text-center mt-8 grey--text">
          <h5>Behöver du mer hjälp?</h5>
          <p class="grey--text"><a href="mailto:support@uthyrd.se">Kontakta oss</a> så hjälper vi dig!</p>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="active = false">Stäng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {},
  data: () => ({
    active: false
  }),
}
</script>
