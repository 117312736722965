<template>
  <v-card
      v-if="bookingPeriod"
      tile
      :flat="flat"
      :color="flat ? null : availableStatuses[bookingPeriod.status].color"
  >
    <v-list-item :class="{'px-0': flat}" dense :dark="!flat">
      <v-list-item-icon v-if="flat">
        <v-icon :color="availableStatuses[bookingPeriod.status].color">
          {{ availableStatuses[bookingPeriod.status].icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-avatar v-else>
          <v-icon color="white">
            {{ availableStatuses[bookingPeriod.status].icon }}
          </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          Status
        </v-list-item-subtitle>
        <v-list-item-title>{{ availableStatuses[bookingPeriod.status].text }}</v-list-item-title>
        <v-list-item-subtitle v-if="hint && showActions">{{ hint }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
            v-if="statusElevation && showActions"
            @click="confirmStatusElevation"
            outlined
            small
            dark
        >
          {{ statusElevation.text }}
          <v-icon right>{{ statusElevation.icon }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";

export default {
  data: () => ({
    availableStatuses: bookingPeriodStatuses.STATUSES
  }),
  props: {
    flat: Boolean,
    showActions: Boolean,
    bookingPeriod: Object
  },
  computed: {
    hint()
    {
      switch (this.bookingPeriod.status)
      {
        case bookingPeriodStatuses.STATUSES_OBJ.OPEN.value:
          return "Lägg till en kund för att ändra status till förfrågad"
        case bookingPeriodStatuses.STATUSES_OBJ.REQUESTED.value:
          return "Klicka på knappen till höger för att ändra status till " + bookingPeriodStatuses.STATUSES_OBJ.BOOKED.text
        case bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value:
          return "Klicka på knappen till höger för att ändra status till " + bookingPeriodStatuses.STATUSES_OBJ.COMPLETED.text
        default:
          return null
      }
    },
    statusElevation()
    {
      if (this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.REQUESTED.value)
      {
        return {
          text: "Ändra till " + bookingPeriodStatuses.STATUSES_OBJ.BOOKED.text,
          icon: bookingPeriodStatuses.STATUSES_OBJ.BOOKED.icon,
        }
      }
      else if (this.bookingPeriod.status === bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value)
      {
        return {
          text: "Ändra till " + bookingPeriodStatuses.STATUSES_OBJ.COMPLETED.text,
          icon: bookingPeriodStatuses.STATUSES_OBJ.COMPLETED.icon,
        }
      }
      return null
    },
  },
  methods: {
    confirmStatusElevation()
    {
      this.$emit('statusElevation')
    },
  }
}
</script>

