,
<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex md8 sm7 xs12>
        <v-card :disabled="loadingBookingPeriods" :loading="loadingBookingPeriods" color="white">
          <v-toolbar color="secondary" dark>
            <v-toolbar-title data-cy="bookingPeriodsHeader" class="text-center">Uthyrningsperioder</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :class="{'highlight-pulse': bookingPeriods.length <= 0}" :to="toNewBookingPeriod"
                       color="primary"
                       fab small
                       v-on="on">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Skapa en ny uthyrningsperiod</span>
            </v-tooltip>
          </v-toolbar>
          <v-fade-transition mode="out-in">
            <booking-periods-calendar-component
                :booking-periods="bookingPeriods"
                :booking-periods-display-options="bookingPeriodsDisplayOptions"
                :dates-render="datesRender"
                :display-options="bookingPeriodsDisplayOptions"
                ref="calendarComponent"
                v-if="this.bookingPeriodsDisplayOptions.value.viewType === 1"
            ></booking-periods-calendar-component>
            <booking-periods-list-component
                :booking-periods="bookingPeriods"
                :booking-periods-display-options="bookingPeriodsDisplayOptions"
                v-else-if="this.bookingPeriodsDisplayOptions.value.viewType === 2"
            ></booking-periods-list-component>
          </v-fade-transition>
        </v-card>
      </v-flex>
      <v-flex md4 sm5 xs12>
        <v-card :disabled="loadingDisplayOptions" class="mb-4" width="100%">
          <v-toolbar color="accent-darken" dark>
            <v-icon class="mr-2">filter_list</v-icon>
            <v-toolbar-title class="text-center">Filter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch :class="{'active': !bookingPeriodsDisplayOptions.value.ignoreDisplayOptions}"
                      :false-value="true" :true-value="false"
                      @change="saveBookingPeriodsDisplayOptions"
                      hide-details
                      v-model="bookingPeriodsDisplayOptions.value.ignoreDisplayOptions"></v-switch>
          </v-toolbar>
          <v-card :disabled="bookingPeriodsDisplayOptions.value.ignoreDisplayOptions" :loading="loading">
            <booking-objects-list-component :@loaded="loadingBookingObjects = false"
                                            :booking-periods-display-options.sync="bookingPeriodsDisplayOptions"
                                            @item-click="saveBookingPeriodsDisplayOptions"
                                            mandatory
                                            select-first
                                            shaped
            ></booking-objects-list-component>
            <booking-periods-display-options-component
                v-if="!loadingDisplayOptions"
                :booking-periods-display-options.sync="bookingPeriodsDisplayOptions"
                @item-click="saveBookingPeriodsDisplayOptions"></booking-periods-display-options-component>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import BookingObjectsListComponent from "../../components/bookingobject/BookingObjectsListComponent";
import BookingPeriodsCalendarComponent from "../../components/bookingperiod/BookingPeriodsCalendarComponent";
import BookingPeriodsDisplayOptionsComponent
  from "../../components/bookingperiod/BookingPeriodsDisplayOptionsComponent";
import BookingPeriodsListComponent from "../../components/bookingperiod/BookingPeriodsListComponent";
import api from "../../api/api"

export default {
  components: {
    BookingPeriodsListComponent,
    BookingPeriodsDisplayOptionsComponent, BookingPeriodsCalendarComponent, BookingObjectsListComponent
  },
  data: () => ({
    bookingPeriodsDisplayOptionsDb: null, // overwritten on mounted
    loadingDisplayOptions: true,
    loadingBookingObjects: true,
    loadingBookingPeriods: true,
    enableDisplayOptions: true,
    noPeriodData: false,
    bookingPeriods: []
  }),
  computed: {
    loading()
    {
      return (this.loadingDisplayOptions || this.loadingBookingObjects)
    },
    stateUser()
    {
      return this.$store.state.user
    },
    bookingPeriodsDisplayOptions()
    {
      if (this.bookingPeriodsDisplayOptionsDb)
      {
        return this.bookingPeriodsDisplayOptionsDb
      }
      return {
        value: {
          viewType: 1,
          statuses: [0, 1, 2],
          ignoreDisplayOptions: false,
          lastStartDate: new Date().toISOString()
        },
        userAccountId: this.stateUser.id
      }
    },
    toNewBookingPeriod()
    {
      let defaultCheckInHour = "16"
      let defaultCheckOutHour = "10"

      return {
        name: 'newBookingPeriod',
        params: {
          start: this.$moment().startOf("week").set({
            hour: defaultCheckInHour,
            second: 0
          }).format("YYYY-MM-DD HH:mm"),
          end: this.$moment().startOf("week").add(5, "days").set({
            hour: defaultCheckOutHour,
            second: 0
          }).format("YYYY-MM-DD HH:mm"),
        }
      }
    }
  },
  mounted()
  {
    this.getBookingPeriodsDisplayOptions()
    this.getBookingPeriods()
  },
  methods: {
    async saveBookingPeriodsDisplayOptions()
    {
      this.bookingPeriodsDisplayOptionsDb = await api.postToEndpoint(api.ENDPOINTS.BOOKINGPERIODSDISPLAYOPTIONS, this.bookingPeriodsDisplayOptions)
      this.getBookingPeriods()
    },
    async getBookingPeriodsDisplayOptions()
    {
      this.loadingDisplayOptions = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGPERIODSDISPLAYOPTION)
      if (serverResponse)
      {
        this.bookingPeriodsDisplayOptionsDb = serverResponse
        if (serverResponse.value.filteredBookingObjects)
        {
          this.filteredBookingObjects = serverResponse.value.filteredBookingObjects
        }
        if (this.bookingPeriodsDisplayOptionsDb.value.lastStartDate)
        {
          this.$refs.calendarComponent.goToDate(this.bookingPeriodsDisplayOptionsDb.value.lastStartDate)
        }
      }
      this.loadingDisplayOptions = false
    },
    async getBookingPeriods()
    {
      this.loadingBookingPeriods = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGPERIODS)
      if (serverResponse)
      {
        this.bookingPeriods = serverResponse
      }
      this.loadingBookingPeriods = false

      let calcomp = this.$refs.calendarComponent
      if (calcomp) // only exists in calendartype view
      {
        calcomp.refetchEvents()
      }
    },
    datesRender(data)
    {
      if (this.bookingPeriodsDisplayOptions.id && this.bookingPeriodsDisplayOptions.id > 0)
      {
        this.bookingPeriodsDisplayOptions.value.lastStartDate = data.view.currentStart.toISOString()
        this.saveBookingPeriodsDisplayOptions()
      }
    }
  }
}
</script>
