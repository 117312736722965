<template>
  <v-card flat :loading="loading" :disabled="loading">
    <iframe
        @load="iframeLoaded"
        :width="width"
        :height="height"
        style="border:0"
        loading="lazy"
        :src=source>
    </iframe>
  </v-card>
</template>

<script>
export default {
  data()
  {
    return {
      loading: false
    }
  },
  watch: {
    source()
    {
      this.loading = true
    }
  },
  props: {
    height: String,
    width: String,
    value: String,
  },
  methods: {
    iframeLoaded()
    {
      this.loading = false
    },
  },
  computed: {
    source()
    {
      return "https://maps.google.com/maps?ie=UTF8&iwloc=&output=embed&q=" +
          this.encodedValue
    },
    encodedValue()
    {
      return encodeURIComponent(this.value)
    }
  }
}
</script>

<style scoped>

</style>