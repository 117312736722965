<template>
  <div v-if="grouped">
    <div :key="key" v-for="[key, value] in Object.entries(attributesByGroup)">
      <v-subheader class="pl-2">{{ key }}</v-subheader>
      <v-chip-group column class="chips-dense">
        <attribute-chip-component
            v-for="item in value"
            :item="item"
            :key="'attr'+item.id"
            color="primary"
        >
        </attribute-chip-component>
      </v-chip-group>
    </div>
  </div>
  <div v-else>
    <v-chip-group column>
      <attribute-chip-component
          v-for="item in attributes"
          :item="item"
          :key="'attr'+item.id"
          color="primary"
      ></attribute-chip-component>
    </v-chip-group>
  </div>
</template>

<script>

import AttributeChipComponent from "@/components/attribute/AttributeChipComponent";

export default {
  components: {AttributeChipComponent},
  props: {
    grouped: Boolean,
    bookingObjectAttributes: Array
  },
  computed: {
    attributes()
    {
      return this.bookingObjectAttributes
    },
    attributesByGroup()
    {
      return this.attributes.reduce((r, a) =>
      {
        r[a.attribute.attributeGroup.name] = [...r[a.attribute.attributeGroup.name] || [], a];
        return r;
      }, {});
    }
  },
}
</script>
<style>
.chips-dense .v-slide-group__content {
  padding-top: 0 !important;
  padding-bottom: 5px !important;
}
</style>
