<template>
    <v-dialog persistent v-model="dialogProp" scrollable max-width="800px">
        <v-card :loading="loading">
            <v-card-title>
                {{message.fromEmailName}}
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-chip label color="secondary" v-on="on" x-small outlined class="ml-2 pl-1 pr-1">
                            {{message.fromEmailAddress}}
                        </v-chip>
                    </template>
                    <span>{{message.from}}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <span class="subtitle-2">{{$moment(message.createdAt).format("LLL")}}</span>
            </v-card-title>
            <v-card-subtitle>{{message.subject}}</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text v-if="!bodyHtml || message.bodyHtml == null || message.bodyHtml.length <= 0" class="pt-5" style="max-height: 600px; white-space: pre-line;">
                {{message.bodyPlain}}
            </v-card-text>
            <v-card-text v-else class="pt-5 responsive-container">
                <iframe @load="renderHtmlInIframe" sandbox="allow-same-origin" width="100%" height="100%" id="htmlIframe"></iframe>
            </v-card-text>
            <div class="mt-4" v-if="message.attachments && message.attachments.length > 0">
                <v-divider></v-divider>
                <message-attachments-component :attachments="message.attachments"></message-attachments-component>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn v-if="showActions" text color="error" @click="confirmDeleteInboxMessage(message)">Ta bort
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogProp = false">Stäng</v-btn>
                <v-menu v-if="showActions" top offset-y
                        transition="slide-y-reverse-transition">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="primary" text>Svara</v-btn>
                    </template>
                    <v-list class="pa-0">
                        <v-list-item @click="connectToExistingConversation">
                            <v-list-item-title>Befintlig konversation</v-list-item-title>
                            <v-list-item-action>
                                <v-icon color="accent-darken">mdi-plus-box-multiple</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="createConversationFromInboxMessage(message)">
                            <v-list-item-title>Ny konversation</v-list-item-title>
                            <v-list-item-action>
                                <v-icon color="primary">mdi-message-plus</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import MessageAttachmentsComponent from "./attachment/MessageAttachmentsComponent";
    export default {
        components: {MessageAttachmentsComponent},
        data: () => ({
            loadingExistingConversationDialog: false,
            existingConversationDialog: false,
        }),
        props: {
            message: Object,
            loading: Boolean,
            bodyHtml: Boolean,
            dialog: Boolean,
            showActions: Boolean
        },
        computed: {
            dialogProp: {
                get: function ()
                {
                    return this.dialog;
                },
                set: function (newValue)
                {
                    this.$emit('update:dialog', newValue)
                }
            },
        },
        watch: {
          message: {
              deep: true,
              handler()
              {
                  this.renderHtmlInIframe()
              }
          }
        },
        methods: {
            confirmDeleteInboxMessage(inboxMessage)
            {
                this.$emit("confirmDeleteInboxMessage", inboxMessage)
            },
            connectToExistingConversation(){
                this.dialogProp = false
                this.$emit("connectToExistingConversation", true)
            },
            createConversationFromInboxMessage(inboxMessage)
            {
                this.$emit("createConversationFromInboxMessage", inboxMessage)
            },
            renderHtmlInIframe(){
                var doc = document.getElementById('htmlIframe').contentWindow.document;
                doc.open();
                doc.write(this.message.bodyHtml);
                doc.close();
            }
        }
    }
</script>
<style>
    .responsive-container {
        position: relative;
        overflow: hidden;
        height: 600px;
    }
    #htmlIframe iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        padding: 25px;
    }
</style>
