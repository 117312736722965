<template>
  <v-dialog max-width="800px" persistent scrollable v-model="dialogProp">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="my-4" ref="form" @submit.prevent>
          <v-slide-x-transition mode="out-in">
            <v-alert v-model="showInfo" type="info" colored-border color="accent" dismissible dense v-if="showInfo"
                     class="mb-4">
              Webadressen är det dina kunder kommer att skriva in i sin webbläsare för att komma till din
              presentationssida.
              Den börjar alltid med {{ baseHref }} och slutar med det du anger nedan.
            </v-alert>
            <v-btn v-else class="mb-4" @click="showInfo = true" small text color="accent">
              <v-icon small left>mdi-information-outline</v-icon>
              Vad är detta?
            </v-btn>
          </v-slide-x-transition>
          <v-text-field :rules="requiredRules" v-model="website.url" counter
                        :hint="'Fullständig webbadress: ' + fullHref"
                        persistent-hint
                        @keyup.enter="submit"
                        :placeholder="'Exempelvis: ' + bookingObjectNameSlug" outlined label="Ange Webbadress">
          </v-text-field>
        </v-form>
        <v-alert colored-border border="left" type="warning">
          <p>Notera att webbadressen får ej innehålla könsord, svordomar eller annat som kan uppfattas som stötande.
            Webbadressen måste även vara max 25 tecken och får inte innehålla åäö, mellanrum eller andra specialtecken.
            Läs mer om <a href="#">vår policy kring webbplatsadresser här</a></p>
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="dialogProp = false" text>Stäng</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit"
               color="primary"
               text>Skapa webbplats
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../api/api";

export default {
  components: {},
  data: () => ({
    loading: false,
    showInfo: false,
    website: {
      url: "",
      userAccountId: null,
      bookingObjectId: null
    },
    requiredRules: [
      v => !!v || 'Obligatoriskt fält'
    ]
  }),
  props: {
    dialog: Boolean,
    copyFrom: Number,
    bookingObject: Object,
    fromTemplate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
    baseHref()
    {
      return `${api.BASE_URL_FRONTEND}boka/`
    },
    fullHref()
    {
      return `${this.baseHref}${this.website.url}`
    },
    bookingObjectNameSlug()
    {
      return this.slugify(this.bookingObject.name)
    },
    title()
    {
      return this.fromTemplate ? "Ny webbplats från mall" :
          this.copyFrom ? 'Duplicerar webbplats' : 'Ny webbplats från grunden'
    },
    user()
    {
      return this.$store.state.user
    }
  },
  watch: {
    'website.url'(val)
    {
      if (val)
      {
        this.website.url = this.slugify(val)
      }
    }
  },
  methods: {
    async submit()
    {
      if (this.$refs.form.validate())
      {
        this.website.url = this.slugify(this.website.url)
        this.website.bookingObjectId = this.bookingObject.id
        this.website.userAccountId = this.user.id
        this.$emit('submit', this.website.url)
        let url = api.ENDPOINTS.WEBSITES

        if (this.copyFrom)
        {
          url += "?copyFrom=" + this.copyFrom
        }
        this.loading = true
        let newWebsite = await api.postToEndpoint(url, this.website)
        if (newWebsite)
        {
          this.$store.commit('setSnackbar', {color: "success", text: "Webbplats skapad! Navigerar till redigering..."})
          window.setTimeout(() =>
          {
            location.href = '/websites/edit/' + newWebsite.id
          }, 1000)
        }
        else
        {
          this.loading = false
        }
      }
    },
    slugify(string) // removes chars unsuitable for an url
    {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')

      return string.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\\-]+/g, '') // Remove all non-word characters
          .replace(/\\-\\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
    }
  }
}
</script>
