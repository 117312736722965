<template>
  <div>
    <v-dialog persistent v-model="newConversationDialog" max-width="600px">
      <v-card :loading="loading" :disabled="loading">
        <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
        >
          <v-card-title>
            Nytt Meddelande
          </v-card-title>
          <v-card-subtitle>
            <customer-email-select-component
                v-model="newMessage"
                prepend-inner-icon="mdi-email-outline"
                hide-selected
                validate
                single-line
                label="Till"
                full-width
                dense
                ref="customerEmail"
                required
                :customer-list="customers"
            ></customer-email-select-component>
            <v-text-field
                label="Ämne"
                flat
                dense
                single-line
                autocomplete="off"
                :rules="[v => !!v || 'Du måste ange ett ämne']"
                required
                v-model="newMessage.subject"
                @keydown.meta.enter.exact.once="sendMessage"
                @keydown.ctrl.enter.exact.once="sendMessage"
            ></v-text-field>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-textarea
                placeholder="Meddelande | Ctrl/Cmd + Enter för att skicka"
                solo
                rows="8"
                flat
                autofocus
                :rules="[v => !!v || 'Du måste skriva ett meddelande']"
                required
                no-resize
                v-model="newMessage.body"
                @keydown.meta.enter="sendMessage"
                @keydown.ctrl.enter="sendMessage"
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-badge
                    :content="newMessage.attachments.length"
                    :value="attachmentsAdded"
                    color="accent"
                    overlap
                >
                  <v-menu top offset-y v-on="on"
                          transition="slide-y-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn small color="white" v-on="on" :disabled="loading" fab
                             class="elevation-3 ml-3"
                      >
                        <v-icon color="accent-darken darken-2">mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item :disabled="loading" @click="fileUploadDialog = true">
                        <v-list-item-title>Ladda upp</v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="accent-darken">mdi-cloud-upload-outline</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item :disabled="loading" @click="fileFromBookingObject">
                        <v-list-item-title>Välj från uthyrningsobjekt</v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="primary">mdi-file-cabinet</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item :disabled="loading" v-if="bookingPeriod"
                                   @click="filesFromPeriod = true; filePickerDialog = true">
                        <v-list-item-title>Välj från uthyrningsperioden</v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="info">mdi-calendar-text-outline</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item :disabled="loading" v-if="bookingPeriod" @click="dynamicDocumentsDialog = true">
                        <v-list-item-title>Skapa fil från dynamiskt dokument</v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="teal">mdi-file-document-outline</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item :disabled="loading" v-if="attachmentsAdded"
                                   @click="attachmentsDialog = true">
                        <v-list-item-title>Se bifogade filer</v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="secondary">mdi-file-edit-outline</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-badge>
              </template>
              <span>Bifoga filer</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn text @click="newConversationDialog = false">Stäng</v-btn>
            <v-btn text
                   color="primary"
                   @click="sendMessage">
              Skicka meddelande
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileUploadDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Ladda upp filer
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <file-upload-component @fileUploaded="filesAttached"
                                 :maxfiles="25" :max-filesize="10"></file-upload-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="fileUploadDialog = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="attachmentsDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Bifogade filer
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-paperclip</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <message-attachments-component :attachments="newMessage.attachments"
                                         show-actions></message-attachments-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="attachmentsDialog = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <file-picker-dialog v-if="bookingObject || bookingPeriod" v-model="newMessage.attachments"
                        :dialog.sync="filePickerDialog" multiple
                        :files="files"></file-picker-dialog>
    <v-dialog persistent v-model="bookingObjectPickerDialog" scrollable max-width="650px">
      <v-card>
        <v-card-title>
          Välj uthyrningsobjekt
        </v-card-title>
        <v-card-subtitle>Välj ett uthyrningsobjekt att koppla meddelandet till</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <!-- No need for a form here. BookingObject is required but we do stuff on change -->
          <booking-object-select-component :value="this"
                                           required
                                           @change="bookingObjectChange"
          ></booking-object-select-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="bookingObjectPickerDialog = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dynamic-documents-picker-dialog-component :dialog.sync="dynamicDocumentsDialog"
                                               @select="selectDynamicDocument"></dynamic-documents-picker-dialog-component>
    <dynamic-document-to-file-dialog-component v-if="bookingPeriod" eager @saved="dynamicDocumentSaved"
                                               :booking-period="bookingPeriod"
                                               :dialog.sync="dynamicDocumentToFileDialog"
                                               :selected-dynamic-document="selectedDynamicDocument"></dynamic-document-to-file-dialog-component>
  </div>
</template>

<script>
import api from "../../api/api";
import CustomerEmailSelectComponent from "../../components/customer/CustomerEmailSelectComponent";
import FileUploadComponent from "../../components/global/file/FileUploadComponent";
import MessageAttachmentsComponent from "../../components/conversation/message/attachment/MessageAttachmentsComponent";
import FilePickerDialog from "../../components/global/file/FilePickerDialog";
import BookingObjectSelectComponent from "../../components/bookingobject/BookingObjectSelectComponent";
import DynamicDocumentToFileDialogComponent from "@/components/dynamicdocument/DynamicDocumentToFileDialogComponent";
import DynamicDocumentsPickerDialogComponent from "@/components/dynamicdocument/DynamicDocumentsPickerDialogComponent";

export default {
  components: {
    DynamicDocumentsPickerDialogComponent,
    DynamicDocumentToFileDialogComponent,
    BookingObjectSelectComponent,
    FilePickerDialog, MessageAttachmentsComponent, FileUploadComponent, CustomerEmailSelectComponent
  },
  data: () => ({
    newMessage:
        {
          subject: "",
          body: "",
          email: "",
          attachments: []
        },
    validForm: true,
    mailError: false,
    emailAddressSearch: "",
    loading: false,
    fileUploadDialog: false,
    attachmentsDialog: false,
    filePickerDialog: false,
    bookingObjectPickerDialog: false,
    filesFromPeriod: false,
    dynamicDocumentToFileDialog: false,
    dynamicDocumentsDialog: false,
    selectedDynamicDocument: null,
  }),
  props: {
    dialog: Boolean,
    bookingObject: Object,
    bookingPeriod: Object,
    customers: Array,
    selectedCustomer: Object,
  },
  watch: {
    newConversationDialog() {
      if (this.selectedCustomer) {
        this.selectEmail(this.selectedCustomer.email)
      }
    }
  },
  computed: {
    newConversationDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    attachmentsAdded() {
      return this.newMessage.attachments.length > 0
    },
    files() {
      if (!this.bookingPeriod && !this.bookingObject) {
        return []
      }
      return this.filesFromPeriod && this.bookingPeriod ? this.bookingPeriod.files : (this.bookingObject ? this.bookingObject.files : [])
    }
  },
  methods: {
    async sendMessage() {
      this.$refs.form.validate()
      if (this.validForm && this.validateEmail()) {
        this.loading = true

        let conversation = await this.createConversation(this.newMessage)

        // Create the object from our form
        let sentEmailMessage = {
          conversation: conversation,
          fromName: this.$store.state.user.firstname + ' ' + this.$store.state.user.lastname,
          fromEmail: this.$store.state.user.applicationEmailAddress,
          toEmail: this.newMessage.email,
          subject: this.newMessage.subject,
          message: this.newMessage.body,
          attachments: this.newMessage.attachments
        }

        let serverResponse = await api.postToEndpoint(api.ENDPOINTS.CONVERSATIONS + "/" + api.ENDPOINTS.MESSAGE, sentEmailMessage)
        if (serverResponse) {
          this.newConversationDialog = false
          // Show snackbar with option to navigate to the conversation
          this.$store.commit('setSnackbar', {
            color: "success",
            text: "Meddelande skapat och skickat till " + sentEmailMessage.toEmail,
            timeout: 7000,
            btnText: "Till meddelandet",
            actionClick: () => this.navigateToConversation(conversation.id)
          })
        }
        this.loading = false
      }
    },
    validateEmail() {
      this.$refs.customerEmail.validateInput()
      return this.newMessage.email && this.newMessage.email.indexOf("@") >= 0
    },
    async createConversation(message) {
      let conversation = {
        userAccountId: this.$store.state.user.id,
        name: message.subject,
        bookingObject: this.bookingObject,
        bookingPeriod: this.bookingPeriod,
      }

      return await api.postToEndpoint(api.ENDPOINTS.CONVERSATIONS, conversation)
    },
    filesAttached(response, file) // when a file is uploaded we update the object
    {
      response.forEach((fileData) => {
        let attachment = {name: file.name, fileData: fileData}
        this.newMessage.attachments.push(attachment)
      })
    },
    selectEmail(email) {
      this.newMessage.email = email
    },
    fileFromBookingObject() {
      this.filesFromPeriod = false
      if (this.bookingObject) {
        this.filePickerDialog = true
      } else {
        this.bookingObjectPickerDialog = true
      }
    },
    bookingObjectChange() {
      if (this.bookingObjectPickerDialog) {
        this.filesFromPeriod = false
        this.bookingObjectPickerDialog = false
        this.filePickerDialog = true
      }
    },
    selectDynamicDocument(dynamicDocument) {
      this.selectedDynamicDocument = Object.assign({}, dynamicDocument)
      this.dynamicDocumentToFileDialog = true
    },
    dynamicDocumentSaved(fileData) {
      this.dynamicDocumentToFileDialog = false
      this.bookingPeriod.files.push(fileData)
      this.$store.commit('setSnackbar', {
        color: "success",
        text: "Dynamiskt dokument sparad som fil i uthyrningsperioden"
      })
      this.dynamicDocumentsDialog = false
      this.filesFromPeriod = true
      this.filePickerDialog = true
    },
    navigateToConversation(id){
      this.$router.push({
        name: 'conversationDetails',
        params: {
          id: id
        }
      })
    }
  }
}
</script>

