<template>
  <v-card>
    <v-card-title class="font-weight-light">
      <v-icon
          left
          color="accent"
      >
        mdi-email-outline
      </v-icon>
      <span>Senaste meddelanden</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" outlined small icon @click="goToMessages">
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Visa alla meddelanden</span>
      </v-tooltip>
    </v-card-title>
    <inbox-list-component @updated="updateCount" @openInboxMessage="goToMessages"></inbox-list-component>
    <v-divider></v-divider>
    <conversation-list-component show-subheader view-only-list></conversation-list-component>
  </v-card>
</template>

<script>
import ConversationListComponent from "../conversation/ConversationListComponent";
import InboxListComponent from "../conversation/message/InboxListComponent";

export default {
  components: {InboxListComponent, ConversationListComponent},
  data: () => ({}),
  props: {
    count: {
      type: Number,
      default: null
    }
  },
  computed: {
    messagesCount: {
      get()
      {
        return this.count
      },
      set(val) {
        this.$emit('update:count', val)
      }
    }
  },
  methods: {
    goToMessages()
    {
      this.$router.push({name: "messages"})
    },
    updateCount(inboxMessages) {
      this.messagesCount = inboxMessages.length
    }
  }
}
</script>

<style scoped>

</style>
