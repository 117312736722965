<template>
  <v-form

      ref="form"
      v-model="validForm"
  >
    <v-subheader class="pl-0">Grundläggande information</v-subheader>
    <v-text-field
        :counter="100"
        :rules="[v => !!v || 'Du måste ange ett förnamn']"
        @keyup.enter="saveUser"
        autocomplete="fname"
        autofocus
        label="Förnamn"
        data-cy="firstnameField"
        required
        v-model="value.firstname"
    ></v-text-field>
    <v-text-field
        :counter="100"
        :rules="[v => !!v || 'Du måste ange ett efternamn']"
        @keyup.enter="saveUser"
        autocomplete="lname"
        label="Efternamn"
        data-cy="lastnameField"
        required
        v-model="value.lastname"
    ></v-text-field>
    <v-text-field
        :counter="100"
        :rules="emailRules"
        @keyup.enter="saveUser"
        autocomplete="email"
        label="E-post"
        prepend-icon="mdi-email-outline"
        required
        data-cy="emailField"
        type="email"
        v-model="value.email"
    ></v-text-field>
    <v-tooltip bottom open-delay="600">
      <template v-slot:activator="{ on }">
        <v-text-field
            :counter="100"
            :rules="emailRules"
            @click:append="copyToClipboard(value.applicationEmailAddress)"
            @keyup.enter="saveUser"
            append-icon="mdi-clipboard-text-outline"
            autocomplete="off"
            label="Uthyrdmail"
            prepend-icon="mdi-email-variant"
            readonly
            data-cy="appEmailField"
            required
            type="email"
            v-if="appEmailShow"
            v-model="value.applicationEmailAddress"
            v-on="on"
        ></v-text-field>
      </template>
      <span>E-postadressen som används för kommunikationen genom Uthyrd. De e-postmeddelanden som skickas till denna adress hamnar automatiskt i din inkorg</span>
    </v-tooltip>
    <v-text-field
        :counter="50"
        @key.enter="saveUser"
        autocomplete="tel"
        class="mb-3"
        label="Telefonnummer"
        prepend-icon="mdi-phone-outline"
        required
        data-cy="phoneField"
        type="tel"
        v-model="value.phone"
    ></v-text-field>
    <address-component
        data-cy="addressPanel"
        flat outlined
        :address.sync="value.address"
        :opened-index="addressContent"
        @keyUpEnter="saveUser"
    ></address-component>
  </v-form>
</template>

<script>
import AddressComponent from "../global/AddressComponent";

export default {
  components: {AddressComponent},
  data: () => ({
    validForm: true,
    emailRules: [
      v => !!v || 'Du måste ange en e-postadress',
      v => /.+@.+/.test(v) || 'Den angivna e-postadressen är inte giltig'
    ],
    addressContent: 1
  }),
  props: {
    value: Object,
    appEmailShow: {
      default: true,
      type: Boolean
    }
  },
  mounted()
  {
    this.addressContent = this.value.firstname.length > 0 ? 1 : 0
  },
  methods: {
    saveUser()
    {
      this.$emit('saved', this.value)
    },
    copyToClipboard(text)
    {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$store.commit('setSnackbar', {color: "primary", text: "Kopierade länken till urklipp!"})
    }
  }
}
</script>

<style scoped>

</style>
