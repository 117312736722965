<template>
    <v-card>
        <v-card-title>
            {{title}}
        </v-card-title>
        <v-card-text>
            <v-label class="pl-0">
                Markerat element
            </v-label>
            <v-card flat>
                <v-card-subtitle>
                    {{selectedStep.target ? selectedStep.target : 'Ingen vald'}}
                    <v-spacer></v-spacer>
                </v-card-subtitle>
            </v-card>
            <v-btn @click="chooseTarget" class="mb-6" color="accent">Välj nytt element</v-btn>
            <v-select
                    :items="placements"
                    hint="Placering relativt till det markerade elementet"
                    label="Placering av innehåll"
                    v-model="selectedStep.params.placement"
            ></v-select>
            <v-text-field
                    label="Rubrik"
                    v-model="selectedStep.header.title"
            ></v-text-field>
            <v-textarea
                    auto-grow
                    label="Innehåll"
                    v-model="selectedStep.content"
            ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn :disabled="loading" @click="cancel" text v-if="showCancel">Avbryt</v-btn>
            <v-btn color="error" :disabled="loading" @click="deleteStep" text v-if="!showCancel">Ta bort</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" @click="saveStep" color="primary"
                   text>Spara
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: {
            step: Object,
            stepIndex: Number,
            showCancel: Boolean,
        },
        computed: {
            title()
            {
                return this.selectedStep.id ? "Redigerar steg " + this.stepIndex : "Nytt steg"
            },
            selectedStep: {
                get()
                {
                    return this.step
                },
                set(val)
                {
                    this.$emit('update:step', val)
                }
            },
        },
        data: () => ({
            placements: [{text: "Över", value: "top"}, {text: "Över", value: "top"}, {
                text: "Under",
                value: "bottom"
            }, {text: "Vänster", value: "left"}, {text: "Höger", value: "right"}],
            loading: false
        }),
        mounted()
        {
        },
        methods: {
            saveStep()
            {
                this.$emit('save', this.step)
            },
            deleteStep()
            {
                this.$emit('delete', this.step.index)
            },
            cancel()
            {
                this.$emit('cancel')
            },
            chooseTarget()
            {
                this.$emit('chooseTarget', this.selectedStep)
            }
        }
    }
</script>
