<template>
  <v-dialog
      v-model="dialogIsOpen"
      :max-width="isMobile ? '100%' : '800px'"
      persistent
      scrollable
      :fullscreen="isMobile"
      :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-icon color="primary">mdi-calendar-question</v-icon>
      </v-card-title>
      <v-card-subtitle class="pl-4 caption grey--text text--darken-1">
        <v-icon small class="mr-2" color="primary">mdi-information-outline</v-icon>
        OBS! Detta är inte en bindande bokning utan endast en förfrågan för vald period.
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="px-0" style="max-height: 1200px;">
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12>
              <v-stepper
                  v-model="currentStep"
                  class="elevation-0 booking-card pb-0"
                  vertical
              >
                <div v-if="calendarEvent">
                  <v-stepper-step
                      :color="currentStep > 1 ? 'success' : 'primary'"
                      :complete="currentStep > 1"
                      step="1"

                  >
                    Bekräfta vald period
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <v-list-item three-line>
                      <v-list-item-avatar tile v-if="bookingObject.fileData">
                        <v-img :src="getImageUrl(bookingObject)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="title">
                          {{ bookingObject.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="parseInt(calendarEvent.price) > 0"
                                              class="my-2">
                          <b>Pris: </b> {{ calendarEvent.priceSeperated }} SEK
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-chip outlined color="secondary" label>
                            <v-icon left>mdi-calendar</v-icon>
                            <b>Incheckning: </b>
                            <span class="ml-1">
                              {{ startDate }}
                              {{ $moment(calendarEvent.start).format('LT') }}
                            </span>
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-2">
                          <v-chip outlined color="secondary" label>
                            <v-icon left>mdi-calendar-arrow-right</v-icon>
                            <b>Utcheckning: </b>
                            <span class="ml-1">
                                {{ endDate }}
                                {{ $moment(calendarEvent.end).format('LT') }}
                            </span>
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-date-picker
                        v-model="periodDates"
                        range
                        :min="startDate"
                        :max="endDate"
                        full-width
                        readonly
                        locale="sv"
                        selected-items-text="Vald period"
                    ></v-date-picker>
                  </v-stepper-content>
                </div>
                <div v-else>
                  <v-stepper-step
                      :color="currentStep > 1 ? 'success' : 'primary'"
                      :complete="currentStep > 1"
                      step="1"

                  >
                    Välj datum
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <v-card flat>
                      <v-card-text>
                        <v-date-picker
                            v-model="request.dates"
                            :allowed-dates="isDateFree"
                            :min="$moment().add(1, 'd').format('YYYY-MM-DD')"
                            :picker-date.sync="pickerDate"
                            :selected-items-text="`${fromDate} - ${toDate}`"
                            class="range-picker"
                            color="primary"
                            elevation="4"
                            full-width
                            locale="sv"
                            range
                            show-current
                            show-week
                            @change="datesError = false"
                        ></v-date-picker>
                        <v-alert :value="datesError" border="left"
                                 class="mt-5" colored-border elevation="3"
                                 transition="slide-y-transition"
                                 type="error">Du måste välja ett önskat datumintervall.
                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                </div>
                <v-stepper-step
                    :color="currentStep > 2 ? 'success' : 'primary'"
                    :complete="currentStep > 2"
                    step="2"
                >
                  Ange dina kontaktuppgifter
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-form
                      ref="form"
                      v-model="validForm"

                      @submit.prevent
                  >
                    <v-card flat>
                      <v-card-text class="pt-0">
                        <external-customer-component :user-account-id="userAccountId"
                                                     :value.sync="request.customer"
                                                     flat></external-customer-component>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <v-stepper-step
                    :complete="currentStep > 3"
                    step="3"
                >
                  Skriv ett meddelande till uthyraren
                  <small>Valfritt</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-card flat>
                    <v-card-text class="pt-0 px-1">
                      <v-form
                          ref="complianceForm"

                          @submit.prevent
                      >
                        <v-checkbox v-model="compliance" :rules="[v => v || 'Obligatoriskt fält']"
                                    class="mb-3"
                                    required>
                          <template v-slot:label>
                            <div>
                              Jag samtycker till behandling av mina personuppgifter enligt
                              EU:s allmänna dataskyddsförordning (GDPR).
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <a
                                      v-on="on"
                                      href="/personuppgiftspolicy.pdf"
                                      target="_blank"
                                      @click.stop
                                  >
                                    Personuppgiftspolicy
                                  </a>
                                </template>
                                Läs personuppgiftspolicy
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                        <v-textarea
                            v-model="request.msg"
                            auto-grow
                            label="Skriv ett valfritt meddelande till uthyraren"
                            solo
                        ></v-textarea>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeDialog">Avbryt</v-btn>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn
              v-if="currentStep > 1"
              @click="currentStep--"
          >
            Tillbaka
          </v-btn>
        </v-fade-transition>
        <v-fade-transition>
          <v-btn
              v-if="currentStep === 3"
              color="success"
              @click="saveRequest"
          >
            Skicka
            <v-icon class="ml-2">mdi-send</v-icon>
          </v-btn>
          <v-btn
              v-else
              color="primary"
              @click="nextStep"
          >
            Nästa
          </v-btn>
        </v-fade-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import api from "../../../api/api";
import bookingPeriodStatuses from "../../../models/bookingPeriodStatuses";
import ExternalCustomerComponent from ".././ExternalCustomerComponent";
import {getImageUrl} from "../../../util/functions";

export default {
  components: {ExternalCustomerComponent},
  data: () => ({
    validForm: false,
    loading: false,
    datesError: false,
    compliance: false,
    occupiedEvents: [],
    pickerDate: "",
    currentStep: 1,
    request: {
      dates: [], // used by datePicker
      fromDate: "",
      toDate: "",
      msg: "",
      bookingPeriodId: null,
      bookingObjectId: null,
      userAccountId: null,
      customer: {
        firstname: "",
        active: 1,
        lastname: "",
        email: "",
        phone: "",
        socialSecurityNumber: "",
        fileData: null,
        address: {
          address1: "",
          address2: "",
          address3: "",
          postcode: "",
          city: "",
          country: "Sverige",
        }
      },
    },
  }),
  watch: {
    pickerDate() {
      this.getOccupiedBookingPeriodsEvents()
    },
    'request.dates'() {
      this.fixDateOrder()
      this.validateFromToDate()
    },
    calendarEvent: {
      handler() { // if event changes we set step to the first one
        this.currentStep = 1
      },
      deep: true
    }

  },
  computed: {
    startDate() {
      if (!this.calendarEvent) {
        return ""
      }
      return this.$moment(this.calendarEvent.start).format("YYYY-MM-DD")
    },
    endDate() {
      if (!this.calendarEvent) {
        return ""
      }
      return this.$moment(this.calendarEvent.end).format("YYYY-MM-DD")
    },
    periodDates() {
      return [this.startDate, this.endDate]
    },
    fromDate() {
      return this.request.dates[0] ? this.$moment(this.request.dates[0]).format("ddd ll") : "Ej vald"
    },
    toDate() {
      return this.request.dates[1] ? this.$moment(this.request.dates[1]).format("ddd ll") : "Ej vald"
    },
    title() {
      return this.calendarEvent ? "Skicka bokningsförfrågan" : "Fråga om annat datum"
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  props: {
    dialogIsOpen: Boolean,
    bookingObject: Object,
    calendarEvent: Object,
    userAccountId: Number,
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialogIsOpen', false)
    },
    getImageUrl(item) {
      return getImageUrl(item)
    },
    async saveRequest() {
      if (this.$refs.complianceForm.validate()) {
        this.loading = true
        if (this.calendarEvent) {
          this.request.bookingPeriodId = this.calendarEvent.bookingPeriodId
          this.request.fromDate = null
          this.request.toDate = null
        } else {
          this.request.fromDate = this.request.dates[0]
          this.request.toDate = this.request.dates[1]
        }
        this.request.bookingObjectId = this.bookingObject.id
        this.request.customer.userAccountId = this.userAccountId
        this.request.userAccountId = this.userAccountId
        let response = await api.postToEndpoint(api.ENDPOINTS.BOOKING + "/" + api.ENDPOINTS.REQUEST, this.request)
        this.loading = false
        if (response) {
          this.$emit('saved', this.request.customer)
          this.closeDialog()
        } else {
          this.$emit("setSnackbar", "Något gick fel", "Det uppstod ett fel när din förfrågan skickades in. Vänligen försök igen eller kontakta oss på support@uthyrd.se", "error")
        }
      }
    },
    async getOccupiedBookingPeriodsEvents() {
      let status = bookingPeriodStatuses.STATUSES_OBJ.BOOKED.value
      this.loading = true
      let fromDate = this.$moment(this.pickerDate + "-01").startOf('month').format('YYYY-MM-DD');
      let toDate = this.$moment(this.pickerDate + "-01").endOf('month').format('YYYY-MM-DD');
      this.occupiedEvents = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.uuid + "/" + api.ENDPOINTS.BOOKINGPERIODS + '/' + api.ENDPOINTS.CALENDAREVENTS + '?start=' + fromDate + '&end=' + toDate + '&statuses=' + status)
      this.loading = false
    },
    isDateFree(val) {
      return this.occupiedEvents.findIndex((ev) => {
        return this.$moment(val).isBetween(ev.start, ev.end, undefined, '[]');
      }) === -1
    },
    getDatesBetweenTwoDates(startDate, endDate) {
      let dateArray = [];
      let currentDate = this.$moment(startDate);
      let stopDate = this.$moment(endDate);
      while (currentDate <= stopDate) {
        dateArray.push(this.$moment(currentDate).format('YYYY-MM-DD'))
        currentDate = this.$moment(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    validateFromToDate() { // makes sure that there are no occupied dates between the selected from- and toDate
      if (this.request.dates.length > 1) {
        let dates = this.getDatesBetweenTwoDates(this.request.dates[0], this.request.dates[1])
        for (let d of dates) {
          if (!this.isDateFree(d)) {
            // if a date is occupied we set dates to only startdate and show a snackbar
            this.request.dates[0] = this.request.dates[1]
            this.$emit("setSnackbar", "Datumintervall upptagen", "Datumen går inte att välja då det finns upptagna perioder i det angivna intervallet", "error")
            break
          }
        }
      }
    },
    fixDateOrder() { // makes sure first date in picker array is always before second date
      if (this.request.dates.length > 1 && this.$moment(this.request.dates[0]).isAfter(this.$moment(this.request.dates[1]))) {
        let date = Object.assign(this.request.dates[0], {})
        this.request.dates[0] = this.request.dates[1]
        this.request.dates[1] = date
      }
    },
    isCurrentStepValid() {
      switch (this.currentStep) {
        case 1:
          if (!this.calendarEvent && this.request.dates.length < 2) {
            this.datesError = true
            return false
          }
          return true
        case 2:
          return this.$refs.form.validate()
      }
    },
    nextStep() {
      if (this.isCurrentStepValid()) {
        this.currentStep++
      }
    }
  },
}
</script>

<style>
.range-picker .v-date-picker-title div {
  font-size: 24px;
}

/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .booking-card .v-stepper__step {
    padding-left: 0 !important;
  }

  .booking-card .v-stepper__content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}

</style>
