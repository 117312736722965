const TYPES = {
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    PDF: "PDF",
    DOCUMENT: "DOCUMENT",
    OTHER: "OTHER",
    properties: {
        IMAGE: {type: "IMAGE", icon: "mdi-image"},
        VIDEO: {type: "VIDEO", icon: "mdi-play-circle-outline"},
        PDF: {type: "PDF", icon: "mdi-file-pdf-box"},
        DOCUMENT: {type: "DOCUMENT", icon: "mdi-file-document-outline"},
        OTHER: {type: "OTHER", icon: "mdi-folder-outline"}
    }
}

export default {
    TYPES
}
