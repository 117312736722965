<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <message-details-component :dialog.sync="inboxMessageDialog" :loading="loadingDialog"
                                 :message="selectedInboxMessage" body-html
                                 show-actions
                                 @confirmDeleteInboxMessage="confirmDeleteInboxMessage"
                                 @connectToExistingConversation="existingConversationDialog = true"
                                 @createConversationFromInboxMessage="createConversationFromInboxMessage"
      ></message-details-component>
      <v-dialog v-model="existingConversationDialog" max-width="650px" persistent scrollable>
        <v-card :loading="loadingExistingConversationDialog">
          <v-card-title>
            Koppla meddelande till befintlig konversation
          </v-card-title>
          <v-card-subtitle>Välj en konversation att koppla meddelandet till</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <conversation-autocomplete-component
                v-model="selectedInboxMessage"
                async-search
                clearable
                required
            ></conversation-autocomplete-component>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="existingConversationDialog = false">Stäng</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text
                   @click="connectInboxMessageToExistingConversation(selectedInboxMessage)">Spara
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <new-conversation-dialog-component
          :selected-customer="newConversationCustomer"
          :dialog.sync="newConversationDialog"
      ></new-conversation-dialog-component>
      <v-flex xs12>
        <v-card>
          <v-toolbar color="secondary" dark>
            <v-toolbar-title data-cy="messagesHeader" class="text-center">
              Meddelanden
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary"
                       fab
                       small
                       @click="newConversationDialog = true">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Skapa en ny konversation</span>
            </v-tooltip>
          </v-toolbar>
          <inbox-list-component ref="inboxComponent" @openInboxMessage="openInboxMessage"></inbox-list-component>
          <v-divider></v-divider>
          <conversation-list-component allow-archive allow-date-filter show-subheader></conversation-list-component>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from "../../api/api"
import ConversationListComponent from "../../components/conversation/ConversationListComponent";
import NewConversationDialogComponent from "./NewConversationDialogComponent";
import MessageDetailsComponent from "../../components/conversation/message/MessageDetailsComponent";
import InboxListComponent from "../../components/conversation/message/InboxListComponent";
import ConversationAutocompleteComponent from "@/components/conversation/ConversationAutocompleteComponent";

export default {
  components: {
    ConversationAutocompleteComponent,
    InboxListComponent,
    MessageDetailsComponent,
    NewConversationDialogComponent, ConversationListComponent
  },
  data: () => ({
    loadingInbox: false,
    loadingDialog: false,
    loadingExistingConversationDialog: false,
    existingConversationDialog: false,
    inboxMessageDialog: false,
    selectedInboxMessage: {},
    newConversationDialog: false,
    newConversationCustomer: null
  }),
  mounted() {
    this.handleNewConversationFromRoute()
  },
  methods: {
    async openInboxMessage(message) {
      this.selectedInboxMessage = message
      this.inboxMessageDialog = true
      if (message.unread > 0) {
        message.unread = 0
        // PUT to backend to save unread status
        await api.putToEndpoint(api.ENDPOINTS.MESSAGES + "/" + api.ENDPOINTS.RECEIVED + "/" + message.id, message)
        this.$store.dispatch('updateUnreadMessages', this.$store.state.user)
      }
    },
    async createConversationFromInboxMessage(message) {
      this.loadingDialog = true
      let conversation = {
        userAccountId: this.$store.state.user.id,
        receivedMessages: [message],
        name: message.fromEmailName + ' - ' + message.subject
      }

      let serverResponse = await api.postToEndpoint(api.ENDPOINTS.CONVERSATIONS, conversation)
      if (serverResponse) {
        conversation = serverResponse
        this.$store.commit('setSnackbar', {color: "success", text: "Konversation skapad"})
        this.$router.push({
          name: 'conversationDetails',
          params: {
            id: conversation.id
          }
        })
      }
      this.loadingDialog = false
    },
    async connectInboxMessageToExistingConversation(message) {
      if (message.conversation) {
        this.loadingExistingConversationDialog = true

        let serverResponse = await api.putToEndpoint(api.ENDPOINTS.MESSAGES + "/" + api.ENDPOINTS.RECEIVED + "/" + message.id, message)
        if (serverResponse) {
          message = serverResponse
          this.$store.commit('setSnackbar', {
            color: "success",
            text: "Meddelande kopplad till konversation"
          })
          this.$router.push({
            name: 'conversationDetails',
            params: {
              id: message.conversation.id
            }
          })
        }
        this.loadingExistingConversationDialog = false
      }
    },
    confirmDeleteInboxMessage(item) {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill ta bort det här meddelandet från ${item.from}?`,
        actionClick: () => {
          this.deleteInboxMessage(item)
        }
      })
    },
    async deleteInboxMessage(message) {
      this.$store.commit('setAppIsLoading', true)
      await api.deleteReqToEndpoint(api.ENDPOINTS.MESSAGES + "/" + api.ENDPOINTS.RECEIVED + "/" + message.id, message)
      this.$refs.inboxComponent.deleteInboxMessage(message)
      this.$store.commit('setAppIsLoading', false)
      this.$store.commit('setSnackbar', {color: "primary", text: "Meddelande borttaget"})
      this.inboxMessageDialog = false
    },
    async getCustomer(id) {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.CUSTOMERS + "/" + id)
      if (serverResponse) {
        return serverResponse
      }
    },
    async handleNewConversationFromRoute() {
      this.newConversationCustomer = null
      const customerId = this.$route.params.customerId
      if (this.$route.params.newConversation) {
        if (customerId) {
          this.newConversationCustomer = await this.getCustomer(customerId)
        }
        this.newConversationDialog = true
      }
    },
  }
}
</script>
