<template>
  <v-avatar :size="hasImageLink ? (size * 1.1) : size" :class="backgroundColor">
    <img alt="avatar" style="object-fit: cover;" v-if="hasImageLink" :src="imgSrc">
    <span v-else class="white--text">{{ initials }}</span>
  </v-avatar>
</template>

<script>
export default {
  data()
  {
    return {
      colorClasses: [
        "primary",
        "accent",
        "secondary",
        "deep-purple lighten-3",
        "pink lighten-2",
        "purple lighten-2",
        "indigo lighten-2",
        "blue lighten-2",
        "light-blue lighten-1",
        "cyan lighten-2",
        "teal lighten-2",
        "green lighten-2",
        "light-green lighten-2",
        "lime lighten-2",
        "orange lighten-2",
        "deep-orange lighten-3",
        "red lighten-3",
        "brown lighten-2",
        "blue-grey lighten-1"
      ]
    }
  },
  computed: {
    initials()
    {
      if (this.fullname)
      {
        let index = 0;
        let split = this.fullname.split(" ");
        let result = ""
        for (let word of split)
        {
          if(index === 2)
          {
            break
          }
          result += word.charAt(0)
          index++
        }
        return result
      }
      else if(this.firstname && this.lastname)
      {
        return this.firstname.charAt(0) + this.lastname.charAt(0)
      }
      return "👩";
    },
    name() {
      return this.fullname ? this.fullname: this.firstname + " " + this.lastname
    },
    backgroundColor()
    {
      if (this.color)
      {
        return this.color
      }
      return this.getColorFromName(this.name)
    },
    hasImageLink()
    {
      return this.imgSrc && this.imgSrc.length > 0;
    }
  },
  methods: {
    // makes sure a user always has the same color (determined by length of the username)
    getColorFromName(name)
    {
      let colorIndex = 0;

      for (let i = 0; i < name.length; i++)
      { // iterate characters in the username
        colorIndex++;
        if (colorIndex >= this.colorClasses.length)
        {
          colorIndex = 0
        }
      }
      return this.colorClasses[colorIndex]
    },
  },
  props: {
    imgSrc: String,
    color: String,
    size: Number,
    firstname: String,
    lastname: String,
    fullname: String
  }
}
</script>
