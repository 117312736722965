<template>
  <transition appear mode="out-in" name="slide-x-transition">
    <uthyrd-loader-component loading-text="Förbereder redigering..."
                             v-if="loading"></uthyrd-loader-component>
    <div v-else-if="website">
      <v-app-bar
          app
          color="secondary"
          flat
      >
        <v-container class="py-0 fill-height">
          <v-fade-transition mode="out-in">
            <v-tooltip v-if="bookingObject" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <initials-avatar-component
                      :imgSrc="getImageUrl(bookingObject)"
                      :size="36"
                      style="vertical-align: middle"
                      :image="getImageUrl(bookingObject)"
                      :fullname="bookingObject.name"
                  ></initials-avatar-component>
                </span>
              </template>
              <span>{{ bookingObject.name }}</span>
            </v-tooltip>
            <v-skeleton-loader type="avatar" v-else></v-skeleton-loader>
          </v-fade-transition>
          <v-tooltip v-if="bookingObject" bottom>
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <v-btn small @click="$router.back()" icon dark class="ml-3 mr-2" outlined>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </span>
            </template>
            <span>Tillbaka</span>
          </v-tooltip>

          <v-chip :href="generatedHref" target="_blank" small class="ml-2" dark outlined label>{{
              generatedHref
            }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn :loading="saving" @click="confirmPublishStatusChange" large class="mr-3" text color="white">
            {{ website.published ? 'Avpublicera' : 'Publicera' }}
          </v-btn>
          <v-btn :loading="saving" @click="save" elevation="6" large color="primary">Spara</v-btn>
        </v-container>
      </v-app-bar>

      <v-main class="grey lighten-3">
        <v-card
            elevation="8"
            class="mt-4 ml-4"
            :width="350"
        >
          <v-navigation-drawer
              floating
              permanent
              :width="350"
          >
            <v-list>
              <v-subheader>
                Sektioner
                <v-spacer></v-spacer>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <website-help-component></website-help-component>
                    </span>
                  </template>
                  <span>Hjälp</span>
                </v-tooltip>
              </v-subheader>
              <v-divider></v-divider>
              <draggable @change="sortOrderChanged" v-model="website.sections">
                <transition-group name="fade-transition">
                  <div v-for="(section, index) in website.sections"
                       :key="'section_'+index">
                    <v-list-item
                        @click="editSection(section)">
                      <v-list-item-icon style="cursor: grab;">
                        <v-icon color="grey">
                          mdi-drag-horizontal
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-truncate">
                          <span v-if="section.data && section.data.text">{{ section.data.text }}</span>
                          <span v-else-if="section.data && section.data.name">{{ section.data.name }}</span>
                          <span v-else>{{ section.websiteSectionType.name }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="primary">
                          {{ section.websiteSectionType.icon }}
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </transition-group>
              </draggable>
              <v-list-item @click="addNewSection">
                <v-list-item-content>
                  <v-list-item-title>
                    Lägg till ny sektion
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="success">mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
        <v-container>
          <v-subheader>Förhandsgranskning</v-subheader>
          <v-sheet
              min-height="70vh"
          >
            <v-slide-y-transition mode="out-in">
              <no-data-component style="padding-top: 25vh" icon="mdi-card-bulleted-off-outline"
                                 v-if="website.sections.length === 0"
                                 text="Du har inte skapat några sektioner för din webbplats än. Det gör du genom att klicka på menyn till vänster"></no-data-component>
              <website-render-component class="mb-12" :website="website" read-only v-else></website-render-component>
            </v-slide-y-transition>
          </v-sheet>
        </v-container>
      </v-main>
      <website-section-drawer-component :dialog.sync="sectionDialog" :section.sync="selectedSection"
                                        :booking-object.sync="bookingObject"
                                        @change="sectionsChanged"
                                        @snackbar="showSnackbar"
                                        @confirm="showConfirmDialog"
                                        :website.sync="website"></website-section-drawer-component>
      <unsaved-changes-dialog-component ref="unsaved" :value.sync="unsavedChanges"></unsaved-changes-dialog-component>
      <v-snackbar
          v-model="snackbar.active"
          :color="snackbar.color"
          :timeout="snackbar.timeout"
      >
        <p>
          {{ snackbar.text }}
        </p>
        <template v-slot:action>
          <v-btn
              dark
              text
              @click="snackbar.active = false"
          >
            Stäng
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="confirmDialog.active" max-width="550" persistent>
        <v-card>
          <v-card-title class="headline secondary--text">{{ confirmDialog.title }}</v-card-title>
          <v-card-text>
            {{ confirmDialog.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn left text @click="confirmDialog.active = false">Avbryt</v-btn>
            <v-btn color="primary" text @click="confirmDialog.action">{{ confirmDialog.btnText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <page-not-found-component v-else></page-not-found-component>
  </transition>
</template>

<script>

import UthyrdLoaderComponent from "@/components/global/UthyrdLoaderComponent";
import api from "@/api/api";
import PageNotFoundComponent from "@/components/errors/PageNotFoundComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";
import NoDataComponent from "@/components/global/NoDataComponent";
import UnsavedChangesDialogComponent from "@/components/global/UnsavedChangesDialogComponent";
import WebsiteRenderComponent from "@/components/website/render/WebsiteRenderComponent";
import WebsiteSectionDrawerComponent from "@/components/website/section/WebsiteSectionDrawerComponent";
import draggable from 'vuedraggable'
import WebsiteHelpComponent from "@/components/website/WebsiteHelpComponent";

export default {
  components: {
    WebsiteSectionDrawerComponent,
    WebsiteRenderComponent,
    UnsavedChangesDialogComponent,
    NoDataComponent,
    PageNotFoundComponent,
    UthyrdLoaderComponent,
    InitialsAvatarComponent,
    draggable,
    WebsiteHelpComponent
  },
  data: () => ({
    website: null,
    bookingObject: null,
    loading: false,
    saving: false,
    sectionDialog: false,
    selectedSection: null,
    unsavedChanges: false,
    timeout: null,
    primaryColor: '#8380B6',
    snackbar:
        {
          active: false,
          title: "",
          color: ""
        },
    confirmDialog:
        {
          active: false,
          title: "",
          text: "",
          btnText: "",
          action: null
        }
  }),
  computed: {
    generatedHref()
    {
      return `${api.BASE_URL_FRONTEND}boka/${this.website.url}`
    },
  },
  async mounted()
  {
    await this.getWebsite(this.$route.params.id)
    if (this.website)
    {
      this.getBookingObject(this.website.bookingObjectId)
    }
  },
  methods: {
    async getWebsite(id)
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.WEBSITES + "/" + id)
      if (serverResponse)
      {
        this.website = serverResponse
      }
      this.loading = false
    },
    async getBookingObject(id)
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + id)
      if (serverResponse)
      {
        this.bookingObject = serverResponse
      }
      this.loading = false
    },
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    addNewSection()
    {
      this.sectionDialog = false
      this.$nextTick(() =>
      {
        this.selectedSection = null
        this.sectionDialog = true
      })
    },
    editSection(section)
    {
      this.sectionDialog = false
      this.$nextTick(() =>
      {
        this.selectedSection = Object.assign({}, section)
        this.sectionDialog = true
      })
    },
    sectionsChanged()
    {
      this.unsavedChanges = true
    },
    confirmPublishStatusChange()
    {
      let actionTxt = this.website.published ? 'avpublicera' : 'publicera'
      let titleTxt = this.website.published ? 'avpublicering' : 'publicering'

      this.showConfirmDialog(
          {
            title: "Bekräfta " + titleTxt,
            text: `Är du säker på att du vill ${actionTxt} denna webbplats? Notera att osparade ändringar kommer att sparas och vid publicering så kommer den nuvarande publicerade webbplatsen att avpubliceras`,
            btnText: actionTxt,
            action: this.changePublishStatus,
            active: true
          }
      )
    },
    async changePublishStatus()
    {
      this.confirmDialog.active = false
      if (this.unsavedChanges)
      {
        await this.save(true)
      }
      this.saving = true
      let response = await api.putToEndpoint(api.ENDPOINTS.WEBSITES + "/" + this.website.id + "/" + api.ENDPOINTS.PUBLISHED)
      if (response != null)
      {
        this.showSnackbar({
          color: "success",
          text: "Webbplats uppdaterad med dina ändringar!",
          timeout: 6000,
          active: true
        })
        this.website.published = !this.website.published
      }
      this.saving = false
    },
    async save(dontWarn)
    {
      this.saving = true
      if (this.website.published && !dontWarn)
      {
        this.showConfirmDialog(
            {
              title: "Bekräfta ändring av publicerad webbplats",
              text: "Din webbplats är publicerad och dina ändringar kommer därmed att publiceras direkt när du sparar. Är du säker på att du vill göra detta?",
              btnText: "Spara",
              action: this.save(true),
              active: true
            }
        )
      }
      this.unsavedChanges = false
      let response = await api.putToEndpoint(api.ENDPOINTS.WEBSITES + "/" + this.website.id, this.website)
      if (response)
      {
        this.website = response;
        this.showSnackbar({
          color: "success",
          text: "Webbplats uppdaterad med dina ändringar!",
          timeout: 6000,
          active: true
        })
      }
      this.saving = false
    },
    showSnackbar(snackbar)
    {
      window.clearTimeout(this.timeout)
      this.snackbar.active = false
      this.timeout = window.setTimeout(() => this.snackbar = snackbar, 100)
    },
    showConfirmDialog(dialog)
    {
      this.confirmDialog = dialog
    },
    sortOrderChanged() // when an item is re-ordered we update its sortOrder
    {
      this.showSnackbar({
        color: "primary",
        text: "Ordningen på dina sektioner har nu uppdaterats",
        timeout: 6000,
        active: true
      })
      for (let i = 0; i < this.website.sections.length; i++)
      {
        this.website.sections[i].sortOrder = i
      }
      this.unsavedChanges = true
    }
  },
  beforeRouteLeave(to, from, next)
  {
    if (this.$refs.unsaved)
    {
      this.$refs.unsaved.beforeRouteLeave(to, from, next)
    }
  },
}
</script>

