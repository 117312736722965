import api from "../api/api";

export const pidPattern = /^(19|20)[0-9]{6}[-\s]?[0-9]{4}$/;

export function validateForm(form)
{
    if (!form.validate())
    {
        let el = document.querySelector(".v-application .error--text:first-of-type");
        form.$vuetify.goTo(el, {offset: 100});
        return false;
    }
    return true
}

export function isInIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
export function getQueryParamFromUrl(paramName)
{
    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if (urlParams.has(paramName))
    {
        return urlParams.get(paramName)
    }
    return null
}

export function getImageUrl(item)
{
    if (item.fileData && item.fileData.src)
    {
        return api.BASE_URL + item.fileData.src
    }
    return ""
}
