<template>
    <v-container v-if="stateUser.systemAdmin === 1" grid-list-xl>
        <v-layout row wrap>
            <v-flex xs12>
                <attribute-admin-component></attribute-admin-component>
            </v-flex>
        </v-layout>
    </v-container>
    <not-allowed-component v-else></not-allowed-component>
</template>

<script>
    import AttributeAdminComponent from "../../../components/attribute/AttributeAdminComponent";
    import NotAllowedComponent from "../../../components/errors/NotAllowedComponent";

    export default {
        components: {
            NotAllowedComponent,
            AttributeAdminComponent
        },
        computed: {
            stateUser()
            {
                return this.$store.state.user
            }
        },
    }
</script>
