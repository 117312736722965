<template>
  <component :is="data.type" :class="classes" :style="style">{{ data.text }}</component>
</template>

<script>

export default {
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
    classes()
    {
      let fontStyles = ""
      if (this.data.fontStyles)
      {
        for (let style of this.data.fontStyles)
        {
          fontStyles += style + " "
        }
      }
      return "text-" + this.data.justify + " " + fontStyles
    },
    style()
    {
      return "color: " + this.data.color + ";"
    }
  }
}
</script>
