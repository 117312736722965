<template>
  <v-container fill-height grid-list-xl>
    <v-card :flat="flat" :outlined="flat" width="100%">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title data-cy="bookingObjectsHeader" class="text-center">Uthyrningsobjekt</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn data-cy="addNewBtn" v-on="on" :class="{'highlight-pulse': bookingObjects.length <= 0}" color="primary" fab small
                   @click="newBookingObject">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Skapa ett nytt uthyrningsobjekt</span>
        </v-tooltip>
      </v-toolbar>
      <v-list v-if="bookingObjects.length > 0" two-line>
        <v-list-item
            v-for="item in bookingObjects"
            :key="item.title"
            @click="editBookingObject(item)"
            :data-cy="'bo_' + item.name.replaceAll(' ', '')"
        >
          <v-list-item-avatar>
            <initials-avatar-component
                :imgSrc="getImageUrl(item)"
                :size="34"
                :fullname="item.name"
            ></initials-avatar-component>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else-if="loading" class="pa-2">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>
      <no-data-component v-else icon="arrow_upward" pulse text="Du har inte skapat några uthyrningsobjekt än. Använd
            plus-knappen längst upp till höger för att göra det"></no-data-component>
    </v-card>
  </v-container>
</template>

<script>
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";
import NoDataComponent from "../../components/global/NoDataComponent";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";

export default {
  components: {NoDataComponent, InitialsAvatarComponent},
  props: {
    setup: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stateUser() {
      return this.$store.state.user
    },
    bookingObjectsMaxLimit() {
      let defaultLimit = 150 // Limit pioneers and testers to 150 for now
      return this.hasSeats && this.subscriptionIsStandardRenter ? this.stateUser.accountSubscription.seats : defaultLimit
    },
    hasSeats() {
      return accountSubscriptionTypes.TYPES[this.stateUser.accountSubscription.type].hasSeats
    },
    subscriptionIsStandardRenter() {
      return accountSubscriptionTypes.TYPES[this.stateUser.accountSubscription.type] === accountSubscriptionTypes.TYPES.PREMIUM
    }
  },
  data: () => ({
    bookingObjects: [],
    loading: false,
  }),
  mounted()
  {
    this.getBookingObjects()
  },
  methods: {
    async getBookingObjects()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGOBJECTS)
      if (serverResponse)
      {
        this.bookingObjects = serverResponse
      }
      this.loading = false
    },
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    newBookingObject()
    {
      if (!this.setup)
      {
        if(this.bookingObjects.length < this.bookingObjectsMaxLimit)
        {
          this.$router.push({
            name: 'newBookingObject'
          })
        }
        else
        {
          this.$store.commit('setDialog', {
            active: true,
            closeBtnText: "Jag förstår",
            actionBtnText: "Ändra prenumeration",
            title: "Du har nått maxgränsen för uthyrningsobjekt på din nuvarande prenumeration",
            text: `Du kan för närvarande endast ha ${this.bookingObjectsMaxLimit} uthyrningsobjekt i ditt konto. Klicka på knappen nedan till höger för att ändra din prenumeration så att du kan lägga till fler.`,
            actionClick: () =>
            {
              this.$router.push({name: "adminUseraccount"})
            }
          })
        }
      }
      else
      {
        this.$emit('newBookingObject')
      }
    },
    editBookingObject(item)
    {
      if (!this.setup)
      {
        this.$router.push({
          name: 'editBookingObject', params: {id: item.id}
        })
      }
      else
      {
        this.$emit('editBookingObject', item)
      }
    }
  },
}
</script>

<style scoped>

</style>
