<template>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
        @click="showConfirmRequest"
        outlined
        color="primary"
        :disabled="disabled"
    >
      Fråga om annat datum <!-- TODO - translate -->
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  components: {
  },
  props: {
    isEmbedded: Boolean,
    disabled: Boolean,
  },
  methods: {
    showConfirmRequest() {
      this.$emit('click')
    },
  }
}
</script>

