<template>
  <div class="my-2">
    <google-maps-component
        :height="data.height"
        :value="data.value"
        :width="data.width + '%'"
    ></google-maps-component>
  </div>
</template>

<script>
import GoogleMapsComponent from "@/components/global/GoogleMapsComponent";

export default {
  components: {
    GoogleMapsComponent
  },
  props: {
    section: Object,
    website: Object,
  },
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
  }
}
</script>
