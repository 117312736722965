import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'
import Moment from "moment";
import Croppa from "vue-croppa";
import 'vue-croppa/dist/vue-croppa.css';

Vue.use(Vuetify);

// TODO - set lang from user
Moment.locale("sv")
Vue.prototype.$moment = Moment

Vue.use(Croppa);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme:
        {
            themes: {
                light: {
                    primary: '#8380B6',
                    secondary: '#505172',
                    accent: '#bb87b0',
                    "accent-darken": '#9f709b',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#e7a116'
                }
            }
        }
});
