<template>
  <v-list subheader three-line>
    <v-progress-linear indeterminate color="primary" :active="loadingInbox"></v-progress-linear>
    <v-subheader class="mt-2">Inkorg</v-subheader>
    <no-data-component class="mb-10 mt-0 pt-0" v-if="inbox.length <= 0"
                       text="Det finns inga nya meddelanden i din inkorg"
                       icon="mdi-inbox"></no-data-component>
    <transition-group name="slide-x-transition">
      <v-list-item
          v-for="item in inbox"
          :key="item.title + item.id"
          @click="openInboxMessage(item)"
      >
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.fromEmailName }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip label color="secondary" v-on="on" x-small outlined
                          class="pl-1 pr-1">{{ item.fromEmailAddress }}
                  </v-chip>
                </template>
                <span>{{ item.from }}</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle class="text--primary">{{ item.subject }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.strippedText }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>
              {{ $moment(item.createdAt).fromNow() }}
            </v-list-item-action-text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mt-2"
                        :color="item.unread > 0 ? 'error' : 'grey'">
                  {{
                    item.unread > 0
                        ?
                        "mdi-email-outline" : "mdi-email-open-outline"
                  }}
                </v-icon>
              </template>
              <span>{{ item.unread > 0 ? 'Oläst' : 'Läst' }} meddelande</span>
            </v-tooltip>
          </v-list-item-action>
        </template>
      </v-list-item>
    </transition-group>
  </v-list>
</template>

<script>
import NoDataComponent from "../../global/NoDataComponent";
import api from "../../../api/api";

export default {
  components: {NoDataComponent},
  data: () => ({
    loadingInbox: false,
    inbox: [],
  }),
  mounted()
  {
    this.getInboxMessages()
  },
  methods: {
    async getInboxMessages()
    {
      this.loadingInbox = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.MESSAGES + "/" + api.ENDPOINTS.RECEIVED + "?noConversationConnected=true")
      if (serverResponse)
      {
        this.inbox = serverResponse
        this.$emit('updated', serverResponse)
      }
      this.loadingInbox = false
    },
    openInboxMessage(message)
    {
      this.$emit('openInboxMessage', message)
    },
    deleteInboxMessage(message)
    {
      this.inbox.splice(this.inbox.findIndex((item) => item.id === message.id), 1)
      this.$emit('updated', this.inbox)
    }
  }
}
</script>

