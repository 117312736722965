<template>
  <v-dialog max-width="600px" v-model="dialogIsOpen">
    <template v-slot:activator="{ on }">
      <v-btn
          text
          color="primary"
          v-on="on"
      >
        Vad är detta/Hur gör man
        <v-icon right>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title avatar class="secondary white--text">
        <span class="headline">Hjälp - Kalenderprenumeration</span>
        <v-spacer></v-spacer>
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-card-title>
      <v-card-text>
        <v-subheader class="pl-0 mt-3 font-weight-bold">Vad är detta?</v-subheader>
        <p>Lägg in din Uthyrdkalender i din egen kalender.</p>
        <p>
          Alla perioder och uthyrningar kommer då automatiskt att hamna i din egen kalender i
          telefonen och/eller datorn!
        </p>
        <v-subheader class="pl-0 font-weight-bold">Hur gör man?</v-subheader>
        <p>Börja med att använda plusknappen för att skapa en prenumerationslänk.</p>
        <p>Olika kalenderprenumerationslänkar kan ha olika filter så att du kan välja att se
          exempelvis endast uthyrda perioder i en kalender och en kombination av olika statusar i
          en annan kalender.</p>
        <p class="mb-6">Därefter kan du öppna länken direkt i ditt kalenderprogram genom att klicka på den i
          listan alternativt kopiera länken och följa instruktionerna nedan för just ditt kalenderprogram</p>
        <v-divider></v-divider>
        <v-select
            :items="externalCalendars"
            class="mt-6"
            label="Välj kalenderprogram"
            solo
            v-model="externalCalendar"
        >
          <template v-slot:selection="{item}">
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
            <span class="ml-2">{{ item.text }}</span>
          </template>
          <template v-slot:item="{item}">
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
            <span class="ml-2">{{ item.text }}</span>
          </template>
        </v-select>
        <div v-html="guideText"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogIsOpen = !dialogIsOpen" text>Stäng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    externalCalendar: null,
    externalCalendars: [{text: "Google Kalender", value: 1, icon: "mdi-google", color: "primary"}, {
      text: "Apple Kalender",
      value: 2,
      icon: "mdi-apple",
      color: "secondary"
    }, {text: "Microsoft Outlook", value: 3, icon: "mdi-outlook", color: "info"}],
    dialogIsOpen: false
  }),
  computed: {
    guideText()
    {
      switch (this.externalCalendar)
      {
        case 1:
          return "" +
              "<p>1. Öppna Google Kalender på datorn.\n</p>" +
              "<p>2. Klicka på Lägg till följt av Från URL till vänster, bredvid Andra Kalendrar.\n</p>" +
              "<p>3. Ange kalenderns webbadress i fältet.\n</p>" +
              "<p>4. Klicka på Lägg till kalender. Kalendern visas till vänster under Andra kalendrar.\n</p>" +
              "<p>5. Det kan ta upp till 12 timmar innan ändringar visas i din Google Kalender.</p>" +
              "<p>Mer info går att <a href='https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&hl=sv' target='_blank'>hitta här</a></p>"

        case 2:
          return "" +
              "<p>1. Välj Arkiv > Ny kalenderprenumeration i programmet Kalender  på datorn.\n</p>" +
              "<p>2. Ange kalenderns webbadress och klicka på Prenumerera.\n</p>" +
              "<p>3. Ange ett namn på kalendern i fältet Namn och klicka sedan på popupmenyn intill och välj en färg.\n</p>" +
              "<p>4. Klicka på popupmenyn Plats och välj ett konto för prenumerationen.\n</p>" +
              "<p>5. Om du väljer ditt iCloud-konto blir kalendern tillgänglig på alla datorer och enheter som du ställt in för användning med iCloud.\n</p>" +
              "<p>6. Om du väljer På min Mac sparas kalendern på datorn.\n</p>" +
              "<p>7. Om du vill ta emot kalenderaktivitetens bilagor eller notiser avmarkerar du motsvarande ta bort-kryssrutor.\n</p>" +
              "<p>8. Klicka på popupmenyn Autouppdatera och välj hur ofta kalendern ska uppdateras.\n</p>" +
              "<p>9. Om du inte vill visa notiser för kalendern markerar du Ignorera notiser.\n</p>" +
              "<p>10. Klicka på OK.\n</p>" +
              "<p>11. Du kan göra ändringar i kalendern senare genom att klicka på kalenderns namn och sedan välja Redigera > Visa info.</p>" +
              "<p>Mer info går att <a href='https://support.apple.com/sv-se/guide/calendar/icl1022/mac' target='_blank'>hitta här</a></p>"
        case 3:
          return "" +
              "<p>1. Logga in på Outlook.com.</p>" +
              "<p>2. Längst ned på sidan väljer du Kalender.</p>" +
              "<p>3. I navigerings fönstret väljer du Importera kalender.</p>" +
              "<p>4. Under Importera kalender väljer du Från webben.\n</p>" +
              "<img style='width: 100%;' src=\"https://support.content.office.net/sv-se/media/91de354e-490f-4f55-afdb-2f9c27d3c373.png\" alt=\"En skärmbild av alternativet Importera från webb\" class=\"image\">" +
              "<p>5. Under Länk till kalender skriver du URL:en för den länkade kalendern.\n</p>" +
              "<p>6. Under Kalendernamn skriver du namnet som du vill använda för kalendern.\n</p>" +
              "<p>7. Välj Importera.</p>" +
              "<p>Mer info går att <a href='https://support.office.com/sv-se/article/importera-och-prenumerera-p%C3%A5-en-kalender-i-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c' target='_blank'>hitta här</a></p>"
        default:
          return "Välj ditt kalenderprogram från listan ovan för att få instruktioner om hur du lägger in en prenumerationslänk"
      }
    }
  },
}
</script>
