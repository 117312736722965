<template>
    <v-list-item
            @click="itemClick(bookingObject)"
            :value="bookingObject.id"
    >
        <v-list-item-avatar>
          <initials-avatar-component
              :imgSrc="getImageUrl(bookingObject)"
              :size="34"
              :fullname="bookingObject.name"
          ></initials-avatar-component>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ bookingObject.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ bookingObject.description }}</v-list-item-subtitle>
        </v-list-item-content>

    </v-list-item>
</template>

<script>
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
        components: {InitialsAvatarComponent},
        props: {
            bookingObject: Object
        },
        methods: {
            itemClick(item)
            {
                this.$emit("item-click", item)
            },
            getImageUrl(item)
            {
                if (item.fileData && item.fileData.src)
                {
                    return api.BASE_URL + item.fileData.src
                }
                return ""
            }
        }
    }
</script>
