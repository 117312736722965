<template>
  <div>
    <v-combobox
        :items="customers"
        v-model="value.email"
        :required="required"
        :label="label"
        :placeholder="placeholder"
        item-text="email"
        item-value="email"
        :return-object="false"
        no-data-text="Din sökning gav inga resultat. Tryck på tab eller enter för att välja det aktuella värdet."
        :disabled="readOnly"
        :loading="loading"
        @change="change"
        :multiple="multiple"
        small-chips
        class="customer-select-input"
        :prepend-inner-icon="prependInnerIcon"
        :hide-selected="hideSelected"
        :hide-details="hideDetails"
        :single-line="singleLine"
        deletable-chips
        :full-width="fullWidth"
        :dense="dense"
        :error="error"
        @blur="validateInput"
        :error-messages="errorMessages"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <initials-avatar-component
                :imgSrc="getImageUrl(data.item)"
                :size="32"
                :fullname="getFullname(data.item)"
            ></initials-avatar-component>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ getFullname(data.item) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.email }}
              <br>
              {{ data.item.phone }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {InitialsAvatarComponent},
  data: () =>
  {
    return {
      customers: [],
      loading: false,
      dialogIsOpen: false,
      emailAddressSearch: "",
      error: false
    }
  },
  props: {
    value: Object,
    readOnly: Boolean,
    required: Boolean,
    multiple: Boolean,
    placeholder: String,
    label: String,
    prependInnerIcon: String,
    hideSelected: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    dense: Boolean,
    fullWidth: Boolean,
    validate: Boolean,
    customerList: Array
  },
  computed: {
    errorMessages()
    {
      return this.error && this.validate ? ['Den angivna e-postadressen är inte giltig'] : null
    }
  },
  async mounted()
  {
    await this.getCustomers()
  },
  methods: {
    change(data)
    {
      this.validateInput()
      this.$emit("change", data)
    },
    isEmailInvalid(val)
    {
      return !val || val.indexOf("@") <= 0
    },
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    validateInput()
    {
      this.error = false
      if (this.validate)
      {
        this.error = this.isEmailInvalid(this.value.email)
      }
    },
    async getCustomers()
    {
      this.loading = true
      if (this.customerList && this.customerList.length > 0)
      {
        this.customers = this.customerList
        this.loading = false
        return
      }
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.CUSTOMERS + "?filterEmailOnly=true")
      if (serverResponse)
      {
        this.customers = serverResponse
      }
      this.loading = false
    },
    getFullname(customer)
    {
      return customer.firstname + " " + customer.lastname
    }
  }
}
</script>
