<template>
    <v-list-item
            :key="searchResult.title"
            :to="searchResult.route"
            class="pb-1"
    >
        <v-list-item-avatar v-if="searchResult.avatar">
            <v-avatar>
                <v-img
                        lazy-src="https://picsum.photos/10/6"
                        :src="searchResult.avatar" alt="avatar">
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-progress-circular indeterminate
                                                 color="primary lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-avatar>
        </v-list-item-avatar>
        <v-list-item-icon v-else-if="searchResult.icon">
            <v-icon :color="searchResult.color">{{searchResult.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                {{searchResult.title}}
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: pre-line; -webkit-line-clamp: 5;" v-if="searchResult.subtitle">
                {{searchResult.subtitle}}
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            searchResult: Object
        },
    }
</script>

<style scoped>

</style>
