<template>
  <v-card :disabled="loading" :flat="flat" :loading="loading" color="white">
    <v-card-title v-if="!hideTitle" class="secondary">
      <v-icon
          dark
          left
      >
        person_outlined
      </v-icon>
      <span class="title textfont-weight-light white--text">{{ title }}</span>
      <v-spacer></v-spacer>
      <v-icon v-if="customer.mainContact" dark>mdi-heart</v-icon>
      <customer-details-request-button-component v-if="customer.id" :customer="customer" button-color="white"
                                                 dark icon-color="info lighten-4"
                                                 icon-color-sent="success lighten-4"></customer-details-request-button-component>
    </v-card-title>
    <v-card-text :class="{'px-0': flat}" :style="{'height: 400px': scrollable}">
      <v-container>
        <v-layout row wrap>
          <v-flex v-if="!hideAvatar" class="text-center" xs12>
            <v-avatar
                v-if="readOnly"
                class="avatar-outline"
                size="128px"
            >
              <v-img v-if="customerAvatar && customerAvatar.length > 0" :src="customerAvatar"
                     alt="avatar"></v-img>
              <v-icon v-else color="primary" x-large>mdi-account</v-icon>
            </v-avatar>
            <avatar-admin-component v-else :avatar-src="customerAvatar"
                                    :size="128"
                                    editable
                                    @avatarClick="fileUploadDialog = true"
                                    @avatarEditClick="imageEditDialog = true"
            ></avatar-admin-component>
          </v-flex>
          <v-flex xs12>
            <div v-if="readOnly">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon class="pt-0">
                    <v-icon color="primary">mdi-account-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>Förnamn</v-list-item-subtitle>
                    <v-list-item-title>{{ customer.firstname }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon> <!-- Needed for formatting -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>Efternamn</v-list-item-subtitle>
                    <v-list-item-title>{{ customer.lastname }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>E-post</v-list-item-subtitle>
                    <v-list-item-title>{{ customer.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>Telefonnummer</v-list-item-subtitle>
                    <v-list-item-title>{{ customer.phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div v-else>
              <v-form
                  ref="form"
                  v-model="validForm"
              >
                <v-text-field
                    v-model="customer.firstname"
                    :counter="100"
                    :readonly="readOnly"
                    :rules="[v => !!v || 'Du måste ange ett förnamn']"
                    autocomplete="off"
                    autofocus
                    label="Förnamn"
                    prepend-icon="mdi-account-outline"
                    required
                    @keyup.enter="saveCustomer"
                ></v-text-field>
                <v-text-field
                    v-model="customer.lastname"
                    :counter="100"
                    :readonly="readOnly"
                    :rules="[v => !!v || 'Du måste ange ett efternamn']"
                    autocomplete="off"
                    label="Efternamn"
                    prepend-icon="icon"
                    required
                    @keyup.enter="saveCustomer"
                ></v-text-field>
                <v-text-field
                    v-model="customer.email"
                    :counter="100"
                    :readonly="readOnly"
                    :rules="emailRules"
                    autocomplete="off"
                    label="E-post"
                    prepend-icon="mdi-email-outline"
                    type="email"
                    @keyup.enter="saveCustomer"
                ></v-text-field>
                <v-text-field
                    v-model="customer.phone"
                    :counter="50"
                    :readonly="readOnly"
                    autocomplete="off"
                    class="mb-3"
                    label="Telefonnummer"
                    prepend-icon="mdi-phone-outline"
                    required
                    type="tel"
                    @key.enter="saveCustomer"
                ></v-text-field>
                <social-security-number-text-field-component
                    v-model="customer"
                    :read-only="readOnly"
                    prepend-icon="mdi-account-lock-outline"
                    @keyupEnter="saveCustomer"
                ></social-security-number-text-field-component>
              </v-form>
            </div>
          </v-flex>
          <v-flex xs12>
            <address-component :address.sync="customer.address" :opened-index="addressContent"
                               :read-only="readOnly" flat outlined
                               @keyUpEnter="saveCustomer"></address-component>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-divider v-if="scrollable"></v-divider>
    <v-card-actions>
      <v-btn v-if="cancelBtn" text @click="cancel">
        Avbryt
      </v-btn>
      <v-btn v-if="!readOnly && customer.id && customer.id > 0" color="error" text
             @click="confirmDelete">Ta bort
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!readOnly" color="primary" text
             @click="saveCustomer">Spara
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="fileUploadDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Ladda upp avatar
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <file-upload-component :max-filesize="10"
                                 acceptedFileTypes="image/*"
                                 @fileUploaded="avatarUploaded"></file-upload-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="fileUploadDialog = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <image-edit-dialog-component v-if="customerAvatar && customerAvatar.length > 0" :dialog.sync="imageEditDialog"
                                 :image="customer.fileData"
                                 @imageSaved="avatarEdited"></image-edit-dialog-component>
  </v-card>
</template>

<script>
import api from "../../api/api";
import FileUploadComponent from "../global/file/FileUploadComponent";
import AddressComponent from "../global/AddressComponent";
import SocialSecurityNumberTextFieldComponent from "../global/SocialSecurityNumberTextFieldComponent";
import AvatarAdminComponent from "../global/AvatarAdminComponent";
import ImageEditDialogComponent from "../global/ImageEditDialogComponent";
import CustomerDetailsRequestButtonComponent from "./CustomerDetailsRequestButtonComponent";

export default {
  components: {
    CustomerDetailsRequestButtonComponent,
    AvatarAdminComponent,
    SocialSecurityNumberTextFieldComponent,
    AddressComponent,
    FileUploadComponent,
    ImageEditDialogComponent
  },
  data: () => ({
    validForm: true,
    loading: false,
    addressContent: 1,
    emailRules: [
      v => !v || v.length <= 0 || /.+@.+/.test(v) || 'Den angivna e-postadressen är inte giltig'
    ],
    customer: {
      firstname: "",
      active: 1,
      lastname: "",
      email: "",
      uuid: null,
      phone: "",
      socialSecurityNumber: "",
      fileData: null,
      detailsProvidedByCustomer: false,
      address: {
        address1: "",
        address2: "",
        address3: "",
        postcode: "",
        city: "",
        country: "Sverige",
      }
    },
    pickingAvatar: false,
    fileUploadDialog: false,
    imageEditDialog: false
  }),
  props: {
    customerId: Number,
    conversationId: Number, // used to connect a conversation when sending an detailsRequest
    customerObject: Object,
    cancelBtn: Boolean,
    shown: Boolean,
    readOnly: Boolean,
    hideAvatar: Boolean,
    hideTitle: Boolean,
    flat: Boolean,
    scrollable: Boolean
  },
  computed: {
    stateUser()
    {
      return this.$store.state.user
    },
    customerAvatar()
    {
      if (this.customer.fileData && this.customer.fileData.src)
      {
        return api.BASE_URL + this.customer.fileData.src
      }
      return ""
    },
    fullname()
    {
      return this.customer.firstname + " " + this.customer.lastname
    },
    title()
    {
      return this.fullname.length > 1 ? this.fullname : "Ny kund"
    },
  },
  watch: {
    shown()
    {
      if (this.shown)
      {
        this.setInitCustomer()
      }
    }
  },
  async mounted()
  {
    this.setInitCustomer()
  },
  methods: {
    async setInitCustomer()
    {
      if (this.customerId && this.customerId > 0)
      {
        this.addressContent = 1
        this.loading = true
        let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.CUSTOMERS + "/" + this.customerId)
        if (serverResponse)
        {
          Object.assign(this.customer, serverResponse);
        }
        this.loading = false
      }
      else if (this.customerObject)
      {
        this.addressContent = 1
        this.customer = this.customerObject
      }
      else
      {
        this.customer = {
          firstname: "",
          active: 1,
          lastname: "",
          email: "",
          phone: "",
          fileData: null,
          address: {
            address1: "",
            address2: "",
            address3: "",
            postcode: "",
            city: "",
            country: "Sverige",
          }
        }
        this.addressContent = 0
      }
      this.customer.userAccountId = this.stateUser.id

    },
    async saveCustomer()
    {
      this.$refs.form.validate()
      if (this.validForm)
      {
        this.$store.commit('setAppIsLoading', true)
        let saveText = this.fullname + " skapad"
        this.loading = true
        if (this.customer.id && this.customer.id > 0)
        {
          saveText = this.fullname + " uppdaterad"
          this.customer = await api.putToEndpoint(api.ENDPOINTS.CUSTOMERS + "/" + this.customer.id, this.customer)
        }
        else
        {
          this.customer = await api.postToEndpoint(api.ENDPOINTS.CUSTOMERS, this.customer)
        }
        this.loading = false
        this.$store.commit('setAppIsLoading', false)
        if (this.customer != null)
        {
          this.$store.commit('setSnackbar', {color: "success", text: saveText})
        }
        this.$emit('saved', this.customer)
      }
    },
    avatarUploaded(response) // when image is uploaded we update the customer object
    {
      let fileData = response[0]
      this.customer.fileData = fileData
      if (this.customer.id && this.customer.id > 0)
      {
        this.imageEditDialog = false
        this.saveCustomer()
      }
    },
    avatarEdited(file) // when image is uploaded we update the user object
    {
      this.customer.fileData = file
      if (this.customer.id && this.customer.id > 0)
      {
        this.imageEditDialog = false
        this.saveCustomer()
      }
    },
    cancel()
    {
      this.$emit('cancel')
    },
    confirmDelete()
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: "Är du säker på att du vill ta bort " + this.fullname + "?",
        actionClick: this.deleteCustomer
      })
    },
    async deleteCustomer()
    {
      this.loading = true
      await api.deleteReqToEndpoint(api.ENDPOINTS.CUSTOMER + "/" + this.customer.id)
      this.loading = false
      this.$store.commit('setSnackbar', {color: "primary", text: this.fullname + " borttagen"})
      this.$emit('saved', this.customer)
    }
  }
}
</script>

<style scoped>

</style>
