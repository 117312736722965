<template>
    <v-dialog v-model="dialogIsOpen" persistent max-width="600px">
        <v-card>
            <v-card-title avatar class="secondary white--text">
                <span class="headline">{{titleText}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-xl>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-form
                                    ref="form"
                                    v-model="validForm"

                                    @submit.prevent
                            >
                                <v-text-field
                                        v-model="attribute.name"
                                        :counter="100"
                                        label="Namn"
                                        :rules="[v => !!v || 'Du måste ange ett namn']"
                                        required
                                        autofocus
                                        autocomplete="off"
                                        @keyup.enter="saveAttribute"
                                        class="mb-2"
                                ></v-text-field>
                                <v-switch
                                        v-model="attribute.hasValue"
                                        :false-value="0"
                                        :true-value="1"
                                        label="Visa antal för attributet"
                                ></v-switch>
                                <v-slide-y-transition>
                                    <v-text-field
                                            v-model="attribute.valueUnit"
                                            :counter="100"
                                            label="Enhet"
                                            :rules="[v => !!v || 'Du måste ange en enhet']"
                                            required
                                            autocomplete="off"
                                            @keyup.enter="saveAttribute"
                                            class="mb-2"
                                            v-if="attribute.hasValue"
                                    ></v-text-field>
                                </v-slide-y-transition>
                                <icon-select-component v-model="attribute.icon"></icon-select-component>
                                <v-textarea
                                        solo
                                        label="Beskrivning"
                                        v-model="attribute.description"
                                        auto-grow
                                        :counter="1024"
                                ></v-textarea>
                                <v-select
                                        v-model="attribute.attributeGroup"
                                        :items="attributeGroups"
                                        label="Attributgrupp"
                                        prepend-icon="folder_open"
                                        single-line
                                        return-object
                                        required
                                        :rules="[(v) => !!v || 'Du måste välja en grupp']"
                                        no-data-text="Inga attributgrupper finns tillgängliga"
                                        item-text="name"
                                ></v-select>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" text @click="closeDialog">Avbryt</v-btn>
                <v-btn :disabled="attributeGroups.length <= 0" color="primary" :loading="loading" text
                       @click="saveAttribute">Spara
                </v-btn>
                <div>
                    <p v-if="attributeGroups.length <= 0" class="error--text">Det måste finnas minst en attributgrupp
                        innan du kan lägga till ett attribut</p>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import api from "../../api/api"
import IconSelectComponent from "../global/IconSelectComponent";

export default {
        components: {IconSelectComponent},
        data: () => ({
            validForm: true,
            loading: false,
            attribute: {
                id: 0,
                name: "",
                description: "",
                hasValue: 0,
                valueUnit: "Antal",
                icon: "",
                active: 1,
                attributeGroup: undefined
            },
            attributeGroups: []
        }),
        watch: {
            async dialogIsOpen()
            {
                if (this.dialogIsOpen)
                {
                    if (this.attributeId > 0)
                    {
                        let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ATTRIBUTES + "/" + this.attributeId)
                        if (serverResponse)
                        {
                            Object.assign(this.attribute, serverResponse);
                        }
                    }
                    else
                    {
                        this.resetAttributeForm()
                    }
                    let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ATTRIBUTEGROUPS)
                    if (serverResponse)
                    {
                        this.attributeGroups = serverResponse
                    }
                }
            }
        },
        props: {
            attributeId: Number,
            dialogIsOpen: Boolean
        },
        computed: {
            titleText()
            {
                if (this.attributeId > 0)
                {
                    return "Redigerar " + this.attribute.name
                }
                return "Nytt attribut"
            }
        },
        methods: {
            resetAttributeForm()
            {
                Object.assign(this.attribute, {
                        id: 0,
                        name: "",
                        description: "",
                        hasValue: 0,
                        icon: "",
                        active: 1,
                        attributeGroup: undefined
                    }
                )
            },
            closeDialog()
            {
                this.$emit('update:dialogIsOpen', false)
            },
            async saveAttribute()
            {
                this.$refs.form.validate()
                if (this.validForm)
                {
                    this.$store.commit('setAppIsLoading', true)
                    let saveText = `${this.attribute.name}  skapad`
                    this.loading = true
                    if (this.attribute.id > 0)
                    {
                        saveText = `${this.attribute.name}  uppdaterad`
                        this.attribute = await api.putToEndpoint(api.ENDPOINTS.ATTRIBUTES + "/" + this.attribute.id, this.attribute)
                    }
                    else
                    {
                        this.attribute = await api.postToEndpoint(api.ENDPOINTS.ATTRIBUTE, this.attribute)
                    }
                    this.loading = false
                    this.$store.commit('setAppIsLoading', false)
                    if (this.attribute != null)
                    {
                        this.$store.commit('setSnackbar', {color: "success", text: saveText})
                        this.resetAttributeForm()
                        this.$emit('saved', this.attribute)
                        this.closeDialog()
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
