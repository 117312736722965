<template>
    <v-data-table
            :headers="headers"
            :items="customers"
            :search="search"
            disable-pagination
            hide-default-footer
            class="clickable-table"
            @click:row="selectCustomer"
            fixed-header
            :no-results-text="`Din sökning på ${search} gav inget resultat`"
    >

        <template v-slot:no-data>
            <no-data-component :text="noDataText" :icon="noDataIcon" :pulse="noDataPulse"></no-data-component>
        </template>
        <template v-slot:item.avatar="{ item }">
          <initials-avatar-component
              :imgSrc="getImageUrl(item)"
              :size="32"
              :firstname="item.firstname"
              :lastname="item.lastname"
          ></initials-avatar-component>

        </template>
        <template v-slot:item.email="{ item }">
            <v-tooltip bottom v-if="item.email.length > 0">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" color="secondary"
                           :to="{name: 'conversationsOverview', params: {customerId: item.id, newConversation: true}}">
                        <v-icon color="primary">mdi-message-text-outline</v-icon>
                    </v-btn>
                </template>
                <span>{{ item.email }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.phone="{ item }">
            <v-tooltip bottom v-if="item.phone.length > 0">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" color="secondary" @click.stop=""
                           :href="`tel:${item.phone}`">
                        <v-icon color="primary">mdi-phone</v-icon>
                    </v-btn>
                </template>
                <span>{{ item.phone }}</span>
            </v-tooltip>
        </template>
        <template v-if="showFavorite" v-slot:item.favourite="{ item }">
            <td>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on"
                               @click.stop="setCustomerAsMainContact(item)"
                               :color="item.mainContact ? 'primary':'grey'" icon>
                            <v-icon>{{item.mainContact ? "mdi-heart" : "mdi-heart-outline"}}</v-icon>
                        </v-btn>
                    </template>
                    <span>Markera som huvudkund</span>
                </v-tooltip>
            </td>
        </template>
    </v-data-table>
</template>

<script>
import api from "../../api/api"
import NoDataComponent from "../../components/global/NoDataComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
        components: {NoDataComponent, InitialsAvatarComponent},
        data: () => ({}),
        props: {
            customers: Array,
            search: String,
            noDataText: String,
            noDataIcon: String,
            noDataPulse: Boolean,
            showFavorite: Boolean
        },
        computed: {
            headers()
            {
                let defaultHeaders = [
                    {text: '', value: 'avatar', sortable: false},
                    {text: 'Förnamn', value: 'firstname'},
                    {text: 'Efternamn', value: 'lastname'},
                    {text: 'E-post', value: 'email'},
                    {text: 'Telefon', value: 'phone'},
                    {text: 'Kundnummer', value: 'id'},
                ]
                if (this.showFavorite)
                {
                    defaultHeaders.push({text: '', value: 'favourite'})
                }
                return defaultHeaders
            }
        },
        methods: {
            selectCustomer(customer)
            {
                this.$emit("selectCustomer", customer)
            },
            getImageUrl(item)
            {
                if (item.fileData && item.fileData.src)
                {
                    api.BASE_URL
                    return api.BASE_URL + item.fileData.src
                }
                return ""
            },
            setCustomerAsMainContact(customer)
            {
                if (customer.mainContact)
                {
                    customer.mainContact = false
                }
                else
                {
                    for (let i = 0; i < this.customers.length; i++)
                    {
                        this.customers[i].mainContact = false
                    }
                    customer.mainContact = true
                }
            },
        }
    }
</script>

<style scoped>

</style>
