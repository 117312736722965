const TYPES = {
    CUSTOMER_DETAILS_REQUEST: {
        value: 'CUSTOMER_DETAILS_REQUEST',
        text: "Förfrågan om kunduppgifter",
        color: "info"
    },
    BOOKING_PERIOD_BOOKED: {
        value: 'BOOKING_PERIOD_BOOKED',
        text: "Bokningsbekräftelse",
        color: "primary",
        template: "Det här är en bekräftelse på din bokning av (Namn på uthyrningsdatum), (Tid och datum incheckning & utcheckning).",
    },
    BOOKING_PERIOD_CANCELLED: {
        value: 'BOOKING_PERIOD_CANCELLED',
        text: "Avbokningsbekräftelse",
        color: "error",
        template: "",
    }
}
const TYPES_ARRAY = [
    TYPES.CUSTOMER_DETAILS_REQUEST,
    TYPES.BOOKING_PERIOD_BOOKED,
    TYPES.BOOKING_PERIOD_CANCELLED
]

export default {
    TYPES,
    TYPES_ARRAY
}
