<template>
    <span>
        <v-avatar v-if="!hideAvatar">
          <initials-avatar-component :fullname="bookingObject.name" :imgSrc="getImageUrl(bookingObject)"
                                     :size="30"
          ></initials-avatar-component>
        </v-avatar>
        <span v-if="!hideName || !hideNotes">
            <span v-if="!hideName">{{ bookingObject.name }}</span>
            <span v-if="!hideNotes">{{ bookingObject.notes }}</span>
        </span>
    </span>
</template>

<script>
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
        components: {InitialsAvatarComponent},
        props: {
            bookingObject: Object,
            hideName: Boolean,
            hideNotes: Boolean,
            hideAvatar: Boolean,
        },
        methods: {
            getImageUrl(item)
            {
                if (item.fileData && item.fileData.src)
                {
                    return api.BASE_URL + item.fileData.src
                }
                return ""
            }
        }
    }
</script>
