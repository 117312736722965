import store from "../store";

const actions = {
    UPDATE_UNREAD_MESSAGES: "UPDATE_UNREAD_MESSAGES"
}

function handleWebSocketMessage(message)
{
    switch (message.action)
    {
        case actions.UPDATE_UNREAD_MESSAGES:
            updateUnreadMessages()
            break
    }
}

function updateUnreadMessages()
{
    store.dispatch('updateUnreadMessages', store.state.user)
}


export default {
    handleWebSocketMessage
}
